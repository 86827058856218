import React from 'react';
import { useIntl } from 'react-intl';
import { Segment } from 'semantic-ui-react';
import messages from 'src/messages';
import MeshListPanel from 'src/pages/mesh/MeshLinksPanel';

function MeshLinksTable({ modified, needsRefresh, id }) {
  const { formatMessage } = useIntl();
  return (
    <>
      {modified || needsRefresh ? (
        <Segment inverted className="update-required">
          {formatMessage(messages.saveChangesPanel, {
            panelName: 'mesh links',
          })}
        </Segment>
      ) : null}
      <Segment basic disabled={modified}>
        <MeshListPanel
          apId={id}
          tableId="mesh_links_for_ap"
          pageSize={10}
        ></MeshListPanel>
      </Segment>
    </>
  );
}

export default MeshLinksTable;
