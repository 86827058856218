import React, { useRef } from 'react';
import { Cell, CellContext, Row } from '@tanstack/react-table';
import { SELECT_WIDTH } from './constants';
import { CellEditButton } from '../editing/CellEditButton';
import { valuesForChoicesKind, type TChoicesKind } from '../ColumnUtils';

// TODO move this somewhere that makes more sense
declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends unknown, TValue> {
    csv?: (cell: CellContext<any, number | 'N/A'>) => string | number;
    tdStyle?: React.CSSProperties;
    editable?: boolean;
    editor?: React.FunctionComponent<any>;
    editorLabel?: string;
    attr?: string;
    kind?: 'number' | 'range' | 'height' | 'string' | 'boolean';
    columnId?: string;
    getChoices?: (projectId: string, row: Row<TData>) => Promise<any>;
    editProps?: any;
    choicesKind?: TChoicesKind;
    boolOptions?: string[];
  }
}

export type TableRowProps<T> = {
  row: Row<T>;
  tooltip?: string;
  onShiftClick?: (rowId: string, shiftKey: boolean, ctrlKey?: boolean) => void;
};

export function TableCell<T>({
  children,
  cell,
  extraStyles,
  subRow,
}: {
  children: React.ReactNode;
  cell: Cell<T, unknown>;
  key: string;
  subRow?: Row<T>;
  extraStyles?: React.CSSProperties;
}) {
  const cellRef = useRef<HTMLTableCellElement>(null);
  const id = cell.column.columnDef.id;
  const isSticky = id === 'select' || id === 'name';
  const tdStyle = cell.column.columnDef.meta?.tdStyle ?? {};

  let className = [];
  if (isSticky) {
    className.push('lp-table-sticky');
  }
  if (cell.column.columnDef.meta?.editable) {
    className.push('lp-table-editable');
  }

  let actualRow;
  if (subRow != null) {
    actualRow = subRow;
  } else {
    actualRow = cell.row;
  }

  return (
    <td
      ref={cellRef}
      className={className.length > 0 ? className.join(' ') : undefined}
      style={{
        left: isSticky
          ? id === 'select'
            ? 0
            : `${SELECT_WIDTH}px`
          : undefined,
        ...tdStyle,
        ...(extraStyles ?? {}),
      }}
    >
      {cell.column.columnDef.meta?.editable && (
        <CellEditButton
          meta={cell.column.columnDef.meta}
          cellRef={cellRef}
          subRow={subRow}
          {...valuesForChoicesKind(
            cell.column.columnDef.meta?.choicesKind,
            actualRow.original as any,
            false
          )}
        />
      )}
      {children}
    </td>
  );
}
