import { get } from 'lodash';
import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form, Label } from 'semantic-ui-react';
import StoredAccordion from 'src/components/StoredAccordion';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import LimitControl from 'src/pages/ptp/LimitControl';
import { PMPPanelProps } from './utils';
import { checkErrors } from './configs/config-utils';
import { getWarningLabel } from './NDAntennaPanel';
import { FormResetContext } from '../utils';

function NDPowerPanel({
  ap,
  sm,
  choices,
  panel,
  setModified,
  modified,
  refreshChoices,
}: PMPPanelProps) {
  const resetValue = useContext(FormResetContext);
  const {
    control,
    getValues,
    setValue,
    getFieldState,
    trigger,
    formState: { dirtyFields },
  } = useFormContext();
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const errors = checkErrors({
    panel,
    getValues,
    getFieldState,
    ap,
    sm,
    setValue,
    choices,
  });
  const { name, fields, show, title } = panel;
  if (show && !show({ formGetter: getValues, choices, ap })) return <></>;
  const product = getValues('radios.0.equipment.product');
  return (
    <StoredAccordion
      name={`nd_${name}`}
      key={`nd_${name}`}
      title={title}
      headerClass={errors ? 'custom-error' : ''}
      contentProps={{ loading: ap.dirty || sm?.dirty }}
    >
      {fields.map((field, i) => {
        const {
          attrName,
          label,
          afterOnChange,
          getter,
          component,
          show,
          units,
          componentProps,
          min,
          max,
          defaultValue,
          reCalculate,
          editable = true,
          warning,
          info,
          infoColor,
        } = field;
        if (
          show != null &&
          !show({ ap, choices, formSetter: setValue, formGetter: getValues })
        ) {
          return null;
        }
        let ctrl: any = null;
        let cprops = componentProps;
        let maxValue = max;
        let minValue = min;
        let derivedVal = get(ap, getter);
        if (typeof max === 'function') {
          maxValue = max({ ap, choices, formGetter: getValues });
        }
        if (typeof min === 'function') {
          minValue = min({ ap, choices, formGetter: getValues });
        }
        if (typeof defaultValue === 'function') {
          derivedVal = defaultValue({ choices, ap, formGetter: getValues });
        }
        if (typeof componentProps === 'function') {
          cprops = componentProps({
            ap,
            sm,
            choices,
            formGetter: getValues,
            refreshChoices: refreshChoices,
            formSetter: setValue,
          });
        }
        const warningContent =
          warning && warning({ ap, sm, choices, formGetter: getValues });
        let infoLabel: any = null;
        if (info != null) {
          const text = info({ ap, sm, choices, formGetter: getValues });
          if (text != null) {
            infoLabel = (
              <Label
                color={infoColor ?? 'yellow'}
                pointing
                style={{ marginTop: 0, width: '100%' }}
              >
                {text}
              </Label>
            );
          }
        }

        if (component === Form.Select) {
          ctrl = (
            <Controller
              key={getter}
              control={control}
              name={getter}
              render={({ field: { ref, onChange, ...rest } }) => (
                <Form.Select
                  {...rest}
                  {...field}
                  {...cprops}
                  disabled={!permissionWrite}
                  onChange={(e, data) => {
                    onChange(data.value);
                    setModified(true);
                    trigger();
                  }}
                />
              )}
            />
          );
        } else if (component === Form.Checkbox) {
          ctrl = (
            <Controller
              key={getter}
              name={getter}
              defaultValue={derivedVal}
              control={control}
              render={({ field: { onChange, value, ...rest } }) => (
                <Form.Checkbox
                  label={label}
                  checked={value}
                  disabled={!permissionWrite}
                  onChange={(e, { checked }) => {
                    setModified(true);
                    onChange(checked);
                    if (afterOnChange)
                      afterOnChange(checked, { getValues, setValue }, choices);
                  }}
                ></Form.Checkbox>
              )}
            ></Controller>
          );
        } else if (component === GenericScaledField) {
          ctrl = (
            <GenericScaledField
              {...field}
              {...cprops}
              disabled={!editable || !permissionWrite}
              units={units}
              min={minValue}
              watch={
                attrName === 'sm_target_level'
                  ? [ap.id, product, resetValue]
                  : [ap.id, resetValue]
              }
              max={maxValue}
              key={getter}
              defaultValue={derivedVal}
              recalc={reCalculate && modified}
              setModified={setModified}
            ></GenericScaledField>
          );
        } else if (component === LimitControl) {
          ctrl = (
            <LimitControl
              {...field}
              choices={choices}
              modified={modified}
              disabled={!permissionWrite}
              key={field.valueGetter}
              type="PMP"
              componentProps={{ watch: resetValue }}
              path={sm != null ? sm : ap}
              min={minValue}
              max={maxValue}
              setModified={setModified}
              refreshChoices={refreshChoices}
            ></LimitControl>
          );
        }
        return (
          <Form.Field key={`${field.getter}-field-${i}`}>
            {ctrl}
            {/* {message} */}
            {warningContent && getWarningLabel(warningContent, modified)}
            {infoLabel}
          </Form.Field>
        );
      })}
    </StoredAccordion>
  );
}

export default NDPowerPanel;
