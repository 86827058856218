import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { type ColumnMeta, type Row } from '@tanstack/react-table';
import { Loader } from 'semantic-ui-react';
import AntennaSelection from 'src/pages/equipment/common/AntennaSelection';

type TProps<T extends { id: string }, U> = {
  getChoices: (
    projectId: string,
    row: Row<T>
  ) => Promise<{ choices: string[] }>;
  projectId: string;
  row: Row<T>;
  meta: ColumnMeta<T, U>;
  value: U;
  setValue: (value: U) => void;
  setAntennaCanClose: (canClose: boolean) => void;
};

export function AntennaEditor<
  T extends { id: string },
  U extends string | number | boolean
>({ getChoices, projectId, row, meta, value, setValue, setAntennaCanClose }: TProps<T, U>) {
  const { editorLabel: label, attr } = meta;
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'bulk_edit',
      'choices',
      projectId,
      meta.choicesKind,
      row.original.id,
      attr,
    ],
    queryFn: () => getChoices(projectId, row),
    cacheTime: 0,
    staleTime: 0,
  });

  if (isLoading) {
    return <Loader active inline="centered" />;
  }

  const { choices } = data ?? {};
  const internalValue: any = choices.find(
    (choice: any) => choice.data.display_name === value
  );

  let componentKind: string = meta.choicesKind;
  if (meta.choicesKind === 'access_point') {
    componentKind = 'ap';
  } else if (meta.choicesKind === 'subscriber') {
    componentKind = 'sm';
  }

  return (
    <div>
      <label
        style={{ fontWeight: 'bold', marginRight: '1rem', display: 'block' }}
      >
        {label}
      </label>
      {isError ? (
        <p>Error loading choices</p>
      ) : choices.length === 0 ? (
        <p>No choices available</p>
      ) : (
        <AntennaSelection
          kind={componentKind}
          value={internalValue.id}
          choices={choices}
          onChange={(_, { value }) => {
            const antenna = choices.find((choice: any) => choice.id === value);
            setValue((antenna as any).data.display_name as U);
          }}
          style={{ marginBottom: '0.5rem' }}
          warning={null}
          modified={false}
          hideLabel={true}
          defaultOpen={true}
          closeCallback={setAntennaCanClose}
        />
      )}
    </div>
  );
}
