// This file is automatically generated by backend/app/scripts/generate_columns.py
import { createColumnHelper } from '@tanstack/react-table';
import * as U from './ColumnUtils';
import * as E from './editing/editors';

export type PTPTableEndData = {
  antenna?: string;
  annual1_way_availability?: number | 'N/A';
  antenna_height?: number | 'N/A';
  rx_antenna_gain?: number | 'N/A';
  bearing?: number | 'N/A';
  gas_group?: string;
  eirp?: number | 'N/A';
  power?: number | 'N/A';
  user_eirp?: number | 'N/A';
  use_user_eirp?: boolean | 'N/A';
  user_power?: number | 'N/A';
  use_user_power?: boolean | 'N/A';
  use_noise?: boolean | 'N/A';
  noise?: number | 'N/A';
  tilt?: number | 'N/A';
  ethernet_cable?: string;
  feeder_loss?: number | 'N/A';
  predicted_receive_power?: string;
  mean_data_rate_predicted?: number | 'N/A';
  mean_data_rate_requirement?: number | 'N/A';
  percentage_of_required_data_rate?: number | 'N/A';
  throughput_reliability?: number | 'N/A';
  minimum_data_rate_requirement?: number | 'N/A';
  minimum_reliability_requirement?: number | 'N/A';
  transmit_frequency?: number | 'N/A';
  tx_golay?: string;
  polarity?: string;
  diversity_spacing?: number | 'N/A';
  clutter_type?: string;
  latitude?: number | 'N/A';
  longitude?: number | 'N/A';
  mac_address?: string;
  max_height?: number | 'N/A';
  height?: number | 'N/A';
  name?: string;
}

export type PTPTableData = {
  local?: PTPTableEndData;
  remote?: PTPTableEndData;
  id?: string;
  path_index?: number;
  name?: string;
  description?: string;
  range_m?: number | 'N/A';
  percentage_of_required_data_rate?: number | 'N/A';
  fresnel_zone_clearance?: number | 'N/A';
  band?: string;
  product?: string;
  link_protection?: string;
  master_end?: string;
  regulation?: string;
  downlink_data?: number | 'N/A';
  frame_period?: number | 'N/A';
  frame_size?: string;
  link_availability?: number | 'N/A';
  system_fade_margin?: number | 'N/A';
  npoints?: number | 'N/A';
  profile_type?: string;
  mean_aggregate_data_rate?: number | 'N/A';
  remote_product?: string;
  bandwidth?: number | 'N/A';
  dl_ul_ratio?: string;
  max_mod_mode?: string;
  aes_encryption?: string;
  guard_interval?: string;
  tdm?: string;
  tdm_type?: string;
  lowest_ethernet_mode?: string;
  lowest_telecoms_mode?: string;
  sync?: string;
  optimisation?: string;
  symmetry?: string;
  dual_payload?: string;
  highest_mod_mode?: string;
  precise_network_timing?: string;
  acmb?: string;
  atpc?: string;
  hi?: string;
  lo?: string;
  header_compression?: string;
  min_mod_mode?: string;
  modulation_mode?: string;
  tr_spacing?: string;
  polarization?: string;
  remote_mount?: string;
  dn_dh?: number | 'N/A';
  area_roughness?: number | 'N/A';
  geo_climatic_factor?: string;
  fade_occurrence_factor?: number | 'N/A';
  inclination?: number | 'N/A';
  terrain_roughness?: number | 'N/A';
  climatic_factor?: number | 'N/A';
  c_factor?: string;
  temperature?: number | 'N/A';
  worst_earth_ke?: number | 'N/A';
  free_space_path_loss?: number | 'N/A';
  gaseous_obsorption_loss?: number | 'N/A';
  excess_path_loss?: number | 'N/A';
  excess_path_loss_at_ke?: number | 'N/A';
  total_path_loss?: number | 'N/A';
  rain_rate?: string;
  rain_attenuation?: number | 'N/A';
  rain_availability?: number | 'N/A';
  rain_unavailability?: string;
  annual_2_way_availability?: number | 'N/A';
  annual_2_way_availability_with_rain?: number | 'N/A';
  annual_2_way_unavailability?: string;
  annual_2_way_unavailability_with_rain?: string;
  e1t1_reliability?: number | 'N/A';
  e1t1_reliability_requirement?: number | 'N/A';
  e1t1_latency?: number | 'N/A';
  power_from_switch?: string;
  min_payload_capacity?: number | 'N/A';
  min_payload_mode?: string;
}

const columnHelper = createColumnHelper<PTPTableData>()

export const columns = [
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Name',
    aggregationFn: 'firstChild',
    getGroupingValue: U.groupById<PTPTableData>,
    cell: U.renderLinkName<PTPTableData>,
    aggregatedCell: U.renderGroupedLinkName<PTPTableData>,
    size: 300,
    enableHiding: false,
    sortingFn: 'basic',
  }),
  columnHelper.accessor('description', {
    id: 'description',
    header: 'Description',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'description',
      columnId: 'description',
      editorLabel: 'Description',
      kind: 'string',
      editor: E.TextEditor,
    },
  }),
  columnHelper.accessor('range_m', {
    id: 'range_m',
    header: U.headerWithRangeUnits('Range'),
    filterFn: U.rangeFilterFn({ precision: 3 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3, prefsKey: 'rangeUnits' }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3, prefsKey: 'rangeUnits' }),
    },
  }),
  columnHelper.accessor('percentage_of_required_data_rate', {
    id: 'percentage_of_required_data_rate',
    header: '% req. Throughput',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
    },
  }),
  columnHelper.accessor('fresnel_zone_clearance', {
    id: 'fresnel_zone_clearance',
    header: U.headerWithHeightUnits('Fresnel Zone Clearance'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('band', {
    id: 'band',
    header: 'Band',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'band',
      columnId: 'band',
      editorLabel: 'Band',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'band'),
    },
  }),
  columnHelper.accessor('product', {
    id: 'product',
    header: 'Product',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'product',
      columnId: 'product',
      editorLabel: 'Product',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'product'),
    },
  }),
  columnHelper.accessor('link_protection', {
    id: 'link_protection',
    header: 'Link Type',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'link_protection',
      columnId: 'link_protection',
      editorLabel: 'Link Type',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'link_protection'),
    },
  }),
  columnHelper.accessor('master_end', {
    id: 'master_end',
    header: 'Master End',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'master_end',
      columnId: 'master_end',
      editorLabel: 'Master End',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'master_end'),
    },
  }),
  columnHelper.accessor('regulation', {
    id: 'regulation',
    header: 'Regulation',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'regulation',
      columnId: 'regulation',
      editorLabel: 'Regulation',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'regulation'),
    },
  }),
  columnHelper.accessor('downlink_data', {
    id: 'downlink_data',
    header: 'Downlink Data (%)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'downlink_data',
      columnId: 'downlink_data',
      editorLabel: 'Downlink Data (%)',
      kind: 'number',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'downlink_data'),
    },
  }),
  columnHelper.accessor('frame_period', {
    id: 'frame_period',
    header: 'Frame Period',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'frame_period',
      columnId: 'frame_period',
      editorLabel: 'Frame Period',
      kind: 'number',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'frame_period'),
    },
  }),
  columnHelper.accessor('frame_size', {
    id: 'frame_size',
    header: 'Frame Size',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('link_availability', {
    id: 'link_availability',
    header: 'Link Availability',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
    },
  }),
  columnHelper.accessor('system_fade_margin', {
    id: 'system_fade_margin',
    header: 'System Gain Margin (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('npoints', {
    id: 'npoints',
    header: '# Points in Profile',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('profile_type', {
    id: 'profile_type',
    header: 'Profile Type',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('mean_aggregate_data_rate', {
    id: 'mean_aggregate_data_rate',
    header: 'Aggregate Throughput (Mbps)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
    },
  }),
  columnHelper.accessor('remote_product', {
    id: 'remote_product',
    header: 'Remote Product',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote_product',
      columnId: 'remote_product',
      editorLabel: 'Remote Product',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'remote_product'),
    },
  }),
  columnHelper.accessor('bandwidth', {
    id: 'bandwidth',
    header: 'Bandwidth (MHz)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'bandwidth',
      columnId: 'bandwidth',
      editorLabel: 'Bandwidth (MHz)',
      kind: 'number',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'bandwidth'),
    },
  }),
  columnHelper.accessor('dl_ul_ratio', {
    id: 'dl_ul_ratio',
    header: 'DL/UL Ratio',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'dl_ul_ratio',
      columnId: 'dl_ul_ratio',
      editorLabel: 'DL/UL Ratio',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'dl_ul_ratio'),
    },
  }),
  columnHelper.accessor('max_mod_mode', {
    id: 'max_mod_mode',
    header: 'Maximum Mod Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'max_mod_mode',
      columnId: 'max_mod_mode',
      editorLabel: 'Maximum Mod Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'max_mod_mode'),
    },
  }),
  columnHelper.accessor('aes_encryption', {
    id: 'aes_encryption',
    header: 'Encryption Variant',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'aes_encryption',
      columnId: 'aes_encryption',
      editorLabel: 'Encryption Variant',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'aes_encryption'),
    },
  }),
  columnHelper.accessor('guard_interval', {
    id: 'guard_interval',
    header: 'Guard Interval',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'guard_interval',
      columnId: 'guard_interval',
      editorLabel: 'Guard Interval',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'guard_interval'),
    },
  }),
  columnHelper.accessor('tdm', {
    id: 'tdm',
    header: 'E1/T1',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'tdm',
      columnId: 'tdm',
      editorLabel: 'E1/T1',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'tdm'),
    },
  }),
  columnHelper.accessor('tdm_type', {
    id: 'tdm_type',
    header: 'TDM Type',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'tdm_type',
      columnId: 'tdm_type',
      editorLabel: 'TDM Type',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'tdm_type'),
    },
  }),
  columnHelper.accessor('lowest_ethernet_mode', {
    id: 'lowest_ethernet_mode',
    header: 'Lowest Ethernet Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'lowest_ethernet_mode',
      columnId: 'lowest_ethernet_mode',
      editorLabel: 'Lowest Ethernet Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'lowest_ethernet_mode'),
    },
  }),
  columnHelper.accessor('lowest_telecoms_mode', {
    id: 'lowest_telecoms_mode',
    header: 'Lowest Telecoms Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'lowest_telecoms_mode',
      columnId: 'lowest_telecoms_mode',
      editorLabel: 'Lowest Telecoms Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'lowest_telecoms_mode'),
    },
  }),
  columnHelper.accessor('sync', {
    id: 'sync',
    header: 'Sync',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'sync',
      columnId: 'sync',
      editorLabel: 'Sync',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'sync'),
    },
  }),
  columnHelper.accessor('optimisation', {
    id: 'optimisation',
    header: 'Optimization',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'optimisation',
      columnId: 'optimisation',
      editorLabel: 'Optimization',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'optimisation'),
    },
  }),
  columnHelper.accessor('symmetry', {
    id: 'symmetry',
    header: 'Symmetry',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'symmetry',
      columnId: 'symmetry',
      editorLabel: 'Symmetry',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'symmetry'),
    },
  }),
  columnHelper.accessor('dual_payload', {
    id: 'dual_payload',
    header: 'Dual Payload',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'dual_payload',
      columnId: 'dual_payload',
      editorLabel: 'Dual Payload',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'dual_payload'),
    },
  }),
  columnHelper.accessor('highest_mod_mode', {
    id: 'highest_mod_mode',
    header: 'Highest Mod Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'highest_mod_mode',
      columnId: 'highest_mod_mode',
      editorLabel: 'Highest Mod Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'highest_mod_mode'),
    },
  }),
  columnHelper.accessor('precise_network_timing', {
    id: 'precise_network_timing',
    header: 'Precise Network Timing',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'precise_network_timing',
      columnId: 'precise_network_timing',
      editorLabel: 'Precise Network Timing',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'precise_network_timing'),
    },
  }),
  columnHelper.accessor('acmb', {
    id: 'acmb',
    header: 'ACMB',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'acmb',
      columnId: 'acmb',
      editorLabel: 'ACMB',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'acmb'),
    },
  }),
  columnHelper.accessor('atpc', {
    id: 'atpc',
    header: 'ATPC',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'atpc',
      columnId: 'atpc',
      editorLabel: 'ATPC',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'atpc'),
    },
  }),
  columnHelper.accessor('hi', {
    id: 'hi',
    header: 'Hi End',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'hi',
      columnId: 'hi',
      editorLabel: 'Hi End',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'hi'),
    },
  }),
  columnHelper.accessor('lo', {
    id: 'lo',
    header: 'Lo End',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('header_compression', {
    id: 'header_compression',
    header: 'Header Compression',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'header_compression',
      columnId: 'header_compression',
      editorLabel: 'Header Compression',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'header_compression'),
    },
  }),
  columnHelper.accessor('min_mod_mode', {
    id: 'min_mod_mode',
    header: 'Minimum Mod Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'min_mod_mode',
      columnId: 'min_mod_mode',
      editorLabel: 'Minimum Mod Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'min_mod_mode'),
    },
  }),
  columnHelper.accessor('modulation_mode', {
    id: 'modulation_mode',
    header: 'Modulation Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'modulation_mode',
      columnId: 'modulation_mode',
      editorLabel: 'Modulation Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'modulation_mode'),
    },
  }),
  columnHelper.accessor('tr_spacing', {
    id: 'tr_spacing',
    header: 'T/R Spacing (MHz)',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'tr_spacing',
      columnId: 'tr_spacing',
      editorLabel: 'T/R Spacing (MHz)',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'tr_spacing'),
    },
  }),
  columnHelper.accessor('polarization', {
    id: 'polarization',
    header: 'Polarization',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'polarization',
      columnId: 'polarization',
      editorLabel: 'Polarization',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'polarization'),
    },
  }),
  columnHelper.accessor('remote_mount', {
    id: 'remote_mount',
    header: 'Remote Mount',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote_mount',
      columnId: 'remote_mount',
      editorLabel: 'Remote Mount',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'remote_mount'),
    },
  }),
  columnHelper.accessor('dn_dh', {
    id: 'dn_dh',
    header: 'dN/dH (N units/km)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('area_roughness', {
    id: 'area_roughness',
    header: 'Area Roughness(m)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('geo_climatic_factor', {
    id: 'geo_climatic_factor',
    header: 'Geoclimatic Factor',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('fade_occurrence_factor', {
    id: 'fade_occurrence_factor',
    header: 'Fade Occurrence Factor (Po)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('inclination', {
    id: 'inclination',
    header: 'Inclination (mr)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('terrain_roughness', {
    id: 'terrain_roughness',
    header: 'Terrain Roughness',
    filterFn: U.heightFilterFn({ precision: 0 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('climatic_factor', {
    id: 'climatic_factor',
    header: 'Climatic Factor',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('c_factor', {
    id: 'c_factor',
    header: 'C Factor',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('temperature', {
    id: 'temperature',
    header: 'Temperature',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, converter: U.temperatureFromKelvin_Table }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, converter: U.temperatureFromKelvin_Table }),
    },
  }),
  columnHelper.accessor('worst_earth_ke', {
    id: 'worst_earth_ke',
    header: 'Worst Earth (ke)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('free_space_path_loss', {
    id: 'free_space_path_loss',
    header: 'Free Space Path Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('gaseous_obsorption_loss', {
    id: 'gaseous_obsorption_loss',
    header: 'Gaseous Absorption Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('excess_path_loss', {
    id: 'excess_path_loss',
    header: 'Excess Path Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, nullValue: '0' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('excess_path_loss_at_ke', {
    id: 'excess_path_loss_at_ke',
    header: 'Excess Path Loss at ke (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, nullValue: '0' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('total_path_loss', {
    id: 'total_path_loss',
    header: 'Link Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('rain_rate', {
    id: 'rain_rate',
    header: '0.01% Rain Rate',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('rain_attenuation', {
    id: 'rain_attenuation',
    header: 'Rain Attenuation (dB/km)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('rain_availability', {
    id: 'rain_availability',
    header: 'Rain Availability %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4, nullValue: '0' }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('rain_unavailability', {
    id: 'rain_unavailability',
    header: 'Rain Unavailability',
    aggregationFn: 'firstChild',
    sortingFn: U.sortAvailabilityTime,
  }),
  columnHelper.accessor('annual_2_way_availability', {
    id: 'annual_2_way_availability',
    header: 'Annual 2-way Availability %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
    },
  }),
  columnHelper.accessor('annual_2_way_availability_with_rain', {
    id: 'annual_2_way_availability_with_rain',
    header: 'Annual 2-way Availability Including Rain %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    size: 85,
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('annual_2_way_unavailability', {
    id: 'annual_2_way_unavailability',
    header: 'Annual 2-way Unavailability',
    aggregationFn: 'firstChild',
    sortingFn: U.sortAvailabilityTime,
  }),
  columnHelper.accessor('annual_2_way_unavailability_with_rain', {
    id: 'annual_2_way_unavailability_with_rain',
    header: 'Annual 2-way Unavailability Including Rain',
    aggregationFn: 'firstChild',
    sortingFn: U.sortAvailabilityTime,
  }),
  columnHelper.accessor('e1t1_reliability', {
    id: 'e1t1_reliability',
    header: 'E1/T1 Availability %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('e1t1_reliability_requirement', {
    id: 'e1t1_reliability_requirement',
    header: 'E1/T1 Availability Required %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'e1t1_reliability_requirement',
      columnId: 'e1t1_reliability_requirement',
      editorLabel: 'E1/T1 Availability Required %',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        max: 100,
        precision: 4,
      },
    },
  }),
  columnHelper.accessor('e1t1_latency', {
    id: 'e1t1_latency',
    header: 'E1/T1 1-way latency (ms)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('power_from_switch', {
    id: 'power_from_switch',
    header: 'Power from Switch',
    size: 60,
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('min_payload_capacity', {
    id: 'min_payload_capacity',
    header: 'FCC Minimum Payload Availability',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
    },
  }),
  columnHelper.accessor('min_payload_mode', {
    id: 'min_payload_mode',
    header: 'FCC Minimum Payload Mode',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('local.antenna', {
    id: 'local_antenna',
    header: 'Left Antenna',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'local.antenna',
      columnId: 'local_antenna',
      editorLabel: 'Left Antenna',
      kind: 'string',
      editor: E.AntennaEditor,
      getChoices: E.getChoices('ptp', 'local_antenna'),
    },
  }),
  columnHelper.accessor('local.annual1_way_availability', {
    id: 'local_annual1_way_availability',
    header: 'Left Annual 1-way Availability %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4, nullValue: '0' }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('local.antenna_height', {
    id: 'local_antenna_height',
    header: U.headerWithHeightUnits('Left Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'local.antenna_height',
      columnId: 'local_antenna_height',
      editorLabel: 'Left Height',
      kind: 'height',
      editor: E.NumberEditor,
      editProps: {
        units: 'heightUnits',
        min: 0,
        max: 3000,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('local.rx_antenna_gain', {
    id: 'local_rx_antenna_gain',
    header: 'Left Gain (dBi)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, nullValue: '0' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('local.bearing', {
    id: 'local_bearing',
    header: 'Left Bearing, True N (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('local.gas_group', {
    id: 'local_gas_group',
    header: 'Left Gas Group',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'local.gas_group',
      columnId: 'local_gas_group',
      editorLabel: 'Left Gas Group',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'local_gas_group'),
    },
  }),
  columnHelper.accessor('local.eirp', {
    id: 'local_eirp',
    header: 'Left Max EIRP (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('local.power', {
    id: 'local_power',
    header: 'Left Max Power (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('local.user_eirp', {
    id: 'local_user_eirp',
    header: 'Left EIRP Limit (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'local.user_eirp',
      columnId: 'local_user_eirp',
      editorLabel: 'Left EIRP Limit (dBm)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: -10,
        max: 90,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('local.use_user_eirp', {
    id: 'local_use_user_eirp',
    header: 'Left User EIRP Limit?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'local.use_user_eirp',
      columnId: 'local_use_user_eirp',
      editorLabel: 'Left User EIRP Limit?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('ptp', 'local_use_user_eirp'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('local.user_power', {
    id: 'local_user_power',
    header: 'Left Power Limit (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'local.user_power',
      columnId: 'local_user_power',
      editorLabel: 'Left Power Limit (dBm)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: -40,
        max: 40,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('local.use_user_power', {
    id: 'local_use_user_power',
    header: 'Left User Power Limit?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'local.use_user_power',
      columnId: 'local_use_user_power',
      editorLabel: 'Left User Power Limit?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('ptp', 'local_use_user_power'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('local.use_noise', {
    id: 'local_use_noise',
    header: 'Left Interference?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'local.use_noise',
      columnId: 'local_use_noise',
      editorLabel: 'Left Interference?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('ptp', 'local_use_noise'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('local.noise', {
    id: 'local_noise',
    header: 'Left Interference (dBm in b/w)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, nullValue: '' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, nullValue: '' }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'local.noise',
      columnId: 'local_noise',
      editorLabel: 'Left Interference (dBm in b/w)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: -144,
        max: -40,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('local.tilt', {
    id: 'local_tilt',
    header: 'Left Antenna Tilt (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('local.ethernet_cable', {
    id: 'local_ethernet_cable',
    header: 'Left Ethernet Cable',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('local.feeder_loss', {
    id: 'local_feeder_loss',
    header: 'Left Feeder Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('local.predicted_receive_power', {
    id: 'local_predicted_receive_power',
    header: 'Left Predicted Receive Power',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('local.mean_data_rate_predicted', {
    id: 'local_mean_data_rate_predicted',
    header: 'Left Mean IP Predicted (Mbps)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
    },
  }),
  columnHelper.accessor('local.mean_data_rate_requirement', {
    id: 'local_mean_data_rate_requirement',
    header: 'Left Mean IP Required (Mbps)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'local.mean_data_rate_requirement',
      columnId: 'local_mean_data_rate_requirement',
      editorLabel: 'Left Mean IP Required (Mbps)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        precision: 3,
      },
    },
  }),
  columnHelper.accessor('local.percentage_of_required_data_rate', {
    id: 'local_percentage_of_required_data_rate',
    header: 'Left % of Required IP',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
    },
  }),
  columnHelper.accessor('local.throughput_reliability', {
    id: 'local_throughput_reliability',
    header: 'Left Min IP Availability Predicted (%)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4, nullValue: '0' }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('local.minimum_data_rate_requirement', {
    id: 'local_minimum_data_rate_requirement',
    header: 'Left Min IP Required (Mbps)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'local.minimum_data_rate_requirement',
      columnId: 'local_minimum_data_rate_requirement',
      editorLabel: 'Left Min IP Required (Mbps)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        precision: 3,
      },
    },
  }),
  columnHelper.accessor('local.minimum_reliability_requirement', {
    id: 'local_minimum_reliability_requirement',
    header: 'Left Min IP Availability Required %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'local.minimum_reliability_requirement',
      columnId: 'local_minimum_reliability_requirement',
      editorLabel: 'Left Min IP Availability Required %',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        max: 100,
        precision: 4,
      },
    },
  }),
  columnHelper.accessor('local.transmit_frequency', {
    id: 'local_transmit_frequency',
    header: 'Left Tx Frequency (MHz)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2, nullValue: '' }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2, nullValue: '' }),
    },
  }),
  columnHelper.accessor('local.tx_golay', {
    id: 'local_tx_golay',
    header: 'Left Golay',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('local.polarity', {
    id: 'local_polarity',
    header: 'Left Polarity',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('local.diversity_spacing', {
    id: 'local_diversity_spacing',
    header: U.headerWithHeightUnits('Left Diversity Spacing'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('local.clutter_type', {
    id: 'local_clutter_type',
    header: 'Left Clutter Type',
    cell: U.renderConvertedString({ converter: U.clutterTypeGetter_Table }),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('local.latitude', {
    id: 'local_latitude',
    header: 'Left Latitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 5, converter: U.latitude_Table }),
    size: 140,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 5, converter: U.latitude_Table }),
    },
  }),
  columnHelper.accessor('local.longitude', {
    id: 'local_longitude',
    header: 'Left Longitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 5, converter: U.longitude_Table }),
    size: 140,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 5, converter: U.longitude_Table }),
    },
  }),
  columnHelper.accessor('local.mac_address', {
    id: 'local_mac_address',
    header: 'Left MAC Address',
    cell: U.renderConvertedString({ converter: U.macAddressGetter_Table }),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'local.mac_address',
      columnId: 'local_mac_address',
      editorLabel: 'Left MAC Address',
      kind: 'string',
      editor: E.NumberEditor,
      editProps: {
        units: 'macAddressFormat',
      },
    },
  }),
  columnHelper.accessor('local.max_height', {
    id: 'local_max_height',
    header: U.headerWithHeightUnits('Left Max Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('local.height', {
    id: 'local_height',
    header: U.headerWithHeightUnits('Left Ground Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('local.name', {
    id: 'local_name',
    header: 'Left Name',
    aggregationFn: 'firstChild',
    sortingFn: 'basic',
  }),
  columnHelper.accessor('remote.antenna', {
    id: 'remote_antenna',
    header: 'Right Antenna',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote.antenna',
      columnId: 'remote_antenna',
      editorLabel: 'Right Antenna',
      kind: 'string',
      editor: E.AntennaEditor,
      getChoices: E.getChoices('ptp', 'remote_antenna'),
    },
  }),
  columnHelper.accessor('remote.annual1_way_availability', {
    id: 'remote_annual1_way_availability',
    header: 'Right Annual 1-way Availability %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4, nullValue: '0' }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('remote.antenna_height', {
    id: 'remote_antenna_height',
    header: U.headerWithHeightUnits('Right Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote.antenna_height',
      columnId: 'remote_antenna_height',
      editorLabel: 'Right Height',
      kind: 'height',
      editor: E.NumberEditor,
      editProps: {
        units: 'heightUnits',
        min: 0,
        max: 3000,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('remote.rx_antenna_gain', {
    id: 'remote_rx_antenna_gain',
    header: 'Right Gain (dBi)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, nullValue: '0' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('remote.bearing', {
    id: 'remote_bearing',
    header: 'Right Bearing, True N (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('remote.gas_group', {
    id: 'remote_gas_group',
    header: 'Right Gas Group',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote.gas_group',
      columnId: 'remote_gas_group',
      editorLabel: 'Right Gas Group',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('ptp', 'remote_gas_group'),
    },
  }),
  columnHelper.accessor('remote.eirp', {
    id: 'remote_eirp',
    header: 'Right Max EIRP (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('remote.power', {
    id: 'remote_power',
    header: 'Right Max Power (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('remote.user_eirp', {
    id: 'remote_user_eirp',
    header: 'Right EIRP Limit (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote.user_eirp',
      columnId: 'remote_user_eirp',
      editorLabel: 'Right EIRP Limit (dBm)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: -10,
        max: 90,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('remote.use_user_eirp', {
    id: 'remote_use_user_eirp',
    header: 'Right User EIRP Limit?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote.use_user_eirp',
      columnId: 'remote_use_user_eirp',
      editorLabel: 'Right User EIRP Limit?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('ptp', 'remote_use_user_eirp'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('remote.user_power', {
    id: 'remote_user_power',
    header: 'Right Power Limit (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote.user_power',
      columnId: 'remote_user_power',
      editorLabel: 'Right Power Limit (dBm)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: -40,
        max: 40,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('remote.use_user_power', {
    id: 'remote_use_user_power',
    header: 'Right User Power Limit?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote.use_user_power',
      columnId: 'remote_use_user_power',
      editorLabel: 'Right User Power Limit?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('ptp', 'remote_use_user_power'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('remote.use_noise', {
    id: 'remote_use_noise',
    header: 'Right Interference?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote.use_noise',
      columnId: 'remote_use_noise',
      editorLabel: 'Right Interference?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('ptp', 'remote_use_noise'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('remote.noise', {
    id: 'remote_noise',
    header: 'Right Interference (dBm in b/w)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, nullValue: '' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, nullValue: '' }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote.noise',
      columnId: 'remote_noise',
      editorLabel: 'Right Interference (dBm in b/w)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: -144,
        max: -40,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('remote.tilt', {
    id: 'remote_tilt',
    header: 'Right Antenna Tilt (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('remote.ethernet_cable', {
    id: 'remote_ethernet_cable',
    header: 'Right Ethernet Cable',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('remote.feeder_loss', {
    id: 'remote_feeder_loss',
    header: 'Right Feeder Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('remote.predicted_receive_power', {
    id: 'remote_predicted_receive_power',
    header: 'Right Predicted Receive Power',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('remote.mean_data_rate_predicted', {
    id: 'remote_mean_data_rate_predicted',
    header: 'Right Mean IP Predicted (Mbps)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
    },
  }),
  columnHelper.accessor('remote.mean_data_rate_requirement', {
    id: 'remote_mean_data_rate_requirement',
    header: 'Right Mean IP Required (Mbps)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote.mean_data_rate_requirement',
      columnId: 'remote_mean_data_rate_requirement',
      editorLabel: 'Right Mean IP Required (Mbps)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        precision: 3,
      },
    },
  }),
  columnHelper.accessor('remote.percentage_of_required_data_rate', {
    id: 'remote_percentage_of_required_data_rate',
    header: 'Right % of Required IP',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
    },
  }),
  columnHelper.accessor('remote.throughput_reliability', {
    id: 'remote_throughput_reliability',
    header: 'Right Min IP Availability Predicted (%)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4, nullValue: '0' }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('remote.minimum_data_rate_requirement', {
    id: 'remote_minimum_data_rate_requirement',
    header: 'Right Min IP Required (Mbps)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote.minimum_data_rate_requirement',
      columnId: 'remote_minimum_data_rate_requirement',
      editorLabel: 'Right Min IP Required (Mbps)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        precision: 3,
      },
    },
  }),
  columnHelper.accessor('remote.minimum_reliability_requirement', {
    id: 'remote_minimum_reliability_requirement',
    header: 'Right Min IP Availability Required %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote.minimum_reliability_requirement',
      columnId: 'remote_minimum_reliability_requirement',
      editorLabel: 'Right Min IP Availability Required %',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        max: 100,
        precision: 4,
      },
    },
  }),
  columnHelper.accessor('remote.transmit_frequency', {
    id: 'remote_transmit_frequency',
    header: 'Right Tx Frequency (MHz)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2, nullValue: '' }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2, nullValue: '' }),
    },
  }),
  columnHelper.accessor('remote.tx_golay', {
    id: 'remote_tx_golay',
    header: 'Right Golay',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('remote.polarity', {
    id: 'remote_polarity',
    header: 'Right Polarity',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('remote.diversity_spacing', {
    id: 'remote_diversity_spacing',
    header: U.headerWithHeightUnits('Right Diversity Spacing'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('remote.clutter_type', {
    id: 'remote_clutter_type',
    header: 'Right Clutter Type',
    cell: U.renderConvertedString({ converter: U.clutterTypeGetter_Table }),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('remote.latitude', {
    id: 'remote_latitude',
    header: 'Right Latitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 5, converter: U.latitude_Table }),
    size: 140,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 5, converter: U.latitude_Table }),
    },
  }),
  columnHelper.accessor('remote.longitude', {
    id: 'remote_longitude',
    header: 'Right Longitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 5, converter: U.longitude_Table }),
    size: 140,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 5, converter: U.longitude_Table }),
    },
  }),
  columnHelper.accessor('remote.mac_address', {
    id: 'remote_mac_address',
    header: 'Right MAC Address',
    cell: U.renderConvertedString({ converter: U.macAddressGetter_Table }),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'ptp',
      attr: 'remote.mac_address',
      columnId: 'remote_mac_address',
      editorLabel: 'Right MAC Address',
      kind: 'string',
      editor: E.NumberEditor,
      editProps: {
        units: 'macAddressFormat',
      },
    },
  }),
  columnHelper.accessor('remote.max_height', {
    id: 'remote_max_height',
    header: U.headerWithHeightUnits('Right Max Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('remote.height', {
    id: 'remote_height',
    header: U.headerWithHeightUnits('Right Ground Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('remote.name', {
    id: 'remote_name',
    header: 'Right Name',
    aggregationFn: 'firstChild',
    sortingFn: 'basic',
  }),
]
