import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import messages from 'src/messages';
import { getCanvas } from 'src/utils/mapUtils';
import { useDispatch } from 'react-redux';
import { uiSet } from 'src/pages/mainframe/mainframe.reducer';

type Item = {
  kind: string;
  id: string; // UUID
};

export const ShowInMap = (item: Item) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Button
      icon
      type="button"
      basic
      style={{ margin: 0 }}
      onClick={() => {
        dispatch(uiSet({ hasModifications: null, resetCallback: null }));
        getCanvas().centerOn(item);
        navigate('/');
      }}
      title={formatMessage(messages.showInMap)}
    >
      <Icon name="map marker alternate" />
    </Button>
  );
};
