import React from 'react';
import StoredAccordion from 'src/components/StoredAccordion';
import { BasicTable } from 'src/components/tables/basic/BasicTable';
import sortBy from 'lodash/sortBy';
import { Link } from 'react-router-dom';

function NDTableAccordion({ aps = [] }) {
  return (
    <StoredAccordion title="PMP Network Devices">
      <BasicTable
        columns={[
          {
            header: 'Name',
            field: 'name',
            render: (row) => <Link to={`/aps/${row.id}`}>{row.name}</Link>,
          },
          { header: 'Product', field: 'product' },
        ]}
        data={sortBy(aps, 'name')}
      />
    </StoredAccordion>
  );
}

export default NDTableAccordion;
