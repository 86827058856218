import type {
  UseFormGetValues,
  FieldValues,
  UseFormSetValue,
  useFormContext,
} from 'react-hook-form';
import type { PTPPath } from './ptp-link-type';
import ptp450PanelConfig from './configs/PTP450ViewConfig';
import PTPePMPPanelConfig from './configs/PTPePMPViewConfig';
import PTPcnWaveViewConfig from './configs/PTPcnWaveViewConfig';
import ptp670PanelConfig from './configs/PTP670ViewConfig';
import ptp700PanelConfig from './configs/PTP700ViewConfig';
import ptpFamilies from './ptp_families.json';
import ptp850PanelConfig from './configs/PTP850ViewConfigs';
import ptp820PanelConfig from './configs/PTP820ViewConfig';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import set from 'lodash/set';
import produce from 'immer';

export type AntennaChoice = {
  id: string;
  data: {
    display_name: string;
    is_default: boolean;
    is_obsolete: boolean;
    allow_tilt: boolean;
    min_tilt: number;
    max_tilt: number;
  } & Record<string, any>;
  center_frequency: number;
  gains: Array<[number, number]>;
};

export type Cabling = {
  internal: boolean;
  allow_feeder_calculate: boolean;
  show_feeder_loss: boolean;
  has_coupler: boolean;
  feeder_length: number;
  feeder_type: string;
  cable_choices: string[];
  cable_options: object;
  default_feeder_type: string;
  requires_diplexer: boolean;
  supports_positioner: boolean;
};

export type PTPEndChoices = {
  antennas: AntennaChoice[];
  cabling: Cabling;
  end: {
    supports_positioner: boolean;
    supports_hazloc: boolean;
    supports_frame_size: boolean;
    default_frame_size: string;
    frame_size_choices: string[];
    golay_choices?: Array<any>;
    polarity_choices?: Array<any>;
    tx_freq_choices?: Array<any>;
    allow_unlicensed_sd: boolean;
    default_unlicensed_sd: number;
    equipment_warning?: string;
    e1t1_warning?: string;
    frequency?: any;
  };
};

export type PTPChoices = {
  equipment: any;
  local: PTPEndChoices;
  remote: PTPEndChoices;
};

export type AntennaType = {
  equipment: any;
  local: PTPEndChoices;
  remote: PTPEndChoices;
};

export type PTPShowProps = {
  path: PTPPath;
  choices: PTPChoices;
  formGetter: (getter: string) => any;
  formSetter: (setter: string, value: any, options: any) => any;
  refreshChoices?: Function;
};

export type PTPParams = {
  path: PTPPath;
  choices: PTPChoices;
  formGetter: (getter: string) => any;
};

type WarningPopupResult = {
  message: string;
  color: string;
  isError: boolean;
};

export type AfterOnChangeMethods = {
  getValues: any;
  setValue: any;
};

export type TMinMax = number | ((params: PMPParams | PTPParams) => number);

export type PMPParams = {
  choices: PMPChoices;
  formGetter: (getter: string) => any;
  ap: any;
  sm?: any;
};

export type PMPChoices = {
  equipment: any;
  cabling: PMPCabling;
  antennas: AntennaChoice[];
  power: any; // define the power choice
  allow_custom_antennas: boolean;
  supported_polarities: string[];
  warnings: any;
};

export type PMPCabling = Cabling & {
  default_cable_loss: number;
  allow_feeder_calculate: boolean;
  allow_user_feeder_loss: boolean;
  cable_loss: number;
};

export type PTPFormField<ShowT> = {
  label?: ((arg: PTPShowProps) => string) | string;
  attrName?: string;
  getter?: string;
  // used for non-equipment controls, e.g. frame size for ePMP
  choicesGetter?: string;
  // used for checkbox components and non-equipment controls
  valueGetter?: string;
  // used for checkbox components
  checkboxGetter?: string | ((params: PTPParams) => string);
  maxValueGetter?: string;
  maxValueFormatter?: (value: any) => any;
  maxValueLabel?: string;
  units?: string | ((getValues: any) => string);
  // useHeightPrefs is a deprecated pattern, use usePrefs instead
  useHeightPrefs?: boolean;
  validate?: (value: any) => boolean;
  usePrefs?:
    | 'rangeUnits'
    | 'heightUnits'
    | 'latLngFormat'
    | 'macAddressFormat'
    | 'bom';
  precision?: number;
  minPrecision?: number;
  maxPrecision?: number;
  min?: TMinMax;
  max?: TMinMax;
  reCalculate?: boolean;
  // this is for limit control when checkbox changes
  // refresh the choices
  checkboxRefreshChoices?: boolean;
  refreshesChoices?: boolean;
  editable?: boolean;
  formatter?: (value: any) => any;
  component?: any;
  componentProps?: Record<string, any> | ((arg: PTPShowProps) => any);
  checkboxCompProps?: Record<string, any> | ((arg: PTPShowProps) => any);
  nextValueNullOk?: boolean;
  defaultValue?: (params) => string;
  nextValue?: (currentValue: any, newChoices: PTPChoices) => any;
  afterOnChange?: (newValue: any, formMethods: AfterOnChangeMethods) => void;
  // afterOnChangeCheckbox is for limitcontrol
  afterOnChangeCheckbox?: (
    newValue: any,
    formMethods: AfterOnChangeMethods
  ) => void;
  show?: (params: ShowT) => boolean;
  tooltip?: (params: PTPParams) => string;
  warning?: (params: PTPParams) => string | boolean;
  warningPopup?: (params: PTPParams) => WarningPopupResult | null;
  warningProps?: ({ modified }: any) => any;
  info?: (params: PTPParams) => string;
  infoColor?: 'yellow' | 'red';
  helpUrl?: ((arg: PTPShowProps) => string) | string;
  calculateUnits?: boolean;
  bold?: boolean;
};

export type PTPFormPanel<ShowT> = {
  kind?: string;
  title?: string | { id: string; defaultMessage: string };
  name: string;
  component?: any;
  show?: (arg: { choices: PTPChoices }) => boolean;
  fields: PTPFormField<ShowT>[];
};

type PTPPanelConfig = {
  equipment: PTPFormPanel<any>[];
  performance: {
    summary: {
      link: PTPFormPanel<any>[];
      local: PTPFormPanel<PTPParams>;
      remote: PTPFormPanel<PTPParams>;
    };
  };
  ends: {
    title: string;
    local: PTPFormPanel<PTPShowProps>;
    remote: PTPFormPanel<PTPShowProps>;
  };
};

export type PTPPanelConfigImpl = PTPPanelConfig & {
  syncFormState: (
    getter: UseFormGetValues<PTPPath>,
    setter: UseFormSetValue<PTPPath>,
    panels: PTPPanelConfig,
    newChoices: PTPChoices
  ) => void;
};

export const complexLinkLabels = ['Link A', 'Link B', 'Link C', 'Link D'];

export const txFrequencySupportedDevices = [
  'PTP 850C',
  'PTP 850E',
  'PTP 850EX',
  'PTP 850EX-P',
  'PTP 820S',
  'PTP 820C',
  'PTP 820S (Narrow)',
  'PTP 820S (Wide)',
  'PTP 820C (Narrow)',
  'PTP 820C (Wide)',
  'PTP 820C HP',
  'PTP 820C HP (Wide)',
  'PTP 820G with RFU-C',
  'PTP 820A with RFU-D',
  'PTP 820A with RFU-D HP',
];

const productPanels = {
  ePMP: PTPePMPPanelConfig,
  cnWave: PTPcnWaveViewConfig,
  'PTP-450': ptp450PanelConfig,
  'PTP-670': ptp670PanelConfig,
  'PTP-700': ptp700PanelConfig,
  'PTP-850': ptp850PanelConfig,
  'PTP-820': ptp820PanelConfig,
};

export function getProductPanels(choices: any, fromDb: boolean) {
  let product: string;
  if (isChoicesComplex(choices)) {
    // complex link
    product = choices.paths[0].equipment.product.value;
  } else {
    product = choices.equipment.product;
    if (!fromDb) {
      product = product.value;
    }
  }

  const family = ptpFamilies[product];
  if (productPanels[family]) {
    return productPanels[family];
  }
  throw new Error(`Unknown product family: ${family}`);
}

export function getLkKind(lk: string) {
  if (
    lk === 'co_polar' ||
    lk === 'cross_polar' ||
    lk === 'xpic' ||
    lk === 'sd_2plus0' ||
    lk === 'unlicensed_2plus0'
  ) {
    return '2plus0';
  } else if (
    lk === 'co_polar_2plus2' ||
    lk === 'cross_polar_2plus2' ||
    lk === 'xpic_2plus2' ||
    lk === 'sd_2plus2'
  ) {
    return '2plus2';
  } else if (lk === 'hot_standby') {
    return '1plus1';
  } else if (lk === 'unlicensed_1plus1') {
    return 'unlicensed_1plus1';
  } else if (
    lk === 'cross_polar_4plus0' ||
    lk === 'co_polar_4plus0' ||
    lk === 'xpic_4plus0'
  ) {
    return '4plus0';
  } else {
    return '1plus0';
  }
}

function getComplexSyncFunction(linkKind, field, final = false) {
  if (!field.hasOwnProperty('lk')) {
    return null;
  }

  const lkKind = getLkKind(linkKind);
  if (!field.lk.hasOwnProperty(lkKind)) {
    return null;
  }

  if (final) {
    return field.lk[lkKind].finalSync;
  }

  return field.lk[lkKind].sync;
}

export function syncFormState(
  getter: UseFormGetValues<PTPPath>,
  setter: UseFormSetValue<PTPPath>,
  panels: PTPPanelConfig,
  newChoices: PTPChoices
) {
  for (const panel of panels.equipment) {
    for (const field of panel.fields) {
      // choices response will return the correct next
      // value to take for each equipment field, so update
      // when they dont match
      const currentValue = getter(field.getter as any);
      let choiceValue = get(
        getEquipmentFromChoices(newChoices),
        field.attrName
      )?.value;

      if (field.nextValue != null) {
        choiceValue = field.nextValue(currentValue, newChoices);
      }

      if (currentValue !== choiceValue) {
        setter(field.getter as any, choiceValue, { shouldDirty: true });
      }
    }
  }

  for (const endName of ['local', 'remote']) {
    for (const field of panels.ends[endName].fields) {
      if (field.nextValue != null) {
        // when present, nextValue() will determine the correct
        // next value for a field when the api response doesn't
        // know how to calculate it, e.g. when antenna needs to change
        const value = field.nextValue(getter(field.getter), newChoices);
        if (field.nextValueNullOk === true || value != null) {
          // set the internal form value to the next value
          setter(field.getter, value, { shouldDirty: true });
        }
      }

      if (field.nextValueExtra != null) {
        // used for cases like antenna protection where the attribute
        // itself doesnt have it's own form field and it must be
        // updated via the antenna field
        // Updates (setter()) happen within the function to make the
        // code simpler here
        field.nextValueExtra(newChoices, getter, setter);
      }
    }

    // do the same but for local/remote performance summary
    for (const field of panels.performance.summary[endName].fields) {
      if (field.nextValue != null) {
        const value = field.nextValue(getter(field.getter), newChoices);
        if (field.nextValueNullOk === true || value != null) {
          setter(field.getter, value, { shouldDirty: true });
        }
      }
    }

    // manually sync frequency_mhz from the choices - this is used for
    // the reflection editor
    setter(
      `${endName}.radios.0.frequency.frequency_mhz`,
      getEndFromChoices(newChoices, endName).frequency?.frequency_mhz
    );
  }

  for (const panel of panels.performance.summary.link) {
    for (const field of panel.fields) {
      if (field.nextValue != null) {
        const value = field.nextValue(getter(field.getter as any), newChoices);
        if (field.nextValueNullOk === true || value != null) {
          setter(field.getter as any, value, { shouldDirty: true });
        }
      }
    }
  }
}

export function valuesFromEqChoices(equipment: any, attrs: string[]) {
  let result = [];
  for (const attr of attrs) {
    result.push(equipment[attr].value);
  }
  return result;
}

export function getEquipmentFromChoices(
  choices: PTPChoices,
  pathIdx: number = 0
) {
  if (isChoicesComplex(choices)) {
    // complex link
    return choices.paths[pathIdx].equipment;
  } else {
    return choices.equipment;
  }
}

export function getAllEquipmentFromChoices(choices: PTPChoices) {
  if (isChoicesComplex(choices)) {
    return choices.paths.map((p) => p.equipment);
  } else {
    return [choices.equipment];
  }
}

export function getLinkFromChoices(choices: PTPChoices, pathIdx: number = 0) {
  if (isChoicesComplex(choices)) {
    return choices.paths[pathIdx].link;
  } else {
    return choices.link;
  }
}

export function getAntennasFromChoices(
  choices: PTPChoices,
  endName: string,
  pathIdx: number = 0
) {
  if (isChoicesComplex(choices)) {
    // complex link
    return choices.paths[pathIdx][endName].antennas;
  } else {
    return choices[endName].antennas;
  }
}

export function getEndFromChoices(
  choices: PTPChoices,
  endName: string,
  pathIdx: number = 0
) {
  if (isChoicesComplex(choices)) {
    // complex link
    return choices.paths[pathIdx][endName].end;
  } else {
    return choices[endName].end;
  }
}

export function getCablingFromChoices(
  choices: PTPChoices,
  endName: string,
  pathIdx: number = 0
) {
  if (isChoicesComplex(choices)) {
    // complex link
    return choices.paths[pathIdx][endName]?.cabling;
  } else {
    return choices[endName]?.cabling;
  }
}

export function isChoicesComplex(choices: PTPChoices) {
  return choices.hasOwnProperty('paths');
}

/**
 * Try and get an attribute from a field that has been specifically
 * declared for the current link kind if available.
 * lk comes from the useLinkKind hook
 */
export function lkAttr(field, attr, lk) {
  // since we pass in the whole lk object, we can make the
  // lookup lk specific at some point
  if (lk.isComplex && field.hasOwnProperty('lk')) {
    return field.lk[attr];
  } else {
    return field[attr];
  }
}

/**
 * Update the internal form state with extra radios when required
 */
export function handleLinkKindChange(panels, choices, getter, reset) {
  if (!choices.hasOwnProperty('lk')) {
    return;
  }

  let data = {};

  for (const endName of ['local', 'remote']) {
    data[endName] = {
      radios: choices.lk[endName].radios,
      properties: choices.lk[endName].end.properties,
    };
  }

  if (data.hasOwnProperty('local') && data.hasOwnProperty('remote')) {
    reset(
      { ...getter(), ...data },
      {
        keepErrors: true,
        keepDirty: true,
        keepTouched: true,
        keepIsSubmitted: true,
        keepIsValid: true,
        keepSubmitCount: true,
      }
    );
  }
}

export function complexControlGetters(field, lk, lkKind) {
  if (!(field.hasOwnProperty('lk') && field.lk.hasOwnProperty(lkKind))) {
    // the product config doesnt support complex getters
    return [field.getter];
  }

  if (
    field.lk[lkKind].hasOwnProperty('separate') &&
    !field.lk[lkKind].separate.includes(lk)
  ) {
    // fields are synced for this specific link kind,
    // e.g. polarization on 2+0 Co-Polar
    return [field.getter];
  }

  return field.lk[lkKind].getter;
}

/**
 * In some cases, the specific configuration of a product impacts whether
 * a certain equipment attribute can be shared. An example is Band is
 * independently configured for Link A and B on a PTP-700 2+0 but only in
 * bands 7GHz and 8GHz.
 * Note: this probably isn't named very well, it just indicates whether
 * the field should be shared based on the current config.
 */
export function configSupportsComplexGetters(field, choices) {
  if (!field.hasOwnProperty('lk')) {
    return false;
  }

  if (field.lk.hasOwnProperty('shared')) {
    if (isFunction(field.lk.shared)) {
      return !field.lk.shared({ choices });
    } else {
      return !field.lk.shared;
    }
  }

  // we can't make an informed judgement here - it will be constrained
  // by a call to complexControlGetters() instead
  return true;
}

export function syncComplexFields(panels, setValue, getValues) {
  for (const panel of panels.equipment) {
    for (const field of panel.fields) {
      const lk = getValues('local.radios.0.equipment.link_protection');
      const lkKind = getLkKind(lk);
      const complexSync = getComplexSyncFunction(lk, field);
      if (complexSync != null) {
        // when changing between complex link types, we may need to re-sync
        // the equipment values between the paths.
        // e.g. co-polar -> cross-polar needs to re-sync polarization so
        // link a and b are opposing values
        const complexGetters = complexControlGetters(field, lk, lkKind);
        complexSync(getValues, setValue, 0, getValues(complexGetters[0]));
      }
    }
  }
}

/**
 * Last sync of complex values before submitting PUT request.
 * This is separate to syncComplexFields because we are updating
 * an object rather than the form state.
 * This is used for ensuring consistency of values across paths
 * when updating the value in the UI does not cause a choices api call.
 */
export function finalComplexSync(panels, form, getValues) {
  const lk = getValues('local.radios.0.equipment.link_protection');
  const lkKind = getLkKind(lk);

  return produce(form, (draft) => {
    for (const panel of panels.equipment) {
      for (const field of panel.fields) {
        const complexSync = getComplexSyncFunction(lk, field, true);
        if (complexSync) {
          complexSync(draft, get, set);
        }
      }
    }

    for (const field of panels.ends.local.fields) {
      // NOTE: called once so handle both ends in single call
      const complexSync = getComplexSyncFunction(lk, field, true);
      if (complexSync) {
        complexSync(draft, get, set);
      }
    }
  });
}

export const isEqualByKey = (value1: any[], value2: any[], key: string) => {
  return !value1 || !value2
    ? false
    : isEqual(
        value1.map((el) => el[key]),
        value2.map((el) => el[key])
      );
};

export function fieldIsShared(lk, field, choices, endName) {
  if (field.label === 'DIVIDER' && field.showInShared) {
    // ensure dividers can still be shown in the shared section of the end panel
    return true;
  }

  const shared = field.lk?.shared;

  if (isFunction(shared)) {
    return shared({ lk, field, choices, endName });
  } else {
    return !!shared;
  }
}

export function defaultLkShared({ lk }) {
  return lk !== 'unlicensed_1plus1' && lk !== 'hot_standby';
}
