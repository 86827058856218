import React from 'react';
import { Link } from 'react-router-dom';
import StoredAccordion from 'src/components/StoredAccordion';
import { BasicTable } from 'src/components/tables/basic/BasicTable';
import sortBy from 'lodash/sortBy';

const getPTPLinks = (paths, id) => {
  let ptpLinks = [];

  if (paths) {
    ptpLinks = paths.filter((path) => {
      return path.kind == 'ptp';
    });
  }

  const ptpTableData = ptpLinks.map((link) => {
    const otherName =
      link.local_site_id === id ? link.remote_site_name : link.local_site_name;
    return {
      otherName,
      id: link.id,
      band: link.band,
    };
  });

  return ptpTableData;
};

function PTPLinksTableAccordion({ paths, siteId }) {
  return (
    <StoredAccordion title="PTP Links">
      <BasicTable
        columns={[
          {
            header: 'Remote End',
            field: 'otherName',
            render: (row) => <Link to={`/ptp/${row.id}`}>{row.otherName}</Link>,
          },
          {
            header: 'Band',
            field: 'band',
          },
        ]}
        data={sortBy(getPTPLinks(paths, siteId), 'otherName')}
      />
    </StoredAccordion>
  );
}

export default PTPLinksTableAccordion;
