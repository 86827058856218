// This file is automatically generated by backend/app/scripts/generate_columns.py
import { createColumnHelper } from '@tanstack/react-table';
import * as U from './ColumnUtils';
import * as E from './editing/editors';

export type SMTableData = {
  id?: string;
  local_end_id?: string;
  remote_end_id?: string;
  warnings?: string;
  name?: string;
  ap_name?: string;
  sm_name?: string;
  range_m?: number | 'N/A';
  sm_site_name?: string;
  ap_site_name?: string;
  ap_msn?: string;
  sm_msn?: string;
  product?: string;
  sm_height_m?: number | 'N/A';
  excess_path_loss_db?: number | 'N/A';
  sm_receive_level_dbm?: number | 'N/A';
  sm_rx_max_usable_mode?: string;
  sm_predicted_availability?: number | 'N/A';
  ap_receive_level_dbm?: number | 'N/A';
  ap_rx_max_usable_mode?: string;
  ap_predicted_availability?: number | 'N/A';
  spatial_frequency?: string;
  "001_rain_rate"?: number | 'N/A';
  rain_attenuation?: number | 'N/A';
  antenna_beamwidth?: number | 'N/A';
  antenna_elevation?: number | 'N/A';
  antenna_gain?: number | 'N/A';
  radio_qty?: number | 'N/A';
  ap_radio_number?: number | 'N/A';
  bearing?: number | 'N/A';
  bearing_from_boresight?: number | 'N/A';
  bearing_ap_to_sm?: number | 'N/A';
  ap_antenna_gain?: number | 'N/A';
  band?: string;
  bandwidth?: string;
  country?: string;
  fresnel_clearance_m?: number | 'N/A';
  link_loss?: number | 'N/A';
  eirp?: number | 'N/A';
  mac_address?: string;
  antenna?: string;
  latitude?: number | 'N/A';
  longitude?: number | 'N/A';
  sm_clutter_type?: string;
  ap_clutter_type?: string;
  sm_sector?: string;
  mu_mimo_grouping_is_ok?: boolean | 'N/A';
  strokeColor: string;
  path_index: number;
  access_point_cable_loss?: number | 'N/A';
  access_point_description?: string;
  access_point_mac_address?: string;
  access_point_place_description?: string;
  antenna_azimuth?: number | 'N/A';
  ap_antenna_height?: number | 'N/A';
  ap_power_to_sm?: number | 'N/A';
  ap_tilt?: number | 'N/A';
  cable_loss?: number | 'N/A';
  description?: string;
  equipment_adjacent_channel_support?: boolean | 'N/A';
  equipment_aes_encryption?: string;
  equipment_antenna_color?: string;
  equipment_ap_max_payload_bytes?: number | 'N/A';
  equipment_ap_modulation_mode?: string;
  equipment_band_setting?: string;
  equipment_broadcast_repeat_count?: number | 'N/A';
  equipment_capacity?: string;
  equipment_cn_capacity_limit?: string;
  equipment_color_code?: number | 'N/A';
  equipment_control_slots?: number | 'N/A';
  equipment_data_channel?: number | 'N/A';
  equipment_dl_max_multiplier?: string;
  equipment_dl_timeslots?: number | 'N/A';
  equipment_dl_ul_ratio?: string;
  equipment_downlink_data?: string;
  equipment_dual_payload_nd?: boolean | 'N/A';
  equipment_dual_payload?: string;
  equipment_dynamic_payload?: string;
  equipment_effective_control_slots?: number | 'N/A';
  equipment_exclude_lower?: number | 'N/A';
  equipment_exclude_upper?: number | 'N/A';
  equipment_frame_period?: string;
  equipment_guard_interval?: string;
  equipment_hi?: string;
  equipment_highest_mod_mode?: string;
  equipment_highest_mod_mode_nd?: string;
  equipment_hop_pattern?: number | 'N/A';
  equipment_io_connectivity?: string;
  equipment_license_region?: string;
  equipment_link_golay?: string;
  equipment_lowest_ethernet_mode?: string;
  equipment_lowest_ethernet_mode_nd?: string;
  equipment_mms_hop_offset?: number | 'N/A';
  equipment_modulation_type?: string;
  equipment_operating_mode?: string;
  equipment_pmp_max_mod_mode?: string;
  equipment_pmp_max_mod_mode_nd?: string;
  equipment_polarity?: string;
  equipment_polarization?: string;
  equipment_range_units?: string;
  equipment_sm_max_mod_mode?: string;
  equipment_sm_max_payload_bytes?: number | 'N/A';
  equipment_sm_min_mod_mode?: string;
  equipment_sm_modulation_mode?: string;
  equipment_sm_range?: number | 'N/A';
  equipment_sm_registration_limit?: number | 'N/A';
  equipment_symmetry?: string;
  equipment_sync?: string;
  equipment_sync_input?: string;
  equipment_sync_source?: string;
  equipment_tdd_frame_mode?: string;
  equipment_tr_spacing?: number | 'N/A';
  equipment_tx_channel?: string;
  equipment_ul_max_multiplier?: string;
  equipment_ul_timeslots?: number | 'N/A';
  external_gain?: number | 'N/A';
  gas_group?: string;
  link_c_factor_str?: string;
  link_climatic_factor_str?: string;
  link_esa_temp_str?: string;
  link_excess_path_loss_at_ke?: number | 'N/A';
  link_fade_margin_max_usable_mode_ap?: number | 'N/A';
  link_fade_margin_max_usable_mode_sm?: number | 'N/A';
  link_free_space_path_loss?: number | 'N/A';
  link_height_ap?: number | 'N/A';
  link_height_sm?: number | 'N/A';
  link_losses_dn1?: number | 'N/A';
  link_losses_ep?: number | 'N/A';
  link_losses_k?: number | 'N/A';
  link_losses_p0?: number | 'N/A';
  link_losses_sa?: number | 'N/A';
  link_mean_atmospheric_loss?: number | 'N/A';
  link_minimum_availability_required_ap?: number | 'N/A';
  link_minimum_availability_required_sm?: number | 'N/A';
  link_mod_mode_required_ap?: string;
  link_mod_mode_required_sm?: string;
  link_profile_npoints?: number | 'N/A';
  link_profile_type?: string;
  link_rain_availability?: number | 'N/A';
  link_rain_unavailability?: string;
  link_system_fade_margin?: number | 'N/A';
  link_terrain_roughness_str?: string;
  link_total_path_loss?: number | 'N/A';
  link_worst_earth?: number | 'N/A';
  mgrs?: string;
  noise?: number | 'N/A';
  place_description?: string;
  place_maximum_height?: number | 'N/A';
  power?: number | 'N/A';
  power_mw?: number | 'N/A';
  use_noise?: boolean | 'N/A';
  use_user_power?: boolean | 'N/A';
  use_user_power_mw?: boolean | 'N/A';
  user_power?: number | 'N/A';
  user_power_mw?: number | 'N/A';
}

const columnHelper = createColumnHelper<SMTableData>()

export const columns = [
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Name',
    aggregationFn: 'firstChild',
    getGroupingValue: U.groupByRemoteEndId<SMTableData>,
    cell: U.renderSubscriberName<SMTableData>,
    aggregatedCell: U.renderGroupedSubscriberName<SMTableData>,
    size: 260,
    enableHiding: false,
    sortingFn: 'basic',
  }),
  columnHelper.accessor('ap_name', {
    id: 'ap_name',
    header: 'ND Name',
    aggregationFn: 'firstChild',
    cell: U.renderNDNameInPMPLinksTable<SMTableData>,
    sortingFn: 'basic',
  }),
  columnHelper.accessor('sm_name', {
    id: 'sm_name',
    header: 'SM Name',
    aggregationFn: 'firstChild',
    cell: U.renderSubscriberName<SMTableData>,
    sortingFn: 'basic',
  }),
  columnHelper.accessor('range_m', {
    id: 'range_m',
    header: U.headerWithRangeUnits('Range'),
    filterFn: U.rangeFilterFn({ precision: 3 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3, prefsKey: 'rangeUnits' }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3, prefsKey: 'rangeUnits' }),
    },
  }),
  columnHelper.accessor('sm_site_name', {
    id: 'sm_site_name',
    header: 'SM Site Name',
    aggregationFn: 'firstChild',
    sortingFn: 'basic',
  }),
  columnHelper.accessor('ap_site_name', {
    id: 'ap_site_name',
    header: 'ND Site Name',
    aggregationFn: 'firstChild',
    sortingFn: 'basic',
  }),
  columnHelper.accessor('ap_msn', {
    id: 'ap_msn',
    header: 'ND MSN',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('sm_msn', {
    id: 'sm_msn',
    header: 'SM MSN',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'sm_msn',
      columnId: 'sm_msn',
      editorLabel: 'SM MSN',
      kind: 'string',
      editor: E.NumberEditor,
      editProps: {
        units: 'msn',
      },
    },
  }),
  columnHelper.accessor('product', {
    id: 'product',
    header: 'Product',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'product',
      columnId: 'product',
      editorLabel: 'Product',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'product'),
    },
  }),
  columnHelper.accessor('sm_height_m', {
    id: 'sm_height_m',
    header: U.headerWithHeightUnits('SM Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
      editable: true,
      choicesKind: 'subscriber',
      attr: 'sm_height_m',
      columnId: 'sm_height_m',
      editorLabel: 'SM Height',
      kind: 'height',
      editor: E.NumberEditor,
      editProps: {
        units: 'heightUnits',
        min: 0,
        max: 3000,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('excess_path_loss_db', {
    id: 'excess_path_loss_db',
    header: 'Excess Path Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('sm_receive_level_dbm', {
    id: 'sm_receive_level_dbm',
    header: 'SM Receive Level (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('sm_rx_max_usable_mode', {
    id: 'sm_rx_max_usable_mode',
    header: 'SM Rx Max Usable Mode',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('sm_predicted_availability', {
    id: 'sm_predicted_availability',
    header: 'SM Predicted Availability (%)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
    },
  }),
  columnHelper.accessor('ap_receive_level_dbm', {
    id: 'ap_receive_level_dbm',
    header: 'ND Receive Level (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('ap_rx_max_usable_mode', {
    id: 'ap_rx_max_usable_mode',
    header: 'ND Rx Max Usable Mode',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('ap_predicted_availability', {
    id: 'ap_predicted_availability',
    header: 'ND Predicted Availability (%)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
    },
  }),
  columnHelper.accessor('spatial_frequency', {
    id: 'spatial_frequency',
    header: 'Spatial Frequency',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('001_rain_rate', {
    id: '001_rain_rate',
    header: '0.01% Rain rate',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('rain_attenuation', {
    id: 'rain_attenuation',
    header: 'Rain Attenuation (dB/km)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('antenna_beamwidth', {
    id: 'antenna_beamwidth',
    header: 'SM Antenna Beamwidth (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('antenna_elevation', {
    id: 'antenna_elevation',
    header: 'SM Antenna Elevation (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('antenna_gain', {
    id: 'antenna_gain',
    header: 'SM Gain (dBi)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('radio_qty', {
    id: 'radio_qty',
    header: 'Number of Component Carriers',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'subscriber',
      attr: 'radio_qty',
      columnId: 'radio_qty',
      editorLabel: 'Number of Component Carriers',
      kind: 'number',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'radio_qty'),
    },
  }),
  columnHelper.accessor('ap_radio_number', {
    id: 'ap_radio_number',
    header: 'Component Carrier Number',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('bearing', {
    id: 'bearing',
    header: 'SM Bearing, True N (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('bearing_from_boresight', {
    id: 'bearing_from_boresight',
    header: 'SM Bearing from ND Boresight (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    size: 100,
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('bearing_ap_to_sm', {
    id: 'bearing_ap_to_sm',
    header: 'ND Bearing, True N (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('ap_antenna_gain', {
    id: 'ap_antenna_gain',
    header: 'ND Gain (dBi)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('band', {
    id: 'band',
    header: 'Band',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('bandwidth', {
    id: 'bandwidth',
    header: 'Bandwidth (MHz)',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('country', {
    id: 'country',
    header: 'Country',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('fresnel_clearance_m', {
    id: 'fresnel_clearance_m',
    header: 'Fresnel Zone Clearance',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('link_loss', {
    id: 'link_loss',
    header: 'Link Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('eirp', {
    id: 'eirp',
    header: 'SM EIRP (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('mac_address', {
    id: 'mac_address',
    header: 'SM MAC Address',
    cell: U.renderConvertedString({ converter: U.macAddressGetter_Table }),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'mac_address',
      columnId: 'mac_address',
      editorLabel: 'SM MAC Address',
      kind: 'string',
      editor: E.NumberEditor,
      editProps: {
        units: 'macAddressFormat',
      },
    },
  }),
  columnHelper.accessor('antenna', {
    id: 'antenna',
    header: 'SM Antenna',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'antenna',
      columnId: 'antenna',
      editorLabel: 'SM Antenna',
      kind: 'string',
      editor: E.AntennaEditor,
      getChoices: E.getChoices('subscriber', 'antenna'),
    },
  }),
  columnHelper.accessor('latitude', {
    id: 'latitude',
    header: 'SM Latitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 5, converter: U.latitude_Table }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 5, converter: U.latitude_Table }),
    },
  }),
  columnHelper.accessor('longitude', {
    id: 'longitude',
    header: 'SM Longitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 5, converter: U.longitude_Table }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 5, converter: U.longitude_Table }),
    },
  }),
  columnHelper.accessor('sm_clutter_type', {
    id: 'sm_clutter_type',
    header: 'SM Clutter Type',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('ap_clutter_type', {
    id: 'ap_clutter_type',
    header: 'AP Clutter Type',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('sm_sector', {
    id: 'sm_sector',
    header: 'SM Sector',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'sm_sector',
      columnId: 'sm_sector',
      editorLabel: 'SM Sector',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'sm_sector'),
    },
  }),
  columnHelper.accessor('mu_mimo_grouping_is_ok', {
    id: 'mu_mimo_grouping_is_ok',
    header: 'MU-MIMO Grouping OK?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('access_point_cable_loss', {
    id: 'access_point_cable_loss',
    header: 'ND Cable Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('access_point_description', {
    id: 'access_point_description',
    header: 'ND Description',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('access_point_mac_address', {
    id: 'access_point_mac_address',
    header: 'ND MAC Address',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('access_point_place_description', {
    id: 'access_point_place_description',
    header: 'ND Site Description',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('antenna_azimuth', {
    id: 'antenna_azimuth',
    header: 'SM Bearing, True N (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('ap_antenna_height', {
    id: 'ap_antenna_height',
    header: U.headerWithHeightUnits('ND Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('ap_power_to_sm', {
    id: 'ap_power_to_sm',
    header: 'ND Power to SM (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('ap_tilt', {
    id: 'ap_tilt',
    header: 'ND Antenna Elevation (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('cable_loss', {
    id: 'cable_loss',
    header: 'SM Cable Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('description', {
    id: 'description',
    header: 'SM Description',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'description',
      columnId: 'description',
      editorLabel: 'SM Description',
      kind: 'string',
      editor: E.TextEditor,
    },
  }),
  columnHelper.accessor('equipment_adjacent_channel_support', {
    id: 'equipment_adjacent_channel_support',
    header: 'Adjacent Channel Support',
    cell: U.renderEnabled(),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_aes_encryption', {
    id: 'equipment_aes_encryption',
    header: 'Encryption Variant',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_antenna_color', {
    id: 'equipment_antenna_color',
    header: 'Color',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_ap_max_payload_bytes', {
    id: 'equipment_ap_max_payload_bytes',
    header: 'ND Max Payload Bytes (Bytes)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('equipment_ap_modulation_mode', {
    id: 'equipment_ap_modulation_mode',
    header: 'ND Modulation Mode',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_band_setting', {
    id: 'equipment_band_setting',
    header: 'Band Setting',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_broadcast_repeat_count', {
    id: 'equipment_broadcast_repeat_count',
    header: 'ND Broadcast Repeat Count',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('equipment_capacity', {
    id: 'equipment_capacity',
    header: 'Capacity',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_cn_capacity_limit', {
    id: 'equipment_cn_capacity_limit',
    header: 'CN Capacity Limit',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_color_code', {
    id: 'equipment_color_code',
    header: 'Color Code',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('equipment_control_slots', {
    id: 'equipment_control_slots',
    header: 'Contention Slots',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('equipment_data_channel', {
    id: 'equipment_data_channel',
    header: 'Data Channels',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'subscriber',
      attr: 'equipment_data_channel',
      columnId: 'equipment_data_channel',
      editorLabel: 'Data Channels',
      kind: 'number',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'equipment_data_channel'),
    },
  }),
  columnHelper.accessor('equipment_dl_max_multiplier', {
    id: 'equipment_dl_max_multiplier',
    header: 'DL Maximum Mod Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'equipment_dl_max_multiplier',
      columnId: 'equipment_dl_max_multiplier',
      editorLabel: 'DL Maximum Mod Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'equipment_dl_max_multiplier'),
    },
  }),
  columnHelper.accessor('equipment_dl_timeslots', {
    id: 'equipment_dl_timeslots',
    header: 'DL Timeslots',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('equipment_dl_ul_ratio', {
    id: 'equipment_dl_ul_ratio',
    header: 'DL/UL Ratio',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_downlink_data', {
    id: 'equipment_downlink_data',
    header: 'Downlink Data (%)',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_dual_payload_nd', {
    id: 'equipment_dual_payload_nd',
    header: 'ND Dual Payload',
    cell: U.renderEnabled(),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_dual_payload', {
    id: 'equipment_dual_payload',
    header: 'Dual Payload',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'equipment_dual_payload',
      columnId: 'equipment_dual_payload',
      editorLabel: 'Dual Payload',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'equipment_dual_payload'),
    },
  }),
  columnHelper.accessor('equipment_dynamic_payload', {
    id: 'equipment_dynamic_payload',
    header: 'ND Dynamic Payload',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_effective_control_slots', {
    id: 'equipment_effective_control_slots',
    header: 'Effective Contention Slots',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('equipment_exclude_lower', {
    id: 'equipment_exclude_lower',
    header: 'Exclude Lower (MHz)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('equipment_exclude_upper', {
    id: 'equipment_exclude_upper',
    header: 'Exclude Upper (MHz)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('equipment_frame_period', {
    id: 'equipment_frame_period',
    header: 'Frame Period (ms)',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_guard_interval', {
    id: 'equipment_guard_interval',
    header: 'Guard Interval',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_hi', {
    id: 'equipment_hi',
    header: 'Hi End',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_highest_mod_mode', {
    id: 'equipment_highest_mod_mode',
    header: 'Highest Mod Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'equipment_highest_mod_mode',
      columnId: 'equipment_highest_mod_mode',
      editorLabel: 'Highest Mod Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'equipment_highest_mod_mode'),
    },
  }),
  columnHelper.accessor('equipment_highest_mod_mode_nd', {
    id: 'equipment_highest_mod_mode_nd',
    header: 'ND Highest Mod Mode',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_hop_pattern', {
    id: 'equipment_hop_pattern',
    header: 'Hop Pattern',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('equipment_io_connectivity', {
    id: 'equipment_io_connectivity',
    header: 'ND I/O Connectivity',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_license_region', {
    id: 'equipment_license_region',
    header: 'SM Region',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_link_golay', {
    id: 'equipment_link_golay',
    header: 'Link Golay',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'equipment_link_golay',
      columnId: 'equipment_link_golay',
      editorLabel: 'Link Golay',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'equipment_link_golay'),
    },
  }),
  columnHelper.accessor('equipment_lowest_ethernet_mode', {
    id: 'equipment_lowest_ethernet_mode',
    header: 'Lowest Ethernet Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'equipment_lowest_ethernet_mode',
      columnId: 'equipment_lowest_ethernet_mode',
      editorLabel: 'Lowest Ethernet Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'equipment_lowest_ethernet_mode'),
    },
  }),
  columnHelper.accessor('equipment_lowest_ethernet_mode_nd', {
    id: 'equipment_lowest_ethernet_mode_nd',
    header: 'ND Lowest Ethernet Mode',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_mms_hop_offset', {
    id: 'equipment_mms_hop_offset',
    header: 'MMS Hop Offset',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('equipment_modulation_type', {
    id: 'equipment_modulation_type',
    header: 'Modulation Type',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_operating_mode', {
    id: 'equipment_operating_mode',
    header: 'ND MIMO Mode',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_pmp_max_mod_mode', {
    id: 'equipment_pmp_max_mod_mode',
    header: 'Maximum Mod Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'equipment_pmp_max_mod_mode',
      columnId: 'equipment_pmp_max_mod_mode',
      editorLabel: 'Maximum Mod Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'equipment_pmp_max_mod_mode'),
    },
  }),
  columnHelper.accessor('equipment_pmp_max_mod_mode_nd', {
    id: 'equipment_pmp_max_mod_mode_nd',
    header: 'ND Maximum Mod Mode',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_polarity', {
    id: 'equipment_polarity',
    header: 'Polarity',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_polarization', {
    id: 'equipment_polarization',
    header: 'ND Polarization',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_range_units', {
    id: 'equipment_range_units',
    header: 'Range Units',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_sm_max_mod_mode', {
    id: 'equipment_sm_max_mod_mode',
    header: 'SM Maximum Mod Mode',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_sm_max_payload_bytes', {
    id: 'equipment_sm_max_payload_bytes',
    header: 'SM Max Payload Bytes (Bytes)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('equipment_sm_min_mod_mode', {
    id: 'equipment_sm_min_mod_mode',
    header: 'SM Minimum Mod Mode',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_sm_modulation_mode', {
    id: 'equipment_sm_modulation_mode',
    header: 'SM Modulation Mode',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_sm_range', {
    id: 'equipment_sm_range',
    header: 'SM Range',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('equipment_sm_registration_limit', {
    id: 'equipment_sm_registration_limit',
    header: 'Max Registrations Allowed',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('equipment_symmetry', {
    id: 'equipment_symmetry',
    header: 'Symmetry',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_sync', {
    id: 'equipment_sync',
    header: 'Sync',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_sync_input', {
    id: 'equipment_sync_input',
    header: 'ND Sync Input',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_sync_source', {
    id: 'equipment_sync_source',
    header: 'Synchronization Source',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_tdd_frame_mode', {
    id: 'equipment_tdd_frame_mode',
    header: 'TDD Frame Mode',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_tr_spacing', {
    id: 'equipment_tr_spacing',
    header: 'T/R Spacing (MHz)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('equipment_tx_channel', {
    id: 'equipment_tx_channel',
    header: 'Channel',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('equipment_ul_max_multiplier', {
    id: 'equipment_ul_max_multiplier',
    header: 'UL Maximum Mod Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'equipment_ul_max_multiplier',
      columnId: 'equipment_ul_max_multiplier',
      editorLabel: 'UL Maximum Mod Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'equipment_ul_max_multiplier'),
    },
  }),
  columnHelper.accessor('equipment_ul_timeslots', {
    id: 'equipment_ul_timeslots',
    header: 'UL Timeslots',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('external_gain', {
    id: 'external_gain',
    header: 'SM External Gain (dBi)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('gas_group', {
    id: 'gas_group',
    header: 'SM Gas Group',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'gas_group',
      columnId: 'gas_group',
      editorLabel: 'SM Gas Group',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'gas_group'),
    },
  }),
  columnHelper.accessor('link_c_factor_str', {
    id: 'link_c_factor_str',
    header: 'C Factor',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('link_climatic_factor_str', {
    id: 'link_climatic_factor_str',
    header: 'Climatic Factor',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('link_esa_temp_str', {
    id: 'link_esa_temp_str',
    header: 'Temperature',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('link_excess_path_loss_at_ke', {
    id: 'link_excess_path_loss_at_ke',
    header: 'Excess Path Loss at ke (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('link_fade_margin_max_usable_mode_ap', {
    id: 'link_fade_margin_max_usable_mode_ap',
    header: 'ND FM at Rx Max Usable Mode (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    size: 70,
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('link_fade_margin_max_usable_mode_sm', {
    id: 'link_fade_margin_max_usable_mode_sm',
    header: 'SM FM at Rx Max Usable Mode (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    size: 70,
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('link_free_space_path_loss', {
    id: 'link_free_space_path_loss',
    header: 'Free Space Path Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('link_height_ap', {
    id: 'link_height_ap',
    header: U.headerWithHeightUnits('ND Ground Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('link_height_sm', {
    id: 'link_height_sm',
    header: U.headerWithHeightUnits('SM Ground Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('link_losses_dn1', {
    id: 'link_losses_dn1',
    header: 'dN/dH (N units/km)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('link_losses_ep', {
    id: 'link_losses_ep',
    header: 'Inclination (mr)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('link_losses_k', {
    id: 'link_losses_k',
    header: 'Geoclimatic Factor',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('link_losses_p0', {
    id: 'link_losses_p0',
    header: 'Fade Occurrence Factor (Po)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('link_losses_sa', {
    id: 'link_losses_sa',
    header: 'Area Roughness',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('link_mean_atmospheric_loss', {
    id: 'link_mean_atmospheric_loss',
    header: 'Gaseous Absorption Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('link_minimum_availability_required_ap', {
    id: 'link_minimum_availability_required_ap',
    header: 'ND Min Availability Required %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
      editable: true,
      choicesKind: 'subscriber',
      attr: 'link_minimum_availability_required_ap',
      columnId: 'link_minimum_availability_required_ap',
      editorLabel: 'ND Min Availability Required %',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        max: 100,
        precision: 4,
      },
    },
  }),
  columnHelper.accessor('link_minimum_availability_required_sm', {
    id: 'link_minimum_availability_required_sm',
    header: 'SM Min Availability Required %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
      editable: true,
      choicesKind: 'subscriber',
      attr: 'link_minimum_availability_required_sm',
      columnId: 'link_minimum_availability_required_sm',
      editorLabel: 'SM Min Availability Required %',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        max: 100,
        precision: 4,
      },
    },
  }),
  columnHelper.accessor('link_mod_mode_required_ap', {
    id: 'link_mod_mode_required_ap',
    header: 'ND Mod Mode Required',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'link_mod_mode_required_ap',
      columnId: 'link_mod_mode_required_ap',
      editorLabel: 'ND Mod Mode Required',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'link_mod_mode_required_ap'),
    },
  }),
  columnHelper.accessor('link_mod_mode_required_sm', {
    id: 'link_mod_mode_required_sm',
    header: 'SM Mod Mode Required',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'link_mod_mode_required_sm',
      columnId: 'link_mod_mode_required_sm',
      editorLabel: 'SM Mod Mode Required',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('subscriber', 'link_mod_mode_required_sm'),
    },
  }),
  columnHelper.accessor('link_profile_npoints', {
    id: 'link_profile_npoints',
    header: '# Points in Profile',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('link_profile_type', {
    id: 'link_profile_type',
    header: 'Profile Type',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('link_rain_availability', {
    id: 'link_rain_availability',
    header: 'Rain Availability %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
    },
  }),
  columnHelper.accessor('link_rain_unavailability', {
    id: 'link_rain_unavailability',
    header: 'Rain Unavailability',
    aggregationFn: 'firstChild',
    sortingFn: U.sortAvailabilityTime,
  }),
  columnHelper.accessor('link_system_fade_margin', {
    id: 'link_system_fade_margin',
    header: 'System Gain Margin (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('link_terrain_roughness_str', {
    id: 'link_terrain_roughness_str',
    header: 'Terrain Roughness',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('link_total_path_loss', {
    id: 'link_total_path_loss',
    header: 'Link Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('link_worst_earth', {
    id: 'link_worst_earth',
    header: 'Worst Earth (ke)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('mgrs', {
    id: 'mgrs',
    header: 'MHRS Ref.',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('noise', {
    id: 'noise',
    header: 'SM Interference (dBm in b/w)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
      editable: true,
      choicesKind: 'subscriber',
      attr: 'noise',
      columnId: 'noise',
      editorLabel: 'SM Interference (dBm in b/w)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: -144,
        max: -40,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('place_description', {
    id: 'place_description',
    header: 'SM Site Description',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('place_maximum_height', {
    id: 'place_maximum_height',
    header: U.headerWithHeightUnits('SM Max Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('power', {
    id: 'power',
    header: 'SM Power (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('power_mw', {
    id: 'power_mw',
    header: 'SM Power (mW)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('use_noise', {
    id: 'use_noise',
    header: 'SM Interference?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'use_noise',
      columnId: 'use_noise',
      editorLabel: 'SM Interference?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('subscriber', 'use_noise'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('use_user_power', {
    id: 'use_user_power',
    header: 'SM User Power (dBm) Limit?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'use_user_power',
      columnId: 'use_user_power',
      editorLabel: 'SM User Power (dBm) Limit?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('subscriber', 'use_user_power'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('use_user_power_mw', {
    id: 'use_user_power_mw',
    header: 'SM User Power (mW) Limit?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'subscriber',
      attr: 'use_user_power_mw',
      columnId: 'use_user_power_mw',
      editorLabel: 'SM User Power (mW) Limit?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('subscriber', 'use_user_power_mw'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('user_power', {
    id: 'user_power',
    header: 'SM Power Limit (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'subscriber',
      attr: 'user_power',
      columnId: 'user_power',
      editorLabel: 'SM Power Limit (dBm)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: -40,
        max: 40,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('user_power_mw', {
    id: 'user_power_mw',
    header: 'SM Power Limit (mW)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'subscriber',
      attr: 'user_power_mw',
      columnId: 'user_power_mw',
      editorLabel: 'SM Power Limit (mW)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 50,
        max: 10000,
        precision: 1,
      },
    },
  }),
]
