// This file is automatically generated by backend/app/scripts/generate_columns.py
import { createColumnHelper } from '@tanstack/react-table';
import * as U from './ColumnUtils';
import * as E from './editing/editors';

export type NetworkSiteTableData = {

  name?: string;
  latitude?: number | 'N/A';
  longitude?: number | 'N/A';
  maximum_height?: number | 'N/A';
  description?: string;
  id: string;
  is_network_site: boolean | 'N/A';
  node_type?: string;
  ptp_link_count?: number | 'N/A';
  access_point_count?: number | 'N/A';
  mesh_link_count?: number | 'N/A';
  total_sms?: number | 'N/A';
  connected_subscribers?: number | 'N/A';
  unconnected_subscribers?: number | 'N/A';
  dl_throughput?: number | 'N/A';
  ul_throughput?: number | 'N/A';
  total_throughput?: number | 'N/A';
  site_owner?: string;
  has_tower_switch?: boolean | 'N/A';
}

const columnHelper = createColumnHelper<NetworkSiteTableData>()

export const columns = [
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Name',
    size: 150,
    aggregationFn: 'firstChild',
    cell: U.renderNetworkSiteName<NetworkSiteTableData>,
    enableHiding: false,
    sortingFn: 'basic',
  }),
  columnHelper.accessor('latitude', {
    id: 'latitude',
    header: 'Latitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 5, converter: U.latitude_Table }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 5, converter: U.latitude_Table }),
    },
  }),
  columnHelper.accessor('longitude', {
    id: 'longitude',
    header: 'Longitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 5, converter: U.longitude_Table }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 5, converter: U.longitude_Table }),
    },
  }),
  columnHelper.accessor('maximum_height', {
    id: 'maximum_height',
    header: U.headerWithHeightUnits('Maximum Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
      editable: true,
      choicesKind: 'site',
      attr: 'maximum_height',
      columnId: 'maximum_height',
      editorLabel: 'Maximum Height',
      kind: 'height',
      editor: E.NumberEditor,
      editProps: {
        units: 'heightUnits',
        min: 0,
        max: 3000,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('description', {
    id: 'description',
    header: 'Description',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'site',
      attr: 'description',
      columnId: 'description',
      editorLabel: 'Description',
      kind: 'string',
      editor: E.TextEditor,
    },
  }),
  columnHelper.accessor('node_type', {
    id: 'node_type',
    header: 'Node Type',
    size: 60,
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'site',
      attr: 'node_type',
      columnId: 'node_type',
      editorLabel: 'Node Type',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('site', 'node_type'),
    },
  }),
  columnHelper.accessor('ptp_link_count', {
    id: 'ptp_link_count',
    header: 'PTP Links',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('access_point_count', {
    id: 'access_point_count',
    header: 'PMP Network Devices',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('mesh_link_count', {
    id: 'mesh_link_count',
    header: 'Mesh Links',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('total_sms', {
    id: 'total_sms',
    header: 'Number of Subscriber Modules',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('connected_subscribers', {
    id: 'connected_subscribers',
    header: 'Connected Subscribers',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('unconnected_subscribers', {
    id: 'unconnected_subscribers',
    header: 'Unconnected Subscribers',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('dl_throughput', {
    id: 'dl_throughput',
    header: 'Total Predicted DL Throughput',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderThroughput({  }),
    size: 95,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderThroughput({  }),
    },
  }),
  columnHelper.accessor('ul_throughput', {
    id: 'ul_throughput',
    header: 'Total Predicted UL Throughput',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderThroughput({  }),
    size: 95,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderThroughput({  }),
    },
  }),
  columnHelper.accessor('total_throughput', {
    id: 'total_throughput',
    header: 'Total Throughput',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderThroughput({  }),
    size: 95,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderThroughput({  }),
    },
  }),
  columnHelper.accessor('site_owner', {
    id: 'site_owner',
    header: 'Site Owner',
    size: 95,
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('has_tower_switch', {
    id: 'has_tower_switch',
    header: 'Switch',
    cell: U.renderYesNo(),
    size: 60,
    aggregationFn: 'firstChild',
  }),
]
