import React from 'react';
import { Table, Row } from '@tanstack/react-table';

type TableContextType = {
  setLastSelectedRowId: (id: string | null) => void;
  table: Table<any>;
  getRowId: (row: Row<any>) => string;
};

export const TableContext = React.createContext<TableContextType | undefined>(
  undefined
);

export const useTableContext = () => {
  const context = React.useContext(TableContext);
  if (context === undefined) {
    throw new Error(
      'useTableContext must be used within a TableContext.Provider'
    );
  }
  return context;
};
