import React from 'react';
import { injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Icon, Input } from 'semantic-ui-react';
import { getWithAuth } from 'src/api';
import { POLARIZATION_OPTIONS } from 'src/app.constants';
import messages from 'src/messages';
import { sortByBandOrder } from './AntennaPanel';
import { pyround, runWithConfirmation } from 'src/utils/useful_functions';
import { RootStateOrAny } from 'react-redux';
import { setAntennaPolarities } from './antenna.reducer';
import { VALID_PMP_BANDS, VALID_PTP_BANDS } from 'src/model/BandDefinition';

function getFileName(antenna, orderBandList) {
  const { dual, scaled_diameter, diameter_units, profile } = antenna;
  const scaledUnits = { feet: 'ft', inches: 'in', meters: 'm' };
  const size = scaled_diameter
    ? `${pyround(scaled_diameter, 1)}${scaledUnits[diameter_units]}`
    : '';
  const bands = Object.keys(antenna).filter((key) => {
    return key.includes('GHz') || key.includes('MHz');
  });
  let gainName = '';
  if (bands.length === 1) {
    gainName = bands[0].replace(/ /g, '').replace(/_/g, '.');
  } else {
    const sortedBands = sortByBandOrder(bands, orderBandList);
    const minBand = sortedBands[sortedBands.length - 1]
      .replace(/ /g, '')
      .replace(/_/g, '.')
      .replace(/(GHz|MHz)/g, '');
    const maxBand = sortedBands[0].replace(/ /g, '').replace(/_/g, '.');
    gainName = `${minBand}-${maxBand}`;
  }
  const dualStr = ['Single', 'Dual'][Number(dual)];
  const nameArgs = [gainName, size, dualStr, profile].filter((v) => Boolean(v));
  return `${nameArgs.join('-')}.adf`;
}

const ToolBar = (props) => {
  const { antennaType } = useParams();
  const dispatch = useDispatch();
  const { projectId, permissionWrite } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const { filter, setFilter, gridApi, selectedAntennas, handleAction } = props;
  const { formatMessage } = props.intl;

  let orderBandList = VALID_PMP_BANDS;
  if (antennaType === 'ptp') {
    orderBandList = VALID_PTP_BANDS;
  }

  const saveAntennaPatternBtnStatus = selectedAntennas.find(
    (antenna) => antenna.profile_filename
  );

  const handleDownload = () => {
    selectedAntennas.forEach((antenna) => {
      if (antenna.profile_filename) {
        getWithAuth(
          `project/${projectId}/antennas/${antennaType}/${antenna.id}/profile`,
          'GET',
          true
        )
          .then((res) => {
            const blobObj = new Blob([res], { type: 'text/plain' });
            const blobUrl = window.URL.createObjectURL(blobObj);
            let a = document.createElement('a');
            a.href = blobUrl;
            const fileName = getFileName(antenna, orderBandList);
            a.download = fileName;
            a.click();
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    });
  };

  function disableDelete() {
    let disabled = false;
    selectedAntennas.forEach((element) => {
      if (!element.user_defined) {
        disabled = true;
        return;
      }
    });
    return disabled;
  }

  const disableButton = !permissionWrite;

  return (
    <div style={{ margin: '.5em 0' }}>
      {antennaType !== 'network_device' && (
        <>
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              dispatch(
                setAntennaPolarities({
                  polarities: POLARIZATION_OPTIONS,
                })
              );
              return handleAction('add');
            }}
            disabled={disableButton}
          >
            <Icon name="add" />
            New Antenna
          </Button>
          <Button
            disabled={
              disableButton || selectedAntennas.length < 1 || disableDelete()
            }
            icon
            labelPosition="left"
            onClick={() =>
              runWithConfirmation({
                header: 'Delete Antenna',
                message: 'Are you sure you want to delete this antenna?',
                onConfirm: () => handleAction('delete'),
              })
            }
          >
            <Icon name="trash alternate" />
            Delete Antenna
          </Button>
          <Button
            disabled={disableButton || selectedAntennas.length !== 1}
            icon
            labelPosition="left"
            onClick={() => {
              dispatch(
                setAntennaPolarities({
                  polarities: POLARIZATION_OPTIONS,
                })
              );
              return handleAction('edit');
            }}
          >
            <Icon name="edit" />
            Edit Antenna
          </Button>
          <Button
            disabled={!saveAntennaPatternBtnStatus}
            onClick={handleDownload}
            icon
            labelPosition="left"
          >
            <Icon name="save" />
            Save Antenna Pattern
          </Button>
        </>
      )}
      <Input
        style={{ float: 'right' }}
        size="mini"
        value={filter}
        transparent
        className={filter ? 'filtered' : 'unfiltered'}
        placeholder={formatMessage(messages.filter)}
        icon={{
          name: 'cancel',
          onClick: () => {
            setFilter('');
            gridApi.setQuickFilter('');
          },
          link: true,
          title: formatMessage(messages.clearFilter),
        }}
        onChange={(e, { value }) => {
          setFilter(value);
          gridApi.setQuickFilter(
            value.toString().toLowerCase().replace(' ', '')
          );
        }}
      />
      <span style={{ clear: 'both', display: 'block' }}></span>
    </div>
  );
};

export default injectIntl(ToolBar);
