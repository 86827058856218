import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, Label } from 'semantic-ui-react';
import StoredAccordion from 'src/components/StoredAccordion';
import { PMPPanelProps } from './utils';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { checkErrors } from './configs/config-utils';
import AntennaField from './AntennaField';

export const getWarningLabel = (content, modified) => {
  if (modified) {
    return (
      <Label pointing color="blue" basic>
        {content}
      </Label>
    );
  } else {
    // warning
    return (
      <Label pointing prompt>
        {content}
      </Label>
    );
  }
};

function NDAntennaPanel(props: PMPPanelProps & WrappedComponentProps) {
  const { getValues, setValue, getFieldState } = useFormContext();
  const { ap, sm, panel, choices } = props;
  const errors = checkErrors({
    panel,
    getValues,
    getFieldState,
    ap,
    sm,
    setValue,
    choices,
  });
  const fieldLayout = panel.fieldLayout;
  const fields = panel.fields;
  let elements;
  if (fieldLayout) {
    elements = fieldLayout.map((row, i) => {
      if (row.length === 1) {
        return (
          <AntennaField
            key={fields[row[0]['attrName']]}
            field={fields[row[0]]}
            {...props}
          ></AntennaField>
        );
      } else {
        return (
          <Grid.Row
            key={`${i}-row`}
            columns={row.length}
            centered
            style={{ paddingTop: '0px' }}
          >
            {row.map((colIndex, j) => {
              const field = fields[colIndex];
              return (
                <Grid.Column key={`${field.attrName}-col-${j}`}>
                  <AntennaField
                    key={`${field.attrName}-${j}`}
                    field={field}
                    {...props}
                  ></AntennaField>
                </Grid.Column>
              );
            })}
          </Grid.Row>
        );
      }
    });
  } else {
    elements = panel.fields.map((field) => {
      return (
        <AntennaField
          key={field['attrName']}
          field={field}
          {...props}
        ></AntennaField>
      );
    });
  }
  return (
    <StoredAccordion
      name={`nd_${panel.name}`}
      title={panel.title}
      key={`nd_${panel.name}`}
      headerClass={errors ? 'custom-error' : ''}
      contentProps={{ loading: ap.dirty || sm?.dirty }}
    >
      {fieldLayout ? <Grid>{elements}</Grid> : elements}
    </StoredAccordion>
  );
}

export default injectIntl(NDAntennaPanel);
