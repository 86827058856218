import React from 'react';
import { Table } from '@tanstack/react-table';

export function useColumnVisibility<T>(table: Table<T>, columnOrder: string[], defaultPinnedColumn: string) {
  const { allVisibleColumns, allHiddenColumns } = React.useMemo(() => {
    const visible = table
      .getAllColumns()
      .filter((col) => col.getCanHide() && col.getIsVisible() && col.id !== defaultPinnedColumn)
      .sort((a, b) => columnOrder.indexOf(a.id) - columnOrder.indexOf(b.id));

    const hidden = table
      .getAllColumns()
      .filter((col) => col.getCanHide() && !col.getIsVisible() && col.id !== defaultPinnedColumn)
      .sort((a, b) => columnOrder.indexOf(a.id) - columnOrder.indexOf(b.id));

    return { allVisibleColumns: visible, allHiddenColumns: hidden };
  }, [table, columnOrder, table.getState().columnVisibility]);

  const getFilteredColumns = React.useCallback(
    (columns: typeof allVisibleColumns, searchTerm: string) => {
      const getHeaderText = (col: any) => {
        const headerDef = col.columnDef.header;
        return typeof headerDef === 'function'
          ? headerDef({
              column: col,
              header: col.columnDef.header as any,
              table,
            })
          : String(headerDef);
      };

      return columns.filter((col) =>
        getHeaderText(col).toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    [table]
  );

  return {
    allVisibleColumns,
    allHiddenColumns,
    getFilteredColumns,
  };
}
