import { pickBy } from 'lodash';

const noiseDensityDef = -114;

// prettier-ignore
export const bands = {
  '80 GHz': { normFreq: 78500.0, noiseDensity: noiseDensityDef, ptp:true, pmp:false, ptpAntenna:false, smAntenna:false },
  '60 GHz': { normFreq: 62640.0, noiseDensity: noiseDensityDef, ptp:true, pmp:true, ptpAntenna:false, smAntenna:false },
  '38 GHz': { normFreq: 38500.0, noiseDensity: noiseDensityDef, ptp:true, pmp:false, ptpAntenna:false, smAntenna:false },
  '32 GHz': { normFreq: 32600.0, noiseDensity: noiseDensityDef, ptp:true, pmp:false, ptpAntenna:false, smAntenna:false },
  '28 GHz': { normFreq: 28500.0, noiseDensity: noiseDensityDef, ptp:true, pmp:true, ptpAntenna:false, smAntenna:false },
  '26 GHz': { normFreq: 25375.0, noiseDensity: noiseDensityDef, ptp:true, pmp:true, ptpAntenna:false, smAntenna:false },
  '24 GHz': { normFreq: 24125.0, noiseDensity: noiseDensityDef, ptp:true, pmp:false, ptpAntenna:false, smAntenna:false },
  '23 GHz': { normFreq: 22400.0, noiseDensity: noiseDensityDef, ptp:true, pmp:false, ptpAntenna:false, smAntenna:false },
  '18 GHz': { normFreq: 18700.0, noiseDensity: noiseDensityDef, ptp:true, pmp:false, ptpAntenna:false, smAntenna:false },
  '15 GHz': { normFreq: 14900.0, noiseDensity: noiseDensityDef, ptp:true, pmp:false, ptpAntenna:false, smAntenna:false },
  '13 GHz': { normFreq: 13000.0, noiseDensity: noiseDensityDef, ptp:true, pmp:false, ptpAntenna:false, smAntenna:false },
  '11 GHz': { normFreq: 11200.0, noiseDensity: noiseDensityDef, ptp:true, pmp:false, ptpAntenna:false, smAntenna:false },
  '10 GHz': { normFreq: 10340.0, noiseDensity: noiseDensityDef, ptp:true, pmp:false, ptpAntenna:false, smAntenna:false },
  '8 GHz': { normFreq: 8112.5, noiseDensity: noiseDensityDef, ptp:true, pmp:true, ptpAntenna:false, smAntenna:true },
  '7 GHz': { normFreq: 7500.0, noiseDensity: noiseDensityDef, ptp:true, pmp:true, ptpAntenna:false, smAntenna:true },
  'Upper 6 GHz': { normFreq: 6750.0, noiseDensity: noiseDensityDef, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  'Lower 6 GHz': { normFreq: 6175.0, noiseDensity: noiseDensityDef, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  '5.9 GHz': { normFreq: 5902.5, noiseDensity: -104, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  '5.8 GHz': { normFreq: 5800.0, noiseDensity: -90, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  '5.4 GHz': { normFreq: 5597.5, noiseDensity: -104, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  '5.3 GHz': { normFreq: 5410.0, noiseDensity: -104, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  '5.2 GHz': { normFreq: 5300.0, noiseDensity: -104, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  '5.1 GHz': { normFreq: 5200.0, noiseDensity: -104, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  '4.9 GHz': { normFreq: 4950.0, noiseDensity: -104, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  '4.8 GHz': { normFreq: 4850.0, noiseDensity: -104, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  '4.7 GHz': { normFreq: 4700.0, noiseDensity: -104, ptp:false, pmp:true, ptpAntenna:true, smAntenna:true },
  '3.9 GHz': { normFreq: 4000.0, noiseDensity: noiseDensityDef, ptp:false, pmp:true, ptpAntenna:true, smAntenna:true },
  '3.6 GHz': { normFreq: 3675.0, noiseDensity: noiseDensityDef, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  '3.5 GHz': { normFreq: 3475.0, noiseDensity: noiseDensityDef, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  '2.6 GHz': { normFreq: (2570 + 2620) / 2.0, noiseDensity: noiseDensityDef, ptp:false, pmp:true, ptpAntenna:true, smAntenna:true },
  '2.5 GHz': { normFreq: 2592.5, noiseDensity: noiseDensityDef, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  '2.4 GHz': { normFreq: 2441.75, noiseDensity: noiseDensityDef, ptp:true, pmp:false, ptpAntenna:true, smAntenna:true },
  '2.3 GHz': { normFreq: (2300 + 2400) / 2.0, noiseDensity: noiseDensityDef, ptp:false, pmp:false, ptpAntenna:true, smAntenna:true },
  '1.4 GHz': { normFreq: 1429.5, noiseDensity: noiseDensityDef, ptp:false, pmp:true, ptpAntenna:true, smAntenna:true },
  '900 MHz': { normFreq: 931.0, noiseDensity: -85, ptp:true, pmp:true, ptpAntenna:true, smAntenna:true },
  '700 MHz': { normFreq: 772.5, noiseDensity: noiseDensityDef, ptp:false, pmp:true, ptpAntenna:true, smAntenna:true },
  'UHF 450-470 MHz': { normFreq: 460.0, noiseDensity: noiseDensityDef, ptp:false, pmp:true, ptpAntenna:true, smAntenna:true },
  'UHF 430-450 MHz': { normFreq: 440.0, noiseDensity: noiseDensityDef, ptp:false, pmp:true, ptpAntenna:true, smAntenna:true },
  'UHF 406-430 MHz': { normFreq: 418.0, noiseDensity: noiseDensityDef, ptp:false, pmp:true, ptpAntenna:true, smAntenna:true },
};

export const NOMINAL_FREQUENCY_MAP = Object.fromEntries(
  Object.keys(bands).map((band) => [band, bands[band]['normFreq']])
);

export const VALID_PTP_BANDS = Object.keys(pickBy(bands, (b) => b.ptp));

export const VALID_PTP_CUSTOM_ANTENNA_BANDS = Object.keys(
  pickBy(bands, (b) => b.ptp && b.ptpAntenna)
);

export const VALID_SM_CUSTOM_ANTENNA_BANDS = Object.keys(
  pickBy(bands, (b) => b.pmp && b.smAntenna)
);

export const VALID_PMP_BANDS = Object.keys(pickBy(bands, (b) => b.pmp));
