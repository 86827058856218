import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { useCSVDownloader } from 'react-papaparse';
import { Button, Dropdown, Message, Popup, Segment } from 'semantic-ui-react';
import { getWithAuth, postWithAuth } from 'src/api';
import messages from 'src/messages';
import { store } from 'src/store';
import { runWithConfirmation } from '../../utils/useful_functions';
import { uiConfirmAction } from '../mainframe/mainframe.reducer';
import CreatePTPLinkModal from './CreatePTPLinkModal';
import { LPTable } from 'src/components/tables/LPTable';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLpTable } from 'src/components/tables/internal/useLpTable';
import { usePtpColumns } from 'src/components/tables/internal/usePtpColumns';
import { toast } from 'react-toastify';
import {
  getCSVData,
  removeLinkSuffix,
} from 'src/components/tables/ColumnUtils';
import sortBy from 'lodash/sortBy';
import produce from 'immer';
import { GREY_COLOR } from 'src/app.constants';
import { createPTPTableQueryKey } from 'src/components/tables/internal/TableQueryKeys';

function PTPLinksTable({ intl }) {
  const { formatMessage } = intl;
  const { CSVDownloader } = useCSVDownloader();

  const {
    projectId,
    projectName,
    permissionWrite,
    networkSiteCount,
    ptpLinksCount,
    userLimits,
  } = useSelector((state: RootStateOrAny) => state.mainFrame);

  const qc = useQueryClient();
  const queryKey = createPTPTableQueryKey(projectId);
  const { data: tableData } = useQuery({
    queryKey,
    initialData: { rows: [], visible: [] },
    queryFn: () => getWithAuth(`project/${projectId}/ptp/table`),
  });

  const { columns } = usePtpColumns() as any;
  const tableModel = useLpTable({
    data: tableData.rows,
    visibleColumns: tableData.visible,
    columns,
    getRowId: (row: any) => `${row.id}-${row.path_index}`,
  });

  const deleteSelection = useMutation({
    mutationFn: () => {
      const selectedRows = tableModel.table
        .getSelectedRowModel()
        .rows.map((row) => row.original.id);

      if (selectedRows.length === 0) {
        return Promise.resolve([]);
      }

      return postWithAuth(`project/${projectId}/ptp`, selectedRows, 'DELETE');
    },
    onSuccess: (deletedIds) => {
      qc.setQueryData(queryKey, (prev: any) => {
        return {
          ...prev,
          rows: prev.rows.filter((row) => !deletedIds.includes(row.id)),
        };
      });
    },
    onError: () => {
      toast(<Message error>Failed to delete links</Message>, {
        autoClose: 5000,
      });
    },
  });

  const [createPTPLinkModal, setCreatePTPLinkModal] = useState(false);

  let addWarning = '';
  if (ptpLinksCount >= userLimits?.ptp_links) {
    addWarning = formatMessage(messages.maximumNumberError, {
      entityName: 'PTP Link',
      limit: userLimits?.ptp_links,
    });
  }

  const enableAdd =
    permissionWrite && addWarning === '' && networkSiteCount >= 2;
  const enableDelete =
    permissionWrite && tableModel.table.getSelectedRowModel().rows.length > 0;

  return (
    <Segment basic style={{ width: '100%' }}>
      {createPTPLinkModal && (
        <CreatePTPLinkModal
          intl={intl}
          onClose={() => setCreatePTPLinkModal(false)}
        ></CreatePTPLinkModal>
      )}

      {addWarning && <Message warning>{addWarning}</Message>}

      <LPTable queryKey={queryKey} model={tableModel} tableId="ptp_links_table">
        <LPTable.Toolbar>
          <Button
            icon="add"
            content={formatMessage(messages.add)}
            onClick={() => setCreatePTPLinkModal(true)}
            disabled={!enableAdd}
          />

          <Button
            icon="trash alternate"
            content={formatMessage(messages.delete)}
            disabled={!enableDelete}
            onClick={() => {
              store.dispatch(
                uiConfirmAction({
                  header: formatMessage(messages.deletePTPLinks),
                  message: formatMessage(messages.confirm),
                  size: 'mini',
                  onConfirm: () => {
                    deleteSelection.mutate();
                  },
                })
              );
            }}
          />

          <CSVDownloader
            filename={`${projectName}_ptp_links`}
            bom={true}
            config={{ delimiter: ',' }}
            data={() => getCSVData(tableModel.table)}
          >
            <Button
              icon="cloud download"
              content="Download CSV"
              disabled={tableModel.table.getRowCount() === 0}
            />
          </CSVDownloader>

          <CopyFrom
            formatMessage={formatMessage}
            rowData={tableData.rows}
            tableModel={tableModel}
            queryKey={queryKey}
          />
        </LPTable.Toolbar>
      </LPTable>
    </Segment>
  );
}

function CopyFrom(props: any) {
  const { formatMessage, rowData, tableModel, queryKey } = props;

  const { projectId, permissionWrite } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );

  const qc = useQueryClient();

  return (
    <Popup
      content={formatMessage(messages.copyFromHelp, { kind: 'link' })}
      position="right center"
      trigger={
        <Dropdown
          button
          fluid
          search
          selection
          labeled
          icon="paste"
          text={formatMessage(messages.dropdownPlaceHolderTxt)}
          options={sortBy(
            rowData
              .filter((row) => {
                const selected = tableModel.table
                  .getSelectedRowModel()
                  .rows.map((row) => row.original.id);
                return !selected.includes(row.id) && row.path_index === 0;
              })
              .map((row) => ({
                key: row.id,
                text: removeLinkSuffix(row.name),
                value: [row.id, removeLinkSuffix(row.name)],
              })),
            (opt) => opt.text
          )}
          disabled={
            !permissionWrite ||
            tableModel.table.getSelectedRowModel().rows.length === 0
          }
          className="icon sm-nd-dropdown-options"
          style={{ margin: '0' }}
          onChange={(_, data) => {
            const rows = tableModel.table.getSelectedRowModel().rows;
            const ids = [
              ...new Set(
                rows
                  .filter((row) => row.original.path_index === 0)
                  .map((row) => row.original.id)
              ),
            ];

            if (ids.length < 1) {
              return;
            }

            const [copyId, selectedName] = data.value as any;
            const qtyAndKind =
              rows.length === 1
                ? 'selected link'
                : `${rows.length} selected links`;

            runWithConfirmation({
              message: `${formatMessage(messages.copyFromConfirmation, {
                qtyAndKind,
              })} ${selectedName}`,
              onConfirm: () => {
                const payload = {
                  ids,
                  copy_id: copyId,
                  data: {},
                  bulk: 'copy',
                };

                postWithAuth(
                  `project/${projectId}/ptp/bulk`,
                  payload,
                  'PATCH'
                ).then(() => {
                  qc.setQueryData(queryKey, (prev: any[]) => {
                    return produce(prev, (draft) => {
                      for (const row of draft.rows) {
                        if (ids.includes(row.id)) {
                          row.strokeColor = GREY_COLOR;
                        }
                      }
                    });
                  });
                });
              },
            });
          }}
        />
      }
    />
  );
}

export default injectIntl(PTPLinksTable);
