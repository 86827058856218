import React from 'react';
import FormSelectTable from './controls/FormSelectTable';
import { ColDef } from 'ag-grid-community';
import { useFormContext, Controller } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import {
  polarity,
  manufacturerColumn,
  sizeColumn,
  descriptionColumn,
  partNumberColumn,
  directColumn,
  complianceColumn,
  gainColumn,
  userDefinedColumn,
  beamwidthColumn,
  getAntennaIndex,
  calcGain,
} from 'src/pages/antennas/common/AntennaColumns';
import {
  filterAndSortAntennas,
  antennaLabel,
} from '../../antennas/antenna-utils';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';

const getAntennaTableRows = (antennas) => {
  return antennas.map((antennaData) => {
    const { id, data } = antennaData;
    return {
      id,
      ...data,
      index: getAntennaIndex(data),
      rawData: antennaData,
      gain: calcGain(antennaData),
    };
  });
};

const antennaTableColumns: any = {
  ptp: [
    { ...polarity },
    { ...manufacturerColumn, width: 60 },
    { ...sizeColumn, width: 40 },
    { ...descriptionColumn, width: 200 },
    { ...partNumberColumn, width: 90 },
    { ...directColumn, width: 30 },
    { ...complianceColumn, width: 40 },
    { ...gainColumn, width: 50 },
    { ...userDefinedColumn },
  ],
  ap: [
    { ...polarity },
    { ...beamwidthColumn, width: 30 },
    { ...manufacturerColumn, width: 60 },
    { ...descriptionColumn, width: 200 },
    { ...partNumberColumn, width: 90 },
    { ...directColumn, width: 30 },
    { ...gainColumn, width: 50 },
  ],
  sm: [
    { ...polarity },
    { ...beamwidthColumn, width: 30 },
    { ...manufacturerColumn, width: 60 },
    { ...sizeColumn, width: 40 },
    { ...descriptionColumn, width: 200 },
    { ...partNumberColumn, width: 90 },
    { ...directColumn, width: 30 },
    { ...gainColumn, width: 50 },
    { ...userDefinedColumn },
  ],
};

function AntennaProtectionLabel(props) {
  const {
    getter,
    antennaProtection,
    antennaProtectionChoices,
    antennaProtectionLabels,
    refresh,
  } = props;
  const { control } = useFormContext();
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );

  if (antennaProtectionChoices?.length > 1) {
    const style = { display: 'flex', alignItems: 'center', columnGap: '1rem' };
    const choices = antennaProtectionChoices.map((c) => ({
      key: c,
      text: antennaProtectionLabels?.[c] ?? c,
      value: c,
    }));

    return (
      <div className="field antenna-protection-dropdown" style={style}>
        <label>Antenna Configuration</label>
        <Controller
          control={control}
          name={getter}
          render={({ field: { ref, onChange, ...rest } }) => (
            <Form.Select
              options={choices}
              {...rest}
              disabled={!permissionWrite}
              onChange={(_, data) => {
                onChange(data.value);
                // hack to force the end panel to redraw
                refresh();
              }}
            />
          )}
        />
      </div>
    );
  }

  return <label>Antenna Configuration: {antennaProtection}</label>;
}

function AntennaSelection({
  kind,
  choices,
  onChange,
  value,
  values = {},
  panels = [],
  warning,
  modified,
  defaultOpen = false,
  closeCallback,
  ...rest
}) {
  const columns: ColDef[] = antennaTableColumns[kind];
  const sortedAntennas = filterAndSortAntennas(choices);
  const gridData = getAntennaTableRows(sortedAntennas);
  const { permissionWrite } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );

  let useAntennaProtectionLabel =
    kind === 'ptp' && rest.antennaProtection != null;

  return (
    <Form.Field className={`${kind}-antenna-selection`}>
      {useAntennaProtectionLabel ? (
        <AntennaProtectionLabel
          getter={rest.protectionGetter}
          antennaProtection={rest.antennaProtection}
          antennaProtectionChoices={rest.antennaProtectionChoices}
          antennaProtectionLabels={rest.antennaProtectionLabels}
          refresh={rest.refresh}
        />
      ) : rest.hideLabel !== true ? (
        <label>{rest.label ?? 'Antenna'}</label>
      ) : null}
      <FormSelectTable
        value={value}
        gridRowData={gridData}
        columns={columns}
        onChange={onChange}
        values={values}
        panels={panels}
        modified={modified}
        warning={warning}
        labelFormatter={antennaLabel}
        disabled={!permissionWrite}
        defaultOpen={defaultOpen}
        setCanClose={closeCallback}
        gridOptions={{
          getRowStyle: (params) => {
            const { data, context } = params;
            const { id, is_obsolete, internal, user_defined } = data;
            let style: {
              fontWeight: string;
              color?: string;
              fontStyle?: string;
            } = {
              fontWeight: '700',
            };
            const selectedRowStyle = { backgroundColor: '#BCDFFB' };
            const selected = id == context?.rowId;
            if (is_obsolete) {
              style = {
                color: 'grey',
                fontStyle: 'italic',
                fontWeight: '400',
              };
            } else if (!internal) {
              style = {
                fontWeight: '400',
              };
            }
            if (selected) {
              style = { ...style, ...selectedRowStyle };
            }
            if (user_defined) {
              style = {
                fontWeight: '700',
              };
            }
            return style;
          },
        }}
        {...rest}
      ></FormSelectTable>
    </Form.Field>
  );
}

export default AntennaSelection;
