import { ColumnMeta, Row, Table } from '@tanstack/react-table';
import React, { useState } from 'react';
import { Button, Message } from 'semantic-ui-react';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { postWithAuth } from 'src/api';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { GREY_COLOR } from 'src/app.constants';
import {
  editRowEquals,
  editRowSelector,
  valuesForChoicesKind,
} from '../ColumnUtils';
import messages from 'src/messages';
import { useIntl } from 'react-intl';

function initialPtpValue(row, pathIndex, attr) {
  let r;
  const len = row.subRows.length;
  if (len > 1 && pathIndex < len) {
    r = row.subRows[pathIndex];
  } else {
    r = row;
  }
  return get(r.original, attr);
}

function initialAccessPointValue(row, radioNumber, attr) {
  let r;
  const idx = radioNumber - 1;
  const len = row.subRows.length;
  if (len > 1 && idx < len) {
    r = row.subRows[idx];
  } else {
    r = row;
  }
  return get(r.original, attr);
}

function initialValue(meta, row, rest) {
  const { attr, choicesKind } = meta;
  if (choicesKind === 'ptp') {
    return initialPtpValue(row, rest.path_index, attr);
  } else if (choicesKind === 'access_point') {
    return initialAccessPointValue(row, rest.radio_number ?? 1, attr);
  } else if (choicesKind === 'subscriber') {
    return initialPtpValue(row, rest.path_index, attr);
  } else if (choicesKind === 'site' || choicesKind === 'mesh') {
    return get(row.original, attr);
  }

  throw new Error(`Unknown choices kind: ${choicesKind}`);
}

function useRow({ context, subRow }: any) {
  if (subRow != null) {
    return subRow;
  } else {
    return context.row;
  }
}

type TContext = {
  projectId: string;
  queryKey: QueryKey;
  row: any;
  table: Table<any>;
};

export function CellEditor<T>({
  context,
  close,
  meta,
  subRow,
  setAntennaCanClose,
  ...rest
}: {
  context: TContext;
  close: (e: any) => void;
  meta: ColumnMeta<T, unknown>;
  subRow?: Row<T>;
  setAntennaCanClose: (canClose: boolean) => void;
} & Record<string, any>) {
  const { formatMessage } = useIntl();
  const qc = useQueryClient();
  const { projectId, queryKey, table } = context;
  const row = useRow({ context, subRow });
  const [value, setValue] = useState(initialValue(meta, row, rest));
  const [error, setError] = useState(null);
  const editor = meta.editor;

  let urlAttr;
  if (meta.choicesKind === 'subscriber') {
    urlAttr = 'remote_end_id';
  } else {
    urlAttr = 'id';
  }

  const save = useMutation({
    mutationFn: () => {
      const selectedRows = table.getSelectedRowModel().rows;

      let editUrl = `project/${projectId}/${meta.choicesKind}/${row.original[urlAttr]}/bulk_edit/save`;

      if (meta.choicesKind === 'site') {
        // sites need to specify the type of site when saving
        const searchParams = new URLSearchParams({
          is_network_site: row.original.is_network_site,
        });
        editUrl += `?${searchParams.toString()}`;
      }

      const extraRows = selectedRows
        .map((r) => ({
          ...valuesForChoicesKind(meta.choicesKind, r.original, true),
        }))
        .filter((r) => {
          const editedRow = valuesForChoicesKind(
            meta.choicesKind,
            row.original,
            true
          );
          // Don't include the "edited row" in the extra rows
          // (i.e. the one the user clicked the edit button on)
          return !Object.keys(r).every((key) => r[key] === editedRow[key]);
        });

      return postWithAuth(
        editUrl,
        {
          column_id: meta.columnId,
          value,
          extra_rows: extraRows,
          ...rest,
        },
        'PATCH'
      );
    },
    onSuccess: () => {
      // turn the rows grey
      const selectedRows = table
        .getSelectedRowModel()
        .rows.map((r) => editRowSelector(meta.choicesKind, r.original));

      qc.setQueryData(queryKey, (prev: any) => {
        return {
          ...prev,
          rows: prev.rows.map((r) => {
            // if the row is the one we edited, turn it grey
            if (r.id === row.original.id) {
              return { ...r, strokeColor: GREY_COLOR };
            }

            // if the row is one of the selected rows, turn it grey
            if (
              selectedRows.some((sr) => editRowEquals(meta.choicesKind, r, sr))
            ) {
              return { ...r, strokeColor: GREY_COLOR };
            }
            return r;
          }),
        };
      });
    },
    onError: (err) => {
      console.error(err);
      toast(<Message error>Failed to save changes</Message>, {
        autoClose: 2000,
      });
    },
    onSettled: () => {
      close(undefined);
    },
  });

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {editor &&
        React.createElement(editor, {
          getChoices: meta.getChoices,
          projectId,
          row,
          meta: { ...meta, attr: meta.attr },
          value,
          setValue,
          setError,
          setAntennaCanClose,
        })}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={close} disabled={save.isLoading} type="button">
          Cancel
        </Button>
        <Button
          // TODO we should only save when the value has changed
          onClick={(e) => {
            e.stopPropagation();
            if (value != null) {
              save.mutate();
            } else {
              close(undefined);
            }
          }}
          color="blue"
          type="button"
          disabled={save.isLoading || error === true}
        >
          {formatMessage(messages.ok)}
        </Button>
      </div>
    </div>
  );
}
