// This file is automatically generated by backend/app/scripts/generate_columns.py
import { createColumnHelper } from '@tanstack/react-table';
import * as U from './ColumnUtils';
import * as E from './editing/editors';

export type NDTableData = {

  id: string;
  radio_number: number;
  name?: string;
  band?: string;
  product?: string;
  regulation?: string;
  io_connectivity?: string;
  sync_input?: string;
  tr_spacing?: string;
  bandwidth?: string;
  tx_channel?: string;
  tx_channel_2?: string;
  height_m?: number | 'N/A';
  sm_range?: number | 'N/A';
  range_units?: string;
  frame_period?: string;
  sm_registration_limit?: number | 'N/A';
  sm_registration_limit_2?: number | 'N/A';
  symmetry?: string;
  sync?: string;
  highest_mod_mode?: string;
  pmp_max_mod_mode?: string;
  sm_modulation_mode?: string;
  sm_max_mod_mode?: string;
  sm_min_mod_mode?: string;
  ap_modulation_mode?: string;
  control_slots?: string;
  dl_ul_ratio?: string;
  downlink_data?: string;
  dual_payload?: string;
  aes_encryption?: string;
  antenna_color?: string;
  guard_interval?: string;
  lowest_ethernet_mode?: string;
  operating_mode?: string;
  polarity?: string;
  polarity_2?: string;
  golay?: string;
  golay_2?: string;
  gas_group?: string;
  eirp?: number | 'N/A';
  use_user_eirp?: boolean | 'N/A';
  user_eirp?: number | 'N/A';
  power?: number | 'N/A';
  power_mw?: number | 'N/A';
  use_user_power?: boolean | 'N/A';
  use_user_power_mw?: boolean | 'N/A';
  user_power?: number | 'N/A';
  user_power_mw?: number | 'N/A';
  noise?: number | 'N/A';
  use_noise?: boolean | 'N/A';
  noise_sm?: number | 'N/A';
  use_noise_sm?: boolean | 'N/A';
  receive_target_level?: number | 'N/A';
  connected_subscribers?: number | 'N/A';
  unconnected_subscribers?: number | 'N/A';
  dl_throughput?: string;
  ul_throughput?: string;
  total_throughput?: string;
  external_gain?: number | 'N/A';
  cable_loss?: number | 'N/A';
  node_type?: string;
  power_from_switch?: boolean | 'N/A';
  identifier?: string;
  carrier_qty?: number | 'N/A';
  component_carrier_qty?: number | 'N/A';
  transmit_frequency?: number | 'N/A';
  antenna?: string;
  antenna_azimuth?: number | 'N/A';
  antenna_beamwidth?: number | 'N/A';
  antenna_tilt?: number | 'N/A';
  latitude?: number | 'N/A';
  longitude?: number | 'N/A';
  mac_address?: string;
  modelled_beamwidth?: number | 'N/A';
  subscriber_qty?: number | 'N/A';
  mesh_link_qty?: number | 'N/A';
  connected_subscribers_sector_1?: number | 'N/A';
  connected_subscribers_sector_2?: number | 'N/A';
  sector_1_links_with?: string;
  sector_2_links_with?: string;
  mesh_links_sector_1?: string;
  mesh_links_sector_2?: string;
  dl_throughput_sector_1?: string;
  ul_throughput_sector_1?: string;
  total_throughput_sector_1?: string;
  dl_throughput_sector_2?: string;
  ul_throughput_sector_2?: string;
  total_throughput_sector_2?: string;
  site_name?: string;
  msn?: string;
}

const columnHelper = createColumnHelper<NDTableData>()

export const columns = [
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Name',
    aggregationFn: 'firstChild',
    getGroupingValue: U.groupById<NDTableData>,
    cell: U.renderNDName<NDTableData>,
    aggregatedCell: U.renderGroupedNDName<NDTableData>,
    size: 200,
    enableHiding: false,
    sortingFn: 'basic',
  }),
  columnHelper.accessor('band', {
    id: 'band',
    header: 'Band',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'band',
      columnId: 'band',
      editorLabel: 'Band',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'band'),
    },
  }),
  columnHelper.accessor('product', {
    id: 'product',
    header: 'Product',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'product',
      columnId: 'product',
      editorLabel: 'Product',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'product'),
    },
  }),
  columnHelper.accessor('regulation', {
    id: 'regulation',
    header: 'Country',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'regulation',
      columnId: 'regulation',
      editorLabel: 'Country',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'regulation'),
    },
  }),
  columnHelper.accessor('io_connectivity', {
    id: 'io_connectivity',
    header: 'I/O Connectivity',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'io_connectivity',
      columnId: 'io_connectivity',
      editorLabel: 'I/O Connectivity',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'io_connectivity'),
    },
  }),
  columnHelper.accessor('sync_input', {
    id: 'sync_input',
    header: 'Sync Input',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'sync_input',
      columnId: 'sync_input',
      editorLabel: 'Sync Input',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'sync_input'),
    },
  }),
  columnHelper.accessor('tr_spacing', {
    id: 'tr_spacing',
    header: 'T/R Spacing (MHz)',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'tr_spacing',
      columnId: 'tr_spacing',
      editorLabel: 'T/R Spacing (MHz)',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'tr_spacing'),
    },
  }),
  columnHelper.accessor('bandwidth', {
    id: 'bandwidth',
    header: 'Bandwidth',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'bandwidth',
      columnId: 'bandwidth',
      editorLabel: 'Bandwidth',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'bandwidth'),
    },
  }),
  columnHelper.accessor('tx_channel', {
    id: 'tx_channel',
    header: 'Channel - Sector 1',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'tx_channel',
      columnId: 'tx_channel',
      editorLabel: 'Channel - Sector 1',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'tx_channel'),
    },
  }),
  columnHelper.accessor('tx_channel_2', {
    id: 'tx_channel_2',
    header: 'Channel - Sector 2',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'tx_channel_2',
      columnId: 'tx_channel_2',
      editorLabel: 'Channel - Sector 2',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'tx_channel_2'),
    },
  }),
  columnHelper.accessor('height_m', {
    id: 'height_m',
    header: U.headerWithHeightUnits('Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
      editable: true,
      choicesKind: 'access_point',
      attr: 'height_m',
      columnId: 'height_m',
      editorLabel: 'Height',
      kind: 'height',
      editor: E.NumberEditor,
      editProps: {
        units: 'heightUnits',
        min: 0,
        max: 3000,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('sm_range', {
    id: 'sm_range',
    header: 'SM Range',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
      editable: true,
      choicesKind: 'access_point',
      attr: 'sm_range',
      columnId: 'sm_range',
      editorLabel: 'SM Range',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        max: 300,
        precision: 3,
      },
    },
  }),
  columnHelper.accessor('range_units', {
    id: 'range_units',
    header: 'Range Units',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'range_units',
      columnId: 'range_units',
      editorLabel: 'Range Units',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'range_units'),
    },
  }),
  columnHelper.accessor('frame_period', {
    id: 'frame_period',
    header: 'Frame Period (ms)',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'frame_period',
      columnId: 'frame_period',
      editorLabel: 'Frame Period (ms)',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'frame_period'),
    },
  }),
  columnHelper.accessor('sm_registration_limit', {
    id: 'sm_registration_limit',
    header: 'Max Registrations Allowed',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'access_point',
      attr: 'sm_registration_limit',
      columnId: 'sm_registration_limit',
      editorLabel: 'Max Registrations Allowed',
      kind: 'number',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'sm_registration_limit'),
    },
  }),
  columnHelper.accessor('sm_registration_limit_2', {
    id: 'sm_registration_limit_2',
    header: 'Max Registrations Allowed - Sector 2',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'access_point',
      attr: 'sm_registration_limit_2',
      columnId: 'sm_registration_limit_2',
      editorLabel: 'Max Registrations Allowed - Sector 2',
      kind: 'number',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'sm_registration_limit_2'),
    },
  }),
  columnHelper.accessor('symmetry', {
    id: 'symmetry',
    header: 'Symmetry',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'symmetry',
      columnId: 'symmetry',
      editorLabel: 'Symmetry',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'symmetry'),
    },
  }),
  columnHelper.accessor('sync', {
    id: 'sync',
    header: 'Sync',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'sync',
      columnId: 'sync',
      editorLabel: 'Sync',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'sync'),
    },
  }),
  columnHelper.accessor('highest_mod_mode', {
    id: 'highest_mod_mode',
    header: 'Highest Mod Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'highest_mod_mode',
      columnId: 'highest_mod_mode',
      editorLabel: 'Highest Mod Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'highest_mod_mode'),
    },
  }),
  columnHelper.accessor('pmp_max_mod_mode', {
    id: 'pmp_max_mod_mode',
    header: 'Maximum Mod Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'pmp_max_mod_mode',
      columnId: 'pmp_max_mod_mode',
      editorLabel: 'Maximum Mod Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'pmp_max_mod_mode'),
    },
  }),
  columnHelper.accessor('sm_modulation_mode', {
    id: 'sm_modulation_mode',
    header: 'SM Modulation Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'sm_modulation_mode',
      columnId: 'sm_modulation_mode',
      editorLabel: 'SM Modulation Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'sm_modulation_mode'),
    },
  }),
  columnHelper.accessor('sm_max_mod_mode', {
    id: 'sm_max_mod_mode',
    header: 'SM Maximum Mod Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'sm_max_mod_mode',
      columnId: 'sm_max_mod_mode',
      editorLabel: 'SM Maximum Mod Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'sm_max_mod_mode'),
    },
  }),
  columnHelper.accessor('sm_min_mod_mode', {
    id: 'sm_min_mod_mode',
    header: 'SM Minimum Mod Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'sm_min_mod_mode',
      columnId: 'sm_min_mod_mode',
      editorLabel: 'SM Minimum Mod Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'sm_min_mod_mode'),
    },
  }),
  columnHelper.accessor('ap_modulation_mode', {
    id: 'ap_modulation_mode',
    header: 'ND Modulation Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'ap_modulation_mode',
      columnId: 'ap_modulation_mode',
      editorLabel: 'ND Modulation Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'ap_modulation_mode'),
    },
  }),
  columnHelper.accessor('control_slots', {
    id: 'control_slots',
    header: 'Contention Slots',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('dl_ul_ratio', {
    id: 'dl_ul_ratio',
    header: 'DL/UL Ratio',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('downlink_data', {
    id: 'downlink_data',
    header: 'Downlink Data (%)',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('dual_payload', {
    id: 'dual_payload',
    header: 'Dual Payload',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'dual_payload',
      columnId: 'dual_payload',
      editorLabel: 'Dual Payload',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'dual_payload'),
    },
  }),
  columnHelper.accessor('aes_encryption', {
    id: 'aes_encryption',
    header: 'Encryption Variant',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('antenna_color', {
    id: 'antenna_color',
    header: 'Color',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'antenna_color',
      columnId: 'antenna_color',
      editorLabel: 'Color',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'antenna_color'),
    },
  }),
  columnHelper.accessor('guard_interval', {
    id: 'guard_interval',
    header: 'Guard Interval',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('lowest_ethernet_mode', {
    id: 'lowest_ethernet_mode',
    header: 'Lowest Ethernet Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'lowest_ethernet_mode',
      columnId: 'lowest_ethernet_mode',
      editorLabel: 'Lowest Ethernet Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'lowest_ethernet_mode'),
    },
  }),
  columnHelper.accessor('operating_mode', {
    id: 'operating_mode',
    header: 'MIMO Mode',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'operating_mode',
      columnId: 'operating_mode',
      editorLabel: 'MIMO Mode',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'operating_mode'),
    },
  }),
  columnHelper.accessor('polarity', {
    id: 'polarity',
    header: 'Polarity',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('polarity_2', {
    id: 'polarity_2',
    header: 'Polarity - Sector 2',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('golay', {
    id: 'golay',
    header: 'Golay',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('golay_2', {
    id: 'golay_2',
    header: 'Golay - Sector 2',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('gas_group', {
    id: 'gas_group',
    header: 'Gas Group',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'gas_group',
      columnId: 'gas_group',
      editorLabel: 'Gas Group',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'gas_group'),
    },
  }),
  columnHelper.accessor('eirp', {
    id: 'eirp',
    header: 'EIRP (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('use_user_eirp', {
    id: 'use_user_eirp',
    header: 'User EIRP (dBm) Limit?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'use_user_eirp',
      columnId: 'use_user_eirp',
      editorLabel: 'User EIRP (dBm) Limit?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('access_point', 'use_user_eirp'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('user_eirp', {
    id: 'user_eirp',
    header: 'EIRP Limit (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'access_point',
      attr: 'user_eirp',
      columnId: 'user_eirp',
      editorLabel: 'EIRP Limit (dBm)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        max: 60,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('power', {
    id: 'power',
    header: 'Power (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('power_mw', {
    id: 'power_mw',
    header: 'Power (mW)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('use_user_power', {
    id: 'use_user_power',
    header: 'User Power (dBm) Limit?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'use_user_power',
      columnId: 'use_user_power',
      editorLabel: 'User Power (dBm) Limit?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('access_point', 'use_user_power'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('use_user_power_mw', {
    id: 'use_user_power_mw',
    header: 'User Power (mW) Limit?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'use_user_power_mw',
      columnId: 'use_user_power_mw',
      editorLabel: 'User Power (mW) Limit?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('access_point', 'use_user_power_mw'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('user_power', {
    id: 'user_power',
    header: 'Power Limit (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'access_point',
      attr: 'user_power',
      columnId: 'user_power',
      editorLabel: 'Power Limit (dBm)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: -40,
        max: 40,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('user_power_mw', {
    id: 'user_power_mw',
    header: 'Power Limit (mW)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'access_point',
      attr: 'user_power_mw',
      columnId: 'user_power_mw',
      editorLabel: 'Power Limit (mW)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 50,
        max: 10000,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('noise', {
    id: 'noise',
    header: 'Interference (dBm in b/w)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
      editable: true,
      choicesKind: 'access_point',
      attr: 'noise',
      columnId: 'noise',
      editorLabel: 'Interference (dBm in b/w)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: -144,
        max: -40,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('use_noise', {
    id: 'use_noise',
    header: 'Interference?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'use_noise',
      columnId: 'use_noise',
      editorLabel: 'Interference?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('access_point', 'use_noise'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('noise_sm', {
    id: 'noise_sm',
    header: 'Default SM Interference (dBm in b/w)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
      editable: true,
      choicesKind: 'access_point',
      attr: 'noise_sm',
      columnId: 'noise_sm',
      editorLabel: 'Default SM Interference (dBm in b/w)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: -144,
        max: -40,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('use_noise_sm', {
    id: 'use_noise_sm',
    header: 'Default SM Interference?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'use_noise_sm',
      columnId: 'use_noise_sm',
      editorLabel: 'Default SM Interference?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('access_point', 'use_noise_sm'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('receive_target_level', {
    id: 'receive_target_level',
    header: 'SM Receive Target Level (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'access_point',
      attr: 'receive_target_level',
      columnId: 'receive_target_level',
      editorLabel: 'SM Receive Target Level (dBm)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: -77,
        max: -37,
        precision: 0,
      },
    },
  }),
  columnHelper.accessor('connected_subscribers', {
    id: 'connected_subscribers',
    header: 'Connected Subscribers',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('unconnected_subscribers', {
    id: 'unconnected_subscribers',
    header: 'Unconnected Subscribers',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('dl_throughput', {
    id: 'dl_throughput',
    header: 'DL Throughput',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('ul_throughput', {
    id: 'ul_throughput',
    header: 'UL Throughput',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('total_throughput', {
    id: 'total_throughput',
    header: 'Total Throughput',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('external_gain', {
    id: 'external_gain',
    header: 'External Gain (dBi)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('cable_loss', {
    id: 'cable_loss',
    header: 'Cable Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('node_type', {
    id: 'node_type',
    header: 'Node Type',
    size: 60,
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'node_type',
      columnId: 'node_type',
      editorLabel: 'Node Type',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'node_type'),
    },
  }),
  columnHelper.accessor('power_from_switch', {
    id: 'power_from_switch',
    header: 'Power from Switch?',
    cell: U.renderYesNo(),
    size: 60,
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'power_from_switch',
      columnId: 'power_from_switch',
      editorLabel: 'Power from Switch?',
      kind: 'boolean',
      editor: E.BooleanEditor,
      getChoices: E.getChoices('access_point', 'power_from_switch'),
      boolOptions: ["Yes", "No"],
    },
  }),
  columnHelper.accessor('identifier', {
    id: 'identifier',
    header: 'Carrier Name',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('carrier_qty', {
    id: 'carrier_qty',
    header: 'Number of Carriers',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'access_point',
      attr: 'carrier_qty',
      columnId: 'carrier_qty',
      editorLabel: 'Number of Carriers',
      kind: 'number',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('access_point', 'carrier_qty'),
    },
  }),
  columnHelper.accessor('component_carrier_qty', {
    id: 'component_carrier_qty',
    header: 'Component Carriers',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('transmit_frequency', {
    id: 'transmit_frequency',
    header: 'Tx Frequency (MHz)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
    },
  }),
  columnHelper.accessor('antenna', {
    id: 'antenna',
    header: 'Antenna',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'access_point',
      attr: 'antenna',
      columnId: 'antenna',
      editorLabel: 'Antenna',
      kind: 'string',
      editor: E.AntennaEditor,
      getChoices: E.getChoices('access_point', 'antenna'),
    },
  }),
  columnHelper.accessor('antenna_azimuth', {
    id: 'antenna_azimuth',
    header: 'Antenna Azimuth, True N (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'access_point',
      attr: 'antenna_azimuth',
      columnId: 'antenna_azimuth',
      editorLabel: 'Antenna Azimuth, True N (deg)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        max: 359,
        precision: 0,
      },
    },
  }),
  columnHelper.accessor('antenna_beamwidth', {
    id: 'antenna_beamwidth',
    header: 'Antenna Beamwidth (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('antenna_tilt', {
    id: 'antenna_tilt',
    header: 'Antenna Elevation (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('latitude', {
    id: 'latitude',
    header: 'Latitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 5, converter: U.latitude_Table }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 5, converter: U.latitude_Table }),
    },
  }),
  columnHelper.accessor('longitude', {
    id: 'longitude',
    header: 'Longitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 5, converter: U.longitude_Table }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 5, converter: U.longitude_Table }),
    },
  }),
  columnHelper.accessor('mac_address', {
    id: 'mac_address',
    header: 'MAC Address',
    cell: U.renderConvertedString({ converter: U.macAddressGetter_Table }),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('modelled_beamwidth', {
    id: 'modelled_beamwidth',
    header: 'Modeled Beamwidth (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
      editable: true,
      choicesKind: 'access_point',
      attr: 'modelled_beamwidth',
      columnId: 'modelled_beamwidth',
      editorLabel: 'Modeled Beamwidth (deg)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 5,
        max: 360,
        precision: 0,
      },
    },
  }),
  columnHelper.accessor('subscriber_qty', {
    id: 'subscriber_qty',
    header: 'Number of Subscriber Modules',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('mesh_link_qty', {
    id: 'mesh_link_qty',
    header: 'Mesh Links',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('connected_subscribers_sector_1', {
    id: 'connected_subscribers_sector_1',
    header: 'Connected Subscribers - Sector 1',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('connected_subscribers_sector_2', {
    id: 'connected_subscribers_sector_2',
    header: 'Connected Subscribers - Sector 2',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('sector_1_links_with', {
    id: 'sector_1_links_with',
    header: 'Sector 1 Links with',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('sector_2_links_with', {
    id: 'sector_2_links_with',
    header: 'Sector 2 Links with',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('mesh_links_sector_1', {
    id: 'mesh_links_sector_1',
    header: 'Mesh Links - Sector 1',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('mesh_links_sector_2', {
    id: 'mesh_links_sector_2',
    header: 'Mesh Links - Sector 2',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('dl_throughput_sector_1', {
    id: 'dl_throughput_sector_1',
    header: 'DL Throughput - Sector 1',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('ul_throughput_sector_1', {
    id: 'ul_throughput_sector_1',
    header: 'UL Throughput - Sector 1',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('total_throughput_sector_1', {
    id: 'total_throughput_sector_1',
    header: 'Total Throughput - Sector 1',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('dl_throughput_sector_2', {
    id: 'dl_throughput_sector_2',
    header: 'DL Throughput - Sector 2',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('ul_throughput_sector_2', {
    id: 'ul_throughput_sector_2',
    header: 'UL Throughput - Sector 2',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('total_throughput_sector_2', {
    id: 'total_throughput_sector_2',
    header: 'Total Throughput - Sector 2',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('site_name', {
    id: 'site_name',
    header: 'Site Name',
    aggregationFn: 'firstChild',
    sortingFn: 'basic',
  }),
  columnHelper.accessor('msn', {
    id: 'msn',
    header: 'MSN',
    aggregationFn: 'firstChild',
  }),
]
