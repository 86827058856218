import React, { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Form, Button, Header, Icon, Loader, Segment } from 'semantic-ui-react';
import { get, set, isEmpty } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import {
  setSelectedPMPLinks,
  uiConfirmAction,
} from '../../mainframe/mainframe.reducer';
import messages from 'src/messages';
import { Accordion } from 'semantic-ui-react';
import NDGeneralPanel from './NDGeneralPanel';
import { postWithAuth } from 'src/api';
import NDEquipmentPanel from './NDEquipmentPanel';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getProductPanels } from './utils';
import NDAntennaPanel from './NDAntennaPanel';
import { useSelector } from 'react-redux';
import { getProductFamily } from 'src/pages/pmp/PMPProductFamilyMap';
import NDPowerPanel from './NDPowerPanel';
import NDSectorPanel from './NDSectorPanel';
import RouteLeavingGuard from 'src/components/RouteLeavingGuard';
import NDComponentCarriersPanel from './NDComponentCarriersPanel';
import StoredAccordion from 'src/components/StoredAccordion';
import AccessPointPerformance from '../aplayout/AccessPointPerformance';
import { updatePostData } from './configs/config-utils';
import { queryCacheKeys } from './useNDData';
import WarningAccordion from '../WarningAccordion';
import { RootStateOrAny, store } from 'src/store';
import { isPMP450v } from '../config-utils';
import { ReportDownloadButton } from './../../ptp/PTPLinkPanel';
import AccessPointBomModal from 'src/pages/equipment/accesspoint/BomModal';
import { PanelHeading, ToolbarSeparator } from 'src/components/PanelHeading';
import { ShowInMap } from 'src/components/ShowInMap';
import { FormResetContext } from '../utils';
import PMPLinksPanel from '../PMPLinksPanel';
import MeshListPanel from 'src/pages/mesh/MeshLinksPanel';
import SubscriberModulesTable from './SubscriberModulesTable';
import MeshLinksTable from './MeshLinksTable';

export const getChoicePayload = (formValues) => {
  const { radios, ...rest } = formValues;
  const band = get(formValues, 'radios.0.equipment.band');
  const product = get(formValues, 'radios.0.equipment.product');
  const carrierQty = get(formValues, 'radios.0.equipment.carrier_qty');
  // when we switch from multi radio products to single radio products
  // (eg: V5000 to PMP450i etc) we see the stale radio exists in form state
  // exist which may cause issues. So we send only the required radio
  if (band === '60 GHz' || (product === 'PMP 450v 4x4' && carrierQty == 2)) {
    const radio1 = formValues['radios'][1];
    if (radio1 != null && band === '60 GHz') {
      radio1['equipment']['band'] = band;
      radio1['equipment']['product'] = product;
      radio1['equipment']['bandwidth'] = get(
        formValues,
        'radios.0.equipment.bandwidth'
      );
    }
    return formValues;
  } else {
    return {
      ...rest,
      radios: [radios[0]],
    };
  }
};

function NDForm({ projectId, id, ap, choices, formatMessage }) {
  const [resetValue, setResetValue] = useState(0);
  const [modified, setModified] = useState<boolean>(false);
  const needsRefresh = useSelector(
    (state: RootStateOrAny) => state.mainFrame.needsRefresh.accessPointPanel
  );
  const prefs = useSelector((state: RootStateOrAny) => state.mainFrame.prefs);
  const permissionWrite = useSelector(
    (state: RootStateOrAny) => state.mainFrame.permissionWrite
  );
  const predictionModel = useSelector(
    (state: RootStateOrAny) => state.mainFrame.predictionModel
  );
  const formMethods = useForm({ defaultValues: ap });
  const { handleSubmit, getValues, setValue, reset, formState } = formMethods;
  const { isSubmitting, errors } = formState;
  const [name, product] = getValues(['name', 'radios.0.equipment.product']);
  let family = getProductFamily(product);
  const siteId = get(ap, 'site.id');
  const qc = useQueryClient();
  const panels = getProductPanels(product, false);
  const { key, choicesKey } = queryCacheKeys(projectId, false, id);

  const clearCache = () => {
    setResetValue((prev) => prev + 1);
    reset();
    qc.invalidateQueries({
      queryKey: key,
    });
    setModified(false);
  };

  // we need to clear the react query cache on component
  // unmount to avoid issues like rendering the stale data
  // when we search from menu and navigate to the page
  useEffect(() => {
    return () => {
      reset();
      qc.removeQueries({
        queryKey: key,
      });
    };
  }, [projectId, id]);

  const onSubmit = async (formData: FieldValues) => {
    if (
      ap.site.latitude == get(formData, 'radios.0.antennas.0.latitude') &&
      ap.site.longitude == get(formData, 'radios.0.antennas.0.longitude')
    ) {
      set(formData, 'radios.0.antennas.0.latitude', ap.site.latitude);
      set(formData, 'radios.0.antennas.0.longitude', ap.site.longitude);
    }
    formData = updatePostData(formData);
    //const finalData = merge(ap, formData);
    await postWithAuth(
      `project/${projectId}/access_point/${id}`,
      formData,
      'PUT'
    );
    setModified(false);
  };

  const refreshChoices = ({ field }) => {
    if (!field) {
      // should not occur in production (programmer error)
      throw new Error('Refresh choices requested without field info');
    }

    const mergedFormData = getValues(); //merge(ap, getValues());
    // request new choices based on the updated form state (i.e.
    // in response to a user action: onChange)
    updateChoices.mutate({
      ap: mergedFormData,
      attr: field.attrName,
    });
  };

  const updateChoices = useMutation(
    ({ ap, attr }: { ap: any; attr: string }) => {
      const payload = getChoicePayload(ap);
      const choicesPayload = {
        project_id: projectId,
        ap: payload,
        changed: attr,
      };
      if (attr !== 'product') {
        const prevProduct = get(ap, 'radios.0.equipment.product');
        setValue('product.prev', prevProduct);
      }
      return Promise.allSettled([
        postWithAuth(`equipment/access_point/choices`, choicesPayload),
        Promise.resolve(attr),
      ]);
    },
    {
      onSuccess: (updateChoicesResponse) => {
        const equipmentChoicesResponse = (
          updateChoicesResponse[0] as PromiseFulfilledResult<any>
        ).value;
        const attr = (updateChoicesResponse[1] as PromiseFulfilledResult<any>)
          .value;
        // TODO after changing the backend api we can remove the below code
        // from 84 to 88 which changes equipment choices array to object
        const equipmentChoiceMap = {};
        equipmentChoicesResponse['equipment'].forEach((attr) => {
          equipmentChoiceMap[attr.attr_name] = attr;
        });
        equipmentChoicesResponse['equipment'] = equipmentChoiceMap;
        // update choices query data to cause form to rerender
        // with updated correct choices
        qc.setQueryData(choicesKey, () => ({ ...equipmentChoicesResponse }));

        // update react hook form internal state based on
        // new choices on a field by field basis (e.g. when form
        // values fall out of sync with choices like changing product
        // updating the antenna choices)
        const product = equipmentChoicesResponse.equipment['product']['value'];
        const newPanels = getProductPanels(product, false);
        newPanels.syncFormState(
          getValues,
          setValue,
          newPanels,
          equipmentChoicesResponse,
          attr
        );
      },
    }
  );

  return (
    <FormResetContext.Provider value={resetValue}>
      <div className="aplayout" style={{ height: '100%' }}>
        <FormProvider {...formMethods}>
          <Form
            className="panel-with-heading"
            method="post"
            onSubmit={handleSubmit(onSubmit)}
            style={{ height: '100%' }}
          >
            <Title
              localProduct={product}
              projectId={projectId}
              siteId={siteId}
              powerFromSwitch={ap.properties.power_from_switch}
              modified={modified}
              isSubmitting={isSubmitting}
              id={id}
              name={name}
              dirty={ap.dirty}
              errors={errors}
              clearCache={clearCache}
              formatMessage={formatMessage}
            ></Title>

            <div
              className="main-panel"
              style={{ display: 'flex', columnGap: '6px' }}
            >
              <div
                id="ap-details"
                className="first-col"
                style={{ padding: '0.8rem' }}
              >
                <Accordion
                  exclusive={false}
                  fluid
                  className="product-family-panels"
                >
                  <WarningAccordion obj={ap} />
                  <NDGeneralPanel
                    ap={ap}
                    title={'General'}
                    setModified={setModified}
                  ></NDGeneralPanel>
                  <NDEquipmentPanel
                    ap={ap}
                    modified={modified}
                    setModified={setModified}
                    refreshChoices={refreshChoices}
                    choices={choices}
                    panel={panels.equipment}
                  ></NDEquipmentPanel>
                  {panels.sector && (
                    <NDSectorPanel
                      ap={ap}
                      modified={modified}
                      setModified={setModified}
                      refreshChoices={refreshChoices}
                      choices={choices}
                      panel={panels.sector}
                    ></NDSectorPanel>
                  )}
                  {panels.product && (
                    <NDEquipmentPanel
                      ap={ap}
                      modified={modified}
                      setModified={setModified}
                      refreshChoices={refreshChoices}
                      choices={choices}
                      panel={panels.product}
                    ></NDEquipmentPanel>
                  )}
                  <NDAntennaPanel
                    ap={ap}
                    modified={modified}
                    panel={panels.antenna}
                    choices={choices}
                    setModified={setModified}
                    refreshChoices={refreshChoices}
                  ></NDAntennaPanel>
                  {panels.power && (
                    <NDPowerPanel
                      ap={ap}
                      modified={modified}
                      panel={panels.power}
                      choices={choices}
                      setModified={setModified}
                      refreshChoices={refreshChoices}
                    ></NDPowerPanel>
                  )}
                  {panels.sectorPower && (
                    <NDSectorPanel
                      ap={ap}
                      modified={modified}
                      setModified={setModified}
                      refreshChoices={refreshChoices}
                      choices={choices}
                      panel={panels.sectorPower}
                    ></NDSectorPanel>
                  )}
                </Accordion>
              </div>
              <div
                id="ap-performance"
                style={{ overflowY: 'auto', flex: '3 1 0%' }}
              >
                <Accordion
                  exclusive={false}
                  fluid
                  style={{ padding: '0.8rem' }}
                >
                  {isPMP450v(product) && (
                    <NDComponentCarriersPanel
                      ap={ap}
                      isTemplate={false}
                      modified={modified}
                      setModified={setModified}
                      refreshChoices={refreshChoices}
                      choices={choices}
                      panel={panels.componentCarrier}
                    ></NDComponentCarriersPanel>
                  )}
                  {family === 'cnWave' && (
                    <StoredAccordion
                      name="MeshLinksModules"
                      title={formatMessage(messages.meshLinks)}
                    >
                      <MeshLinksTable
                        modified={modified}
                        needsRefresh={needsRefresh}
                        id={id}
                      ></MeshLinksTable>
                    </StoredAccordion>
                  )}
                  <StoredAccordion
                    name={'LinksToSubscriberModules'}
                    title={formatMessage(messages.apLinksToSM)}
                  >
                    <SubscriberModulesTable
                      modified={modified}
                      needsRefresh={needsRefresh}
                      id={id}
                    ></SubscriberModulesTable>
                  </StoredAccordion>
                  {family !== 'cnReach' && (
                    <StoredAccordion
                      name="AccessPointPerformance"
                      title={`${formatMessage(
                        messages.apPanelPerformance
                      )} (${predictionModel})`}
                    >
                      <AccessPointPerformance
                        projectId={projectId}
                        apId={id}
                        needsRefresh={needsRefresh}
                        disabled={modified}
                      />
                    </StoredAccordion>
                  )}
                </Accordion>
              </div>
            </div>
          </Form>
        </FormProvider>
        <RouteLeavingGuard
          when={modified}
          shouldBlockNavigation={() => modified}
          yes="Yes"
          no="No"
          title={`Network Device: ${name}`}
          content={formatMessage(messages.warning)}
          callback={clearCache}
        />
      </div>
    </FormResetContext.Provider>
  );
}

function Title({
  projectId,
  id,
  name,
  dirty,
  powerFromSwitch,
  siteId,
  formatMessage,
  localProduct,
  modified,
  isSubmitting,
  clearCache,
  errors,
}) {
  const chevronLink = '/aps';
  const installationReportURL: `/${string}` = `/access_point/${id}/installation`;
  const proposalReportURL: `/${string}` = `/access_point/${id}/proposal`;
  let disableReportButton = modified || dirty;
  const navigate = useNavigate();
  const permissionWrite = useSelector(
    (state) => state.mainFrame.permissionWrite
  );

  const message = formatMessage(messages.deleteAccessPoints);
  const deleteEntity = async () => {
    store.dispatch(
      uiConfirmAction({
        header: message,
        message: formatMessage(messages.confirm),
        size: 'mini',
        onConfirm: () => {
          postWithAuth(`project/${projectId}/access_points`, [id], 'DELETE')
            .then((el) => {
              console.log(el);
              store.dispatch(setSelectedPMPLinks([]));
              navigate(`${chevronLink}`);
            })
            .catch(console.error);
        },
      })
    );
  };
  const disableSave = !modified || isSubmitting || !isEmpty(errors);
  return (
    <PanelHeading
      title={
        <Link
          title={`${formatMessage(messages.viewSite)}`}
          to={`/network_sites/${siteId}`}
        >
          {name}
        </Link>
      }
      toolbar={
        <Form.Group>
          {permissionWrite && !modified && (
            <>
              <Button
                compact
                basic
                type="button"
                title={'Delete'}
                onClick={() => deleteEntity()}
                icon="trash alternate"
                color="red"
                style={{ margin: '0' }}
              ></Button>
              <ToolbarSeparator />
            </>
          )}
          {permissionWrite && modified && (
            <>
              <Button
                icon
                basic
                compact
                type="button"
                title="Undo changes"
                onClick={() => {
                  clearCache();
                }}
                color="red"
                style={{ margin: '0' }}
              >
                <Icon name="undo" />
              </Button>
              <ToolbarSeparator />
            </>
          )}
          <ShowInMap kind="access_point" id={id} />
          <ToolbarSeparator />
          <AccessPointBomModal
            objId={id}
            kind="access_point"
            localProduct={localProduct}
            powerFromSwitch={powerFromSwitch}
            siteId={siteId}
            url={`project/${projectId}/access_point/${id}/bom`}
            modified={modified}
            name={name}
          />

          <ReportDownloadButton
            projectId={projectId}
            name={name}
            kind="Proposal"
            endpoint={proposalReportURL}
            title={formatMessage(messages.salesReport)}
            disabled={disableReportButton}
          >
            <Icon className="proposal-report-icon" size="large" />
          </ReportDownloadButton>

          <ReportDownloadButton
            projectId={projectId}
            name={name}
            kind="Installation"
            endpoint={installationReportURL}
            title={formatMessage(messages.installationReport)}
            disabled={disableReportButton}
          >
            <Icon className="installation-report-icon" size="large" />
          </ReportDownloadButton>

          {dirty && (
            <Header style={{ margin: '0' }}>
              <Loader active inline size="tiny" />{' '}
              {formatMessage(messages.calculatingResults)}
            </Header>
          )}
        </Form.Group>
      }
      apply={
        <Form.Button
          compact
          type="submit"
          className="save"
          primary
          disabled={disableSave}
          accesskey="a"
        >
          {formatMessage(messages.apply)}
        </Form.Button>
      }
    />
  );
}

export default NDForm;
