import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Accordion, Loader, Segment } from 'semantic-ui-react';
import { get } from 'lodash';
import { getWithAuth } from '../../api';
import EquipmentSummary from '../equipment/meshlink/EquipmentSummary';
import messages from '../../messages';
import { MeshPerformanceSummary } from '../ptp/PTPPerformanceSummary';
import { setModified } from './mesh.reducer';
import { meshLinkSummary, getMeshEndSummary } from './mesh-summary-config';
import ProfileEditor from 'src/components/profilechart/ProfileEditorModal';
import StoredAccordion from 'src/components/StoredAccordion';
import { blankProfile } from 'src/components/profilechart/profile.reducer';
import { PerformanceDetailsTabs } from 'src/pages/ptp/PerformanceDetailsTabs';

const summaryConfig = {
  link: meshLinkSummary,
  local: getMeshEndSummary('local'),
  remote: getMeshEndSummary('remote'),
};

const MeshLinkDetailsPanel = (props) => {
  const { id, currentMesh, intl, loader, modified, parentFormSubmitHandler } =
    props;
  const {
    local_site: localSite,
    remote_site: remoteSite,
    local,
    remote,
    details,
  } = currentMesh;
  const { formatMessage } = intl;

  const dispatch = useDispatch();

  const { prefs, projectId, predictionModel, needsRefresh } = useSelector(
    (state) => state.mainFrame
  );
  const possibleRecalcRdx = useSelector((state) => state.mesh.possibleRecalc);
  const possibleRecalc = possibleRecalcRdx[id];

  const [profileData, setProfileData] = useState(blankProfile);

  useEffect(() => {
    if (
      localSite.latitude &&
      remoteSite.latitude &&
      localSite.longitude &&
      remoteSite.longitude
    ) {
      setProfileData({ ...blankProfile, ...{ loading: true } });
      getWithAuth(
        `profile?project_id=${projectId}&loc_lat=${localSite.latitude}&loc_lng=${localSite.longitude}&rem_lat=${remoteSite.latitude}&rem_lng=${remoteSite.longitude}`
      )
        .then((response) => {
          if (response) {
            setProfileData({
              loading: false,
              errMsg: null,
              ...response,
            });
          } else {
            const errMsg = formatMessage(messages.chartError);
            setProfileData({
              ...blankProfile,
              ...{ errMsg: errMsg, loading: false },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setProfileData(
            { ...blankProfile },
            { errMsg: err.detail, loading: false }
          );
        });
    }
  }, [localSite, remoteSite, projectId]);

  if (loader) {
    return <Loader active />;
  }

  const profileProps = {
    accordionName: 'MeshLinkPanelProfile',
    profileData: profileData,
    localHeight: localSite.height,
    remoteHeight: remoteSite.height,
    // Unlike on PTP, we don't have a path here (but we should).
    // Hack one in for now so that the profile works.
    path: {},
    frequencyGHz: get(local, 'radios[0].frequency.frequency_mhz', 5800) / 1000,
    rangeUnits: prefs.rangeUnits,
    heightUnits: prefs.heightUnits,
    localSiteName: local?.name,
    remoteSiteName: remote?.name,
    errMsg: profileData.errMsg,
    loading: profileData.loading,
    kind: 'mesh',
    modified,
    parentFormSubmitHandler,
  };

  return (
    <Accordion className="mesh-link-details product-page-right-panels">
      <StoredAccordion
        name="MeshLinkEquipmentSummary"
        title={formatMessage(messages.meshLinkDetailsEquipmentSummary)}
      >
        <EquipmentSummary
          currentMesh={currentMesh}
          equipmentSummary={{
            localName: currentMesh.local.name,
            values: currentMesh.summary.equipment_summary,
            remoteName: currentMesh.remote.name,
            warnings: currentMesh.warnings,
          }}
        />
      </StoredAccordion>

      <ProfileEditor {...profileProps} />

      <StoredAccordion
        name="MeshLinkPerformanceSummary"
        title={`${formatMessage(
          messages.apPanelPerformance
        )} (${predictionModel})`}
      >
        <>
          {possibleRecalc || needsRefresh.meshLinkPanel ? (
            <Segment inverted className="update-required">
              Apply your changed to update the performance summary
            </Segment>
          ) : null}
          <MeshPerformanceSummary
            path={currentMesh}
            modified={modified}
            config={summaryConfig}
            disabled={false}
            setModified={(value) => dispatch(setModified({ id, value }))}
            possibleRecalc={possibleRecalc}
            intl={intl}
          />
        </>
      </StoredAccordion>

      <StoredAccordion
        name="MeshLinkPerformanceDetails"
        title={formatMessage(messages.apPanelPerformanceDetails)}
      >
        <PerformanceDetailsTabs
          details={currentMesh.details}
          localEndName={currentMesh.local.name}
          remoteEndName={currentMesh.remote.name}
          disabled={possibleRecalc}
          kind="mesh"
          needsRefresh={needsRefresh.meshLinkPanel}
        />
      </StoredAccordion>
    </Accordion>
  );
};

export default injectIntl(MeshLinkDetailsPanel);
