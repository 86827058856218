import { useQuery } from '@tanstack/react-query';
import { type ColumnMeta, type Row } from '@tanstack/react-table';
import React, { useState } from 'react';
import { Dropdown, Loader } from 'semantic-ui-react';

type TProps<T extends { id: string }, U> = {
  getChoices: (
    projectId: string,
    row: Row<T>
  ) => Promise<{ choices: string[] }>;
  projectId: string;
  row: Row<T>;
  meta: ColumnMeta<T, U>;
  value: U;
  setValue: (value: U) => void;
};

export function DropdownEditor<
  T extends { id: string },
  U extends string | number | boolean
>({ getChoices, projectId, row, meta, value, setValue }: TProps<T, U>) {
  const [isOpen, setIsOpen] = useState(true);
  const { editorLabel: label, attr } = meta;

  let urlAttr;
  if (meta.choicesKind === 'subscriber') {
    urlAttr = 'remote_end_id';
  } else {
    urlAttr = 'id';
  }

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'bulk_edit',
      'choices',
      projectId,
      meta.choicesKind,
      row.original[urlAttr],
      attr,
    ],
    queryFn: () => getChoices(projectId, row),
  });

  if (isLoading) {
    return <Loader active inline="centered" />;
  }

  const { choices } = data ?? {};

  return (
    <div>
      <label
        style={{ fontWeight: 'bold', marginRight: '1rem', display: 'block' }}
      >
        {label}
      </label>
      {isError ? (
        <p>Error loading choices</p>
      ) : choices.length === 0 ? (
        <p>No choices available</p>
      ) : (
        <Dropdown
          selection
          value={value}
          options={choices.map((choice) => ({
            key: choice,
            text: choice,
            value: choice,
          }))}
          onChange={(_, { value }) => {
            setValue(value as U);
            setIsOpen(false);
          }}
          onClick={() => setIsOpen(!isOpen)}
          style={{ marginBottom: '0.5rem' }}
          open={isOpen}
          onBlur={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}
