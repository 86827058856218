import React from 'react';
import { Icon } from 'semantic-ui-react';

/**
 * Render the header content with a sort icon but without the actual <th> element
 */
export function HeaderContent({
  children,
  id,
  sortString,
  style,
}: {
  children: React.ReactNode;
  id: string;
  sortString: string | null;
  style?: React.CSSProperties;
}) {
  if (id === 'select') {
    // select column doesn't need a sort icon
    return <>{children}</>;
  }

  let sortIcon: React.ReactNode | null = null;
  if (sortString === 'asc') {
    sortIcon = <Icon name="arrow up" style={{ margin: 0 }} />;
  } else if (sortString === 'desc') {
    sortIcon = <Icon name="arrow down" style={{ margin: 0 }} />;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flex: '1 1 5.5em',
        justifyContent: 'space-between',
        ...style,
      }}
      className="lp-table-header-content"
    >
      <span>{children}</span>
      {sortIcon}
    </div>
  );
}
