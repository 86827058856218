import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getProductPanels, getSMCacheKeys } from './utils';
import { postWithAuth } from 'src/api';
import { get, isEmpty } from 'lodash';
import {
  Accordion,
  Form,
  Button,
  Header,
  Icon,
  Loader,
} from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { store } from 'src/store';
import messages from 'src/messages';
import NDGeneralPanel from '../nd/NDGeneralPanel';
import NDAntennaPanel from '../nd/NDAntennaPanel';
import WarningAccordion from '../WarningAccordion';
import NDPowerPanel from '../nd/NDPowerPanel';
import StoredAccordion from 'src/components/StoredAccordion';
import SubscriberComponentCarrier from '../SubscriberComponentCarrier';
import PMPProfileChart from './PMPProfileChart';
import SMPerformanceSummary from './SMPerformanceSummary';
import RouteLeavingGuard from 'src/components/RouteLeavingGuard';
import AntennaFormContainer from 'src/pages/antennas/AntennaFormContainer';
import NDSectorPanel from '../nd/NDSectorPanel';
import SMPerformanceDetailsTabs from './SMPerformanceDetailsTabs';
import { isPMP450v } from '../config-utils';
import { ReportDownloadButton } from './../../ptp/PTPLinkPanel';
import BomModal from 'src/components/BomModal';
import { PanelHeading, ToolbarSeparator } from 'src/components/PanelHeading';
import {
  setSelectedPMPLinks,
  uiConfirmAction,
} from 'src/pages/mainframe/mainframe.reducer';
import { ShowInMap } from 'src/components/ShowInMap';
import { FormResetContext } from '../utils';

function SMForm({ projectId, id, sm, ap, choices, profile }) {
  const [modified, setModified] = useState<boolean>(false);
  const [resetValue, setResetValue] = useState(0);
  const { key, choicesKey, profileKey } = getSMCacheKeys(projectId, id);
  const { formatMessage } = useIntl();
  const predictionModel = useSelector(
    (state) => state.mainFrame.predictionModel
  );
  const formMethods = useForm({
    defaultValues: { ...sm['subscriber'], results: sm.results },
  });
  const apProduct = get(ap, 'radios.0.equipment.product');
  const { handleSubmit, getValues, setValue, reset, formState } = formMethods;
  const { isSubmitting, errors } = formState;
  const product = getValues('radios.0.equipment.product');
  const qc = useQueryClient();
  const panels = getProductPanels(product, false);
  const onSubmit = async (formData: FieldValues) => {
    const { results, ...subscriber } = formData;
    await postWithAuth(
      `project/${projectId}/subscriber/${id}`,
      {
        path_id: sm.path_id,
        subscriber: subscriber,
        local: sm.local,
        remote: sm.remote,
        access_point: { id: ap.id, name: ap.name },
        results: results,
      },
      'PATCH'
    );
    setModified(false);
  };

  const clearCache = () => {
    setResetValue((prev) => prev + 1);
    reset();
    qc.invalidateQueries({
      queryKey: key,
    });
    qc.invalidateQueries({
      queryKey: profileKey,
      exact: true,
    });
    setModified(false);
  };

  // we need to clear the react query cache on component
  // unmount to avoid issues like rendering the stale data
  // when we search from menu and navigate to the page
  useEffect(() => {
    return () => {
      reset();
      qc.removeQueries({
        queryKey: key,
      });
      qc.removeQueries({
        queryKey: profileKey,
        exact: true,
      });
    };
  }, [projectId, id]);

  const refreshChoices = ({ field }) => {
    if (!field) {
      // should not occur in production (programmer error)
      throw new Error('Refresh choices requested without field info');
    }

    const mergedFormData = getValues(); //merge(ap, getValues());
    // request new choices based on the updated form state (i.e.
    // in response to a user action: onChange)
    updateChoices.mutate({
      sm: mergedFormData,
      attr: field.attrName,
    });
  };

  const updateChoices = useMutation(
    ({ sm, attr }: { sm: any; attr: string }) => {
      const equipment = get(sm, 'radios.0.equipment');
      if (attr !== 'product') {
        setValue('product.prev', equipment.product);
      }
      return Promise.allSettled([
        postWithAuth(`equipment/subscriber/choices`, {
          project_id: projectId,
          sm,
          changed: attr,
        }),
        Promise.resolve(attr),
      ]);
    },
    {
      onSuccess: (updateChoicesResponse) => {
        const choicesResponse = (
          updateChoicesResponse[0] as PromiseFulfilledResult<any>
        ).value;

        const attr = (updateChoicesResponse[1] as PromiseFulfilledResult<any>)
          .value;
        // TODO after changing the backend api we can remove the below code
        // from 84 to 88 which changes equipment choices array to object
        const equipmentChoiceMap = {};
        choicesResponse['equipment'].forEach((attr) => {
          equipmentChoiceMap[attr.attr_name] = attr;
        });
        choicesResponse['equipment'] = equipmentChoiceMap;
        // update choices query data to cause form to rerender
        // with updated correct choices
        qc.setQueryData(choicesKey, () => ({
          ...choicesResponse,
        }));
        // update react hook form internal state based on
        // new choices on a field by field basis (e.g. when form
        // values fall out of sync with choices like changing product
        // updating the antenna choices)
        const product = choicesResponse.equipment['product']['value'];
        const newPanels = getProductPanels(product, false);
        const choices = {
          ...choicesResponse,
        };

        choices.power.forEach((power, idx) => {
          setValue(`radios.${idx}.power.use_noise`, power.use_noise);
          setValue(`radios.${idx}.power.use_noise_sm`, power.use_noise_sm);
          setValue(`radios.${idx}.power.use_user_power`, power.use_user_power);
          setValue(`radios.${idx}.power.noise`, power.noise);
        });

        newPanels.syncFormState(
          getValues,
          setValue,
          newPanels,
          choices,
          attr,
          ap
        );
      },
    }
  );

  return (
    <FormResetContext.Provider value={resetValue}>
      <div className="pmplayout" style={{ height: '100%' }}>
        <FormProvider {...formMethods}>
          <Form
            className="panel-with-heading"
            method="post"
            onSubmit={handleSubmit(onSubmit)}
            style={{ height: '100%' }}
          >
            <Title
              sm={sm}
              localProduct={product}
              projectId={projectId}
              modified={modified}
              isSubmitting={isSubmitting}
              id={id}
              apId={ap.id}
              smSiteId={sm.remote.site_id}
              name={sm.subscriber.name}
              apName={ap.name}
              dirty={sm.dirty}
              errors={errors}
              clearCache={clearCache}
              formatMessage={formatMessage}
            ></Title>

            <div
              className="main-panel"
              style={{ display: 'flex', columnGap: '6px' }}
            >
              <div
                id="subscriber-details"
                className="first-col"
                style={{ padding: '0.8rem' }}
              >
                <Accordion
                  exclusive={false}
                  fluid
                  className="product-family-panels"
                >
                  <WarningAccordion
                    obj={{
                      warnings: sm.warnings,
                      ...sm.subscriber,
                    }}
                  />
                  <NDGeneralPanel
                    ap={ap}
                    sm={sm.subscriber}
                    isAp={false}
                    title={'General'}
                    setModified={setModified}
                  ></NDGeneralPanel>
                  {panels.equipment && (
                    <panels.equipment.component
                      ap={ap}
                      sm={sm.subscriber}
                      modified={modified}
                      setModified={setModified}
                      refreshChoices={refreshChoices}
                      choices={choices}
                      panel={panels.equipment}
                    ></panels.equipment.component>
                  )}
                  {panels.multiRadioEquipment && (
                    <panels.multiRadioEquipment.component
                      ap={ap}
                      sm={sm.subscriber}
                      modified={modified}
                      setModified={setModified}
                      refreshChoices={refreshChoices}
                      choices={choices}
                      panel={panels.multiRadioEquipment}
                    ></panels.multiRadioEquipment.component>
                  )}
                  <panels.product.component
                    ap={ap}
                    sm={sm.subscriber}
                    modified={modified}
                    setModified={setModified}
                    refreshChoices={refreshChoices}
                    choices={choices}
                    panel={panels.product}
                  ></panels.product.component>
                  {panels.multiRadioAntenna && (
                    <panels.multiRadioAntenna.component
                      ap={ap}
                      sm={sm.subscriber}
                      modified={modified}
                      setModified={setModified}
                      refreshChoices={refreshChoices}
                      choices={choices}
                      panel={panels.multiRadioAntenna}
                    ></panels.multiRadioAntenna.component>
                  )}
                  {panels.antenna && (
                    <NDAntennaPanel
                      ap={ap}
                      sm={sm.subscriber}
                      modified={modified}
                      panel={panels.antenna}
                      choices={choices}
                      setModified={setModified}
                      refreshChoices={refreshChoices}
                    ></NDAntennaPanel>
                  )}
                  {panels.power && (
                    <NDPowerPanel
                      ap={ap}
                      sm={sm.subscriber}
                      modified={modified}
                      panel={panels.power}
                      choices={choices}
                      setModified={setModified}
                      refreshChoices={refreshChoices}
                    ></NDPowerPanel>
                  )}
                  {panels.sectorPower && (
                    <NDSectorPanel
                      ap={ap}
                      sm={sm.subscriber}
                      modified={modified}
                      setModified={setModified}
                      refreshChoices={refreshChoices}
                      choices={choices}
                      panel={panels.sectorPower}
                    ></NDSectorPanel>
                  )}
                </Accordion>
              </div>
              <div
                id="link-performance-details"
                style={{ overflowY: 'auto', flex: '3 1 0%' }}
              >
                <Accordion
                  exclusive={false}
                  fluid
                  style={{ padding: '0.8rem' }}
                >
                  {isPMP450v(apProduct) && (
                    <StoredAccordion
                      name="sm_component_carrier"
                      title="Component Carrier"
                    >
                      <SubscriberComponentCarrier
                        sm={sm.subscriber}
                        ap={ap}
                        modified={modified}
                        choices={choices}
                        setModified={setModified}
                        refreshChoices={refreshChoices}
                      ></SubscriberComponentCarrier>
                    </StoredAccordion>
                  )}
                  <PMPProfileChart
                    ap={ap}
                    profile={profile}
                    sm={sm.subscriber}
                    local={sm.local}
                    remote={sm.remote}
                    parentFormSubmitHandler={handleSubmit(onSubmit)}
                    modified={modified}
                    setModified={setModified}
                  ></PMPProfileChart>

                  <StoredAccordion
                    name="SubscriberPerformanceSummary"
                    title={`${formatMessage(
                      messages.apPanelPerformance
                    )} (${predictionModel})`}
                  >
                    <SMPerformanceSummary
                      modified={modified}
                      setModified={setModified}
                      sm={sm.subscriber}
                      local={sm.local}
                      remote={sm.remote}
                      results={sm.results}
                      choices={choices}
                      warnings={sm.warnings}
                    />
                  </StoredAccordion>
                  <StoredAccordion
                    name="SubscriberPerformanceDetails"
                    title={formatMessage(messages.apPanelPerformanceDetails)}
                  >
                    <SMPerformanceDetailsTabs
                      details={sm.results.details}
                      localEndName={sm.local.site_name}
                      remoteEndName={sm.remote.site_name}
                      disabled={modified}
                      kind="pmp"
                      needsRefresh={sm.dirty}
                    />
                  </StoredAccordion>
                </Accordion>
              </div>
            </div>
          </Form>
        </FormProvider>
        <RouteLeavingGuard
          when={modified}
          shouldBlockNavigation={() => modified}
          yes="Yes"
          no="No"
          title={`PMP Link: ${ap.name} to ${sm.remote.site_name}`}
          content={formatMessage(messages.warning)}
          callback={clearCache}
        />
        <AntennaFormContainer
          path={sm.subscriber}
          kind="sm"
          refetch={(field) => refreshChoices({ field })}
        />
      </div>
    </FormResetContext.Provider>
  );
}

function Title({
  sm,
  projectId,
  id,
  apId,
  smSiteId,
  name,
  apName,
  dirty,
  formatMessage,
  localProduct,
  modified,
  isSubmitting,
  clearCache,
  errors,
}) {
  const chevronLink = '/subscribers';
  const installationReportURL: `/${string}` = `/subscriber/${id}/installation`;
  let disableReportButton = modified || dirty;
  const navigate = useNavigate();
  const permissionWrite = useSelector(
    (state) => state.mainFrame.permissionWrite
  );

  const message = formatMessage(messages.deletePMPLinks);
  const deleteEntity = async () => {
    store.dispatch(
      uiConfirmAction({
        header: message,
        message: formatMessage(messages.confirm),
        size: 'mini',
        onConfirm: () => {
          postWithAuth(`project/${projectId}/subscribers`, [id], 'DELETE')
            .then((el) => {
              console.log(el);
              store.dispatch(setSelectedPMPLinks([]));
              navigate(`${chevronLink}`);
            })
            .catch(console.error);
        },
      })
    );
  };

  const disableSave = !modified || isSubmitting || !isEmpty(errors);

  return (
    <PanelHeading
      title={
        <>
          <Link
            title={`${formatMessage(messages.viewAccessPoint)}`}
            to={`/aps/${apId}`}
          >
            {apName}
          </Link>{' '}
          to{' '}
          <Link
            title={`${formatMessage(messages.viewSite)}`}
            to={`/subscriber_sites/${smSiteId}`}
          >
            {name}
          </Link>
        </>
      }
      toolbar={
        <Form.Group>
          {permissionWrite && !modified && (
            <>
              <Button
                compact
                basic
                type="button"
                title={'Delete'}
                onClick={() => deleteEntity()}
                icon="trash alternate"
                color="red"
                style={{ margin: '0' }}
              ></Button>
              <ToolbarSeparator />
            </>
          )}
          {permissionWrite && modified && (
            <>
              <Button
                icon
                basic
                compact
                type="button"
                title={formatMessage(messages.revertChanges)}
                onClick={() => {
                  clearCache();
                }}
                color="red"
                style={{ margin: '0' }}
              >
                <Icon name="undo" />
              </Button>
              <ToolbarSeparator />
            </>
          )}

          <ShowInMap kind="pmp_link" id={sm.path_id} />
          <ToolbarSeparator />
          <BomModal
            objId={id}
            kind="subscriber"
            localProduct={localProduct}
            url={`project/${projectId}/subscriber/${id}/bom`}
            modified={modified}
            name={name}
          />

          <ReportDownloadButton
            projectId={projectId}
            name={name}
            kind="Installation"
            endpoint={installationReportURL}
            title={formatMessage(messages.installationReport)}
            disabled={disableReportButton}
          >
            <Icon className="installation-report-icon" size="large" />
          </ReportDownloadButton>

          {dirty && (
            <Header style={{ margin: '0' }}>
              <Loader active inline size="tiny" />{' '}
              {formatMessage(messages.calculatingResults)}
            </Header>
          )}
        </Form.Group>
      }
      apply={
        <Form.Button
          compact
          type="submit"
          className="save"
          primary
          disabled={disableSave}
          accesskey="a"
        >
          {formatMessage(messages.apply)}
        </Form.Button>
      }
    />
  );
}

export default SMForm;
