import { get } from 'lodash';
import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FormGroup, Form } from 'semantic-ui-react';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import StoredAccordion from 'src/components/StoredAccordion';
import { FormResetContext } from '../pmp/utils';

function SitePropertiesAccordion({ site, setModified }) {
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const resetValue = useContext(FormResetContext);
  const heightUnits = useSelector(
    (state: any) => state.mainFrame.prefs.heightUnits
  );
  const latLngFormat = useSelector(
    (state: any) => state.mainFrame.prefs.latLngFormat
  );
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  return (
    <StoredAccordion name="site_properties" title={'Details'}>
      <FormGroup widths="equal">
        <Controller
          control={control}
          name="name"
          rules={{
            required: true,
            validate: {
              name: (value) => {
                return value.trim().length > 0;
              },
            },
          }}
          render={({ field: { ref, onChange, ...rest } }) => (
            <Form.Input
              {...rest}
              label="Name"
              required
              disabled={!permissionWrite}
              error={errors['name'] != null}
              onChange={(e, { value }) => {
                onChange(value.slice(0, 100));
                trigger();
                setModified(true);
              }}
              maxLength={100}
            ></Form.Input>
          )}
        />
        <GenericScaledField
          key={'maximum_height'}
          getter="maximum_height"
          alwaysEnabled={false}
          setModified={setModified}
          precision={1}
          label={'Maximum Height'}
          defaultValue={get(site, 'maximum_height')}
          min={0}
          max={3000}
          units={heightUnits}
          watch={resetValue}
          disabled={!permissionWrite}
        ></GenericScaledField>
      </FormGroup>
      <Form.Group widths="equal">
        <GenericScaledField
          key="latitude"
          label="Latitude"
          required={true}
          alwaysEnabled={false}
          extraProps={{ isLat: true }}
          getter={'latitude'}
          defaultValue={site.latitude}
          setModified={setModified}
          watch={resetValue}
          width={16}
          units={latLngFormat}
          disabled={!permissionWrite}
        />
        <GenericScaledField
          key="longitude"
          label="Longitude"
          required={true}
          alwaysEnabled={false}
          getter={'longitude'}
          extraProps={{ isLat: false }}
          defaultValue={site.longitude}
          units={latLngFormat}
          watch={resetValue}
          width={16}
          setModified={setModified}
          disabled={!permissionWrite}
        />
      </Form.Group>
      {site.is_network_site && (
        <Controller
          control={control}
          name={'node_type'}
          render={({ field: { ref, onChange, ...rest } }) => (
            <Form.Select
              label={'Node Type'}
              options={[
                { key: '', value: '', text: '' },
                { key: 'DN', value: 'DN', text: 'DN' },
                { key: 'POP', value: 'POP', text: 'POP' },
              ]}
              disabled={!permissionWrite}
              onChange={(e, data) => {
                setValue('node_type', data.value);
                onChange(data.value);
                setModified(true);
              }}
              {...rest}
            ></Form.Select>
          )}
        ></Controller>
      )}
      <Controller
        control={control}
        name={'description'}
        render={({ field: { ref, onChange, ...rest } }) => (
          <Form.TextArea
            {...rest}
            label={'Description'}
            disabled={!permissionWrite}
            maxLength={500}
            onChange={(e, { value }) => {
              onChange(value.slice(0, 500));
              setModified(true);
            }}
          ></Form.TextArea>
        )}
      ></Controller>
    </StoredAccordion>
  );
}

export default SitePropertiesAccordion;
