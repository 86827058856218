import React, { createContext, useState } from 'react';
import _ from 'lodash';
import {
  Sidebar,
  Menu,
  Icon,
  Loader,
  Divider,
  Search,
  Popup,
  Button,
  Input,
  Dimmer,
  Segment,
} from 'semantic-ui-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import additionalMessages from '../../messages';
import { RootStateOrAny, store } from '../../store';
import { NetworkSiteMenuItem } from './NetworkSiteMenuItem';
import { SubscriberSiteMenuItem } from './SubscriberSiteMenuItem';
import PTPLinksMenuItem from './PTPLinksMenuItem';
import { AccessPointsMenuItem } from './AccessPointsMenuItem';
import { uiExpandSidebar, uiSet } from './mainframe.reducer';
import messages from '../../messages';
import { getLocalJSON, setSessionJSON } from 'src/utils/useful_functions';
import MapIconPopup from '../map/MapIconPopup';

const featureMatch = (exp, result, f, category, baseUrl) => {
  if (exp.test(f.properties.name)) {
    if (!result[category]) {
      result[category] = { name: category, results: [] };
    }
    result[category].results.push({
      title: f.properties.name,
      // PMP Links have an ID for the link and the SM ID
      url: `/${baseUrl}/${f.properties.sm_id || f.properties.id}`,
    });
  }
};

const configMatch = (exp, result, f, category, baseUrl) => {
  if (exp.test(f.name)) {
    if (!result[category]) {
      result[category] = { name: category, results: [] };
    }
    result[category].results.push({
      title: f.name,
      url: `/${baseUrl}/${f.id}`,
    });
  }
};

const doSearch = (state, searchStr) => {
  const {
    networkSites,
    subscriberSites,
    accessPoints,
    ptpLinks,
    pmpLinks,
    meshLinks,
    collapseSidebar,
  } = state.mainFrame;
  const { equipmentConfigs } = state.equipment;
  const result = {};

  let exp = null;
  try {
    exp = new RegExp(searchStr, 'i');
  } catch (e) {
    console.error(e);
    return result;
  }

  if (!networkSites) {
    return result;
  }

  networkSites.features.forEach((f) => {
    featureMatch(exp, result, f, 'Network Sites', 'network_sites');
  });
  subscriberSites.features.forEach((f) => {
    featureMatch(exp, result, f, 'Subscriber Sites', 'subscriber_sites');
  });
  equipmentConfigs.forEach((f) => {
    configMatch(exp, result, f, 'PMP Equipment', 'equipment');
  });
  accessPoints.features.forEach((f) => {
    featureMatch(exp, result, f, 'PMP Network Devices', 'aps');
  });
  ptpLinks.features.forEach((f) => {
    featureMatch(exp, result, f, 'PTP Links', 'ptp');
  });
  pmpLinks.features.forEach((f) => {
    featureMatch(exp, result, f, 'PMP Links', 'subscribers');
  });
  meshLinks.features.forEach((f) => {
    featureMatch(exp, result, f, 'Mesh Links', 'mesh');
  });

  // sort the results
  for (let category in result) {
    result[category].results.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      } else if (a.title > b.title) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  return result;
};

/*
 * Search rendering
 */
const categoryLayoutRenderer = ({ categoryContent, resultsContent }) => (
  <div>
    <div className="name">{categoryContent}</div>
    <div className="results">{resultsContent}</div>
  </div>
);

const FeatureSearch = ({ formatMessage, size = 'mini' }) => {
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState({});
  const [searchValue, setSearchvalue] = useState('');
  const currentState = useSelector((state) => state);

  return (
    <Search
      placeholder="Search for a feature..."
      category
      aligned="left"
      size={size}
      title={formatMessage(additionalMessages.searchHelp)}
      onResultSelect={(e, { result }) => navigate(result.url)}
      onSearchChange={_.debounce(
        (e, { value }) => {
          setSearchvalue(value);
          setSearchResults(doSearch(currentState, value));
        },
        500,
        {
          leading: true,
        }
      )}
      results={searchResults}
      categoryLayoutRenderer={categoryLayoutRenderer}
      value={searchValue}
    />
  );
};
export const FilterContext = createContext('');
const MainMenuContainer = (props) => {
  const {
    expandSidebar,
    loadingProject,
    projectId,
    permissionWrite,
    hasWritePermission,
    warning,
    locked,
  } = props;
  const { formatMessage } = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState('');
  const filterOnMapBounds = useSelector(
    (state) => state.mainFrame.filterOnMapBounds
  );
  const showBestServer = warning?.heading != null;

  let iconLabel: string | boolean = 'labeled';
  if (!projectId) {
    iconLabel = false;
  } else if (!expandSidebar) {
    iconLabel = false;
  }

  if (locked && location.pathname !== '/') {
    navigate('/');
  }

  if (!projectId || locked) {
    return (
      <Sidebar
        as={Menu}
        animation="uncover"
        icon={iconLabel}
        size="small"
        visible
        vertical
      >
        <Loader active={locked} indeterminate content="Please wait..." />
      </Sidebar>
    );
  }

  const iconExtraStyle = {
    placeContent: expandSidebar ? null : 'center',
  };

  const maxWidth = Math.max(
    parseInt(getLocalJSON('cn.lp.sidebarWidth', '250px')),
    200
  );
  return (
    <FilterContext.Provider value={filter}>
      <Sidebar
        as={Menu}
        animation="uncover"
        icon={iconLabel}
        size="small"
        visible
        vertical
        className="sidebar-menu"
        style={{ maxWidth }}
      >
        <div className="sidebar-menu-header">
          <Menu.Item
            name="map"
            active={false}
            className="menu-loader"
            as={Link}
            to="/"
            title={formatMessage(additionalMessages.mapTitle)}
          >
            {loadingProject ? (
              <Dimmer active inverted>
                <Loader inverted></Loader>
              </Dimmer>
            ) : (
              <div className="show-map">
                <Icon name="map outline" style={{ fontSize: '1.2em' }}></Icon>
                {expandSidebar && ' Show Map'}
              </div>
            )}
          </Menu.Item>

          {expandSidebar ? (
            <>
              <div
                style={{ marginTop: '1.5rem' }}
                title={formatMessage(messages.searchHelp)}
              >
                <Input
                  style={{ width: '80%' }}
                  transparent
                  className={
                    filter
                      ? 'lp-table-filter filtered'
                      : 'lp-table-filter unfiltered'
                  }
                  icon={{
                    name: 'cancel',
                    onClick: () => {
                      setFilter('');
                    },
                    link: true,
                    title: formatMessage(additionalMessages.clearSearch),
                  }}
                  value={filter}
                  placeholder="Search Project..."
                  onChange={_.debounce(
                    (e, { value }) => {
                      setFilter(value);
                    },
                    500,
                    {
                      leading: true,
                    }
                  )}
                />
              </div>
              {/*Though we added css class to button the class has been applied to both
            button and wrapper div by semantic, so we write inline styles*/}
              <MapIconPopup
                kind="map_filter"
                trigger={
                  <div>
                    <Button
                      icon="globe"
                      toggle
                      style={{
                        marginTop: '5px',
                        width: '80%',
                        marginRight: '0px',
                      }}
                      className="filter-by-map"
                      active={filterOnMapBounds}
                      label={{
                        basic: true,
                        content: formatMessage(
                          additionalMessages.mapViewFilter
                        ),
                      }}
                      labelPosition="right"
                      onClick={() => {
                        store.dispatch(
                          uiSet({
                            filterOnMapBounds: !filterOnMapBounds,
                          })
                        );
                      }}
                    />
                  </div>
                }
              />
              <Divider style={{ marginBottom: '0px' }}></Divider>
            </>
          ) : (
            <Menu.Item className="collapsed-menu-search">
              <Popup
                trigger={
                  <Icon
                    name="search"
                    title={formatMessage(additionalMessages.searchHelp)}
                    circular
                  />
                }
                on="click"
                position="right center"
                content={<FeatureSearch formatMessage={formatMessage} />}
              />
            </Menu.Item>
          )}
        </div>

        <div className="sidebar-menu-content">
          <Menu.Item
            className="menu-header"
            name={
              expandSidebar
                ? formatMessage(additionalMessages.projectSettings)
                : null
            }
            active={location.pathname === '/project'}
            as={Link}
            to={location.pathname === '/project' ? '/' : '/project'}
            title={formatMessage(additionalMessages.projectTitle)}
            data-testid="menuProject"
            style={iconExtraStyle}
          >
            <div>
              <Icon className="setting" />
            </div>
            {expandSidebar && (
              <span>{formatMessage(additionalMessages.projectSettings)}</span>
            )}
          </Menu.Item>

          <NetworkSiteMenuItem expandSidebar={expandSidebar} />

          <SubscriberSiteMenuItem expandSidebar={expandSidebar} />

          <PTPLinksMenuItem expandSidebar={expandSidebar} />

          <AccessPointsMenuItem expandSidebar={expandSidebar} />

          <Menu.Item
            name="bom"
            active={location.pathname === '/bom'}
            className="menu-header"
            as={Link}
            to={location.pathname === '/bom' ? '/' : '/bom'}
            title={formatMessage(additionalMessages.bom)}
            data-testid="menuBillOfMaterials"
            style={iconExtraStyle}
          >
            <div>
              <Icon name="file alternate" />
            </div>
            {expandSidebar && (
              <span>
                <FormattedMessage
                  id="common.bom"
                  defaultMessage="Bill of Materials"
                  description="Project BOM main menu command"
                />
              </span>
            )}
          </Menu.Item>

          <Menu.Item
            name="antennas"
            active={location.pathname.includes('/antennas')}
            className="menu-header"
            as={Link}
            to={location.pathname.includes('/antennas') ? '/' : '/antennas/ptp'}
            title={formatMessage(additionalMessages.antennas)}
            data-testid="antennas"
            style={iconExtraStyle}
          >
            <div>
              <Icon className="antenna-icon" />
            </div>
            {expandSidebar && (
              <span>
                <FormattedMessage
                  id="common.antennas"
                  defaultMessage="Antennas"
                  description="Antennas"
                />
              </span>
            )}
          </Menu.Item>

          {(permissionWrite || showBestServer) && (
            <Menu.Item
              name="best_server"
              active={location.pathname === '/best_server'}
              className="menu-header"
              as={Link}
              to={location.pathname === '/best_server' ? '/' : '/best_server'}
              title={formatMessage(additionalMessages.bestServerTitle)}
              data-testid="menuBestServer"
              style={iconExtraStyle}
            >
              <div>
                <Icon className="best-server-icon" />
              </div>
              {expandSidebar && (
                <span>
                  <FormattedMessage
                    id="common.bestServer"
                    defaultMessage="Best Server"
                    description="Best Server main menu command"
                  />
                </span>
              )}
            </Menu.Item>
          )}

          {hasWritePermission && (
            <Menu.Item
              name="terragraph"
              active={location.pathname === '/terragraph_planner'}
              className="menu-header"
              as={Link}
              to={
                location.pathname === '/terragraph_planner'
                  ? '/'
                  : '/terragraph_planner'
              }
              title={formatMessage(additionalMessages.terragraphTitle)}
              data-testid="terragraphPlanner"
              style={iconExtraStyle}
            >
              <div>
                <Icon className="terragraph-logo-icon" />
              </div>
              {expandSidebar && (
                <span>
                  <FormattedMessage
                    id="common.terragraph"
                    defaultMessage="Terragraph Planner"
                    description="terragraph main menu command"
                  />
                </span>
              )}
            </Menu.Item>
          )}
          {!expandSidebar && (
            <div
              style={{
                position: 'absolute',
                bottom: '1em',
                right: '1px',
              }}
            >
              <Segment basic>
                <Icon
                  name={
                    expandSidebar ? 'angle double left' : 'angle double right'
                  }
                  link
                  size="large"
                  title={formatMessage(additionalMessages.expandSidebar)}
                  onClick={() => {
                    const defaultSidebarWidth = 250;
                    setSessionJSON('cn.lp.sidebarWidth', defaultSidebarWidth);
                    store.dispatch(uiExpandSidebar());
                  }}
                />
              </Segment>
            </div>
          )}
        </div>
      </Sidebar>
    </FilterContext.Provider>
  );
};

const MainMenu = connect((state: RootStateOrAny) => {
  return {
    expandSidebar: state.mainFrame.expandSidebar,
    networkSiteCount: state.mainFrame.networkSiteCount,
    subscriberSiteCount: state.mainFrame.subscriberSiteCount,
    ptpLinksCount: state.mainFrame.ptpLinksCount,
    pmpLinksCount: state.mainFrame.pmpLinksCount,
    accessPointsCount: state.mainFrame.accessPointsCount,
    meshLinksCount: state.mainFrame.meshLinksCount,
    smsCount: state.pmp.smsPerProject.count,
    viewshedsCount: state.mainFrame.viewshedsCount,
    loadingProject: state.mainFrame.loadingProject,
    projectId: state.mainFrame.projectId,
    equipmentConfigsCount: state.equipment.equipmentConfigsCount,
    permissionWrite: state.mainFrame.permissionWrite,
    hasWritePermission: state.mainFrame.hasWritePermission,
    warning: state.mainFrame.warning,
    locked: state.mainFrame.locked,
  };
})(MainMenuContainer);

export default MainMenu;
