import React, { useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { CellEditor } from './CellEditor';
import { type ColumnMeta, type Row } from '@tanstack/react-table';
import { PrimaryRowContext } from '../internal/TableBody';
import { useTableContext } from '../internal/TableContext';
import { multiSelectKey } from 'src/utils/useful_functions';

export function CellEditButton<T>({
  cellRef,
  meta,
  subRow,
  ...rest
}: {
  cellRef: React.RefObject<HTMLTableCellElement>;
  meta: ColumnMeta<T, unknown>;
  subRow?: Row<T>;
} & Record<string, any>) {
  const context = React.useContext(PrimaryRowContext);
  const { table, setLastSelectedRowId, getRowId } = useTableContext();
  const { row } = context;
  const [isOpen, setIsOpen] = useState(false);
  const [antennaCanClose, setAntennaCanClose] = useState(false);

  let actualRow;
  if (subRow != null) {
    actualRow = subRow;
  } else {
    actualRow = row;
  }

  const openEditor = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const closeEditor = (e) => {
    e?.stopPropagation();
    setIsOpen(false);
  };

  const toggleEditor = (e) => {
    e.stopPropagation();

    // create a mapping between two row models using the original data
    const thisRowId = getRowId(actualRow.original);
    const selectedRowIds = Object.fromEntries(
      table.getSelectedRowModel().rows.map((r) => [getRowId(r.original), r])
    );

    if (!selectedRowIds[thisRowId]) {
      if (!multiSelectKey(e)) {
        // clicking buttons should keep multi-select behavior
        table.resetRowSelection();
      }
      actualRow.toggleSelected(true);
    }

    setLastSelectedRowId(actualRow.id);
    setIsOpen((open) => !open);
    setAntennaCanClose(false);
  };

  return (
    <>
      <Popup
        basic
        on="click"
        open={isOpen}
        onOpen={openEditor}
        onClose={closeEditor}
        // TODO might have to be smarter about this so the behavior is consistent
        // prevent this so that the popup used in the antenna selection
        // doesn't close when clicking on the antenna selection dropdown
        closeOnDocumentClick={!meta.attr?.endsWith('antenna') || antennaCanClose}
        positionFixed
        context={cellRef.current}
        trigger={
          <button
            onClick={toggleEditor}
            type="button"
            className="lp-table-edit-button"
          >
            <Icon name="pencil" />
          </button>
        }
        content={
          <CellEditor
            context={context}
            close={closeEditor}
            meta={meta}
            subRow={subRow}
            setAntennaCanClose={setAntennaCanClose}
            {...rest}
          />
        }
      />
    </>
  );
}
