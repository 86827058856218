import React, { useCallback, useEffect, useState } from 'react';
import { useBlocker } from '../hooks/useBlocker';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmActionContainer } from 'src/components/ConfirmAction';
import { useDispatch } from 'react-redux';
import { uiSet } from 'src/pages/mainframe/mainframe.reducer';

function usePrompt(cb, when) {
  const blocker = useCallback(
    (tx) => {
      if (cb(tx.location)) {
        tx.retry();
      }
    },
    [cb]
  );

  useBlocker(blocker, when);
}

export const RouteLeavingGuard = ({
  when,
  shouldBlockNavigation,
  yes,
  no,
  content,
  title,
  callback,
  resetCallback = null,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [modalVisible, updateModalVisible] = useState(false);
  const [lastLocation, updateLastLocation] = useState();
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  const showModal = (location) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (cb) => {
    updateModalVisible(false);
    if (typeof cb === 'function') {
      cb();
    }
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    if (when) {
      dispatch(
        uiSet({
          hasModifications: location.pathname,
          resetCallback: resetCallback ?? callback,
        })
      );
    } else {
      dispatch(uiSet({ hasModifications: null, resetCallback: null }));
    }
  }, [when]);

  usePrompt(handleBlockedNavigation, when);

  useEffect(() => {
    if (confirmedNavigation) {
      if (callback) {
        callback();
      }
      navigate(lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation]);

  return (
    <ConfirmActionContainer
      open={modalVisible}
      size="mini"
      header={title}
      message={content}
      cancelButton={no}
      confirmButton={yes}
      onCancel={closeModal}
      onConfirm={handleConfirmNavigationClick}
    />
  );
};

export default RouteLeavingGuard;
