import React from 'react';
import { Table } from '@tanstack/react-table';
import { PrimaryRow } from './PrimaryRow';
import { type PathIndexed } from './constants';
import { useSelector } from 'react-redux';
import { QueryKey } from '@tanstack/react-query';

export const PrimaryRowContext = React.createContext<{
  projectId: string;
  queryKey: QueryKey;
  row: any;
  table: Table<any>;
} | null>(null);

export function TableBody<T extends PathIndexed>({
  queryKey,
  table,
  getTooltip,
  onShiftClick,
}: {
  queryKey: QueryKey;
  table: Table<T>;
  getTooltip: Function;
  onShiftClick?: (rowId: string, shiftKey: boolean, ctrlKey?: boolean) => void;
}) {
  const projectId = useSelector((state: any) => state.mainFrame.projectId);

  return (
    <tbody>
      {table.getRowModel().rows.map((row) => (
        <PrimaryRowContext.Provider
          key={row.id}
          value={{ projectId, queryKey, row, table }}
        >
          <PrimaryRow
            row={row}
            tooltip={(getTooltip && getTooltip(row)) || ''}
            onShiftClick={onShiftClick}
          />
        </PrimaryRowContext.Provider>
      ))}
    </tbody>
  );
}

export const MemoizedTableBody = React.memo(
  TableBody,
  (prev, next) => prev.table.options.data === next.table.options.data
) as typeof TableBody;
