import React from 'react';
import { CellContext, Column, Row, Table } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  encodeLatLong,
  formatMacAddress,
  meterToAny,
  temperatureFromKelvin,
  throughputString,
} from 'src/utils/useful_functions';
import get from 'lodash/get';
import { store } from 'src/store';

export function groupById<T extends { id: string }>(row: T) {
  return row.id;
}

export function groupByRemoteEndId<T extends { remote_end_id: string }>(
  row: T
) {
  return row.remote_end_id;
}

function headerWithUnits(text: string, units: 'rangeUnits' | 'heightUnits') {
  return ({ column }: { column: Column<any, number> }) =>
    `${text} (${column.columnDef.meta?.prefs?.[units]})`;
}

export function headerWithRangeUnits(text: string) {
  return headerWithUnits(text, 'rangeUnits');
}

export function headerWithHeightUnits(text: string) {
  return headerWithUnits(text, 'heightUnits');
}

type TRenderNumber = {
  precision: number;
  nullValue?: string;
  prefsKey?: string;
  converter?: any;
};

export function renderNumber({
  precision,
  nullValue,
  prefsKey,
  converter,
}: TRenderNumber) {
  return (cell: CellContext<any, number | 'N/A'>) => {
    const prefs = store.getState().mainFrame.prefs;

    const value = cell.getValue();
    if (value === 'N/A') {
      return value;
    }

    const default_value = nullValue ?? '';
    if (value == null) {
      return default_value;
    }

    let numericValue = Number(value);

    if (prefsKey != null) {
      // range or height column
      const scaledValue = meterToAny(
        numericValue,
        prefs[prefsKey],
        precision,
        false
      );
      return scaledValue.toFixed(precision);
    }

    if (converter != null) {
      // assume converter handles precision
      return converter(numericValue, prefs);
    }

    return numericValue.toFixed(precision);
  };
}

export function renderThroughput({}) {
  return (cell: CellContext<any, number | 'N/A'>) => {
    const value = cell.getValue();
    if (value === 'N/A' || value === null) {
      return 'N/A';
    }

    const throughput = Number(value);
    return throughputString(throughput);
  };
}

export function macAddressGetter_Table(value, prefs) {
  return formatMacAddress(value, prefs.macAddressFormat);
}

export function clutterTypeGetter_Table(value, { heightUnits }) {
  if (!value) {
    return '';
  }

  // get the height from the value and convert it to the
  // preferred units. The value from the backend is always in metres
  const [clutterType, heightStr] = value.split('(');
  const heightMetres = parseFloat(heightStr);
  const heightDisplayUnits = meterToAny(heightMetres, heightUnits, 0, false);
  return `${clutterType} (${heightDisplayUnits.toFixed(1)} ${heightUnits})`;
}

export function renderConvertedString({ converter }) {
  return (cell: CellContext<any, string>) => {
    const prefs = useSelector((state: any) => state.mainFrame.prefs);
    return converter(cell.getValue(), prefs);
  };
}

export function renderYesNo() {
  return (cell: CellContext<any, boolean | 'N/A'>) => {
    const value = cell.getValue();
    if (value === 'N/A') {
      return value;
    }
    return value ? 'Yes' : 'No';
  };
}

export function renderEnabled() {
  return (cell: CellContext<any, boolean | 'N/A'>) => {
    const value = cell.getValue();
    if (value === 'N/A') {
      return value;
    }
    return value ? 'Enabled' : 'Disabled';
  };
}

export function removeLinkSuffix(text: string): string {
  return text.replace(
    / \((Link [ABCD]|Primary to Primary|Primary to Secondary|Secondary to Primary|Secondary to Secondary|Aggregate|1|2)\)$/,
    ''
  );
}

function renderName<T extends { id: string }>(
  urlBase: string,
  props: CellContext<T, string> & { isSubRow?: boolean },
  idAttr: string = 'id'
) {
  // NOTE: LPWEB-513 - add this back if we reintroduce
  // visual row grouping (see also: GroupedRow.tsx)
  // if (props.isSubRow === true) {
  //   return props.getValue();
  // }

  const color = (props.row.original as any).strokeColor ?? undefined;
  return (
    <Link
      to={`/${urlBase}/${get(props.row.original, idAttr)}`}
      style={{ color }}
    >
      {props.getValue()}
    </Link>
  );
}

export function renderNetworkSiteName<T extends { id: string }>(
  props: CellContext<T, string> & { isSubRow?: boolean }
) {
  return renderName('network_sites', props);
}

export function renderSubscriberSiteName<T extends { id: string }>(
  props: CellContext<T, string> & { isSubRow?: boolean }
) {
  return renderName('subscriber_sites', props);
}

export function renderLinkName<T extends { id: string }>(
  props: CellContext<T, string>
) {
  return renderName('ptp', props);
}

export function renderNDName<T extends { id: string }>(
  props: CellContext<T, string> & { isSubRow?: boolean }
) {
  return renderName('aps', props);
}

export function renderNDNameInPMPLinksTable<T extends { id: string }>(
  props: CellContext<T, string> & { isSubRow?: boolean }
) {
  return renderName('aps', props, 'local_end_id');
}
export function renderSubscriberName<T extends { id: string }>(
  props: CellContext<T, string> & { isSubRow?: boolean }
) {
  return renderName('subscribers', props, 'remote_end_id');
}

export function renderMeshName<T extends { id: string }>(
  props: CellContext<T, string>
) {
  return renderName('mesh', props);
}

export function renderMeshNDName<T extends { id: string }>(endName: string) {
  return (props: CellContext<T, string>) => {
    return renderName('aps', props, `${endName}.id`);
  };
}

function renderGroupedName<T extends { id: string }>(
  urlBase: string,
  props: CellContext<T, string>,
  idAttr: string = 'id'
) {
  const color = (props.row.original as any).strokeColor ?? undefined;
  return (
    <Link to={`/${urlBase}/${props.row.original[idAttr]}`} style={{ color }}>
      {removeLinkSuffix(props.getValue())}
    </Link>
  );
}

export function renderGroupedLinkName<T extends { id: string }>(
  props: CellContext<T, string>
) {
  return renderGroupedName('ptp', props);
}

export function renderGroupedNDName<T extends { id: string }>(
  props: CellContext<T, string>
) {
  return renderGroupedName('aps', props);
}

export function renderGroupedSubscriberName<T extends { id: string }>(
  props: CellContext<T, string>
) {
  return renderGroupedName('subscribers', props, 'remote_end_id');
}

export function getCSVData<T>(table: Table<T>) {
  // Get all rows including grouped rows, ignoring pagination
  const rows = table.getPrePaginationRowModel().rows;

  // Get headers in their visible order, excluding 'select' column
  const headers = table
    .getFlatHeaders()
    .filter((header) => header.column.id !== 'select')
    .reduce((acc, header) => {
      const headerDef = header.column.columnDef.header;
      acc[header.column.id] =
        typeof headerDef === 'function'
          ? headerDef(header.getContext())
          : headerDef;
      return acc;
    }, {} as { [key: string]: string });

  const csvData = rows.flatMap((row) => {
    // For grouped rows, return subrows
    if (row.getIsGrouped()) {
      return row.subRows.map((subRow) => getRowData(subRow, headers));
    }

    // For regular rows
    return [getRowData(row, headers)];
  });

  return csvData;
}

// Helper function for the csv downloader to get row data in correct order
function getRowData<T>(row: Row<T>, headers: { [key: string]: string }) {
  const rowData: { [key: string]: any } = {};

  // Maintain column order by using the headers object
  Object.entries(headers).forEach(([columnId, headerValue]) => {
    const cell = row.getAllCells().find((cell) => cell.column.id === columnId);
    if (cell) {
      if (cell.column.columnDef?.meta?.csv) {
        rowData[headerValue] = cell.column.columnDef.meta.csv(
          cell.getContext()
        );
      } else {
        rowData[headerValue] = cell.getValue();
      }
    }
  });

  return rowData;
}

export function temperatureFromKelvin_Table(value, { heightUnits }) {
  return temperatureFromKelvin(value, heightUnits);
}

export function latitude_Table(value, { latLngFormat }) {
  return encodeLatLong(value, latLngFormat, true);
}

export function longitude_Table(value, { latLngFormat }) {
  return encodeLatLong(value, latLngFormat, false);
}

export type TChoicesKind =
  | 'ptp'
  | 'access_point'
  | 'subscriber'
  | 'site'
  | 'mesh';

function propsForChoicesKind(choicesKind: TChoicesKind) {
  if (choicesKind === 'ptp') {
    return [{ prop: 'path_index', defaultValue: 0 }];
  } else if (choicesKind === 'access_point') {
    return [{ prop: 'radio_number', defaultValue: 1 }];
  } else if (choicesKind === 'subscriber') {
    return [{ prop: 'path_index', defaultValue: 0 }];
  }
  return [];
}

export function editRowSelector(choicesKind: TChoicesKind, row: any) {
  // row = row.original
  if (choicesKind === 'ptp') {
    return [row.id, row.path_index];
  } else if (choicesKind === 'access_point') {
    return [row.id, row.radio_number ?? 1];
  } else if (choicesKind === 'subscriber') {
    return [row.remote_end_id, row.path_index];
  } else if (choicesKind === 'site' || choicesKind === 'mesh') {
    return [row.id];
  }
  throw new Error(`Unknown choices kind: ${choicesKind}`);
}

export function editRowEquals(choicesKind: TChoicesKind, row: any, cmp: any) {
  // row = row.original
  // cmp is previous output of editRowSelector
  const rowValues = editRowSelector(choicesKind, row);
  for (let i = 0; i < rowValues.length; i++) {
    if (rowValues[i] !== cmp[i]) {
      return false;
    }
  }
  return true;
}

export function valuesForChoicesKind(
  choicesKind: TChoicesKind,
  row: any,
  includeId: boolean
) {
  // row = row.original
  let result = {};
  for (const props of propsForChoicesKind(choicesKind)) {
    const { prop, defaultValue } = props;
    result[prop] = row[prop] ?? defaultValue;
  }

  if (includeId) {
    if (choicesKind === 'subscriber') {
      result['id'] = row.remote_end_id;
    } else {
      result['id'] = row.id;
    }
  }

  return result;
}

export function rangeFilterFn({ precision }) {
  return (
    row: Row<any>,
    columnId: string,
    filterValue: any,
    addMeta: (meta: any) => void
  ) => {
    // const prefs = useSelector((state: any) => state.mainFrame.prefs);
    // not sure why the renderNumber function can useSelector
    // but it triggers an error here.
    const prefs = store.getState().mainFrame.prefs;
    const range = row.getValue(columnId) as string | number;
    if (range === 'N/A' || range === '') {
      return (range as string).includes(filterValue);
    }
    const scaledValue = meterToAny(
      range as number,
      prefs.rangeUnits,
      99,
      false
    );
    const scaledString = scaledValue.toFixed(precision);
    return scaledString.includes(filterValue);
  };
}

export function heightFilterFn({ precision }) {
  return (
    row: Row<any>,
    columnId: string,
    filterValue: any,
    addMeta: (meta: any) => void
  ) => {
    // const prefs = useSelector((state: any) => state.mainFrame.prefs);
    // not sure why the renderNumber function can useSelector
    // but it triggers an error here.
    const prefs = store.getState().mainFrame.prefs;
    const height = row.getValue(columnId) as string | number;
    if (height === 'N/A' || height === '') {
      return (height as string).includes(filterValue);
    }
    const scaledValue = meterToAny(
      height as number,
      prefs.heightUnits,
      99,
      false
    );
    const scaledString = scaledValue.toFixed(precision);
    return scaledString.includes(filterValue);
  };
}

/**
 * Parses availability time strings in the format "X.Y timeframe/year" (e.g., "5.0 secs/year")
 * and converts them to a common unit (seconds) for comparison.
 *
 * @param timeStr The time string to parse
 * @returns The time value converted to seconds
 */
function parseTimeString(timeStr: string): number {
  // Extract the numeric value and unit from strings like "5.0 secs/year"
  const match = timeStr.match(/^([\d.]+)\s+(\w+)/);
  if (!match) return 0;

  const value = parseFloat(match[1]);
  const unit = match[2].toLowerCase();

  // Convert all units to seconds for comparison
  switch (unit) {
    case 'secs':
      return value;
    case 'mins':
      return value * 60;
    case 'hrs':
      return value * 60 * 60;
    case 'days':
      return value * 60 * 60 * 24;
    default:
      return value;
  }
}

/**
 * Sorts availability time strings in the format "X.Y timeframe/year" (e.g., "5.0 secs/year", "2.1 mins/year")
 * as produced by the availability_as_time_string function in the backend.
 *
 * @returns A sorting function compatible with the tanstack table API that correctly orders availability times
 */
export function sortAvailabilityTime(
  rowA: Row<any>,
  rowB: Row<any>,
  columnId: string
) {
  const a = rowA.original[columnId];
  const b = rowB.original[columnId];

  if (a === 'N/A' && b === 'N/A') return 0;
  if (a === 'N/A') return 1;
  if (b === 'N/A') return -1;

  const aSeconds = parseTimeString(a);
  const bSeconds = parseTimeString(b);

  if (aSeconds < bSeconds) return -1;
  if (aSeconds > bSeconds) return 1;
  return 0;
}
