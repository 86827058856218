import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import productFamily from '../pmp_families.json';
import { cloneDeep } from 'lodash';
import { pmp450ViewConfig } from './configs/PMP450ViewConfig';
import {
  AfterOnChangeMethods,
  PMPChoices,
  PMPParams,
  PTPFormField,
  PTPFormPanel,
  TMinMax,
} from 'src/pages/ptp/utils';
import { ePMPViewConfig } from './configs/EPMPViewConfig';
import { cnReachViewConfig } from './configs/cnReachViewConfig';
import { cnWave28ViewConfig } from './configs/cnWave28ViewConfig';
import { cnWave60ViewConfig } from './configs/cnWave60ViewConfig';
import { PTP670HCMPViewConfig } from './configs/PTP670HCMPViewConfig';
import { PTP700HCMPViewConfig } from './configs/PTP700HCMPViewConfig';

const productViewConfigs = {
  'PMP-450': pmp450ViewConfig,
  'PTP-670-HCMP': PTP670HCMPViewConfig,
  'PTP-700-HCMP': PTP700HCMPViewConfig,
  ePMP: ePMPViewConfig,
  cnReach: cnReachViewConfig,
  'cnWave 28': cnWave28ViewConfig,
  cnWave: cnWave60ViewConfig,
};
export function getProductPanels(product: string, isTemplate: boolean) {
  const family = productFamily[product];
  const panels = productViewConfigs[family];
  if (isTemplate) {
    return getTemplatePanels(panels);
  }
  return panels;
}

const getTemplatePanels = (panels) => {
  const excludeTemplateFields = [
    'range_units',
    'sm_range',
    'azimuth',
    'tilt',
    'height',
    'channel',
    'polarity',
    'golay',
    'tx_channel',
  ];
  let configClone = cloneDeep(panels);
  Object.keys(configClone).forEach((panelKey) => {
    configClone[panelKey].fields = configClone[panelKey].fields?.filter(
      (field) => {
        return !excludeTemplateFields.includes(field.attrName);
      }
    );
  });
  return configClone;
};

export function syncFormState(
  getter: UseFormGetValues<any>,
  setter: UseFormSetValue<any>,
  panels: any,
  newChoices: PMPChoices,
  attr: string
) {
  for (const name of ['equipment', 'product', 'sector']) {
    const panel = panels[name];
    let show = true;
    if (panel && panel.show) {
      show = panel.show({ formGetter: getter });
    }
    if (panel) {
      for (const field of panel.fields) {
        // choices response will return the correct next
        // value to take for each equipment field, so update
        // when they dont match
        const currentValue = getter(field.getter as any);
        let choiceValue = newChoices.equipment[field.attrName]?.value;

        if (field.nextValue != null) {
          choiceValue = field.nextValue(currentValue, newChoices, getter, attr);
        }
        if (currentValue !== choiceValue) {
          setter(field.getter as any, choiceValue, { shouldDirty: true });
        }
      }
    }
  }
  for (const name of ['antenna', 'power']) {
    const panel = panels[name];
    if (panel) {
      for (const field of panel.fields) {
        if (field.nextValue != null) {
          // when present, nextValue() will determine the correct
          // next value for a field when the api response doesn't
          // know how to calculate it, e.g. when antenna needs to change
          const getterKey =
            field.valueGetter != null ? field.valueGetter : field.getter;
          let value = field.nextValue(
            getter(getterKey),
            newChoices,
            getter,
            attr
          );

          if (field.nextValueNullOk === true || value != null) {
            // set the internal form value to the next value
            setter(getterKey, value, {
              shouldDirty: true,
            });
          }
        }
      }
    }
  }

  if (panels.sectorPower) {
    let show = true;
    if (panels.sectorPower.show)
      show = panels.sectorPower.show({ formGetter: getter });
    if (show) {
      for (const field of panels.sectorPower.fields) {
        if (field.nextValue != null) {
          // when present, nextValue() will determine the correct
          // next value for a field when the api response doesn't
          // know how to calculate it, e.g. when antenna needs to change
          const getterKey =
            field.valueGetter != null ? field.valueGetter : field.getter;
          let value = field.nextValue(
            getter(getterKey),
            newChoices,
            getter,
            attr
          );
          if (field.nextValueNullOk === true || value != null) {
            // set the internal form value to the next value
            setter(getterKey, value, {
              shouldDirty: true,
            });
          }
        }
      }
    }
  }
}

export type PMPPanelProps = {
  modified: boolean;
  setModified: React.Dispatch<React.SetStateAction<boolean>>;
  choices: PMPChoices;
  refreshChoices: any;
  panel: PMPFormPanel;
  ap: any;
  isTemplate?: boolean;
  sm?: any;
};

export type PMPShowProps = {
  ap: any;
  sm?: any;
  choices: PMPChoices;
  formGetter: (getter: string) => any;
  formSetter: (setter: string, value: any, options: any) => any;
  refreshChoices?: Function;
};

export type PMPFormPanel = Omit<PTPFormPanel<any>, 'fields' | 'show'> & {
  show?: (arg: {
    ap?: any;
    choices: PMPChoices;
    formGetter: (getter: string) => any;
  }) => boolean;
  fields: PMPFormField[];
  fieldLayout?: number[][];
  customClass?: string;
  // in case of sector panels we use titles property to display the sector
  // titles
  sectorTitles?: string[];
};

export type PMPFormField = Omit<
  PTPFormField<any>,
  | 'show'
  | 'nextValue'
  | 'max'
  | 'min'
  | 'tooltip'
  | 'editable'
  | 'componentProps'
  | 'warning'
  | 'info'
  | 'afterOnChange'
  | 'label'
  | 'checkboxCompProps'
> & {
  min?: TMinMax;
  max?: TMinMax;
  nextValue?: (
    currentValue: any,
    newChoices: PMPChoices,
    formGetter: (getter: string) => any,
    attr: string
  ) => any;
  tooltip?: (params: PMPParams) => string;
  editable?: boolean | ((params: PMPParams) => boolean);
  componentProps?: any | ((arg: PMPShowProps) => any);
  checkboxCompProps?: any | ((arg: PMPShowProps) => any);
  show?: (params: PMPShowProps) => boolean;
  warning?: (params: PMPParams) => boolean | string;
  info?: (params: PMPParams) => boolean | string;
  afterOnChange?: (
    newValue: any,
    formMethods: AfterOnChangeMethods,
    newChoices: PMPChoices
  ) => void;
  label?: ((arg: PMPParams) => string) | string;
};
