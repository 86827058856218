import { get, isFunction } from 'lodash';
import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form, Label } from 'semantic-ui-react';
import StoredAccordion from 'src/components/StoredAccordion';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import { PMPPanelProps } from './utils';
import { checkErrors } from './configs/config-utils';
import { getDisplayValue } from 'src/utils/useful_functions';
import { getWarningLabel } from './NDAntennaPanel';
import { FormResetContext } from '../utils';

function NDEquipmentPanel({
  ap,
  isTemplate,
  sm,
  choices,
  panel,
  modified,
  setModified,
  refreshChoices,
}: PMPPanelProps) {
  const resetValue = useContext(FormResetContext);
  const { control, getValues, setValue, getFieldState } = useFormContext();
  const { equipment } = choices;
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const errors = checkErrors({
    panel,
    getValues,
    getFieldState,
    ap,
    sm,
    setValue,
    choices,
  });
  const show = panel.show;
  if (show && !show({ formGetter: getValues, choices })) return <></>;
  return (
    <StoredAccordion
      name={`nd_${panel.name}`}
      title={panel.title}
      headerClass={errors ? 'custom-error' : ''}
      contentProps={{ loading: ap.dirty || sm?.dirty }}
    >
      {panel.fields.map((field) => {
        const {
          attrName,
          getter,
          component,
          label,
          max,
          min,
          show,
          units,
          refreshesChoices,
          componentProps,
          editable = true,
          afterOnChange,
          warning,
          info,
          infoColor,
        } = field;
        const choiceObj = equipment[attrName];
        let cprops: Record<string, any> = componentProps;
        if (typeof componentProps === 'function') {
          cprops = componentProps({
            ap,
            choices,
            formGetter: getValues,
            refreshChoices: refreshChoices,
            formSetter: setValue,
          });
        }
        if (
          choiceObj == null &&
          get(cprops, 'options', []).length == 0 &&
          attrName != 'total_virtual_circuits'
        ) {
          return null;
        }

        if (
          show != null &&
          !show({
            choices,
            formGetter: getValues,
            ap,
            sm,
            formSetter: setValue,
          })
        ) {
          return null;
        }
        const level = choiceObj;
        let editableField = editable;

        let finalLabel = '';
        if (label && component === GenericScaledField) {
          // Do nothing - we don't want finalLabel
          // since it will get rendered as part of the GenericScaledField
        } else if (typeof label === 'function') {
          finalLabel = label({ choices, formGetter: getValues, ap, sm });
        } else if (label) {
          finalLabel = label;
        } else if (level) {
          finalLabel = level.display_name;
        }

        let maxValue = max;
        let minValue = min;
        if (typeof max === 'function') {
          maxValue = max({ choices, formGetter: getValues, ap });
        }
        if (typeof min === 'function') {
          minValue = min({ choices, formGetter: getValues, ap });
        }
        const warningContent =
          warning && warning({ ap, sm, choices, formGetter: getValues });

        let infoLabel: any = null;
        if (info != null) {
          const text = info({ ap, sm, choices, formGetter: getValues });
          if (text != null) {
            infoLabel = (
              <Label
                color={infoColor ?? 'yellow'}
                pointing
                style={{ marginTop: 0, width: '100%' }}
              >
                {text}
              </Label>
            );
          }
        }

        let ctrl: any = null;

        if (component === GenericScaledField) {
          ctrl = (
            <GenericScaledField
              key={getter}
              {...field}
              disabled={!editableField || !permissionWrite}
              {...cprops}
              units={isFunction(units) ? units(getValues) : units}
              defaultValue={sm != null ? get(sm, getter) : get(ap, getter)}
              min={minValue}
              max={maxValue}
              setModified={setModified}
              watch={resetValue}
            ></GenericScaledField>
          );
        } else if (
          (level != null &&
            level.choices != null &&
            level.choices.length > 1) ||
          get(cprops, 'options', []).length > 0
        ) {
          ctrl = (
            <Controller
              control={control}
              name={getter}
              render={({ field: { ref, onChange, ...rest } }) => (
                <>
                  <Form.Select
                    options={level != null ? level.choices : cprops['options']}
                    {...rest}
                    disabled={!permissionWrite}
                    onChange={(e, data) => {
                      if (attrName === 'product') {
                        setValue(
                          'product.prev',
                          getValues('radios.0.equipment.product')
                        );
                      }
                      onChange(data.value);
                      setModified(true);
                      if (refreshesChoices) {
                        refreshChoices({ field });
                      }
                      if (afterOnChange) {
                        afterOnChange(
                          data.value,
                          { getValues, setValue },
                          choices
                        );
                      }
                    }}
                  />
                  {!isTemplate &&
                    warningContent &&
                    // The sync source warning hides/shows based on frontend
                    // logic so it should never appear blue
                    getWarningLabel(
                      warningContent,
                      modified && level.attr_name !== 'sync_source'
                    )}
                  {infoLabel}
                </>
              )}
            />
          );
        } else if (
          level != null &&
          level.choices != null &&
          level.choices.length == 1
        ) {
          ctrl = (
            <>
              <p>{getDisplayValue(field, level)}</p>
              {!isTemplate &&
                warningContent &&
                getWarningLabel(warningContent, modified)}
              {infoLabel}
            </>
          );
        }
        return (
          <Form.Field key={field.getter}>
            {finalLabel ? <label>{finalLabel}</label> : null}
            {ctrl}
            {/* {message} */}
          </Form.Field>
        );
      })}
    </StoredAccordion>
  );
}

export default NDEquipmentPanel;
