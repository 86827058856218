import React from 'react';
import { AggregationFn } from '@tanstack/react-table';
import { Icon, Input } from 'semantic-ui-react';
import { HeaderCell } from './internal/HeaderCell';
import { useIntl } from 'react-intl';
import { ColumnVisibilityModal } from './internal/ColumnVisibilityModal';
import { MemoizedTableBody, TableBody } from './internal/TableBody';
import { useColumnSizing } from './internal/useColumnSizing';
import { useLpTable } from './internal/useLpTable';
import { useQuery, type QueryKey } from '@tanstack/react-query';
import { Toolbar } from './Toolbar';
import { type PathIndexed } from './internal/constants';
import messages from 'src/messages';
import TableSkeleton from './TableSkeleton';
import { TableContext } from './internal/TableContext';

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends unknown, TValue> {
    // prefs is always passed, but dynamically in the component
    // need the optional here to allow the generated columns to
    // pass typecheck
    prefs?: {
      rangeUnits: string;
      heightUnits: string;
      latLngFormat: string;
      macAddressFormat: string;
      [key: string]: any;
    };
  }

  interface AggregationFns {
    firstChild: AggregationFn<unknown>;
  }
}

function getChild(children: React.ReactNode, kind: React.ElementType) {
  return React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === kind
  );
}

/*
 * Return the footer label that shows the number of visible rows
 * along with the total number of items in the table
 */
function getRowCountLabel(
  rowCount: number,
  isShowingAllRows: boolean,
  pagination: { pageIndex: number; pageSize: number }
): string {
  const minCount =
    rowCount > 0 ? 1 + pagination.pageIndex * pagination.pageSize : 0;
  const visibleCount = isShowingAllRows
    ? rowCount
    : Math.min(
        rowCount,
        pagination.pageSize + pagination.pageIndex * pagination.pageSize
      );
  return `${minCount} to ${visibleCount} of ${rowCount}`;
}

type TProps<T extends PathIndexed> = {
  queryKey: QueryKey;
  model: ReturnType<typeof useLpTable<T>>;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  tableId: string;
};

function LPTable<T extends PathIndexed>({
  queryKey,
  model,
  children,
  style,
  tableId,
}: TProps<T>) {
  const [isColumnModalOpen, setIsColumnModalOpen] = React.useState(false);

  const { formatMessage } = useIntl();
  const {
    table,
    getRowId,
    pagination,
    globalFilter,
    setGlobalFilter,
    columnOrder,
    setColumnOrder,
    isShowingAllRows,
    toggleShowAllRows,
    getTooltip,
    setLastSelectedRowId,
    defaultPinnedColumn,
  } = model;

  const { columnSizeVars, isResizingColumn } = useColumnSizing({ table });

  const selectedRowCount = table.getSelectedRowModel().rows.length;
  const rowCount = table.getRowCount();
  const { fetchStatus } = useQuery({ queryKey, enabled: false });
  return (
    <TableContext.Provider value={{ table, setLastSelectedRowId, getRowId }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          ...(style ?? {}),
        }}
      >
        {/* Toolbar */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
            paddingBottom: '12px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {getChild(children, Toolbar)}
          </div>

          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <Input
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder={formatMessage(messages.filterAnyColumn)}
              transparent
              className={
                globalFilter
                  ? 'lp-table-filter filtered'
                  : 'lp-table-filter unfiltered'
              }
              icon={{
                name: 'cancel',
                onClick: () => setGlobalFilter(''),
                link: true,
                title: formatMessage(messages.clearFilter),
              }}
            />

            <ColumnVisibilityModal
              table={table}
              columnOrder={columnOrder}
              setColumnOrder={setColumnOrder}
              isOpen={isColumnModalOpen}
              setIsOpen={setIsColumnModalOpen}
              tableId={tableId}
              defaultPinnedColumn={defaultPinnedColumn}
            />
          </div>
        </div>

        {/* Main table */}
        <div
          className="lp-table-wrapper"
          onClick={(e) => {
            // Only handle clicks directly on the wrapper (not on children)
            if (e.target === e.currentTarget) {
              e.stopPropagation();
              table.resetRowSelection();
            }
          }}
        >
          {fetchStatus === 'fetching' ? (
            <TableSkeleton rows={model.pagination.pageSize} columns={5} />
          ) : (
            <table
              className="lp-table"
              style={{ width: table.getCenterTotalSize(), ...columnSizeVars }}
            >
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <HeaderCell
                    key={headerGroup.id}
                    headerGroup={headerGroup}
                    isResizingColumn={isResizingColumn}
                  />
                ))}
              </thead>

              {isColumnModalOpen ||
              table.getState().columnSizingInfo.isResizingColumn ? (
                <MemoizedTableBody
                  queryKey={queryKey}
                  table={table}
                  getTooltip={getTooltip}
                  onShiftClick={model.handleRowSelectionWithShift}
                />
              ) : (
                <TableBody
                  queryKey={queryKey}
                  table={table}
                  getTooltip={getTooltip}
                  onShiftClick={model.handleRowSelectionWithShift}
                />
              )}
            </table>
          )}
        </div>

        {/* Pagination */}
        <div className="lp-table-footer">
          <div
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
          >
            <label
              style={{
                display: 'flex',
                alignItems: 'start',
                cursor: 'pointer',
                flex: '1',
              }}
            >
              {(rowCount > pagination.pageSize || isShowingAllRows) && (
                <>
                  <input
                    type="checkbox"
                    checked={isShowingAllRows}
                    onChange={toggleShowAllRows}
                    style={{ marginRight: '8px', cursor: 'pointer' }}
                  />
                  Show All Rows{' '}
                </>
              )}
              {selectedRowCount > 0 ? <>({selectedRowCount} selected)</> : null}
            </label>

            {getRowCountLabel(rowCount, isShowingAllRows, pagination)}

            <button
              onClick={() => table.firstPage()}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                height: '30px',
                padding: '0px',
                opacity: table.getCanPreviousPage() ? 1 : 0.5,
              }}
            >
              <Icon className="lp-icon first-page" size="large" />
            </button>
            <button
              onClick={() => table.previousPage()}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                height: '30px',
                padding: '0px',
                opacity: table.getCanPreviousPage() ? 1 : 0.5,
              }}
            >
              <Icon className="lp-icon prev-page" size="large" />
            </button>
            <span>
              Page {pagination.pageIndex + 1} of {table.getPageCount()}
            </span>
            <button
              disabled={!table.getCanNextPage()}
              onClick={() => table.nextPage()}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                height: '30px',
                padding: '0px',
                opacity: table.getCanNextPage() ? 1 : 0.5,
              }}
            >
              <Icon className="lp-icon next-page" size="large" />
            </button>
            <button
              onClick={() => table.lastPage()}
              disabled={!table.getCanNextPage()}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                height: '30px',
                padding: '0px',
                opacity: table.getCanNextPage() ? 1 : 0.5,
              }}
            >
              <Icon className="lp-icon last-page" size="large" />
            </button>
          </div>
        </div>
      </div>
    </TableContext.Provider>
  );
}

LPTable.Toolbar = Toolbar;

export { LPTable };
