import React from 'react';

// Types for the BasicTable component
export type ColumnDef = {
  header: string;
  field: string;
  render?: (row: Record<string, any>) => React.ReactNode;
};

type BasicTableProps = {
  columns: ColumnDef[];
  data: Record<string, any>[];
  title?: string;
  onRowClick?: (row: Record<string, any>) => void;
  style?: React.CSSProperties;
};

export function BasicTable({
  columns,
  data,
  title,
  onRowClick,
  style,
}: BasicTableProps) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...(style || {}),
      }}
    >
      {/* Title */}
      {title && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '12px',
          }}
        >
          <h3>{title}</h3>
        </div>
      )}

      {/* Main table */}
      <div className="lp-table-wrapper">
        <table className="lp-table" style={{ width: '100%' }}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  style={{
                    position: 'relative',
                    fontWeight: 'bold',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    textAlign: 'left',
                    borderBottom: '1px solid rgba(34, 36, 38, 0.1)',
                  }}
                >
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  No rows to show
                </td>
              </tr>
            ) : (
              data.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  onClick={() => onRowClick && onRowClick(row)}
                  style={{
                    cursor: onRowClick ? 'pointer' : 'default',
                  }}
                >
                  {columns.map((column, colIndex) => (
                    <td
                      key={colIndex}
                      style={{
                        borderBottom: '1px solid rgba(34, 36, 38, 0.1)',
                      }}
                    >
                      {column.render
                        ? column.render(row)
                        : row[column.field] !== undefined
                        ? row[column.field]
                        : ''}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
