import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { store } from 'src/store';
import {
  Segment,
  Accordion,
  Form,
  Header,
  Icon,
  Loader,
  Button,
} from 'semantic-ui-react';
import { getWithAuth, postWithAuth } from 'src/api';
import {
  getProductPanels,
  type PTPChoices,
  getLinkFromChoices,
  handleLinkKindChange,
  syncComplexFields,
  finalComplexSync,
} from './utils';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useForm,
  useFormContext,
  FormProvider,
  FieldValues,
} from 'react-hook-form';
import StoredAccordion from 'src/components/StoredAccordion';
import PTPGeneralPanel from './PTPGeneralPanel';
import PTPProfileChart from './PTPProfilePanel';
import PTPEndsPanel from './PTPEndsPanel';
import merge from 'lodash/merge';
import { PTPPerformanceSummary } from './PTPPerformanceSummary';
import PTPWarningsPanel from './PTPWarningsPanel';
import { PerformanceDetailsTabs } from 'src/pages/ptp/PerformanceDetailsTabs';
import BomModal from 'src/components/BomModal';
import messages from 'src/messages';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useIsFetching,
} from '@tanstack/react-query';
import RouteLeavingGuard from 'src/components/RouteLeavingGuard';
import type { PTPEnd, PTPLink, PTPPath } from './ptp-link-type';
import isEmpty from 'lodash/isEmpty';
import { downloadReport } from 'src/utils/useful_functions';
import { RootStateOrAny } from 'src/store';
import AntennaFormContainer from '../antennas/AntennaFormContainer';
import { uiConfirmAction } from '../mainframe/mainframe.reducer';
import { PanelHeading, ToolbarSeparator } from 'src/components/PanelHeading';
import { ShowInMap } from 'src/components/ShowInMap';
import { FormResetContext } from '../pmp/utils';
function mergeFormWithPath(path, formValues) {
  // formValues comes from getValues() call
  const merged = merge(path, formValues);

  for (const endName of ['local', 'remote']) {
    const requiredLength = formValues[endName].radios.length;
    // when changing 4+0 -> 2+0 -> 4+0, the size of the radios array
    // would never shrink so 4 radios would stay in the array and we
    // wouldnt detect a LK change going from 2+0 -> 4+0
    // formValues always gives us the correct count, so shrink if needed
    if (merged[endName].radios.length > requiredLength) {
      merged[endName].radios = merged[endName].radios.slice(0, requiredLength);
    }
  }

  return merged;
}

function undoChanges() {
  location.reload();
}
function getLinkKindPayload(local: PTPEnd, remote: PTPEnd) {
  const lk = local.radios[0].equipment.link_protection;
  if (lk != null && lk !== 'no_protection') {
    let equipment = [
      {
        local: local.radios[0].equipment,
        remote: remote.radios[0].equipment,
      },
    ];

    if (local.radios.length > 1 && remote.radios.length > 1) {
      for (let i = 1; i < local.radios.length; i++) {
        equipment.push({
          local: local.radios[i].equipment,
          remote: remote.radios[i].equipment,
        });
      }
    }

    return { equipment };
  } else {
    return {
      equipment: [
        {
          local: local.radios[0].equipment,
          remote: remote.radios[0].equipment,
        },
      ],
    };
  }
}

function extraEndChoices(end: any) {
  return {
    lat: end.site.latitude,
    lng: end.site.longitude,
    power: end.properties.power_source,
    ethernet_cable: end.properties.ethernet_cable,
    antenna_id: end.radios[0].antennas[0].lp_antenna_id,
    antenna_height: end.radios[0].antennas[0].height,
    antenna_protection: end.radios[0].antennas[0].config?.antenna_protection,
    diverse_antenna_id:
      end.radios[0].antennas[0].diverse_antenna?.lp_antenna_id,
    diverse_antenna_height: end.radios[0].antennas[0].diverse_antenna?.height,
    redundant_antenna_id: end.radios[1]?.antennas[0].lp_antenna_id,
  };
}

async function fetchChoices(
  projectId: string,
  local: PTPEnd,
  remote: PTPEnd,
  attr: string
) {
  // TODO for 2+0, instead of local/remote at the top level in the
  // object, we submit: equipment: [ { local/remote }, { local/remote } ]
  const equipment = getLinkKindPayload(local, remote);
  return await postWithAuth(`equipment/ptp/${projectId}/choices`, {
    ...equipment,
    extra: {
      changed: attr,
      local_site_name: local.site.name,
      remote_site_name: remote.site.name,
      local: extraEndChoices(local),
      remote: extraEndChoices(remote),
    },
  });
}

async function fetchChoicesId(projectId: string, id: string) {
  return await postWithAuth(`equipment/ptp/${projectId}/${id}/choices`, {});
}

async function updatePowerFromSwitch(
  projectId: string,
  id: string,
  power_from_switch: string
) {
  return await postWithAuth(
    `project/${projectId}/ptp/${id}/properties`,
    { power_from_switch },
    'PATCH'
  );
}

type ReportDownloadButtonProps = {
  children: JSX.Element;
  projectId: string;
  name: string;
  kind: 'Proposal' | 'Installation';
  endpoint: `/${string}`; // endpoint must start with /
  title: string;
  disabled: boolean;
};

const safeNameRegex = /[\\\/:*?"<>|\s]+/g;
const getFileNamePrefix = (endPoint) => {
  if (endPoint.includes('access_point')) {
    return 'Network_Device';
  } else if (endPoint.includes('subscriber')) {
    return 'Subscriber';
  } else {
    return 'Link';
  }
};

export function ReportDownloadButton(props: ReportDownloadButtonProps) {
  const { children, projectId, name, kind, endpoint, title, disabled } = props;
  const [loading, setLoading] = useState(false);

  const safeName = name.replace(safeNameRegex, '_');

  return (
    <Button
      icon
      basic
      compact
      type="button"
      disabled={disabled || loading}
      loading={loading}
      title={title}
      onClick={() => {
        setLoading(true);
        getWithAuth(`project/${projectId}/reports${endpoint}`)
          .then((id) => {
            if (id != null) {
              downloadReport(
                getWithAuth,
                projectId,
                id,
                `${getFileNamePrefix(endpoint)}_${safeName}_${kind}_Report`
              ).finally(() => {
                setLoading(false);
              });
            } else {
              setLoading(false);
            }
          })
          .catch(() => {
            setLoading(false);
          });
      }}
    >
      {children}
    </Button>
  );
}

function pathHeader(path: PTPPath) {
  const { local, remote, identifier, user_name } = path;

  if (identifier.includes(' to ')) {
    const [localName, remoteName] = identifier.split(' to ');
    const isCustomUserName = user_name !== path.identifier;
    const userNameComponent = isCustomUserName ? <div>{user_name}</div> : null;
    const fontSizeStyle = userNameComponent ? { fontSize: '12px' } : {};

    return (
      <>
        {userNameComponent}
        <div style={fontSizeStyle}>
          <LinkToNetworkSite id={local.site.id} name={localName} />
          {' to '}
          <LinkToNetworkSite id={remote.site.id} name={remoteName} />
        </div>
      </>
    );
  } else {
    return identifier;
  }
}

function LinkToNetworkSite({ id, name }: { id: string; name: string }) {
  return <Link to={`/network_sites/${id}`}>{name}</Link>;
}

type SwitchDropdownProps = {
  choices: PTPChoices;
  projectId: string;
  id: string;
  refreshBom: any;
};

function SwitchDropdown(props: SwitchDropdownProps) {
  /*
   * semantic puts the modal outside the form so we have to manually
   * handle submit with handleSubmit
   */
  const { watch, setValue } = useFormContext();

  const powerFromSwitch = watch('local.properties.power_from_switch');

  const permissionWrite = useSelector(
    (state: RootStateOrAny) => state.mainFrame.permissionWrite
  );

  const { switch_choices } = getLinkFromChoices(props.choices);
  if (switch_choices == null || switch_choices.length === 0) {
    return;
  }

  const style = {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5rem',
    marginBottom: '0.5rem',
    paddingLeft: '0.5rem',
  };

  return (
    <div style={style}>
      <label htmlFor="bomPowerFromSwitch">Power from switch:</label>
      <Form.Select
        id="bomPowerFromSwitch"
        title="Configure switch equipment at network site"
        value={powerFromSwitch}
        options={switch_choices}
        disabled={!permissionWrite}
        onChange={(e, data) => {
          const { value } = data;

          // persist new value in form state
          setValue('local.properties.power_from_switch', value);
          setValue('remote.properties.power_from_switch', value);

          // update backend
          if (typeof value === 'string') {
            updatePowerFromSwitch(props.projectId, props.id, value)
              .then(() => {
                props.refreshBom({ status: true });
              })
              .catch(() => {
                console.warn('Updating power from switch failed');
              });
          }
        }}
      />
    </div>
  );
}

function PTPLinkPanel(props: any) {
  const projectId = useSelector((state: any) => state.mainFrame.projectId);

  const { id } = useParams();

  // initial link api response (used to populate default form data)
  const { data: link, isError: linkFailed } = useQuery<PTPLink>(
    [projectId, 'ptp', id],
    async () => {
      return await getWithAuth(`project/${projectId}/ptp/${id}`);
    }
  );

  // used to determine whether we can request choices yet
  let local = null;
  let remote = null;
  if (link != null) {
    local = link.local;
    remote = link.remote;
  }

  // initial and ongoing choices response
  const {
    data: choices,
    refetch,
    isError: choicesFailed,
    error: choicesError,
  } = useQuery<PTPChoices>({
    queryKey: [projectId, 'ptp', id, 'choices'],
    queryFn: async () => {
      return await fetchChoicesId(projectId, id);
    },
  });

  const { data: profile } = useQuery(
    [projectId, 'profile', id],
    async () => {
      const params = new URLSearchParams({
        project_id: projectId,
        loc_lat: local!.site.latitude.toFixed(5),
        loc_lng: local!.site.longitude.toFixed(5),
        rem_lat: remote!.site.latitude.toFixed(5),
        rem_lng: remote!.site.longitude.toFixed(5),
      });
      return await getWithAuth(`profile?${params}`);
    },
    {
      enabled: !!(local && remote),
    }
  );

  if (choicesFailed || linkFailed) {
    let error;
    if (choicesFailed) {
      error = choicesError;
    } else {
      error = { detail: 'Unable to load the link information' };
    }

    return (
      <Segment color="red" inverted>
        Something went wrong: {(error as any)?.detail}
      </Segment>
    );
  }

  if (id != null && link != null && choices != null) {
    return (
      <PTPLinkForm
        intl={props.intl}
        projectId={projectId}
        id={id}
        link={link}
        choices={choices}
        profile={profile}
      />
    );
  }

  return <Loader active />;
}

type PTPLinkFormProps = {
  projectId: string;
  id: string;
  link: PTPLink;
  choices: PTPChoices;
  profile: any;
  intl: any;
};

function PTPLinkForm(props: PTPLinkFormProps) {
  const { projectId, id, link, choices, profile } = props;
  const [resetValue, setResetValue] = useState(0);
  const [needsSave, setNeedsSave] = useState(false);
  const [modified, setModified] = useState<boolean>(false);
  const { formatMessage } = props.intl;
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const path = link;

  const formMethods = useForm({ defaultValues: path });
  const { handleSubmit, getValues, setValue, reset, formState } = formMethods;
  const { isSubmitting, errors, dirtyFields } = formState;
  const panels = getProductPanels(getValues('local.radios.0'), true);

  const qc = useQueryClient();

  const fetchingFeeder =
    useIsFetching({
      queryKey: [projectId, 'ptp', id, 'feeder_losses'],
      exact: false,
    }) > 0;
  const fetchingFreqs =
    useIsFetching({
      queryKey: [projectId, 'ptp', id, 'frequencies'],
      exact: false,
    }) > 0;
  const fetchingDisablesApply = fetchingFeeder || fetchingFreqs;

  const clearCache = () => {
    setResetValue((prev) => prev + 1);
    formMethods.reset();
    qc.invalidateQueries({
      queryKey: [projectId, 'ptp', id],
    });
    qc.invalidateQueries({
      queryKey: [projectId, 'profile', id],
      exact: true,
    });
    setModified(false);
  };

  const doSubmit = async (formData: FieldValues) => {
    // merge then apply final changes for any fields that haven't
    // been synced with complex paths because they don't refresh
    // the choices call, therefore not calling sync (syncComplexFields call above)
    const finalData = finalComplexSync(
      getProductPanels(choices, false),
      mergeFormWithPath(path, formData),
      getValues
    );
    await postWithAuth(`project/${projectId}/ptp/${id}`, finalData, 'PUT');
    setModified(false);
  }

  const onSubmit = async (formData: FieldValues) => {
    // wrapper for submitting so we can force a submit after
    // choices have been updated
    if (updateChoices.isLoading) {
      setNeedsSave(true);
      return;
    }

    await doSubmit(formData);
  };

  const updateChoices = useMutation(
    ({
      local,
      remote,
      attr,
    }: {
      local: PTPEnd;
      remote: PTPEnd;
      attr: string;
    }) => {
      return Promise.allSettled([
        fetchChoices(projectId, local, remote, attr),
        Promise.resolve(attr),
      ]);
    },
    {
      onSuccess: (
        updateChoicesResponse: [
          PromiseSettledResult<PTPChoices>,
          PromiseSettledResult<string>
        ]
      ) => {
        const choicesResponse = (
          updateChoicesResponse[0] as PromiseFulfilledResult<PTPChoices>
        ).value;

        // update choices query data to cause form to rerender
        // with updated correct choices
        qc.setQueryData([projectId, 'ptp', id, 'choices'], () => ({
          ...choicesResponse,
        }));

        // update react hook form internal state based on
        // new choices on a field by field basis (e.g. when form
        // values fall out of sync with choices like changing product
        // updating the antenna choices)
        const newPanels = getProductPanels(choicesResponse, false);

        newPanels.syncFormState(
          getValues,
          setValue,
          newPanels,
          choicesResponse
        );

        handleLinkKindChange(newPanels, choicesResponse, getValues, reset);
        // run field sync after all the other choice driven equipment
        // changes have completed
        syncComplexFields(newPanels, setValue, getValues);
      },
      onSettled: async () => {
        if (needsSave) {
          setNeedsSave(false);
          handleSubmit(doSubmit)();
        }
      },
    }
  );

  // dodgy hack to get the bom to refresh when power from switch changes
  const [refreshSwitch, setRefreshSwitch] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    reset(path);
  }, [path]);

  const refreshChoices = ({ field }) => {
    if (!field) {
      // should not occur in production (programmer error)
      throw new Error('Refresh choices requested without field info');
    }

    const mergedFormData = mergeFormWithPath(path, getValues());
    // request new choices based on the updated form state (i.e.
    // in response to a user action: onChange)
    updateChoices.mutate({
      local: mergedFormData.local,
      remote: mergedFormData.remote,
      attr: field.attrName,
    });
  };

  const [localProduct, remoteProduct] = getValues([
    'local.radios.0.equipment.product',
    'remote.radios.0.equipment.product',
  ]);

  const disableSave =
    (!modified && isEmpty(dirtyFields)) ||
    isSubmitting ||
    !isEmpty(errors) ||
    fetchingDisablesApply ||
    needsSave;

  const deleteLink = async () => {
    store.dispatch(
      uiConfirmAction({
        header: formatMessage(messages.deletePTPLinks),
        message: formatMessage(messages.confirm),
        size: 'mini',
        onConfirm: () => {
          postWithAuth(`project/${projectId}/ptp`, [id], 'DELETE')
            .then((el) => {
              console.log(el);
              navigate('/ptp');
            })
            .catch(console.error);
        },
      })
    );
  };

  return (
    <FormResetContext.Provider value={resetValue}>
      <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
        <FormProvider {...formMethods}>
          <Form
            className="panel-with-heading"
            method="post"
            onSubmit={handleSubmit(onSubmit)}
            style={{ height: '100%' }}
          >
            <PanelHeading
              title={pathHeader(path)}
              apply={
                <Form.Button
                  compact
                  type="submit"
                  className="save"
                  primary
                  disabled={disableSave}
                  loading={fetchingDisablesApply}
                  accesskey="a"
                >
                  {formatMessage(messages.apply)}
                </Form.Button>
              }
              toolbar={
                <Form.Group>
                  {permissionWrite && !modified && (
                    <>
                      <Button
                        icon
                        basic
                        compact
                        type="button"
                        title="Delete"
                        onClick={() => deleteLink()}
                        color="red"
                        style={{ margin: '0' }}
                      >
                        <Icon name="trash alternate" />
                      </Button>
                      <ToolbarSeparator />
                    </>
                  )}
                  {permissionWrite && modified && (
                    <>
                      <Button
                        icon
                        basic
                        compact
                        type="button"
                        onClick={undoChanges}
                        title={formatMessage(messages.revertChanges)}
                        color="red"
                        style={{ margin: '0' }}
                      >
                        <Icon name="undo" />
                      </Button>
                      <ToolbarSeparator />
                    </>
                  )}
                  <ShowInMap kind="ptp_link" id={id} />
                  <ToolbarSeparator />
                  <BomModal
                    objId={id}
                    localProduct={localProduct}
                    remoteProduct={remoteProduct}
                    kind="ptp"
                    url={`project/${projectId}/ptp/${id}/bom`}
                    modified={!disableSave || fetchingDisablesApply}
                    name="Link"
                    bomRefresh={refreshSwitch}
                    toolbarSlot={
                      <SwitchDropdown
                        choices={choices}
                        projectId={projectId}
                        id={id}
                        refreshBom={setRefreshSwitch}
                      />
                    }
                  />

                  <ReportDownloadButton
                    projectId={projectId}
                    name={path.user_name}
                    kind="Proposal"
                    endpoint={`/ptp/${id}/proposal`}
                    title={formatMessage(messages.salesReport)}
                    disabled={!disableSave || fetchingDisablesApply}
                  >
                    <Icon className="proposal-report-icon" size="large" />
                  </ReportDownloadButton>

                  <ReportDownloadButton
                    projectId={projectId}
                    name={path.user_name}
                    kind="Installation"
                    endpoint={`/ptp/${id}/installation`}
                    title={formatMessage(messages.installationReport)}
                    disabled={!disableSave || fetchingDisablesApply}
                  >
                    <Icon className="installation-report-icon" size="large" />
                  </ReportDownloadButton>

                  {isSubmitting && (
                    <Header style={{ margin: '0' }}>
                      <Loader active inline size="tiny" />{' '}
                      {formatMessage(messages.calculatingResults)}
                    </Header>
                  )}
                </Form.Group>
              }
            />
            <div
              className="main-panel"
              style={{
                display: 'flex',
                columnGap: '6px',
              }}
            >
              <div
                id="ptp-details"
                className="first-col"
                style={{ padding: '0.8rem' }}
              >
                {/* equipment panels */}
                <Accordion exclusive={false} fluid>
                  <PTPWarningsPanel path={path} />
                  <PTPGeneralPanel
                    title="Link Description"
                    path={path}
                    setModified={setModified}
                  />
                  {panels.equipment.map((panel, i) => {
                    if (panel.show != null && !panel.show({ choices })) {
                      return null;
                    }
                    return (
                      <panel.component
                        key={i}
                        path={path}
                        choices={choices}
                        panel={panel}
                        refreshChoices={refreshChoices}
                        setModified={setModified}
                      />
                    );
                  })}
                  {/*
                   * Hack for TDD Sync panel, otherwise the bottom of it's
                   * panel will be cut off
                   */}
                  {panels.equipment.length > 2 ? (
                    <div style={{ marginBottom: '2rem' }}></div>
                  ) : null}
                </Accordion>
              </div>

              <div
                style={{ overflowY: 'auto', flex: '3 1 0%', padding: '0.8rem' }}
              >
                <Accordion exclusive={false} fluid>
                  {/* profile */}
                  <PTPProfileChart
                    path={path}
                    profile={profile}
                    setModified={setModified}
                    parentFormSubmitHandler={handleSubmit(onSubmit)}
                    modified={modified}
                  />

                  {/* local remote */}
                  <PTPEndsPanel
                    title={panels.ends.title}
                    modified={modified}
                    setModified={setModified}
                    choices={choices}
                    refreshChoices={refreshChoices}
                    path={path}
                    panel={panels.ends}
                  />

                  {/* performance */}
                  <PTPPerformanceSummary
                    id={id}
                    intl={props.intl}
                    path={path}
                    choices={choices}
                    modified={modified}
                    config={panels.performance.summary}
                    disabled={modified}
                    setModified={setModified}
                  />

                  <StoredAccordion
                    name="ptp_performance_details"
                    title="Performance Details"
                  >
                    <PerformanceDetailsTabs
                      details={path.details}
                      localEndName={path.local.site.name}
                      remoteEndName={path.remote.site.name}
                      disabled={modified}
                      kind="ptp"
                      needsRefresh={false}
                    />
                    {/* <Tab panes={performanceDetailsPanes({ path, modified })} /> */}
                  </StoredAccordion>
                </Accordion>
              </div>
              {/* </div> */}
            </div>
          </Form>
        </FormProvider>

        <RouteLeavingGuard
          when={!disableSave}
          shouldBlockNavigation={() => !disableSave}
          yes="Yes"
          no="No"
          title={`PTP Link: ${path.identifier}`}
          content={formatMessage(messages.warning)}
          callback={clearCache}
          resetCallback={undoChanges}
        />
        <AntennaFormContainer
          path={path}
          kind="ptp"
          refetch={(field) => refreshChoices({ field })}
        />
      </div>
    </FormResetContext.Provider>
  );
}

export default injectIntl(PTPLinkPanel);
