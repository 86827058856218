import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useSelector } from "react-redux";
import {
  type SMTableData,
  columns as smTableColumns,
} from "../SMTableColumns";
import { SelectCell } from "./SelectCell";
import { getStickyColumnStyle } from "./usePtpColumns";
import { SELECT_WIDTH } from "./constants";

export function useSubscriberColumns(pinnedColumn: string) {
  const prefs = useSelector((state: any) => state.mainFrame.prefs);
  const columnHelper = createColumnHelper<SMTableData>();

  const hideName = pinnedColumn !== 'name';
  let allAvailableColumns = smTableColumns;
  if (hideName) {
    allAvailableColumns = allAvailableColumns.filter((column) => column.id !== 'name');
  }

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => <SelectCell kind="header" table={table} />,
        cell: ({ row, table }) => <SelectCell kind="row" row={row} table={table} />,
        enableResizing: false,
        enableHiding: false,
        meta: {
          tdStyle: getStickyColumnStyle(SELECT_WIDTH, 0),
        },
      }),
      // TODO probably better to do this with mutation and findIndex
      // since we only want to modify the name column
      ...allAvailableColumns.map((column) => ({
        ...column,
        meta: {
          ...column.meta,
          prefs,
          tdStyle:
            // don't use pinnedColumn here because the header wont be sticky
            column.id === 'name'
              ? getStickyColumnStyle(null, SELECT_WIDTH)
              : column.meta?.tdStyle,
        },
        enableGrouping: column.id === pinnedColumn,
      })),
    ],
    [prefs, pinnedColumn]
  );

  return { columns };
}