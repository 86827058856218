import React from 'react';
import { HeaderContent } from './HeaderContent';
import { flexRender, HeaderGroup } from '@tanstack/react-table';
import { SELECT_WIDTH } from './constants';
import { Input } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import messages from 'src/messages';

export function HeaderCell<T>({
  headerGroup,
  isResizingColumn,
}: {
  headerGroup: HeaderGroup<T>;
  isResizingColumn: string | boolean;
}) {
  const { formatMessage } = useIntl();

  return (
    <tr>
      {headerGroup.headers.map((header) => {
        const id = header.column.columnDef.id!;
        const isSticky = id === 'select' || id === 'name';
        const thStyle: React.CSSProperties = {
          width:
            id === 'select'
              ? '40px'
              : `calc(var(--header-${header.id}-size) * 1px)`,
          left: isSticky
            ? id === 'select'
              ? 0
              : `${SELECT_WIDTH}px`
            : undefined,
          cursor: header.column.getCanSort() ? 'pointer' : 'default',
          // prevent mouse release triggering a sort when finishing a resize
          pointerEvents: isResizingColumn ? 'none' : 'auto',
        };
        return (
          <th
            key={header.id}
            style={thStyle}
            className={isSticky ? 'lp-table-sticky' : undefined}
            onClick={(e) => {
              // Allow sorting only when clicking the th or the header content div
              // i.e. not the resizer or the filter input
              if (
                e.target === e.currentTarget ||
                (e.target as HTMLElement).closest('.lp-table-header-content')
              ) {
                header.column.getToggleSortingHandler()?.(e);
              }
            }}
          >
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: id === 'select' ? 'center' : 'space-between',
              }}
            >
              <HeaderContent
                id={id}
                sortString={header.column.getIsSorted() as string | null}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </HeaderContent>

              {header.column.getCanFilter() && (
                <div>
                  <Input
                    value={(header.column.getFilterValue() as string) ?? ''}
                    onChange={(e) =>
                      header.column.setFilterValue(e.target.value)
                    }
                    placeholder={formatMessage(messages.filter)}
                    className={
                      (header.column.getFilterValue() as string)
                        ? 'lp-table-filter filtered'
                        : 'lp-table-filter unfiltered'
                    }
                    transparent
                    icon={{
                      name: 'cancel',
                      onClick: () => header.column.setFilterValue(''),
                      link: true,
                      title: formatMessage(messages.clearFilter),
                    }}
                  />
                </div>
              )}

              {header.column.getCanResize() && (
                <div
                  onMouseDown={header.getResizeHandler()}
                  onTouchStart={header.getResizeHandler()}
                  className={`resizer ${
                    header.column.getIsResizing() ? 'isResizing' : ''
                  }`}
                />
              )}
            </div>
          </th>
        );
      })}
    </tr>
  );
}
