import React from 'react';
import { TableCell, TableRowProps } from './TableCell';
import { TableRow } from './TableRow';
import { flexRender } from '@tanstack/react-table';
import { GroupedRow } from './GroupedRow';
import { type PathIndexed } from './constants';

/**
 * Top level row component which also handles groups (e.g. for complex links)
 * This is the component used directly by the table
 */
export function PrimaryRow<T extends PathIndexed>({
  row,
  tooltip,
  onShiftClick,
}: TableRowProps<T> & {
  onShiftClick?: (rowId: string, shiftKey: boolean, ctrlKey?: boolean) => void;
}) {
  const isGrouped = row.getIsGrouped();

  // If it's a grouped row with only one subrow, render just the subrow
  if (isGrouped && row.subRows.length === 1) {
    const subRow = row.subRows[0];
    return (
      <TableRow key={subRow.id} row={subRow} tooltip={tooltip} onShiftClick={onShiftClick}>
        {subRow.getVisibleCells().map((cell) => {
          return (
            <TableCell key={cell.id} cell={cell}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  // Handle groups with multiple subrows (e.g. complex links)
  if (isGrouped) {
    return <GroupedRow row={row} onShiftClick={onShiftClick} />;
  }

  // Handle non-grouped rows (e.g. 1+0 links)
  return (
    <TableRow row={row} tooltip={tooltip} onShiftClick={onShiftClick}>
      {row.getVisibleCells().map((cell) => {
        return (
          <TableCell key={cell.id} cell={cell}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        );
      })}
    </TableRow>
  );
}
