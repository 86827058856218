import React from 'react';
import { type PathIndexed } from './constants';
import { Table, Updater } from '@tanstack/react-table';
import { useColumnVisibility } from './useColumnVisibility';
import { Button, Icon, Input, Modal } from 'semantic-ui-react';
import { setUserSelectedColumns } from 'src/api';
import { toast } from 'react-toastify';
import messages from 'src/messages';
import { useIntl } from 'react-intl';
import {
  GenericOrderableListControl,
  onUpGeneric,
  onDownGeneric,
} from 'src/components/controls/OrderableCheckListControl';

export function ColumnVisibilityModal<T extends PathIndexed>({
  table,
  columnOrder,
  setColumnOrder,
  isOpen,
  setIsOpen,
  tableId,
  defaultPinnedColumn,
}: {
  table: Table<T>;
  columnOrder: string[];
  setColumnOrder: (updater: Updater<string[]>) => void;
  isOpen: boolean;
  setIsOpen: (updater: Updater<boolean>) => void;
  tableId: string;
  defaultPinnedColumn: string;
}) {
  const { formatMessage } = useIntl();
  const [visibleSearch, setVisibleSearch] = React.useState('');
  const [hiddenSearch, setHiddenSearch] = React.useState('');
  const [selectedVisibleColumns, setSelectedVisibleColumns] = React.useState<
    Set<string>
  >(new Set());
  const [selectedHiddenColumns, setSelectedHiddenColumns] = React.useState<
    Set<string>
  >(new Set());

  // Store original column states
  const [originalColumnStates, setOriginalColumnStates] = React.useState<
    {
      id: string;
      visible: boolean;
    }[]
  >([]);

  // Store original column order
  const [originalColumnOrder, setOriginalColumnOrder] = React.useState<
    string[]
  >([]);

  // Save original state when modal opens
  React.useEffect(() => {
    if (isOpen) {
      const columns = table.getAllColumns();
      const states = columns.map((col) => ({
        id: col.id,
        visible: col.getIsVisible(),
      }));
      setOriginalColumnStates(states);
      setOriginalColumnOrder([...columnOrder]);
    }
  }, [isOpen, table]);

  const closeModal = (shouldCancel = false) => {
    if (shouldCancel) {
      // Restore original column visibility
      originalColumnStates.forEach(({ id, visible }) => {
        const column = table.getColumn(id);
        if (column && column.getIsVisible() !== visible) {
          column.toggleVisibility(visible);
        }
      });

      setColumnOrder(originalColumnOrder);
    }

    // Reset modal state
    setIsOpen(false);
    setVisibleSearch('');
    setHiddenSearch('');
    setSelectedVisibleColumns(new Set());
    setSelectedHiddenColumns(new Set());
  };

  const { allVisibleColumns, allHiddenColumns, getFilteredColumns } =
    useColumnVisibility(table, columnOrder, defaultPinnedColumn);

  const visibleColumns = React.useMemo(
    () => getFilteredColumns(allVisibleColumns, visibleSearch),
    [allVisibleColumns, visibleSearch, getFilteredColumns]
  );

  const hiddenColumns = React.useMemo(
    () => getFilteredColumns(allHiddenColumns, hiddenSearch),
    [allHiddenColumns, hiddenSearch, getFilteredColumns]
  );

  const toggleColumnsVisibility = React.useCallback(
    (direction: 'show' | 'hide', columnName?: string) => {
      const sourceColumns =
        direction === 'show' ? hiddenColumns : visibleColumns;
      let sourceSelection =
        direction === 'show' ? selectedHiddenColumns : selectedVisibleColumns;
      if (columnName) {
        sourceSelection = new Set([columnName]);
      }
      const setSourceSelection =
        direction === 'show'
          ? setSelectedHiddenColumns
          : setSelectedVisibleColumns;
      const setDestinationSelection =
        direction === 'show'
          ? setSelectedVisibleColumns
          : setSelectedHiddenColumns;

      // Track the IDs of columns being moved for selecting them in the destination
      const movedColumnIds = new Set<string>();

      // Only toggle selected columns
      sourceColumns.forEach((col) => {
        if (sourceSelection.has(col.id)) {
          col.toggleVisibility(!col.getIsVisible());
          movedColumnIds.add(col.id);
        }
      });

      setSourceSelection(new Set());
      setDestinationSelection(movedColumnIds);
    },
    [
      hiddenColumns,
      visibleColumns,
      selectedHiddenColumns,
      selectedVisibleColumns,
    ]
  );

  const toggleAllDisplayedColumns = React.useCallback(
    (direction: 'show' | 'hide') => {
      const sourceColumns =
        direction === 'show' ? hiddenColumns : visibleColumns;

      const movedColumnIds = new Set<string>();

      // Toggle all displayed columns
      sourceColumns.forEach((col) => {
        col.toggleVisibility(!col.getIsVisible());
        movedColumnIds.add(col.id);
      });

      setSelectedHiddenColumns(new Set());
      setSelectedVisibleColumns(new Set());
    },
    [hiddenColumns, visibleColumns]
  );

  const [isSaving, setIsSaving] = React.useState(false);

  const saveColumnState = async () => {
    if (!tableId) return closeModal(false);

    try {
      setIsSaving(true);
      const visibleColumnIds = table
        .getVisibleLeafColumns()
        .map((col) => col.id);

      // Sort visible column IDs based on their location in the columnOrder array
      const sortedVisibleColumnIds = [...visibleColumnIds].sort((a, b) => {
        const indexA = columnOrder.indexOf(a);
        const indexB = columnOrder.indexOf(b);
        return indexA - indexB;
      });

      // Save to database
      await setUserSelectedColumns(tableId, sortedVisibleColumnIds);
      closeModal(false);
    } catch (error) {
      console.error('Failed to save column visibility settings:', error);
      toast.error('Failed to save column settings');
      // Don't cancel in case of error - keep changes in the UI
      closeModal(false);
    } finally {
      setIsSaving(false);
    }
  };

  // Create display strings for columns (mapping column IDs to header text)
  const getColumnHeaderText = (col) => {
    const headerDef = col.columnDef.header;
    return typeof headerDef === 'function'
      ? headerDef({
          column: col,
          header: col.columnDef.header,
          table,
        })
      : headerDef;
  };

  const visibleColumnIds = React.useMemo(
    () => visibleColumns.map((col) => col.id),
    [visibleColumns]
  );

  const hiddenColumnIds = React.useMemo(
    () => hiddenColumns.map((col) => col.id),
    [hiddenColumns]
  );

  const displayStrings = React.useMemo(() => {
    const result = {};
    [...visibleColumns, ...hiddenColumns].forEach((col) => {
      result[col.id] = getColumnHeaderText(col);
    });
    return result;
  }, [visibleColumns, hiddenColumns]);

  const setVisibleColumnOrder = React.useCallback(
    (newOrder: string[]) => {
      const validNewOrder = newOrder.filter((id) => columnOrder.includes(id));
      const remainingColumns = columnOrder.filter(
        (id) => !validNewOrder.includes(id)
      );
      const updatedOrder = [...validNewOrder, ...remainingColumns];
      setColumnOrder(updatedOrder);
    },
    [columnOrder, setColumnOrder]
  );

  const onDoubleClick = (item: string) => (e) => {
    e.preventDefault();
    const isHidden = hiddenColumnIds.includes(item);
    if (isHidden) {
      toggleColumnsVisibility('show', item);
    } else {
      toggleColumnsVisibility('hide', item);
    }
  };

  return (
    <Modal
      onClose={() => closeModal(true)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      trigger={
        <Button
          color="blue"
          type="button"
          content="Choose Columns"
          className="nowrap"
        />
      }
    >
      <Modal.Header>
        Manage Columns
        <Button
          circular
          icon="close"
          title={formatMessage(messages.close)}
          floated="right"
          onClick={() => closeModal(true)}
        />
      </Modal.Header>
      <Modal.Content>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '1rem',
            alignItems: 'flex-start',
          }}
        >
          <div style={{ flex: 1 }}>
            <h4>Hidden Columns</h4>
            <div style={{ position: 'relative', marginBottom: '8px' }}>
              <Input
                transparent
                type="text"
                value={hiddenSearch}
                onChange={(e) => setHiddenSearch(e.target.value)}
                placeholder="Search hidden columns..."
                tabIndex={1}
                className={
                  hiddenSearch
                    ? 'lp-table-filter filtered'
                    : 'lp-table-filter unfiltered'
                }
                icon={{
                  name: 'cancel',
                  onClick: () => setHiddenSearch(''),
                  link: true,
                  title: formatMessage(messages.clearFilter),
                }}
              />
            </div>

            <GenericOrderableListControl
              order={hiddenColumnIds}
              displayStrings={displayStrings}
              selected={selectedHiddenColumns}
              onSelect={setSelectedHiddenColumns}
              onUp={onUpGeneric(setVisibleColumnOrder)}
              onDown={onDownGeneric(setVisibleColumnOrder)}
              onDoubleClick={onDoubleClick}
              showArrows={false}
            />
          </div>

          <div
            style={{
              display: 'flex',
              gap: '4px',
              flexDirection: 'column',
              alignSelf: 'center',
            }}
          >
            <Button
              icon="angle double right"
              title="Show all displayed columns"
              onClick={() => toggleAllDisplayedColumns('show')}
              disabled={hiddenColumns.length === 0}
            />
            <Button
              icon="angle right"
              title="Show selected columns"
              onClick={() => toggleColumnsVisibility('show')}
              disabled={selectedHiddenColumns.size === 0}
            />
            <Button
              icon="angle left"
              title="Hide selected columns"
              onClick={() => toggleColumnsVisibility('hide')}
              disabled={selectedVisibleColumns.size === 0}
            />
            <Button
              icon="angle double left"
              title="Hide all displayed columns"
              onClick={() => toggleAllDisplayedColumns('hide')}
              disabled={visibleColumns.length === 0}
            />
          </div>

          <div style={{ flex: 1 }}>
            <h4>Visible Columns</h4>
            <div style={{ position: 'relative', marginBottom: '8px' }}>
              <Input
                transparent
                type="text"
                value={visibleSearch}
                onChange={(e) => setVisibleSearch(e.target.value)}
                placeholder="Search visible columns..."
                tabIndex={2}
                className={
                  visibleSearch
                    ? 'lp-table-filter filtered'
                    : 'lp-table-filter unfiltered'
                }
                icon={{
                  name: 'cancel',
                  onClick: () => setVisibleSearch(''),
                  link: true,
                  title: formatMessage(messages.clearFilter),
                }}
              />
            </div>

            <GenericOrderableListControl
              order={visibleColumnIds}
              displayStrings={displayStrings}
              selected={selectedVisibleColumns}
              onSelect={setSelectedVisibleColumns}
              onDoubleClick={onDoubleClick}
              onUp={onUpGeneric(setVisibleColumnOrder)}
              onDown={onDownGeneric(setVisibleColumnOrder)}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => closeModal(true)}>
          {formatMessage(messages.cancel)}
        </Button>
        <Button
          color="blue"
          onClick={saveColumnState}
          loading={isSaving}
          disabled={isSaving}
        >
          {formatMessage(messages.ok)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
