import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
  Dimmer,
  Grid,
  Header,
  Icon,
  Label,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';
import NetworkPlanPanel from './NetworkPlanPanel';
import InputsStep from './InputsStep';
import EquipmentConfigStep from './EquipmentConfigStep';
import FinancialsStep from './FinancialsStep';
import CreateNetworkDesignStep from './CreateNetworkDesignStep';
import {
  CreateNetwork,
  EquipmentConfig,
  Inputs,
  NetworkPlan,
  terragraphActionTypes,
  terragraphStatusTypes,
} from './terragraph.modal';
import StepsWizard from 'src/components/controls/lpWizard/StepsWizard';
import { get } from 'lodash';
import messages from './../../messages';
import {
  ANP_PROGRESS_MESSAGE,
  ANP_PROGRESS_SUBMESSAGE,
} from '../../app.constants';
import config from '../../config';
import {
  CreateAndScheduleTerragraph,
  UpdateAndScheduleTerragraph,
  CancelScheduledJobTerragraph,
  getData,
  getFiles,
  getStatus,
  getParsedData,
  getResults,
  getMinMcsLevel,
  resetAnp,
  resetBoundaryDraft,
} from './terragraph.service';
import { useSelector, useDispatch } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import TerragraphDetailsViewer from './TerragraphDetailsViewer';
import ConditionalPopup from 'src/components/ConditionalPopup';
import _ from 'lodash';
import { TP_STATUS_MESSAGES } from './terragraph.socket';
import {
  uiConfirmAction,
  updateAnpJobStatus,
  longTaskStarted,
  longTaskComplete,
} from '../mainframe/mainframe.reducer';
import RouteLeavingGuard from 'src/components/RouteLeavingGuard';
import { PanelHeading2Col } from 'src/components/PanelHeading';

export const TOAST_AUTOCLOSE = 3000;

const STATUS_NEW = 'new';
const STATUS_DRAFT = 'draft';
const STATUS_CREATED = 'created';
const STATUS_STARTED = 'started';
const STATUS_COMPLETED = 'completed';
const STATUS_FAILED = 'failed';
const STATUS_READY = 'ready';
const STATUS_CANCELLED = 'cancelled';
const STATUS_CANCELLING = 'cancelling';

function TerragraphPanel(props) {
  const { formatMessage } = props.intl;
  const dispatch = useDispatch();
  const { subscriberSiteCount, networkSites, projectId, anpJobStatus } =
    useSelector((state: RootStateOrAny) => state.mainFrame);

  const [validate, setValidate] = useState(true);

  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState<terragraphStatusTypes>(STATUS_NEW);

  const [statusMessage, setStatusMessage] = useState('TODO created');

  useEffect(() => {
    dispatch(
      updateAnpJobStatus({ objects: { status: STATUS_NEW, message: null } })
    );
  }, [projectId]);

  const setDefaults = () => {
    setNetworkPlanFormData({
      networkPlan: 'Distribution',
      networkSubPlan: 'Cost Model',
    });
    setInputsFormData(defaultInputsFormData);
    fetchAndSetSitesSummary();
    setEquipmentConfigFormData(defaultEquipmentConfig);
    setFinancialsFormData(defaultFinancialsFormData);
    setCreateNetworkFormData(defaultCreateNetworkFormData);
  };

  let [jobDetails, setJobDetails] = useState({
    queueStarted: null,
    started: null,
    ended: null,
    message: null,
  });

  const [boundaryFile, setBoundaryFile] = useState({
    file: null,
    uploaded: false,
    loading: false,
    changed: false,
    warning: '',
  });

  const [showLoading, setShowLoading] = useState(false);

  const [uploadedFile, setUploadedFile] = useState<any>();

  const [details, setDetails] = useState(null);

  const [results, setResults] = useState(null);

  const [editCase, setEditCase] = useState({
    value: false,
    buttonName: 'Create',
  });

  const { heightUnits } = useSelector(
    (state: RootStateOrAny) => state.mainFrame.prefs
  );

  const [networkPlanFormData, setNetworkPlanFormData] = useState<NetworkPlan>({
    networkPlan: 'Distribution',
    networkSubPlan: 'Cost Model',
  });

  const defaultInputsFormData: Inputs = {
    boundryPolygon: '',
    runWithoutLidar: false,
    loadDsm: 0, // boolean value 1 or 0
    pops: 0,
    cns: 0,
    dns: 0,
    cnsCanbeUsedAsDns: 0, // boolean value 1 or 0
    popHeight: 5,
    cnHeight: 2,
    dnHeight: 5,
    maxLosDistance: 200,
    minMcsOfAccessLink: 'mcs2',
    minMcsOfMeshLink: 'mcs8',
    setHeight: 'useHeightAboveSurface',
    heightUnits: '',
  };

  const [inputsFormData, setInputsFormData] = useState<Inputs>(
    defaultInputsFormData
  );

  useEffect(() => {
    setStatus(anpJobStatus.status);
    if (
      anpJobStatus.status === STATUS_FAILED ||
      anpJobStatus.status === STATUS_COMPLETED
    ) {
      getDataAndResults();
    }
    setStatusMessage(anpJobStatus.message);
  }, [anpJobStatus]);

  useEffect(() => {
    fetchAndSetSitesSummary();
    fetchAndSetStatus();
    getFiles(projectId)
      .then((resp: any) => {
        // Set the stored value for runWithoutLidar
        setInputsFormData((prevState) => {
          return { ...prevState, runWithoutLidar: resp.run_without_lidar };
        });
        if (resp.files.length) {
          setBoundaryFile({
            file: { name: resp.files[0] },
            uploaded: true,
            loading: false,
            changed: false,
            warning: resp.warning,
          });
        } else {
          setBoundaryFile({
            file: null,
            uploaded: false,
            loading: false,
            changed: false,
            warning: '',
          });
        }
      })
      .catch((err) => {
        setBoundaryFile({
          file: null,
          uploaded: false,
          loading: false,
          changed: false,
          warning: '',
        });
      });
  }, [subscriberSiteCount, networkSites, projectId, editCase]);

  // Set a flag when boundary file is changed.
  // useEffect(()=>{
  //   if (inputsFormData.boundryPolygon != ""){
  //     setBoundaryFileChanged(true);
  //   }
  // },[inputsFormData])
  const fetchAndSetSitesSummary = () => {
    const popLength = get(
      networkSites &&
        networkSites?.features.filter(
          (el) => el.properties.node_type === 'POP'
        ),
      'length',
      ''
    );
    const dnLength = get(
      networkSites &&
        networkSites?.features.filter((el) => el.properties.node_type === 'DN'),
      'length',
      ''
    );
    setInputsFormData((prev) => {
      return {
        ...prev,
        pops: popLength,
        dns: dnLength,
        cns: subscriberSiteCount,
      };
    });
  };

  const setFormData = () => {
    if (details) {
      setNetworkPlanFormData(getParsedData(details).networkPlan);
      setInputsFormData(getParsedData(details).inputs);
      fetchAndSetSitesSummary();
      setEquipmentConfigFormData(getParsedData(details).equipment);
      setFinancialsFormData(getParsedData(details).financials);
      setCreateNetworkFormData(getParsedData(details).createNetwork);
    }
  };

  async function getDataAndResults() {
    setShowLoading(true);
    const responses = await Promise.allSettled([
      getData(projectId),
      getResults(projectId),
    ]);

    const [data, results] = handleResponses(responses);

    setDetails(data);
    //let message = { 'Last job ran for '{() => getJobDataFormat(data)}};
    if (data.status == 'started' && data.progress_message) {
      setStatusMessage(data.progress_message);
    }
    setJobDetails({
      queueStarted: data?.queue_started,
      started: data?.job_started,
      ended: data?.job_ended,
      message: (
        <>
          <div>
            <b>Queue time:</b> {runTime(data?.queue_started, data?.job_started)}
          </div>
          <div>
            <b>Run time:</b> {runTime(data?.job_started, data?.job_ended)}
          </div>
        </>
      ),
    });
    setResults(results);
  }

  useEffect(() => {
    setFormData();
  }, [details]);

  function handleResponses(responses: any[]) {
    setShowLoading(false);
    return [
      responses[0].status === 'fulfilled' ? responses[0].value : [],
      responses[1].status === 'fulfilled' ? responses[1].value : [],
    ];
  }

  function runTime(startTime, endTime): string {
    if (startTime && endTime) {
      const diffSecs =
        (new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000;

      const days = Math.floor(diffSecs / (24 * 60 * 60));
      const daySecs = days * (24 * 60 * 60);
      const hours = Math.floor((diffSecs - daySecs) / (60 * 60));
      const hourSecs = hours * 60 * 60;
      const minutes = Math.floor((diffSecs - daySecs - hourSecs) / 60);
      const minSecs = minutes * 60;
      const sec = Math.floor(diffSecs - daySecs - hourSecs - minSecs);
      let timeComponents = [];
      if (days) {
        timeComponents.push(`${days}d`);
      }
      if (hours) {
        timeComponents.push(`${hours}h`);
      }
      if (minutes) {
        timeComponents.push(`${minutes}m`);
      }
      if (sec) {
        timeComponents.push(`${sec}s`);
      }
      if (timeComponents.length === 0) {
        return 'less than a minute';
      } else {
        return timeComponents.join(':');
      }
    } else {
      return 'unknown';
    }
  }

  const fetchAndSetStatus = () => {
    setShowLoading(true);
    getStatus(projectId)
      .then((resp: any) => {
        setStatus(resp.status);
        const status: terragraphStatusTypes = resp.status;
        if (status !== STATUS_NEW && status !== STATUS_DRAFT) {
          getDataAndResults();
        }
        setShowLoading(false);
      })
      .catch((err) => {
        setStatus(STATUS_NEW);
        setShowLoading(false);
      });
  };

  const setFileValues = (value) => {
    setUploadedFile(value.file);
    setBoundaryFile({
      file: value.file,
      uploaded: value.uploaded,
      loading: value.loading,
      changed: value.changed,
      warning: value.warning,
    });
  };

  const defaultEquipmentConfig: EquipmentConfig = {
    channel: 'channel1',
    country: 'fcc',
    popDn: 'V5000',
    minmcspopdn: 'mcs2',
    cnv1000: 0,
    cnv2000: 0,
    cnv3000: 0,
    minmcscnv1000: 'mcs2',
    minmcscnv2000: 'mcs2',
    minmcscnv3000: 'mcs2',
    cnTypeV3000hg: 1,
    cnTypeV3000hgr: 0,
    cnTypeV3000sf: 0,
    maxeirp: 38,
  };

  const [equipmentConfigFormData, setEquipmentConfigFormData] =
    useState<EquipmentConfig>(defaultEquipmentConfig);

  const defaultFinancialsFormData = {
    ['V5000' || 'V2000']: 1578,
    V1000: 365,
    V2000: 725,
    V3000: 1103,
    budget: 0,
  };

  const [financialsFormData, setFinancialsFormData] = useState(
    defaultFinancialsFormData
  );

  const defaultCreateNetworkFormData: CreateNetwork = {
    defaultCncir: 0.025,
    overSubscription: 1,
    popCapacity: 4,
    linkAvailability: 99.9,
    extraPops: 0,
    meshLinksPerSector: 2,
    useAllPopSites: 1,
    noOfChannels: 1,
    maxGuaranteedBw: 0,
    maxNumberOfHops: 15,
    redundancyLevel: 'Legacy Method',
    demandRadius: 100,
    demandSpacing: 20,
  };

  const [createNetworkFormData, setCreateNetworkFormData] =
    useState<CreateNetwork>(defaultCreateNetworkFormData);

  function setEquipmentConfigonNetworkPlanChange(
    networkPlan: 'Distribution' | 'Mesh'
  ) {
    if (networkPlan === 'Mesh' && details) {
      let eqConfig = {
        channel: details.equipment?.channel || 'channel1',
        country: details.equipment?.country || 'fcc',
        minmcspopdn: getMinMcsLevel(details, 'V5000') || 'mcs2',
        maxeirp: details.equipment?.max_eirp || 38,
      };
      setEquipmentConfigFormData({ ...defaultEquipmentConfig, ...eqConfig });
    }
  }

  let wizardSteps: {
    title: string;
    description: string;
    validated: boolean;
    content: JSX.Element;
  }[] = [];

  wizardSteps = [
    {
      title: formatMessage(messages.networkPlan),
      description: '',
      validated: validate,
      content: (
        <NetworkPlanPanel
          networkPlanFormData={networkPlanFormData}
          loading={showLoading}
          networkPlanFormDataChange={(value) => {
            setNetworkPlanFormData(value);
            setEquipmentConfigonNetworkPlanChange(value.networkPlan);
          }}
          setValidate={(value) => {
            setValidate(value);
          }}
        />
      ),
    },
    {
      title: formatMessage(messages.inputsData),
      description: '',
      validated: validate,
      content: (
        <InputsStep
          inputsFormData={inputsFormData}
          networkPlanData={networkPlanFormData}
          inputsFormDataChange={(value) => {
            setInputsFormData(value);
          }}
          setValidate={(value) => {
            setValidate(value);
          }}
          setFileValues={(value) => {
            setFileValues(value);
          }}
          setLoading={(value) => setLoading(value)}
          status={status}
          boundaryFile={boundaryFile}
        />
      ),
    },
    {
      title: formatMessage(messages.equipmentConfiguration),
      description: '',
      validated: validate,
      content: (
        <EquipmentConfigStep
          equipmentConfigFormData={equipmentConfigFormData}
          networkPlanData={networkPlanFormData}
          equipmentConfigFormDataChange={(value) => {
            setEquipmentConfigFormData(value);
          }}
          setValidate={(value) => {
            setValidate(value);
          }}
        />
      ),
    },
    {
      title: formatMessage(messages.financials),
      description: '',
      validated: validate,
      content: (
        <FinancialsStep
          equipmentConfigFormData={equipmentConfigFormData}
          networkPlanData={networkPlanFormData}
          financialsFormData={financialsFormData}
          financialsFormDataChange={(value) => {
            setFinancialsFormData(value);
          }}
          setValidate={(value) => {
            setValidate(value);
          }}
          editCase={editCase}
        />
      ),
    },
    {
      title: formatMessage(messages.createNetworkDesign),
      description: '',
      validated: validate,
      content: (
        <CreateNetworkDesignStep
          networkPlanData={networkPlanFormData}
          createNetworkFormData={createNetworkFormData}
          createNetworkFormDataChange={(value) => {
            setCreateNetworkFormData(value);
          }}
          setValidate={(value) => {
            setValidate(value);
          }}
          popSites={inputsFormData.pops}
        />
      ),
    },
  ];
  const cancelAction = () => {
    return resetBoundaryDraft(projectId);
  };

  const save = (
    action: terragraphActionTypes,
    status: terragraphStatusTypes,
    jobRun: boolean = false
  ) => {
    setStatus(status);
    setStatusMessage(TP_STATUS_MESSAGES[status]);
    return new Promise((resolve, reject) => {
      const formValues = {
        networkPlan: networkPlanFormData,
        inputs: inputsFormData,
        equipment: equipmentConfigFormData,
        financials: financialsFormData,
        createNetwork: createNetworkFormData,
        boundaryFile: boundaryFile,
      };
      setDetails(null);
      const handler = {
        add: CreateAndScheduleTerragraph,
        update: UpdateAndScheduleTerragraph,
        cancel: CancelScheduledJobTerragraph,
      };

      handler[action](projectId, formValues, heightUnits, status)
        .then((response) => {
          if (response && response.success) {
            setEditCase((prevState) => {
              return { ...prevState, value: false };
            });
            fetchAndSetStatus();
            if (action === 'update') {
              dispatch(
                longTaskStarted({
                  heading: ANP_PROGRESS_MESSAGE,
                  message: ANP_PROGRESS_SUBMESSAGE,
                })
              );
            } else if (action === 'cancel') {
              dispatch(longTaskComplete());
            }
            resolve(true);
          } else {
            setStatusMessage('Error creating terragraph');
            dispatch(longTaskComplete());
            reject(false);
          }
        })
        .catch((err) => {
          setStatusMessage(
            (err && JSON.stringify(err.detail)) || 'Error creating terragraph'
          );
          dispatch(longTaskComplete());
          reject(false);
        });
    });
  };

  function nextStepHandler(curStep): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(curStep);
      if (curStep === 2) {
        setInputsFormData((prevState) => {
          return { ...prevState, boundryPolygon: '' };
        });
        resolve(true);
      } else {
        resolve(true);
      }
    });
  }

  function prevStepHandler(curStep): Promise<any> {
    return new Promise((resolve, reject) => {
      if (curStep === 2) {
        setInputsFormData((prevState) => {
          return { ...prevState, boundryPolygon: '' };
        });
        if (boundaryFile.uploaded === false) {
          if (uploadedFile) {
            setBoundaryFile({
              file: uploadedFile,
              uploaded: true,
              loading: false,
              changed: false,
              warning: '',
            });
          } else {
            setBoundaryFile({
              file: null,
              uploaded: false,
              loading: false,
              changed: false,
              warning: '',
            });
          }
        }
        resolve(true);
      } else {
        resolve(true);
      }
    });
  }

  const editHandler = () => {
    setFormData();
    setEditCase((prevState) => {
      return { value: true, buttonName: 'Update' };
    });
  };

  const resetHandler = () => {
    dispatch(
      uiConfirmAction({
        header: formatMessage(messages.resetTerragraph),
        message: formatMessage(messages.resetTerragraphConfirm),
        size: 'mini',
        onConfirm: () => {
          resetAnp(projectId).then((resp) => {
            fetchAndSetStatus();
            setEditCase({ value: null, buttonName: 'Create' });
            setDefaults();
          });
        },
      })
    );
  };

  const getTitle = () => {
    return (
      '60 GHz Terragraph Planner - ' +
      formatMessage(messages[networkPlanFormData.networkPlan]) +
      (networkPlanFormData.networkPlan === 'Mesh' &&
      networkPlanFormData.networkSubPlan
        ? ` - ${networkPlanFormData.networkSubPlan}`
        : '')
    );
  };

  const steps = (
    <div
      className="grid-margin-reset"
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <PanelHeading2Col
        left={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '0.5rem',
              flex: '1 1',
            }}
          >
            <Button
              icon="help"
              basic
              as="a"
              href={`${config.userGuide}terragraph_planner.html`}
              target="_blank"
              title="Terragraph Planner Help"
            />
            <Header title={getTitle()} className="terragraph-title">
              {getTitle()}
            </Header>
            {(status === STATUS_FAILED || status === STATUS_COMPLETED) &&
              editCase.value === false && (
                <>
                  <ConditionalPopup
                    showPopup={
                      jobDetails.started !== null && jobDetails.ended !== null
                    }
                    message={jobDetails.message}
                  >
                    <Label
                      as="p"
                      style={{ 'text-transform': 'capitalize' }}
                      tag
                      className="ml-4"
                      color={status === STATUS_FAILED ? 'red' : 'green'}
                    >
                      {status}
                    </Label>
                  </ConditionalPopup>
                </>
              )}
            {(status === STATUS_CREATED || status == STATUS_CANCELLED) &&
              editCase.value === false && (
                <Label
                  as="p"
                  style={{ 'text-transform': 'capitalize' }}
                  tag
                  className="ml-4"
                  color={status == STATUS_CANCELLED ? 'yellow' : 'blue'}
                >
                  {status}
                </Label>
              )}
          </div>
        }
        right={
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {statusMessage && editCase.value === false && (
                <Message
                  compact
                  className="custom-message"
                  info={status !== STATUS_FAILED}
                  error={status === STATUS_FAILED}
                >
                  {statusMessage}
                  {status === STATUS_CREATED && <Icon name="arrow right" />}
                </Message>
              )}
              {editCase.value === false &&
                ![STATUS_NEW, STATUS_DRAFT].includes(status) && (
                  <>
                    {
                      // Hide Edit, Run and Reset button when job is ready or running
                      // Show cancel button when job is running
                      status === STATUS_READY ||
                      status === STATUS_STARTED ||
                      status === STATUS_CANCELLING ? (
                        <Button
                          color="blue"
                          size="small"
                          disabled={status === STATUS_CANCELLING}
                          onClick={_.debounce(
                            () => save('cancel', STATUS_CANCELLED, true),
                            200
                          )}
                        >
                          {' '}
                          {status === STATUS_CANCELLING
                            ? 'Cancelling'
                            : 'Cancel'}
                        </Button>
                      ) : (
                        <>
                          <Button
                            size="small"
                            onClick={_.debounce(editHandler, 200)}
                          >
                            Edit
                          </Button>
                          <Button
                            color="blue"
                            size="small"
                            onClick={_.debounce(
                              () => save('update', STATUS_READY, true),
                              200
                            )}
                          >
                            Run
                          </Button>
                          <Button
                            className="header-icon-padding"
                            icon
                            labelPosition="left"
                            size="small"
                            basic
                            color="grey"
                            onClick={_.debounce(resetHandler, 200)}
                          >
                            {' '}
                            <Icon name="warning" />
                            Reset{' '}
                          </Button>
                        </>
                      )
                    }
                    {[STATUS_READY, STATUS_STARTED, STATUS_CANCELLING].includes(
                      status
                    ) && <Loader inline active />}
                  </>
                )}
            </div>
          </>
        }
      ></PanelHeading2Col>
      {[STATUS_NEW, STATUS_DRAFT].includes(status) ||
      editCase.value === true ? (
        <StepsWizard
          steps={wizardSteps}
          nextStepHandler={(curStep) => nextStepHandler(curStep)}
          prevStepHandler={(curStep) => prevStepHandler(curStep)}
          finalStepButtonName={editCase.buttonName}
          createEntity={() => save('add', STATUS_CREATED)}
          loading={loading}
          editCase={editCase}
          setEditCase={setEditCase}
          setCancelAction={cancelAction}
        />
      ) : (
        details && (
          <TerragraphDetailsViewer
            details={details}
            status={status}
            results={results}
            updateEntity={() => save('update', STATUS_STARTED)}
          />
        )
      )}

      {showLoading && (
        <Dimmer active inverted>
          <Loader inverted inline>
            Loading
          </Loader>
        </Dimmer>
      )}
      <RouteLeavingGuard
        when={boundaryFile.changed}
        shouldBlockNavigation={() => true}
        yes="Yes"
        no="No"
        title={`You have unsaved changes in Terragraph Planner`}
        content={formatMessage(messages.warning)}
        callback={() => {
          resetBoundaryDraft(projectId);
        }}
      />
    </div>
  );

  return steps;
}

export default injectIntl(TerragraphPanel);
