import { Row } from '@tanstack/react-table';
import { getWithAuth } from 'src/api';

export { DropdownEditor } from './DropdownEditor';
export { AntennaEditor } from './AntennaEditor';
export { BooleanEditor } from './BooleanEditor';
export { NumberEditor } from './NumberEditor';
export { TextEditor } from './TextEditor';

type TChoices = { id: string } & ({ path_index: number } | { radio_number: number } | { is_network_site: boolean });

export function getChoices<T extends TChoices>(
  kind: 'ptp' | 'access_point' | 'subscriber' | 'site' | 'mesh',
  columnId: string
) {
  return (projectId: string, row: Row<T>) => {
    let urlAttr;
    if (kind === 'subscriber') {
      urlAttr = 'remote_end_id';
    } else {
      urlAttr = 'id';
    }

    const baseUrl = `project/${projectId}/${kind}/${row.original[urlAttr]}/bulk_edit/choices`;

    let paramsObj: any = { column_id: columnId };
    if ((kind === 'ptp' || kind === 'subscriber') && 'path_index' in row.original) {
      paramsObj.path_index = row.original.path_index.toString();
    } else if (kind === 'access_point' && 'radio_number' in row.original) {
      paramsObj.radio_number = row.original.radio_number ?? 1;
    } else if (kind === 'site' && 'is_network_site' in row.original) {
      paramsObj.is_network_site = row.original.is_network_site;
    }

    const searchParams = new URLSearchParams(paramsObj);
    const url = `${baseUrl}?${searchParams.toString()}`;

    return getWithAuth(url);
  };
}
