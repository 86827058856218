import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { type ColumnMeta, type Row } from '@tanstack/react-table';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import { useSelector } from 'react-redux';

function processEditProps(editProps, prefs) {
  if (editProps == null) {
    return {};
  }

  let finalProps = { ...editProps };
  if (editProps.units && editProps.units !== 'msn') {
    finalProps.units = prefs[editProps.units];
  }

  return finalProps;
}

type TProps<T extends { id: string }, U> = {
  projectId: string;
  row: Row<T>;
  meta: ColumnMeta<T, U>;
  value: U;
  setValue: (value: U) => void;
  setError: (err: any) => void;
};

export function NumberEditor<
  T extends { id: string },
  U extends string | number | boolean
>({ meta, value, setValue, setError }: TProps<T, U>) {
  const { editorLabel: label, editProps } = meta;

  const prefs = useSelector((state: any) => state.mainFrame.prefs);
  const rest = processEditProps(editProps, prefs);

  // since the GenericScaledField relies on react-hook-form
  // we create an ad-hoc form here to manage the value
  // and sync the internal value with the table via a useEffect
  const methods = useForm<{ value: number }>({
    defaultValues: { value: value as number },
  });

  const internalValue = methods.watch('value');
  const isError = methods.formState.errors.value != null;

  let className: string;
  let textColor: 'red' = null;
  if (isError) {
    className = 'table-edit-error';
    textColor = 'red';
  }

  useEffect(() => {
    // keep the table value in sync with the form value
    setValue(internalValue as U);
  }, [internalValue]);

  useEffect(() => {
    // keep the table error in sync with the form error
    // in order to disable the save button
    if (isError) {
      setError(true);
    } else {
      setError(null);
    }
  }, [isError]);

  return (
    <FormProvider {...methods}>
      <form>
        <label
          style={{ fontWeight: 'bold', marginRight: '1rem', display: 'block' }}
        >
          {label}
        </label>

        <GenericScaledField
          name="value"
          label={null}
          getter="value"
          defaultValue={value as number}
          alwaysEnabled={true}
          disabled={false}
          required={true}
          className={className}
          textColor={textColor}
          showUnits={false}
          style={{ marginBottom: '0.5rem' }}
          autoFocus={true}
          {...rest}
        />
      </form>
    </FormProvider>
  );
}
