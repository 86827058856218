import { Row, Table } from '@tanstack/react-table';
import React from 'react';
import { Loader } from 'semantic-ui-react';
import { GREY_COLOR } from 'src/app.constants';
import { useTableContext } from './TableContext';

type TSelectRow<T> = { kind: 'row'; row: Row<T>; table: Table<T> };
type TSelectHeader<T> = { kind: 'header'; table: Table<T> };

export function SelectCell<T>(props: TSelectRow<T> | TSelectHeader<T>) {
  let checked: boolean;
  let onChange: React.ChangeEventHandler<HTMLInputElement>;
  const tableContext = useTableContext();

  if (props.kind === 'row') {
    const row = props.row;
    const isLoading = (row.original as any).strokeColor === GREY_COLOR;
    if (isLoading) {
      return <Loader active size="mini" />;
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <input
          type="checkbox"
          checked={row.getIsSelected()}
          onChange={(e) => e.stopPropagation()}
          onClick={(e) => {
            e.stopPropagation();
            row.toggleSelected();
            tableContext.setLastSelectedRowId(row.id);
          }}
          title="Select row"
        />
      </div>
    );
  } else {
    const table = props.table;
    const currentPageRows = table.getPaginationRowModel().rows;

    const allSelected =
      currentPageRows.length > 0 &&
      currentPageRows.every(
        (row) =>
          row.getIsSelected() ||
          (row.subRows.length > 1 && row.getIsAllSubRowsSelected())
      );
    checked = allSelected;

    // need a custom handler for the header checkbox because the default one
    // will not select the group row parents
    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      // so use the normal selector for rows
      table.getToggleAllPageRowsSelectedHandler()(event);
      // but we also need a custom one for the grouped row parents.
      // negating the value of allSelected means pressing the header checkbox
      // will select all if some or none of the rows are selected
      const newValue = !allSelected;
      currentPageRows
        .filter((r) => r.getIsGrouped())
        .forEach((row) => {
          row.toggleSelected(newValue);
        });
    };

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          title="Select all"
          ref={(el) => {
            // sometimes display intermediate checkbox in header
            if (el && props.kind === 'header') {
              const table = props.table;
              const currentPageRows = table.getPaginationRowModel().rows;
              const allSelected = currentPageRows.every(
                (row) => row.getIsSelected() && row.getIsAllSubRowsSelected()
              );
              const someSelected = currentPageRows.some(
                (row) =>
                  row.getIsSelected() ||
                  row.subRows.some((sr) => sr.getIsSelected())
              );
              el.indeterminate = someSelected && !allSelected;
            }
          }}
        />
      </div>
    );
  }
}
