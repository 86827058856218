// This file is automatically generated by backend/app/scripts/generate_columns.py
import { createColumnHelper } from '@tanstack/react-table';
import * as U from './ColumnUtils';
import * as E from './editing/editors';

export type MeshTableEndData = {
  name?: string;
  sector?: string;
  antenna_height?: number | 'N/A';
  max_height?: number | 'N/A';
  ground_height?: number | 'N/A';
  rx_antenna_gain?: number | 'N/A';
  antenna?: string;
  bearing?: number | 'N/A';
  tilt?: number | 'N/A';
  latitude?: number | 'N/A';
  longitude?: number | 'N/A';
  mac_address?: string;
  throughput_reliability?: number | 'N/A';
  mean_data_rate_predicted?: number | 'N/A';
  percentage_of_required_data_rate?: number | 'N/A';
  power?: number | 'N/A';
  eirp?: number | 'N/A';
  use_user_power?: boolean | 'N/A';
  user_power?: number | 'N/A';
  use_user_eirp?: boolean | 'N/A';
  user_eirp?: number | 'N/A';
  use_noise?: boolean | 'N/A';
  noise?: number | 'N/A';
  mean_data_rate_requirement?: number | 'N/A';
  minimum_data_rate_requirement?: number | 'N/A';
  minimum_reliability_requirement?: number | 'N/A';
  transmit_frequency?: number | 'N/A';
  receive_frequency?: number | 'N/A';
  link_golay?: string;
  polarity?: string;
  predicted_receive_power?: string;
  clutter_type?: string;
}

export type MeshTableData = {
  local?: MeshTableEndData;
  remote?: MeshTableEndData;
  warnings?: any;
  strokeColor?: string;
  local_name?: string;
  remote_name?: string;
  name?: string;
  id: string;
  range_m?: number | 'N/A';
  band?: string;
  product?: string;
  regulation?: string;
  link_availability?: number | 'N/A';
  system_fade_margin?: number | 'N/A';
  npoints?: number | 'N/A';
  profile_type?: string;
  dn_dh?: string;
  area_roughness?: number | 'N/A';
  geo_climatic_factor?: string;
  fade_occurrence_factor?: string;
  inclination?: number | 'N/A';
  terrain_roughness?: number | 'N/A';
  climatic_factor?: string;
  c_factor?: string;
  temperature?: number | 'N/A';
  worst_earth_ke?: number | 'N/A';
  free_space_path_loss?: number | 'N/A';
  gaseous_obsorption_loss?: number | 'N/A';
  excess_path_loss?: number | 'N/A';
  excess_path_loss_at_ke?: number | 'N/A';
  total_path_loss?: number | 'N/A';
  rain_rate?: string;
  rain_attenuation?: number | 'N/A';
  rain_availability?: number | 'N/A';
  rain_unavailability?: string;
  annual_2_way_availability?: number | 'N/A';
  annual_2_way_unavailability?: string;
  annual_2_way_availability_with_rain?: string;
  annual_2_way_unavailability_with_rain?: string;
  left_annual1_way_availability?: number | 'N/A';
  right_annual1_way_availability?: number | 'N/A';
  remote_product?: string;
  master_end?: string;
  bandwidth?: string;
  dl_ul_ratio?: string;
  max_mod_mode?: string;
  fresnel_zone_clearance?: number | 'N/A';
}

const columnHelper = createColumnHelper<MeshTableData>()

export const columns = [
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Link Name',
    aggregationFn: 'firstChild',
    cell: U.renderMeshName<MeshTableData>,
    size: 300,
    enableHiding: false,
    sortingFn: 'basic',
  }),
  columnHelper.accessor('local.name', {
    id: 'local_name',
    header: 'Left Name',
    aggregationFn: 'firstChild',
    cell: U.renderMeshNDName<MeshTableData>('local'),
    sortingFn: 'basic',
  }),
  columnHelper.accessor('local.sector', {
    id: 'local_sector',
    header: 'Left Sector',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'mesh',
      attr: 'local.sector',
      columnId: 'local_sector',
      editorLabel: 'Left Sector',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('mesh', 'local_sector'),
    },
  }),
  columnHelper.accessor('local.antenna_height', {
    id: 'local_antenna_height',
    header: U.headerWithHeightUnits('Left Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('local.max_height', {
    id: 'local_max_height',
    header: U.headerWithHeightUnits('Left Max Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('local.ground_height', {
    id: 'local_ground_height',
    header: U.headerWithHeightUnits('Left Ground Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('local.rx_antenna_gain', {
    id: 'local_rx_antenna_gain',
    header: 'Left Gain (dBi)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('local.antenna', {
    id: 'local_antenna',
    header: 'Left Antenna',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('local.bearing', {
    id: 'local_bearing',
    header: 'Left Bearing, True N (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('local.tilt', {
    id: 'local_tilt',
    header: 'Left Antenna Tilt (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('local.latitude', {
    id: 'local_latitude',
    header: 'Left Latitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, converter: U.latitude_Table }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, converter: U.latitude_Table }),
    },
  }),
  columnHelper.accessor('local.longitude', {
    id: 'local_longitude',
    header: 'Left Longitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, converter: U.longitude_Table }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, converter: U.longitude_Table }),
    },
  }),
  columnHelper.accessor('local.mac_address', {
    id: 'local_mac_address',
    header: 'Left MAC Address',
    cell: U.renderConvertedString({ converter: U.macAddressGetter_Table }),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('local.throughput_reliability', {
    id: 'local_throughput_reliability',
    header: 'Left Min IP Availability Predicted (%)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4, nullValue: '0' }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('local.mean_data_rate_predicted', {
    id: 'local_mean_data_rate_predicted',
    header: 'Left Mean IP Predicted (Mbps)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3, nullValue: '0' }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('local.percentage_of_required_data_rate', {
    id: 'local_percentage_of_required_data_rate',
    header: 'Left % of Required IP',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
    },
  }),
  columnHelper.accessor('local.power', {
    id: 'local_power',
    header: 'Left Max Power (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('local.eirp', {
    id: 'local_eirp',
    header: 'Left Max EIRP (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('local.use_user_power', {
    id: 'local_use_user_power',
    header: 'Left User Power Limit?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('local.user_power', {
    id: 'local_user_power',
    header: 'Left Power Limit (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('local.use_user_eirp', {
    id: 'local_use_user_eirp',
    header: 'Left User EIRP Limit?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('local.user_eirp', {
    id: 'local_user_eirp',
    header: 'Left EIRP Limit (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('local.use_noise', {
    id: 'local_use_noise',
    header: 'Left Interference?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('local.noise', {
    id: 'local_noise',
    header: 'Left Interference (dBm in b/w)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('local.mean_data_rate_requirement', {
    id: 'local_mean_data_rate_requirement',
    header: 'Left Mean IP Required (Mbps)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
      editable: true,
      choicesKind: 'mesh',
      attr: 'local.mean_data_rate_requirement',
      columnId: 'local_mean_data_rate_requirement',
      editorLabel: 'Left Mean IP Required (Mbps)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        precision: 3,
      },
    },
  }),
  columnHelper.accessor('local.minimum_data_rate_requirement', {
    id: 'local_minimum_data_rate_requirement',
    header: 'Left Min IP Required (Mbps)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
      editable: true,
      choicesKind: 'mesh',
      attr: 'local.minimum_data_rate_requirement',
      columnId: 'local_minimum_data_rate_requirement',
      editorLabel: 'Left Min IP Required (Mbps)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        precision: 3,
      },
    },
  }),
  columnHelper.accessor('local.minimum_reliability_requirement', {
    id: 'local_minimum_reliability_requirement',
    header: 'Left Min IP Availability Required %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
      editable: true,
      choicesKind: 'mesh',
      attr: 'local.minimum_reliability_requirement',
      columnId: 'local_minimum_reliability_requirement',
      editorLabel: 'Left Min IP Availability Required %',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        max: 100,
        precision: 4,
      },
    },
  }),
  columnHelper.accessor('local.transmit_frequency', {
    id: 'local_transmit_frequency',
    header: 'Left Tx Frequency (MHz)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2, nullValue: '' }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2, nullValue: '' }),
    },
  }),
  columnHelper.accessor('local.receive_frequency', {
    id: 'local_receive_frequency',
    header: 'Left Rx Frequency (MHz)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2, nullValue: '' }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2, nullValue: '' }),
    },
  }),
  columnHelper.accessor('local.link_golay', {
    id: 'local_link_golay',
    header: 'Left Golay',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'mesh',
      attr: 'local.link_golay',
      columnId: 'local_link_golay',
      editorLabel: 'Left Golay',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('mesh', 'local_link_golay'),
    },
  }),
  columnHelper.accessor('local.polarity', {
    id: 'local_polarity',
    header: 'Left Polarity',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('local.predicted_receive_power', {
    id: 'local_predicted_receive_power',
    header: 'Left Predicted Receieve Power',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('local.clutter_type', {
    id: 'local_clutter_type',
    header: 'Left Clutter Type',
    cell: U.renderConvertedString({ converter: U.clutterTypeGetter_Table }),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('remote.name', {
    id: 'remote_name',
    header: 'Right Name',
    aggregationFn: 'firstChild',
    cell: U.renderMeshNDName<MeshTableData>('remote'),
    sortingFn: 'basic',
  }),
  columnHelper.accessor('remote.sector', {
    id: 'remote_sector',
    header: 'Right Sector',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'mesh',
      attr: 'remote.sector',
      columnId: 'remote_sector',
      editorLabel: 'Right Sector',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('mesh', 'remote_sector'),
    },
  }),
  columnHelper.accessor('remote.antenna_height', {
    id: 'remote_antenna_height',
    header: U.headerWithHeightUnits('Right Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('remote.max_height', {
    id: 'remote_max_height',
    header: U.headerWithHeightUnits('Right Max Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('remote.ground_height', {
    id: 'remote_ground_height',
    header: U.headerWithHeightUnits('Right Ground Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('remote.rx_antenna_gain', {
    id: 'remote_rx_antenna_gain',
    header: 'Right Gain (dBi)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('remote.antenna', {
    id: 'remote_antenna',
    header: 'Right Antenna',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('remote.bearing', {
    id: 'remote_bearing',
    header: 'Right Bearing, True N (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('remote.tilt', {
    id: 'remote_tilt',
    header: 'Right Antenna Tilt (deg)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('remote.latitude', {
    id: 'remote_latitude',
    header: 'Right Latitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, converter: U.latitude_Table }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, converter: U.latitude_Table }),
    },
  }),
  columnHelper.accessor('remote.longitude', {
    id: 'remote_longitude',
    header: 'Right Longitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, converter: U.longitude_Table }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, converter: U.longitude_Table }),
    },
  }),
  columnHelper.accessor('remote.mac_address', {
    id: 'remote_mac_address',
    header: 'Right MAC Address',
    cell: U.renderConvertedString({ converter: U.macAddressGetter_Table }),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('remote.throughput_reliability', {
    id: 'remote_throughput_reliability',
    header: 'Right Min IP Availability Predicted (%)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4, nullValue: '0' }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('remote.mean_data_rate_predicted', {
    id: 'remote_mean_data_rate_predicted',
    header: 'Right Mean IP Predicted (Mbps)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3, nullValue: '0' }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('remote.percentage_of_required_data_rate', {
    id: 'remote_percentage_of_required_data_rate',
    header: 'Right % of Required IP',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
    },
  }),
  columnHelper.accessor('remote.power', {
    id: 'remote_power',
    header: 'Right Max Power (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('remote.eirp', {
    id: 'remote_eirp',
    header: 'Right Max EIRP (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('remote.use_user_power', {
    id: 'remote_use_user_power',
    header: 'Right User Power Limit?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('remote.user_power', {
    id: 'remote_user_power',
    header: 'Right Power Limit (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('remote.use_user_eirp', {
    id: 'remote_use_user_eirp',
    header: 'Right User EIRP Limit?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('remote.user_eirp', {
    id: 'remote_user_eirp',
    header: 'Right EIRP Limit (dBm)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('remote.use_noise', {
    id: 'remote_use_noise',
    header: 'Right Interference?',
    cell: U.renderYesNo(),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('remote.noise', {
    id: 'remote_noise',
    header: 'Right Interference (dBm in b/w)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('remote.mean_data_rate_requirement', {
    id: 'remote_mean_data_rate_requirement',
    header: 'Right Mean IP Required (Mbps)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
      editable: true,
      choicesKind: 'mesh',
      attr: 'remote.mean_data_rate_requirement',
      columnId: 'remote_mean_data_rate_requirement',
      editorLabel: 'Right Mean IP Required (Mbps)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        precision: 3,
      },
    },
  }),
  columnHelper.accessor('remote.minimum_data_rate_requirement', {
    id: 'remote_minimum_data_rate_requirement',
    header: 'Right Min IP Required (Mbps)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3 }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3 }),
      editable: true,
      choicesKind: 'mesh',
      attr: 'remote.minimum_data_rate_requirement',
      columnId: 'remote_minimum_data_rate_requirement',
      editorLabel: 'Right Min IP Required (Mbps)',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        precision: 3,
      },
    },
  }),
  columnHelper.accessor('remote.minimum_reliability_requirement', {
    id: 'remote_minimum_reliability_requirement',
    header: 'Right Min IP Availability Required %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
      editable: true,
      choicesKind: 'mesh',
      attr: 'remote.minimum_reliability_requirement',
      columnId: 'remote_minimum_reliability_requirement',
      editorLabel: 'Right Min IP Availability Required %',
      kind: 'number',
      editor: E.NumberEditor,
      editProps: {
        min: 0,
        max: 100,
        precision: 4,
      },
    },
  }),
  columnHelper.accessor('remote.transmit_frequency', {
    id: 'remote_transmit_frequency',
    header: 'Right Tx Frequency (MHz)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2, nullValue: '' }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2, nullValue: '' }),
    },
  }),
  columnHelper.accessor('remote.receive_frequency', {
    id: 'remote_receive_frequency',
    header: 'Right Rx Frequency (MHz)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2, nullValue: '' }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2, nullValue: '' }),
    },
  }),
  columnHelper.accessor('remote.link_golay', {
    id: 'remote_link_golay',
    header: 'Right Golay',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'mesh',
      attr: 'remote.link_golay',
      columnId: 'remote_link_golay',
      editorLabel: 'Right Golay',
      kind: 'string',
      editor: E.DropdownEditor,
      getChoices: E.getChoices('mesh', 'remote_link_golay'),
    },
  }),
  columnHelper.accessor('remote.polarity', {
    id: 'remote_polarity',
    header: 'Right Polarity',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('remote.predicted_receive_power', {
    id: 'remote_predicted_receive_power',
    header: 'Right Predicted Receieve Power',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('remote.clutter_type', {
    id: 'remote_clutter_type',
    header: 'Right Clutter Type',
    cell: U.renderConvertedString({ converter: U.clutterTypeGetter_Table }),
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('range_m', {
    id: 'range_m',
    header: U.headerWithRangeUnits('Range'),
    filterFn: U.rangeFilterFn({ precision: 3 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 3, prefsKey: 'rangeUnits' }),
    size: 100,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 3, prefsKey: 'rangeUnits' }),
    },
  }),
  columnHelper.accessor('band', {
    id: 'band',
    header: 'Band',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('product', {
    id: 'product',
    header: 'Product',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('regulation', {
    id: 'regulation',
    header: 'Regulation',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('link_availability', {
    id: 'link_availability',
    header: 'Link Availability',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
    },
  }),
  columnHelper.accessor('system_fade_margin', {
    id: 'system_fade_margin',
    header: 'System Gain Margin (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('npoints', {
    id: 'npoints',
    header: '# Points in Profile',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
  columnHelper.accessor('profile_type', {
    id: 'profile_type',
    header: 'Profile Type',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('dn_dh', {
    id: 'dn_dh',
    header: 'dN/dH (N units/km)',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('area_roughness', {
    id: 'area_roughness',
    header: 'Area Roughness (m)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('geo_climatic_factor', {
    id: 'geo_climatic_factor',
    header: 'Geoclimatic Factor',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('fade_occurrence_factor', {
    id: 'fade_occurrence_factor',
    header: 'Fade Occurrence Factor (Po)',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('inclination', {
    id: 'inclination',
    header: 'Inclination (mr)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('terrain_roughness', {
    id: 'terrain_roughness',
    header: U.headerWithHeightUnits('Terrain Roughness'),
    filterFn: U.heightFilterFn({ precision: 2 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2, prefsKey: 'heightUnits' }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2, prefsKey: 'heightUnits' }),
    },
  }),
  columnHelper.accessor('climatic_factor', {
    id: 'climatic_factor',
    header: 'Climatic Factor',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('c_factor', {
    id: 'c_factor',
    header: 'C Factor',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('temperature', {
    id: 'temperature',
    header: 'Temperature',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, converter: U.temperatureFromKelvin_Table }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, converter: U.temperatureFromKelvin_Table }),
    },
  }),
  columnHelper.accessor('worst_earth_ke', {
    id: 'worst_earth_ke',
    header: 'Worst Earth (ke)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('free_space_path_loss', {
    id: 'free_space_path_loss',
    header: 'Free Space Path Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('gaseous_obsorption_loss', {
    id: 'gaseous_obsorption_loss',
    header: 'Gaseous Absorption Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('excess_path_loss', {
    id: 'excess_path_loss',
    header: 'Excess Path Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, nullValue: '0' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('excess_path_loss_at_ke', {
    id: 'excess_path_loss_at_ke',
    header: 'Excess Path Loss at ke (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, nullValue: '0' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('total_path_loss', {
    id: 'total_path_loss',
    header: 'Link Loss (dB)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 2 }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 2 }),
    },
  }),
  columnHelper.accessor('rain_rate', {
    id: 'rain_rate',
    header: '0.01% Rain Rate',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('rain_attenuation', {
    id: 'rain_attenuation',
    header: 'Rain Attenuation (dB/km)',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1 }),
    },
  }),
  columnHelper.accessor('rain_availability', {
    id: 'rain_availability',
    header: 'Rain Availability %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4, nullValue: '0' }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('rain_unavailability', {
    id: 'rain_unavailability',
    header: 'Rain Unavailability',
    aggregationFn: 'firstChild',
    sortingFn: U.sortAvailabilityTime,
  }),
  columnHelper.accessor('annual_2_way_availability', {
    id: 'annual_2_way_availability',
    header: 'Annual 2-way Availability %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4 }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4 }),
    },
  }),
  columnHelper.accessor('annual_2_way_unavailability', {
    id: 'annual_2_way_unavailability',
    header: 'Annual 2-way Unavailability',
    aggregationFn: 'firstChild',
    sortingFn: U.sortAvailabilityTime,
  }),
  columnHelper.accessor('annual_2_way_availability_with_rain', {
    id: 'annual_2_way_availability_with_rain',
    header: 'Annual 2-way Availability Including Rain %',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('annual_2_way_unavailability_with_rain', {
    id: 'annual_2_way_unavailability_with_rain',
    header: 'Annual 2-way Unavailability Including Rain',
    aggregationFn: 'firstChild',
    sortingFn: U.sortAvailabilityTime,
  }),
  columnHelper.accessor('left_annual1_way_availability', {
    id: 'left_annual1_way_availability',
    header: 'Left Annual 1-way Availability %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4, nullValue: '0' }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('right_annual1_way_availability', {
    id: 'right_annual1_way_availability',
    header: 'Right Annual 1-way Availability %',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 4, nullValue: '0' }),
    size: 120,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 4, nullValue: '0' }),
    },
  }),
  columnHelper.accessor('remote_product', {
    id: 'remote_product',
    header: 'Remote Product',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('master_end', {
    id: 'master_end',
    header: 'Master End',
    aggregationFn: 'firstChild',
    sortingFn: 'basic',
  }),
  columnHelper.accessor('bandwidth', {
    id: 'bandwidth',
    header: 'Bandwidth (MHz)',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('dl_ul_ratio', {
    id: 'dl_ul_ratio',
    header: 'DL/UL Ratio',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('max_mod_mode', {
    id: 'max_mod_mode',
    header: 'Maximum Mod Mode',
    aggregationFn: 'firstChild',
  }),
  columnHelper.accessor('fresnel_zone_clearance', {
    id: 'fresnel_zone_clearance',
    header: U.headerWithHeightUnits('Fresnel Zone Clearance'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    },
  }),
]
