import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button, Dropdown, Message, Popup } from 'semantic-ui-react';
import produce from 'immer';
import { useCSVDownloader } from 'react-papaparse';
import { toast } from 'react-toastify';
import { sortBy } from 'lodash';
import { has60GHz, runWithConfirmation } from '../../utils/useful_functions';
import { store } from '../../store';
import { getWithAuth, postWithAuth } from 'src/api';
import { uiConfirmAction } from '../mainframe/mainframe.reducer';
import MeshLinkDialog from './MeshLinkDialog';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLpTable } from 'src/components/tables/internal/useLpTable';
import { useMeshColumns } from 'src/components/tables/internal/useMeshColumns';
import { LPTable } from 'src/components/tables/LPTable';
import messages from '../../messages';
import { getCSVData } from 'src/components/tables/ColumnUtils';
import { GREY_COLOR, TABLE_MAX_ROWS } from 'src/app.constants';
import { RootStateOrAny } from 'react-redux';
import { createMeshTableQueryKey } from 'src/components/tables/internal/TableQueryKeys';

function MeshListPanel({ apId, tableId, pageSize = TABLE_MAX_ROWS }) {
  const { formatMessage } = useIntl();
  const [showAttachML, setShowAttachML] = useState(false);
  const { projectId, projectName, accessPoints, permissionWrite } = useSelector(
    (state) => state.mainFrame
  );
  const { CSVDownloader } = useCSVDownloader();

  let queryKey = createMeshTableQueryKey(projectId);
  let url = `project/${projectId}/mesh_links`;
  if (apId) {
    queryKey = ['project', projectId, 'ap', apId, 'mesh', 'table'];
    url = `project/${projectId}/access_point/${apId}/mesh_links`;
  }
  const { data: tableData } = useQuery({
    queryKey,
    initialData: { rows: [], visible: [] },
    queryFn: () => getWithAuth(url),
  });

  const { columns } = useMeshColumns() as any;
  const tableModel = useLpTable({
    data: tableData.rows,
    visibleColumns: tableData.visible,
    columns,
    getRowId: (row: any) => row.id,
    options: {
      pageSize,
    },
  });

  const deleteSelection = useMutation({
    mutationFn: () => {
      const selectedRows = tableModel.table
        .getSelectedRowModel()
        .rows.map((row) => row.original.id);

      if (selectedRows.length === 0) {
        return Promise.reject();
      }

      return postWithAuth(
        `project/${projectId}/mesh_links`,
        selectedRows,
        'DELETE'
      );
    },
    onError: () => {
      toast(<Message error>Failed to delete mesh links</Message>, {
        autoClose: 5000,
      });
    },
  });

  const enableAdd = permissionWrite && has60GHz(accessPoints);
  const enableDelete =
    permissionWrite && tableModel.table.getSelectedRowModel().rows.length > 0;

  return (
    <>
      {showAttachML && (
        <MeshLinkDialog
          apId={apId}
          onClose={() => setShowAttachML(false)}
        ></MeshLinkDialog>
      )}

      <LPTable
        queryKey={queryKey}
        model={tableModel}
        tableId={tableId || 'mesh_links_table'}
      >
        <LPTable.Toolbar>
          <Button
            icon="add"
            type="button"
            content={formatMessage(messages.add)}
            disabled={!enableAdd}
            onClick={() => setShowAttachML(true)}
          />

          <Button
            icon="trash alternate"
            type="button"
            content={formatMessage(messages.delete)}
            disabled={!enableDelete}
            onClick={() => {
              store.dispatch(
                uiConfirmAction({
                  header: formatMessage(messages.deleteMeshLinks),
                  message: formatMessage(messages.confirm),
                  size: 'mini',
                  onConfirm: () => {
                    deleteSelection.mutate();
                  },
                })
              );
            }}
          />

          <CSVDownloader
            filename={`${projectName}_mesh_links`}
            bom={true}
            config={{ delimiter: ',' }}
            data={() => getCSVData(tableModel.table)}
          >
            <Button
              icon="cloud download"
              type="button"
              content="Download CSV"
              disabled={tableModel.table.getRowCount() === 0}
            />
          </CSVDownloader>

          <CopyFrom
            rowData={tableData.rows}
            tableModel={tableModel}
            queryKey={queryKey}
          />
        </LPTable.Toolbar>
      </LPTable>
    </>
  );
}

function CopyFrom(props) {
  const { formatMessage } = useIntl();
  const { rowData, tableModel, queryKey } = props;

  const { projectId, permissionWrite } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );

  const qc = useQueryClient();

  return (
    <Popup
      content={formatMessage(messages.copyFromHelp, {
        kind: 'link',
      })}
      position="right center"
      trigger={
        <Dropdown
          button
          fluid
          search
          selection
          labeled
          icon="paste"
          text={formatMessage(messages.dropdownPlaceHolderTxt)}
          options={sortBy(
            rowData
              .filter((row) => {
                const selected = tableModel.table
                  .getSelectedRowModel()
                  .rows.map((r) => r.original.id);
                return !selected.includes(row.id) && row.path_index === 0;
              })
              .map((row) => ({
                key: row.id,
                text: row.name,
                value: [row.id, row.name],
              })),
            (opt) => opt.text
          )}
          disabled={
            !permissionWrite ||
            tableModel.table.getSelectedRowModel().rows.length === 0
          }
          className="icon sm-nd-dropdown-options"
          style={{ margin: '0' }}
          onChange={(_, data) => {
            const rows = tableModel.table.getSelectedRowModel().rows;
            const ids = [
              ...new Set(
                rows
                  .filter((row) => row.original.path_index === 0)
                  .map((row) => row.original.id)
              ),
            ];

            if (ids.length < 1) {
              return;
            }

            const [copyId, selectedName] = data.value as any;
            const qtyAndKind =
              rows.length === 1
                ? 'selected link'
                : `${rows.length} selected links`;

            runWithConfirmation({
              message: `${formatMessage(messages.copyFromConfirmation, {
                qtyAndKind,
              })} ${selectedName}`,
              onConfirm: () => {
                const payload = {
                  ids,
                  copy_id: copyId,
                  data: {},
                  bulk: 'copy',
                };

                postWithAuth(
                  `project/${projectId}/mesh_links/bulk`,
                  payload,
                  'PATCH'
                )
                  .then(() => {
                    qc.setQueryData(queryKey, (prev: any[]) => {
                      return produce(prev, (draft) => {
                        for (const row of draft.rows) {
                          if (ids.includes(row.id)) {
                            row.strokeColor = GREY_COLOR;
                          }
                        }
                      });
                    });
                  })
                  .catch(() => {
                    toast(<Message error>Failed to copy links</Message>);
                  });
              },
            });
          }}
        />
      }
    />
  );
}

export default MeshListPanel;
