import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Menu, MenuItem, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import messages from 'src/messages';
import { setPmpSelectedTableTab } from 'src/pages/mainframe/mainframe.reducer';
import { PanelHeading } from 'src/components/PanelHeading';

function PMPTabbedView(props) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const {
    accessPointsCount,
    pmpLinksCount,
    meshLinksCount,
  } = useSelector((state: any) => state.mainFrame);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <PanelHeading
        ignoreToolbar
        style={{ borderBottom: '1px solid #D4D4D5' }}
        title={(
          <Menu attached tabular style={{ borderBottom: 'none' }}>
            <MenuItem
              name="aps"
              as={NavLink}
              to="/aps"
              onClick={() => dispatch(setPmpSelectedTableTab({ tab: '/aps' }))}
              content={`${formatMessage(messages.networkDevices)} (${accessPointsCount})`}
            />
            <MenuItem
              name="mesh"
              as={NavLink}
              to="/mesh"
              onClick={() => dispatch(setPmpSelectedTableTab({ tab: '/mesh' }))}
              content={`${formatMessage(messages.meshLinks)} (${meshLinksCount})`}
            />
            <MenuItem
              name="pmp"
              as={NavLink}
              to="/subscribers"
              onClick={() => dispatch(setPmpSelectedTableTab({ tab: '/subscribers' }))}
              content={`${formatMessage(messages.pmpLinks)} (${pmpLinksCount})`}
            />
          </Menu>
        )}
      />

      <div className="detailWrapper" style={{ flex: '1', overflow: 'auto' }}>
        <Segment basic style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          {props.children}
        </Segment>
      </div>
    </div>
  );
}

export default PMPTabbedView;
