import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useCSVDownloader } from 'react-papaparse';
import { Message, Button, Popup, Dropdown } from 'semantic-ui-react';
import { runWithConfirmation } from '../../utils/useful_functions';
import { store } from '../../store';
import { uiConfirmAction } from '../mainframe/mainframe.reducer';
import { postWithAuth, getWithAuth } from 'src/api';
import CreateAccessPointModal from './create-access-points/CreateAccessPointModal';
import messages from '../../messages';
import { GREY_COLOR } from 'src/app.constants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNDColumns } from 'src/components/tables/internal/useNDColumns';
import { useLpTable } from 'src/components/tables/internal/useLpTable';
import { getCSVData } from 'src/components/tables/ColumnUtils';
import { LPTable } from 'src/components/tables/LPTable';
import { toast } from 'react-toastify';
import sortBy from 'lodash/sortBy';
import produce from 'immer';
import { makePlural } from 'src/utils/useful_functions';
import { createAPTableQueryKey } from 'src/components/tables/internal/TableQueryKeys';

/*
 * Return a tooltip for an ND row
 */
function getWarning(row) {
  const rowData = row.original;
  const hover = rowData?.warnings?.hover;
  const count = rowData.connected_subscribers + rowData.unconnected_subscribers;
  const text = makePlural(count, 'module', 'modules');
  let result = `${count} subscriber ${text}`;
  if (hover?.length > 0) {
    result = `${result} - Reason for error: ${hover.join(', ')}`;
  }
  return result;
}

const AccessPointsPanel = (props) => {
  const { formatMessage } = props.intl;
  const { CSVDownloader } = useCSVDownloader();

  const {
    projectId,
    projectName,
    permissionWrite,
    networkSiteCount,
    accessPointsCount,
    userLimits,
  } = useSelector((state) => state.mainFrame);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const queryKey = createAPTableQueryKey(projectId);
  const { data: tableData } = useQuery({
    queryKey,
    initialData: { rows: [], visible: [] },
    queryFn: () => getWithAuth(`project/${projectId}/access_points`),
  });

  const { columns } = useNDColumns();
  const tableModel = useLpTable({
    data: tableData.rows,
    visibleColumns: tableData.visible,
    columns,
    getRowId: (row) => `${row.id}-${row.radio_number}`,
    options: {
      grouping: ['name'],
      getTooltip: getWarning,
    },
  });

  const deleteSelection = useMutation({
    mutationFn: () => {
      const selectedRows = tableModel.table
        .getSelectedRowModel()
        .rows.map((row) => row.original.id);

      if (selectedRows.length === 0) {
        return Promise.reject();
      }

      return postWithAuth(
        `project/${projectId}/access_points`,
        selectedRows,
        'DELETE'
      );
    },
    onError: () => {
      toast(<Message error>Failed to delete network devices</Message>, {
        autoClose: 5000,
      });
    },
  });

  let addWarning = '';
  if (accessPointsCount >= userLimits?.pmp_network_devices) {
    addWarning = formatMessage(messages.maximumNumberError, {
      entityName: 'PMP Network Device',
      limit: userLimits?.pmp_network_devices,
    });
  }
  const enableAdd =
    permissionWrite && addWarning === '' && networkSiteCount > 0;

  const enableDelete =
    permissionWrite && tableModel.table.getSelectedRowModel().rows.length > 0;

  return (
    <>
      {showCreateModal && (
        <CreateAccessPointModal
          showModal={showCreateModal}
          handleClose={() => setShowCreateModal(false)}
          projectId={projectId}
          intl={props.intl}
        />
      )}

      <LPTable queryKey={queryKey} model={tableModel} tableId="pmp_nd_table">
        <LPTable.Toolbar>
          <Button
            icon="add"
            content={formatMessage(messages.add)}
            disabled={!enableAdd}
            onClick={() => setShowCreateModal(true)}
          />

          <Button
            icon="trash alternate"
            content={formatMessage(messages.delete)}
            disabled={!enableDelete}
            onClick={() => {
              store.dispatch(
                uiConfirmAction({
                  header: formatMessage(messages.deleteAccessPoints),
                  message: formatMessage(messages.confirm),
                  size: 'mini',
                  onConfirm: () => {
                    deleteSelection.mutate();
                  },
                })
              );
            }}
          />

          <CSVDownloader
            filename={`${projectName}_pmp_network_devices`}
            bom={true}
            config={{ delimiter: ',' }}
            data={() => getCSVData(tableModel.table)}
          >
            <Button
              icon="cloud download"
              content="Download CSV"
              disabled={tableModel.table.getRowCount() === 0}
            />
          </CSVDownloader>

          <CopyFrom
            formatMessage={formatMessage}
            rowData={tableData.rows}
            tableModel={tableModel}
            queryKey={queryKey}
          />
        </LPTable.Toolbar>
      </LPTable>
    </>
  );
};

function CopyFrom(props) {
  const { formatMessage, rowData, tableModel, queryKey } = props;

  const { projectId, permissionWrite } = useSelector(
    (state) => state.mainFrame
  );

  const qc = useQueryClient();

  return (
    <Popup
      content={formatMessage(messages.copyFromHelp, {
        kind: 'PMP network device',
      })}
      position="right center"
      trigger={
        <Dropdown
          button
          fluid
          search
          selection
          labeled
          icon="paste"
          text={formatMessage(messages.dropdownPlaceHolderTxt)}
          options={sortBy(
            rowData
              .filter((row) => {
                const selected = tableModel.table
                  .getSelectedRowModel()
                  .rows.map((r) => r.original.id);
                return !selected.includes(row.id) && row.radio_number === 1;
              })
              .map((row) => ({
                key: row.id,
                text: row.name,
                value: [row.id, row.name],
              })),
            (opt) => opt.text
          )}
          disabled={
            !permissionWrite ||
            tableModel.table.getSelectedRowModel().rows.length === 0
          }
          className="icon sm-nd-dropdown-options"
          style={{ margin: '0' }}
          onChange={(_, data) => {
            const rows = tableModel.table.getSelectedRowModel().rows;
            const ids = [
              ...new Set(
                rows
                  .filter((row) => row.original.radio_number === 1)
                  .map((row) => row.original.id)
              ),
            ];

            if (ids.length < 1) {
              return;
            }

            const [copyId, selectedName] = data.value;
            const qtyAndKind =
              rows.length === 1
                ? 'selected PMP network device'
                : `${rows.length} selected PMP network devices`;

            runWithConfirmation({
              message: `${formatMessage(messages.copyFromConfirmation, {
                qtyAndKind,
              })} ${selectedName}`,
              onConfirm: () => {
                getWithAuth(`project/${projectId}/access_point/${copyId}`)
                  .then((apData) => {
                    const payload = {
                      ids,
                      data: apData,
                      bulk: 'copy',
                    };

                    postWithAuth(
                      `project/${projectId}/access_points/bulk`,
                      payload,
                      'PATCH'
                    ).then(() => {
                      qc.setQueryData(queryKey, (prev) => {
                        return produce(prev, (draft) => {
                          for (const row of draft.rows) {
                            if (ids.includes(row.id)) {
                              row.strokeColor = GREY_COLOR;
                            }
                          }
                        });
                      });
                    });
                  })
                  .catch(() => {
                    toast(
                      <Message error>Failed to copy network devices</Message>
                    );
                  });
              },
            });
          }}
        />
      }
    />
  );
}

export default injectIntl(AccessPointsPanel);
