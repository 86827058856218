import React from 'react';
import { Manager } from 'socket.io-client';
import config from './config';
import { store } from './store';
import {
  updateObjects,
  deleteObjects,
  unloadProject,
  updatePreferences,
  panelNeedsRefresh,
  setDirtyObjects,
  uiSet,
  longTaskComplete,
  setProjectModified,
  recalculateProject,
  getAllowedFeatures,
  setImportingAnp,
  updateAnpJobStatus,
} from './pages/mainframe/mainframe.reducer';
import { userMsg } from './reducers/socket_reducer';
import { batch } from 'react-redux';
import {
  viewshedCompleted,
  viewshedFailed,
} from './pages/viewshed/viewshed.reducer';
import {
  addOorResults,
  addResults,
  calculationComplete,
  creatingComplete,
  incLinksCreatedCount,
  setWorkerError,
} from './pages/best_server/best_server.reducer';
import { renewTokens } from './api';
import { setLocalJSON, makePlural } from './utils/useful_functions';
import { toast } from 'react-toastify';
import { Message } from 'semantic-ui-react';
import {
  calcTiltProgressUpdate,
  setCalcTiltResults,
  calcTiltStatusUpdate,
} from './reducers/calc_tilt.reducer';
import {
  exportSocketHandler,
  reportSocketHandler,
} from './pages/mainframe/reportHelpers';
import {
  deleteFromTable,
  refreshTable,
  refreshSiteGraphTable,
} from './components/tables/internal/TableQueryKeys';

// To enable websocket debugging
// localStorage.debug = '*';

const NAMESPACE = 'LINKPlanner';

const manager = new Manager(config.apiURL, {
  path: '/ws/socket.io',
  withCredentials: true,
  autoConnect: false,
});

/*
 * Return true if the action is raised after
 * a project export event is complete
 * (Google Earth or LINKPlanner project export
 */
const exportIsComplete = (action) => {
  return action === 'lpp' || action === 'kmz';
};
const reportIsComplete = (action) => {
  return action === 'report';
};

/*
 * Handle create/update actions on features (sites, links, etc)
 */
const handleFeatureActions = (data) => {
  const projectId = store.getState().mainFrame.projectId;
  if (data?.objects) {
    // object update
    store.dispatch(updateObjects(data));
    if (data.objects && data.objects.length > 0) {
      // The objects array can contain different feature kinds
      const idsPerKind = {};
      data.objects.forEach((o) => {
        let columns = o.columns;
        if (o.columns && !Array.isArray(o.columns)) {
          columns = [o.columns];
        }

        if (!idsPerKind[o.kind]) {
          idsPerKind[o.kind] = [];
        }
        if (columns) {
          columns.forEach((c) => idsPerKind[o.kind].push(c));
        }
      });
      for (const kind in idsPerKind) {
        refreshTable(kind, projectId, idsPerKind[kind]);
      }
    }
  }

  if (data.action === 'site_rename') {
    // TODO: This really shouldn't be necessary. The site panel
    // should be updated to invalidate the query and remove
    // the site reducer
    const { proj_id, site_id } = data;
    refreshSiteGraphTable(proj_id, site_id);
  }
};

const handleUpdateMessage = (data) => {
  if (data.action === 'best_server_result') {
    store.dispatch(addResults(data));
  } else if (data.action === 'calc_tilt_progress') {
    console.log(data);
    store.dispatch(calcTiltProgressUpdate(data.message));
  } else if (data.action === 'calc_tilt_complete') {
    store.dispatch(calcTiltStatusUpdate('completed'));
    store.dispatch(setCalcTiltResults(data.message));
  } else if (data.action === 'LIDAR_REQUEST_FAILED') {
    console.log(data);
    toast(
      <Message error>
        Missing high resolution profile data for {data.objects.length}{' '}
        {makePlural(data.objects.length, 'profile', 'profiles')}
      </Message>,
      {
        autoClose: 2000,
      }
    );
  } else if (data.action === 'best_server_oor') {
    store.dispatch(addOorResults(data));
  } else if (data.action === 'best_server_worker_error') {
    batch(() => {
      store.dispatch(setWorkerError(true));
      store.dispatch(longTaskComplete());
    });
  } else if (data.action === 'best_server_complete') {
    batch(() => {
      store.dispatch(calculationComplete());
      store.dispatch(longTaskComplete());
    });
  } else if (data.action === 'best_server_creating_complete') {
    batch(() => {
      store.dispatch(creatingComplete());
      store.dispatch(longTaskComplete());
    });
  } else if (data.action === 'best_server_created_sm') {
    store.dispatch(incLinksCreatedCount(data.objects[0]));
  } else if (data.action === 'anp_update') {
    store.dispatch(setProjectModified(true));
  } else if (data.action === 'bom') {
    store.dispatch(setProjectModified(true));
  } else if (data.action === 'project_recalc_complete') {
    store.dispatch(recalculateProject({ complete: true }));
    store.dispatch(setImportingAnp(false));
  } else if (data.action === 'ANP_JOB_UPDATE') {
    store.dispatch(updateAnpJobStatus(data));
    const anpStatus = data.objects.status;
    if (
      anpStatus === 'completed' ||
      anpStatus === 'failed' ||
      anpStatus === 'cancelled'
    ) {
      store.dispatch(longTaskComplete());
    }
  } else {
    handleFeatureActions(data);
  }
};

const _socket = {
  socket: null,
  userRoom: null,
  projectRoom: null,
  manager: manager,
  knownVersion: window.runtime.version,
  knownChangeset: window.runtime.changeset,

  ensureConnection: function () {
    if (!(this.socket && this.socket.connected)) {
      this.connect();
    }
  },

  enterProjectRoom: function (roomName) {
    this.ensureConnection();
    if (this.socket && this.socket.connected) {
      const projectRoom = roomName || sessionStorage.getItem('cn.lp.projectId');
      if (projectRoom) {
        console.debug('Joining project room:', projectRoom);
        this.socket.emit('join', { room: projectRoom });
        this.projectRoom = projectRoom;
      } else {
        console.debug('Missing project room name');
      }
    } else {
      console.warn('Unable to enter project room. Not connected.');
    }
  },

  leaveProjectRoom: function () {
    console.debug('Leaving project room:', this.projectRoom);
    if (this.socket && this.socket.connected) {
      this.socket.emit('leave', { room: this.projectRoom });
      this.projectRoom = null;
    }
  },

  enterUserRoom: function (userId) {
    this.ensureConnection();
    if (this.socket && this.socket.connected) {
      const userRoom = userId || sessionStorage.getItem('cn.lp.id');
      if (userRoom) {
        console.debug('Joining user room:', userRoom);
        this.socket.emit('join', { userRoom: userRoom });
        this.userRoom = userRoom;
      } else {
        console.debug('Missing user room id');
      }
    } else {
      console.warn('Unable to enter user room. Not connected.');
    }
  },

  leaveUserRoom: function () {
    const room = this.userRoom || sessionStorage.getItem('cn.lp.id');
    if (room) {
      console.debug('Leaving user room:', room);
      if (this.socket) {
        this.socket.emit('leave', { userRoom: room });
      }
      this.userRoom = null;
    }
  },

  sendMessage: function (msg) {
    // Note: The server isn't listening for messages at the moment.
    console.debug('Sending message:', msg);
    this.ensureConnection();
    if (this.socket && this.socket.connected) {
      if (this.projectRoom) {
        this.socket.emit('room_message', {
          room: this.projectRoom,
          msg: msg,
        });
      } else {
        console.warn('Cannot send message without a room');
      }
    } else {
      console.warn('Unable to send message. Not connected.');
    }
  },

  disconnect: function (dispatch = true) {
    console.debug('Disconnect');
    if (this.socket) {
      this.socket.removeAllListeners();
      this.leaveProjectRoom();
      this.leaveUserRoom();
      this.socket.disconnect();
      if (dispatch) {
        store.dispatch(uiSet({ connected: false }));
      }
    }
    this.socket = null;
  },

  connect: function () {
    if (this.socket) {
      console.debug('Removing old socket');
      if (this.socket.connected) {
        console.debug('Disconnecting socket');
        this.socket.disconnect();
        console.debug('Removing event handlers');
      }
      this.socket.removeAllListeners();
      this.socket = null;
    }

    const socket = manager.socket(`/${NAMESPACE}`);

    socket.on('connect', () => {
      console.debug('SOCKET: on_connect');
      this.enterProjectRoom(this.projectRoom);
      this.enterUserRoom(store.getState().mainFrame.userId);
      store.dispatch(uiSet({ connected: true }));
      store.dispatch(getAllowedFeatures());
    });

    socket.on('disconnect', () => {
      console.debug('SOCKET: on_disconnect');
      store.dispatch(uiSet({ connected: false }));
    });

    socket.on('broadcast', (data) => {
      if (data?.ping) {
        // If the server has "pinged" the client then
        // "pong" a response back. The server
        // can use this to count how many clients are
        // connected
        this.socket.emit('pong', {});
      } else {
        console.debug('SOCKET: broadcast', data);
      }
    });

    socket.on('room_message', (data) => {
      console.debug('SOCKET: on_room_message', data);
    });

    socket.on('user_message', (data) => {
      console.debug('SOCKET: on_user_message:', data);

      if (
        data &&
        data.version &&
        (data.version !== this.knownVersion ||
          data.changeset !== this.knownChangeset)
      ) {
        // Set a localStorage flag to show that LP has updated
        // then refresh the page. When the UI refreshes it will
        // show a modal that guides the user to the release notes
        this.knownVersion = data.version;
        this.knownChangeset = data.changeset;
        console.info('Updating...');
        setLocalJSON('cn.lp.hasUpdated', true);
        // trigger the updating component...
        store.dispatch(uiSet({ isUpdating: true }));
        // Wait 20 seconds and then reload the page to check
        // for updates again
        setTimeout(() => window.location.reload(), 20000);
      } else if (data) {
        const { action, status, id } = data;
        if (status === 'success' && action === 'account') {
          store.dispatch(updatePreferences(data));
        } else if (action === 'LINKPLANNER_IMPORT_PROGRESS') {
          if (status === 'WARNING') {
            toast(<Message error>{data.message}</Message>, {
              autoClose: true,
            });
          }
          store.dispatch(
            userMsg({
              status: data.status?.toLowerCase(),
              message: data.message,
            })
          );
        } else if (exportIsComplete(action)) {
          exportSocketHandler(data);
        } else if (reportIsComplete(action)) {
          reportSocketHandler(data);
        } else {
          store.dispatch(userMsg(data));
        }
      }
    });

    socket.on('update', (data) => {
      console.debug('SOCKET: on_update');
      handleUpdateMessage(data);
    });

    socket.on('refresh', (data) => {
      console.debug('SOCKET: on_refresh');
      const { panels, dirtyObjs } = data;
      batch(() => {
        store.dispatch(
          panelNeedsRefresh({
            panels,
            status: true,
          })
        );
        if (dirtyObjs) {
          store.dispatch(
            setDirtyObjects({
              value: false,
              objects: dirtyObjs,
            })
          );
        } else {
          store.dispatch(uiSet({ bulkEditSelectedRows: [] }));
        }
      });
    });

    socket.on('viewshed', (data) => {
      if (data.status === 'done') {
        store.dispatch(viewshedCompleted(data));
      } else {
        store.dispatch(viewshedFailed(data));
      }
    });

    socket.on('delete', (data) => {
      console.debug('SOCKET: on_delete');
      const kind = data?.kind;
      const projectId = store.getState().mainFrame.projectId;
      deleteFromTable(kind, projectId, data.objects);
      store.dispatch(deleteObjects(data));
    });

    /*
     * The current project has been deleted so
     * unload the project and refresh the session.
     */
    socket.on('closed', (data) => {
      console.debug('SOCKET: on_closed');
      store.dispatch(unloadProject());
      store.dispatch(uiSet({ locked: false }));
    });

    socket.on('saved', (data) => {
      console.debug('SOCKET: on_saved');
      store.dispatch(uiSet({ locked: false }));
    });

    socket.on('reconnect_event', () => {
      console.debug('SOCKET: on_reconnect_event');
      this.enterProjectRoom(this.projectRoom);
      this.enterUserRoom(store.getState().mainFrame.userId);
      store.dispatch(uiSet({ connected: true }));
    });

    socket.on('reconnect_error', (err) => {
      console.debug('SOCKET: on_reconnect_error', err);
      store.dispatch(uiSet({ connected: false }));
    });

    console.debug('Socket created');

    this.socket = socket;
    socket.connect();
  },
};

window.addEventListener('focus', () => {
  if (_socket.socket && !_socket.socket.connected) {
    renewTokens(() => {
      _socket.disconnect();
      _socket.ensureConnection();
    });
  }
});

export default _socket;
