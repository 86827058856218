import { Row } from '@tanstack/react-table';
import React from 'react';
import { multiSelectKey } from 'src/utils/useful_functions';

export function TableRow<T>({
  row,
  children,
  className,
  extraStyles,
  tooltip,
  onShiftClick,
  preventClick,
}: {
  key?: string;
  row: Row<T>;
  children: React.ReactNode;
  className?: string;
  extraStyles?: React.CSSProperties;
  tooltip?: string;
  onShiftClick?: (rowId: string, shiftKey: boolean, ctrlKey?: boolean) => void;
  preventClick?: boolean;
}) {
  let handleRowClick = undefined;
  if (!preventClick) {
    handleRowClick = (e: React.MouseEvent) => {
      // Check if we have a shift/ctrl click handler
      if (onShiftClick) {
        e.preventDefault();
        onShiftClick(row.id, e.shiftKey, multiSelectKey(e));
      } else {
        // If no shift click handler, use the default toggle handler
        row.getToggleSelectedHandler()(e);
      }
    };
  }

  let finalClass = '';
  if (className) {
    finalClass = className;
  }
  if (row.getIsSelected()) {
    finalClass += ' lp-table-selected';
  }

  return (
    <tr
      className={finalClass}
      onClick={handleRowClick}
      style={{
        color: (row.original as any).strokeColor ?? undefined,
        ...(extraStyles ?? {}),
      }}
      title={tooltip}
    >
      {children}
    </tr>
  );
}
