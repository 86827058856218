import React from 'react';

function Toolbar(props) {
    return (
        <>{props.children}</>
    );
}

Toolbar.tableSection = 'toolbar';

export { Toolbar };