import { get } from 'lodash';
import React, { useContext, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import { getWithAuth } from 'src/api';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import type { PTPFormField } from './utils';
import type { PTPPath } from './ptp-link-type';
import { FormResetContext } from 'src/pages/pmp/utils';

const AZIMUTH_LIMIT = 60; // +/-
const ELEVATION_LIMIT = 15; // +/-

const isValid = (num, limit) => {
  return !isNaN(parseInt(num)) && num >= -limit && num <= limit;
};

async function fetchScanLoss(
  projectId,
  frequencyMhz,
  azimuthOffset,
  elevationOffset
) {
  if (
    isValid(azimuthOffset, AZIMUTH_LIMIT) &&
    isValid(elevationOffset, ELEVATION_LIMIT)
  ) {
    return getWithAuth(
      `project/${projectId}/ptp/scan_loss/${frequencyMhz}/${azimuthOffset}/${elevationOffset}`
    );
  } else {
    return 0;
  }
}

type Props = PTPFormField<any> & {
  endName: string;
  path: PTPPath;
  setModified: React.Dispatch<React.SetStateAction<boolean>>;
};

const ScanLossEditor = ({ endName, pathIndex, readOnly, refreshText }) => {
  return (
    <Form.Field
      key={`${endName}_scan_loss`}
      style={{
        paddingTop: '0rem',
      }}
    >
      <GenericScaledField
        label="Scan Loss"
        getter={`${endName}.radios.${pathIndex}.antennas.0.config.scan_loss`}
        defaultValue={0}
        units="dB"
        min={0}
        max={10}
        precision={1}
        disabled={readOnly}
        style={{ maxWidth: '8em' }}
        watch={refreshText}
      />
    </Form.Field>
  );
};

const AzimuthOffset = ({
  endName,
  pathIndex,
  show,
  onAcceptChange,
  formResetValue,
}) => {
  if (show) {
    return (
      <Form.Field
        key={`${endName}_azimuth_offset`}
        style={{
          paddingTop: '0rem',
        }}
      >
        <GenericScaledField
          label="Azimuth Offset"
          getter={`${endName}.radios.${pathIndex}.antennas.0.config.azimuth_offset`}
          defaultValue={0}
          units="°"
          min={-AZIMUTH_LIMIT}
          max={AZIMUTH_LIMIT}
          precision={0}
          watch={formResetValue}
          style={{ maxWidth: '5em' }}
          onAcceptChange={() => onAcceptChange()}
        />
      </Form.Field>
    );
  }
};

const ElevationOffset = ({
  endName,
  pathIndex,
  show,
  onAcceptChange,
  formResetValue,
}) => {
  if (show) {
    return (
      <Form.Field
        key={`${endName}_elevation_offset`}
        style={{
          paddingTop: '0rem',
        }}
      >
        <GenericScaledField
          label="Elevation Offset"
          getter={`${endName}.radios.${pathIndex}.antennas.0.config.elevation_offset`}
          defaultValue={0}
          units="°"
          min={-ELEVATION_LIMIT}
          max={ELEVATION_LIMIT}
          precision={0}
          watch={formResetValue}
          style={{ maxWidth: '5em' }}
          onAcceptChange={() => onAcceptChange()}
        />
      </Form.Field>
    );
  }
};

function ScanLoss(props: Props) {
  const { control, setValue, getValues } = useFormContext();
  const resetValue = useContext(FormResetContext);
  const { endName, path, setModified } = props;
  const pathIndex = props.pathIndex ?? 0;

  const [refreshText, setRefreshText] = useState(0);

  const calculateAttrPath = `${endName}.radios.${pathIndex}.antennas.0.config.user_calculate_scan_loss`;
  const calculateScanLoss = useWatch({
    name: calculateAttrPath,
    control,
    defaultValue: get(path, calculateAttrPath),
  });

  const projectId = useSelector((state) => state.mainFrame.projectId);
  const frequencyMhz = getValues(
    `${endName}.radios.${pathIndex}.frequency.frequency_mhz`
  );
  const azimuthOffset = getValues(
    `${endName}.radios.${pathIndex}.antennas.0.config.azimuth_offset`
  );
  const elevationOffset = getValues(
    `${endName}.radios.${pathIndex}.antennas.0.config.elevation_offset`
  );
  const queryKey = [
    projectId,
    frequencyMhz,
    azimuthOffset,
    elevationOffset,
    calculateScanLoss,
  ];
  const { refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      const fetchedLoss = await fetchScanLoss(
        projectId,
        frequencyMhz,
        azimuthOffset,
        elevationOffset
      );
      setValue(
        `${endName}.radios.${pathIndex}.antennas.0.config.scan_loss`,
        fetchedLoss,
        {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        }
      );
      return fetchedLoss;
    },
    retry: false,
    refetchOnWindowFocus: true,
    enabled: false,
  });

  return (
    <Form.Group>
      <ScanLossEditor
        endName={endName}
        pathIndex={pathIndex}
        readOnly={calculateScanLoss}
        refreshText={refreshText}
      />
      <Form.Field
        key={calculateAttrPath}
        style={{
          paddingTop: '1.8rem',
        }}
      >
        <Controller
          control={control}
          name={calculateAttrPath}
          defaultValue={calculateScanLoss}
          render={({ field: { ref, onChange, value, ...rest } }) => {
            return (
              <Form.Checkbox
                style={{ paddingTop: '0.4rem' }}
                label="Calculate?"
                onChange={(e, { checked }) => {
                  refetch();
                  setModified(true);
                  onChange(checked);
                  if (checked) {
                    setRefreshText((c) => c + 1);
                  }
                }}
                checked={value}
              />
            );
          }}
        />
      </Form.Field>
      <AzimuthOffset
        endName={endName}
        pathIndex={pathIndex}
        show={calculateScanLoss}
        formResetValue={resetValue}
        onAcceptChange={() => {
          refetch();
          setModified(true);
        }}
      />
      <ElevationOffset
        endName={endName}
        pathIndex={pathIndex}
        formResetValue={resetValue}
        show={calculateScanLoss}
        onAcceptChange={() => {
          refetch();
          setModified(true);
        }}
      />
    </Form.Group>
  );
}

export default ScanLoss;
