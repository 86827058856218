import { createColumnHelper } from "@tanstack/react-table";
import { useSelector } from "react-redux";
import {
  type NDTableData,
  columns as ndTableColumns,
} from "../NDTableColumns";
import React from "react";
import { SelectCell } from "./SelectCell";
import { getStickyColumnStyle } from "./usePtpColumns";
import { SELECT_WIDTH } from "./constants";

export function useNDColumns() {
  const prefs = useSelector((state: any) => state.mainFrame.prefs);
  const columnHelper = createColumnHelper<NDTableData>();

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => <SelectCell kind="header" table={table} />,
        cell: ({ row, table }) => <SelectCell kind="row" row={row} table={table} />,
        enableResizing: false,
        enableHiding: false,
        meta: {
          tdStyle: getStickyColumnStyle(SELECT_WIDTH, 0),
        },
      }),
      // TODO probably better to do this with mutation and findIndex
      // since we only want to modify the name column
      ...ndTableColumns.map((column) => ({
        ...column,
        meta: {
          ...column.meta,
          prefs,
          tdStyle:
            column.id === 'name'
              ? getStickyColumnStyle(null, SELECT_WIDTH)
              : column.meta?.tdStyle,
        },
        enableGrouping: column.id === 'name',
      })),
    ],
    [prefs]
  );

  return { columns };
}