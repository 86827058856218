import React, { useContext, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import { DESCRIPTION_MAX_LENGTH, NAME_MAX_LENGTH } from 'src/app.constants';
import StoredAccordion from 'src/components/StoredAccordion';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import messages from 'src/messages';
import Offset from './Offset';
import { get } from 'lodash';
import { FormResetContext } from '../utils';

function NDGeneralPanel(props) {
  const resetValue = useContext(FormResetContext);
  const { formatMessage } = useIntl();
  const { control, getFieldState } = useFormContext();
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const prefs = useSelector((state: any) => state.mainFrame.prefs);
  const { title, ap, sm, setModified, isAp = true } = props;
  const apOrSm = isAp ? ap : sm;
  const siteLink = isAp
    ? `/network_sites/${apOrSm.site.id}`
    : `/subscriber_sites/${apOrSm.site.id}`;
  const changeHandler = (fn) => (e) => {
    setModified(true);
    return fn(e);
  };
  const [resetLat, setResetLat] = useState(0);
  const [resetLng, setResetLng] = useState(0);
  const resetLatLng = () => {
    setResetLat((c) => c + 1);
    setResetLng((c) => c + 1);
    setModified(true);
  };
  let errors = false;
  const fields = [
    'radios.0.antennas.0.latitude',
    'radios.0.antennas.0.longitude',
    'offset',
    'properties.mac_address',
    'properties.msn',
  ];
  errors = fields.some((field) => {
    const { error } = getFieldState(field);
    return error;
  });
  return (
    <StoredAccordion
      name="nd_general_panel"
      title={title}
      headerClass={errors ? 'custom-error' : ''}
      contentProps={{ loading: apOrSm.dirty }}
    >
      <Controller
        control={control}
        name="name"
        defaultValue={apOrSm.name}
        render={({ field: { ref, onChange, ...rest } }) => (
          <Form.Input
            label="Name"
            {...rest}
            onChange={changeHandler(onChange)}
            disabled={!permissionWrite}
            maxLength={NAME_MAX_LENGTH}
          />
        )}
      />
      <div>
        <div>Site Name</div>
        <Link
          to={siteLink}
          title={`${formatMessage(messages.viewSite)}`}
          style={{ display: 'inline-block', margin: '10px 0 10px 8px' }}
        >
          {apOrSm.site.name}
        </Link>
      </div>
      {isAp && (
        <>
          <Form.Group widths="equal">
            <GenericScaledField
              key="latitude"
              label="Latitude"
              watch={[ap.id, resetLat, resetValue]}
              extraProps={{ isLat: true }}
              getter={'radios.0.antennas.0.latitude'}
              defaultValue={ap.site.latitude}
              setModified={setModified}
              width={16}
              units={prefs['latLngFormat']}
              disabled={!permissionWrite}
            />
            <GenericScaledField
              key="longitude"
              label="Longitude"
              watch={[ap.id, resetLng, resetValue]}
              getter={'radios.0.antennas.0.longitude'}
              extraProps={{ isLat: false }}
              defaultValue={ap.site.longitude}
              units={prefs['latLngFormat']}
              width={16}
              setModified={setModified}
              disabled={!permissionWrite}
            />
          </Form.Group>
          <Offset
            key="site.offset"
            ap={ap}
            resetLatLng={resetLatLng}
            heightUnits={prefs.heightUnits}
          ></Offset>
        </>
      )}

      <Form.Group widths="equal">
        <GenericScaledField
          key="radios.0.mac_address"
          defaultValue={get(apOrSm, 'radios.0.mac_address')}
          label="MAC Address"
          width={16}
          setModified={setModified}
          watch={resetValue}
          units={prefs['macAddressFormat']}
          getter="radios.0.mac_address"
          disabled={!permissionWrite}
        />
        <GenericScaledField
          key="properties.msn"
          defaultValue={apOrSm.properties.msn}
          label="MSN"
          width={16}
          units="msn"
          watch={resetValue}
          setModified={setModified}
          getter="properties.msn"
          disabled={!permissionWrite}
        />
      </Form.Group>
      <Controller
        control={control}
        name="description"
        defaultValue={apOrSm.description}
        render={({ field: { ref, onChange, ...rest } }) => (
          <Form.TextArea
            label="Description"
            {...rest}
            onChange={changeHandler(onChange)}
            disabled={!permissionWrite}
            maxLength={DESCRIPTION_MAX_LENGTH}
          />
        )}
      />
    </StoredAccordion>
  );
}

export default NDGeneralPanel;
