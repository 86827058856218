import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getGroupedRowModel,
  getFilteredRowModel,
  type ColumnDef,
  type RowSelectionState,
  Row,
} from '@tanstack/react-table';
import React, { useEffect } from 'react';
import { PathIndexed } from './constants';
import sortBy from 'lodash/sortBy';
import { get } from 'lodash';
import { TABLE_MAX_ROWS } from 'src/app.constants';

type TTooltipFunc<T> = {
  (row: Row<T>): string;
};

type TOptions<T> = {
  grouping?: string[];
  getTooltip?: TTooltipFunc<T>;
  defaultPinnedColumn?: string;
  pageSize?: Number;
};

function getWarning<T extends { warnings: any }>(row: Row<T>) {
  const hover = row.original?.warnings?.hover;
  if (hover) {
    return `Reason for error: ${hover}`;
  } else {
    return '';
  }
}

const DEFAULT_OPTIONS = {
  grouping: ['name'],
  getTooltip: getWarning,
};

function getColumnOrder<T>(columns: ColumnDef<T>[], visibleColumns: string[]) {
  const allColumnIds = columns.map((col) => col.id);
  const originalOrderMap = new Map(
    allColumnIds.map((id, index) => [id, index])
  );

  const visibleColumnIds = allColumnIds.filter((id) =>
    visibleColumns.includes(id)
  );
  const nonVisibleColumnIds = allColumnIds.filter(
    (id) => !visibleColumns.includes(id)
  );

  const sortedVisibleColumnIds = sortBy(visibleColumnIds, (id) =>
    visibleColumns.indexOf(id)
  );
  const sortedNonVisibleColumnIds = sortBy(nonVisibleColumnIds, (id) =>
    originalOrderMap.get(id)
  );

  return [...sortedVisibleColumnIds, ...sortedNonVisibleColumnIds];
}

// Helper function to get range of rows between two selected rows
// see: https://github.com/TanStack/table/discussions/3068#discussioncomment-12041689
function getRowRange<TData>(
  rows: Array<Row<TData>>,
  clickedRowID: string,
  previousClickedRowID: string,
  getOriginalId: (row: Row<TData>) => string
) {
  const flatUIRows = rows.flatMap((row) => {
    const result = [row];
    if (row.subRows && row.subRows.length > 0) {
      // see GroupedRow.tsx
      result.push(...sortBy(row.subRows, (r: any) => r.original.path_index));
    }
    return result;
  });

  // Find indices of our target rows in the flattened array
  const clickedRowIndex = flatUIRows.findIndex(
    (row) => getOriginalId(row) === clickedRowID
  );
  const prevRowIndex = flatUIRows.findIndex(
    (row) => getOriginalId(row) === previousClickedRowID
  );

  // If we can't find one of the rows, just return the clicked row
  if (
    clickedRowIndex === -1 ||
    (prevRowIndex === -1 && previousClickedRowID !== '')
  ) {
    return [
      flatUIRows.find((row) => getOriginalId(row) === clickedRowID),
    ].filter(Boolean) as Array<Row<TData>>;
  }

  // If no previous row was selected, just return the clicked row
  if (previousClickedRowID === '') {
    return [flatUIRows[clickedRowIndex]];
  }

  // Determine start and end indices for the range
  const startIdx = Math.min(clickedRowIndex, prevRowIndex);
  const endIdx = Math.max(clickedRowIndex, prevRowIndex);

  // Return the slice of rows between start and end indices (inclusive)
  return flatUIRows.slice(startIdx, endIdx + 1);
};

export function useLpTable<T extends PathIndexed>({
  data,
  visibleColumns,
  columns,
  getRowId,
  options,
}: {
  data: T[];
  visibleColumns: string[];
  columns: ColumnDef<T>[];
  getRowId: (row: T) => string;
  options?: TOptions<T>;
}) {
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  // Treated as the "anchor" row for range selection
  const [lastSelectedRowId, setLastSelectedRowId] = React.useState<
    string | null
  >(null);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: get(options, 'pageSize', TABLE_MAX_ROWS),
  });
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});

  const [columnOrder, setColumnOrder] = React.useState(
    columns.map((col) => col.id)
  );

  const defaultPinnedColumn = options?.defaultPinnedColumn ?? 'name';

  useEffect(() => {
    setColumnVisibility(
      Object.fromEntries(
        columns.map((c) => [
          c.id,
          c.id === 'select' || c.id === defaultPinnedColumn || visibleColumns.includes(c.id),
        ])
      )
    );

    // Unfortunately, we don't have the visibleColumns initially since
    // they come from an API request, so we have to fix the order in an effect
    setColumnOrder(getColumnOrder(columns, visibleColumns));
  }, [visibleColumns, defaultPinnedColumn]);

  const [isShowingAllRows, setIsShowingAllRows] = React.useState(false);
  const previousPageSizeRef = React.useRef(pagination.pageSize);

  useEffect(() => {
    // Adjust pagination when data length is within threshold (aka fuzzy pagination)
    if (data.length > 0) {
      const threshold = TABLE_MAX_ROWS * 1.1; // 10% larger than TABLE_MAX_ROWS
      if (
        data.length > TABLE_MAX_ROWS &&
        data.length <= Math.floor(threshold)
      ) {
        setPagination((prev) => ({
          ...prev,
          pageSize: data.length,
        }));
      }
    }
  }, [data.length]);

  const toggleShowAllRows = () => {
    if (isShowingAllRows) {
      table.setPageSize(previousPageSizeRef.current);
    } else {
      previousPageSizeRef.current = pagination.pageSize;
      table.setPageSize(data.length);
    }
    setIsShowingAllRows(!isShowingAllRows);
  };

  // Handle row selection with shift and control key modifiers
  const handleRowSelectionWithShift = (
    rowId: string,
    shiftKey: boolean,
    ctrlKey = false
  ) => {
    // Case 1: No modifiers
    if (!shiftKey && !ctrlKey) {
      // Check if the row is already selected
      if (rowSelection[rowId]) {
        // If already selected, remove it from selection
        const newSelection = { ...rowSelection };
        delete newSelection[rowId];
        setRowSelection(newSelection);
      } else {
        // Otherwise, clear selection and select only this row
        setRowSelection({ [rowId]: true });
        setLastSelectedRowId(rowId);
      }
      return;
    }

    // Case 2: Control key (without shift) - toggle selection of the clicked row and set as anchor
    if (ctrlKey && !shiftKey) {
      const newSelection = { ...rowSelection };
      if (newSelection[rowId]) {
        delete newSelection[rowId];
      } else {
        newSelection[rowId] = true;
        setLastSelectedRowId(rowId);
      }
      setRowSelection(newSelection);
      return;
    }

    // Case 3: Shift key - select range from anchor to clicked row
    if (shiftKey) {
      // If no anchor exists yet, treat as single selection
      if (!lastSelectedRowId) {
        setRowSelection({ [rowId]: true });
        setLastSelectedRowId(rowId);
        return;
      }

      const { rows } = table.getSortedRowModel();
      const rowsToSelect = getRowRange(
        rows,
        rowId,
        lastSelectedRowId,
        (row) => {
          if (row.parentId == null) {
            return row.id;
          }
          return getRowId(row.original);
        }
      );

      // Create a new selection object
      // If control is also pressed, keep existing selection, otherwise start fresh
      const newSelection = ctrlKey ? { ...rowSelection } : {};

      // Add all rows in the range to the selection
      rowsToSelect.forEach((row) => {
        const originalId = getRowId(row.original);
        newSelection[originalId] = true;
      });

      setRowSelection(newSelection);
    }
  };

  if (options == null) {
    options = { ...DEFAULT_OPTIONS } as any;
  } else {
    options = { ...DEFAULT_OPTIONS, ...options } as any;
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: true,
    enableSorting: true,
    initialState: {
      columnPinning: {
        left: ['select', defaultPinnedColumn],
      },
      sorting: [{ id: defaultPinnedColumn, desc: false }],
      grouping: options.grouping,
      expanded: true,
    },
    state: {
      rowSelection,
      pagination,
      columnFilters,
      globalFilter,
      columnVisibility,
      columnOrder,
    },
    aggregationFns: {
      firstChild: (id, _, childRows) => {
        return childRows[0].getValue(id);
      },
    },
    getRowId,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: 'auto',
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
  });

  return {
    table,
    getRowId,
    rowSelection,
    pagination,
    globalFilter,
    setGlobalFilter,
    columnOrder,
    setColumnOrder,
    isShowingAllRows,
    toggleShowAllRows,
    setLastSelectedRowId,
    handleRowSelectionWithShift,
    getTooltip: options.getTooltip,
    defaultPinnedColumn,
  };
}
