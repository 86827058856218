import { flexRender, Row } from '@tanstack/react-table';
import React from 'react';
import { TableRow } from './TableRow';
import { TableCell } from './TableCell';
import sortBy from 'lodash/sortBy';
import { type PathIndexed } from './constants';


type GroupedRowProps<T extends PathIndexed> = {
  row: Row<T>;
  onShiftClick?: (rowId: string, shiftKey: boolean, ctrlKey?: boolean) => void;
};

export function GroupedRow<T extends PathIndexed>({
  row,
  onShiftClick,
}: GroupedRowProps<T>) {
  // NOTE: LPWEB-513 - uncomment this to reintroduce visual row grouping
  return (
    <>
      {/* Render the group row - all cells after select and name will be empty */}
      {/* <TableRow
        row={row}
        className="lp-table-grouped"
        onShiftClick={onShiftClick}
        preventClick
        extraStyles={{
          // need the grouped bg colour here because it conflicts with other styles
          // when placed in the stylesheet
          backgroundColor: row.getIsSelected() ? '#dbeeff' : '#efefef',
        }}
      >
        {row.getVisibleCells().map((cell) => {
          const id = cell.column.columnDef.id!;

          if (!['name', 'select'].includes(id)) {
            return <td key={cell.id}></td>;
          }

          if (cell.getIsGrouped()) {
            // name is the only grouped column, so the cell within this branch is always name
            return (
              <TableCell
                key={cell.id}
                cell={cell}
                extraStyles={{ fontWeight: 'bold' }}
              >
                {flexRender(
                  // render the aggregated cell: for name this removes the
                  // path no. identifier (e.g. Link A, etc)
                  cell.column.columnDef.aggregatedCell,
                  cell.getContext()
                )}
              </TableCell>
            );
          }

          return (
            <TableCell key={cell.id} cell={cell}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          );
        })}
      </TableRow> */}

      {/* Render all subrows */}
      {sortBy(row.subRows, (subRow) => subRow.original.path_index).map(
        (subRow) => (
          <TableRow key={subRow.id} row={subRow} onShiftClick={onShiftClick}>
            {subRow.getVisibleCells().map((cell) => {
              const id = cell.column.columnDef.id;
              const cellContext = { ...cell.getContext(), isSubRow: true };

              return (
                <TableCell
                  key={cell.id}
                  cell={cell}
                  subRow={subRow}
                  // extraStyles={{
                  //   paddingLeft: id === 'name' ? '2rem' : undefined,
                  // }}
                >
                  {flexRender(cell.column.columnDef.cell, cellContext)}
                </TableCell>
              );
            })}
          </TableRow>
        )
      )}
    </>
  );
}
