import { throttle } from 'lodash';
import { queryClient } from 'src/query-client';

type FeatureKind =
  | 'network_site'
  | 'tower_switch'
  | 'subscriber_site'
  | 'ptp_link'
  | 'access_point'
  | 'mesh_link'
  | 'pmp_link';

export function createNetworkSiteTableQueryKey(projectId: string): string[] {
  return getTableQueryKey('network_site', projectId);
}

export function createSubscriberSiteTableQueryKey(projectId: string): string[] {
  return getTableQueryKey('subscriber_site', projectId);
}

export function createPTPTableQueryKey(projectId: string): string[] {
  return getTableQueryKey('ptp_link', projectId);
}

export function createAPTableQueryKey(projectId: string): string[] {
  return getTableQueryKey('access_point', projectId);
}

export function createMeshTableQueryKey(projectId: string): string[] {
  return getTableQueryKey('mesh_link', projectId);
}

export function createPMPLinkTableQueryKey(projectId: string): string[] {
  return getTableQueryKey('pmp_link', projectId);
}

export function getTableQueryKey(
  kind: FeatureKind,
  projectId: string
): string[] {
  if (kind === 'tower_switch') {
    // refresh the network_site table
    kind = 'network_site';
  }
  return ['project', projectId, kind, 'table'];
}

export function deleteFromTable(
  kind: FeatureKind,
  projectId: string,
  idsToDelete: string[]
) {
  const queryKey = getTableQueryKey(kind, projectId);

  if (queryKey) {
    queryClient.setQueryData(queryKey, (prev: any) => {
      if (prev != null) {
        const deletedIds = new Set(idsToDelete);
        return {
          ...prev,
          rows: prev.rows.filter((r: any) => !deletedIds.has(r.id)),
        };
      }
    });
  }
}

// export const refreshTable = throttle(
//   (kind: FeatureKind, projectId: string) => {
//     const queryKey = getTableQueryKey(kind, projectId);
//     queryClient.invalidateQueries(queryKey);
//   },
//   500
//   //   { leading: false }
//   //   { trailing: false }
// );
export function refreshTable(
  kind: FeatureKind,
  projectId: string,
  newRows?: Object[]
) {
  const queryKey = getTableQueryKey(kind, projectId);
  if (newRows && newRows.length > 0) {
    // The socket message has included the columns along with the geometry so
    // it is possible to refresh the table rather than requesting the
    // entire table again
    queryClient.setQueryData(queryKey, (prev) => {
      if (prev != null) {
        let rows = [];
        if (kind === 'pmp_link') {
          const rowIds = newRows.map((r) => r.remote_end_id);
          rows = prev.rows.filter((r) => !rowIds.includes(r.remote_end_id));
        } else {
          const rowIds = newRows.map((r) => r.id);
          rows = prev.rows.filter((r) => !rowIds.includes(r.id));
        }
        rows.push(...newRows);
        return { ...prev, rows };
      }
    });
    return;
  }

  // fall-back to refresh the whole table
  queryClient.invalidateQueries(queryKey);
}

export function getSiteGraphQueryKey(
  projectId: string,
  siteId: string
): string[] {
  return [projectId, 'site', siteId, 'graph'];
}

export function refreshSiteGraphTable(projectId: string, siteId: string) {
  const queryKey = getSiteGraphQueryKey(projectId, siteId);
  queryClient.invalidateQueries(queryKey);
}
