// This file is automatically generated by backend/app/scripts/generate_columns.py
import { createColumnHelper } from '@tanstack/react-table';
import * as U from './ColumnUtils';
import * as E from './editing/editors';

export type SubscriberSiteTableData = {

  name?: string;
  latitude?: number | 'N/A';
  longitude?: number | 'N/A';
  maximum_height?: number | 'N/A';
  description?: string;
  id: string;
  is_network_site: boolean | 'N/A';
  sm_count?: number | 'N/A';
}

const columnHelper = createColumnHelper<SubscriberSiteTableData>()

export const columns = [
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Name',
    aggregationFn: 'firstChild',
    cell: U.renderSubscriberSiteName<SubscriberSiteTableData>,
    size: 150,
    enableHiding: false,
  }),
  columnHelper.accessor('latitude', {
    id: 'latitude',
    header: 'Latitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 5, converter: U.latitude_Table }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 5, converter: U.latitude_Table }),
    },
  }),
  columnHelper.accessor('longitude', {
    id: 'longitude',
    header: 'Longitude',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 5, converter: U.longitude_Table }),
    size: 80,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 5, converter: U.longitude_Table }),
    },
  }),
  columnHelper.accessor('maximum_height', {
    id: 'maximum_height',
    header: U.headerWithHeightUnits('Maximum Height'),
    filterFn: U.heightFilterFn({ precision: 1 }),
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 1, prefsKey: 'heightUnits' }),
      editable: true,
      choicesKind: 'site',
      attr: 'maximum_height',
      columnId: 'maximum_height',
      editorLabel: 'Maximum Height',
      kind: 'height',
      editor: E.NumberEditor,
      editProps: {
        units: 'heightUnits',
        min: 0,
        max: 3000,
        precision: 1,
      },
    },
  }),
  columnHelper.accessor('description', {
    id: 'description',
    header: 'Description',
    aggregationFn: 'firstChild',
    meta: {
      editable: true,
      choicesKind: 'site',
      attr: 'description',
      columnId: 'description',
      editorLabel: 'Description',
      kind: 'string',
      editor: E.TextEditor,
    },
  }),
  columnHelper.accessor('sm_count', {
    id: 'sm_count',
    header: 'PMP Links',
    filterFn: 'includesString',
    sortingFn: 'alphanumeric',
    cell: U.renderNumber({ precision: 0 }),
    size: 60,
    aggregationFn: 'min',
    meta: {
      tdStyle: { textAlign: 'right' },
      csv: U.renderNumber({ precision: 0 }),
    },
  }),
]
