import React from 'react';
import { Placeholder } from 'semantic-ui-react';

type TableSkeletonProps = {
  rows: number;
  columns: number;
};

const TableSkeleton: React.FC<TableSkeletonProps> = ({ rows, columns }) => {
  return (
    <div className="lp-table-wrapper">
      <table className="lp-table">
        <thead>
          <tr>
            {Array.from({ length: columns }).map((_, index) => (
              <th key={index}>
                <Placeholder>
                  <Placeholder.Line />
                </Placeholder>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: rows }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {Array.from({ length: columns }).map((_, colIndex) => (
                <td key={colIndex}>
                  <Placeholder>
                    <Placeholder.Line />
                  </Placeholder>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
