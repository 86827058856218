import { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Dropdown, DropdownProps, Form, Label, Popup } from 'semantic-ui-react';
import { ColDef, GridOptions } from 'ag-grid-community';
import YesNoRenderer from 'src/components/controls/lpgrid/cellrenderers/YesNoRenderer';
import { ProductPanel } from 'src/utils/types/commonTypes';
export interface FormSelectTableType extends DropdownProps {
  gridRowData: Array<Object>;
  columns: ColDef[];
  labelFormatter: Function;
  gridOptions?: GridOptions;
  cssClass?: string;
  value: string; // value to be displayed in dropdown
  values?: any;
  panels?: ProductPanel[];
  warning?: string;
  modified?: boolean;
  disabled?: boolean;
  defaultOpen?: boolean;
  setCanClose?: (canClose: boolean) => void;
}

function FormSelectTable({
  gridRowData = [],
  columns,
  onChange,
  onClose,
  values,
  panels = [],
  labelFormatter,
  gridOptions = {},
  value,
  warning,
  modified,
  disabled = false,
  defaultOpen = false,
  setCanClose,
}: FormSelectTableType) {
  const rowData =
    gridRowData.length && gridRowData.filter((row: any) => row.id === value)[0];
  const dropdownLabel = !isEmpty(rowData) && labelFormatter(rowData['rawData']);
  const gridRef = useRef(null);
  const popupRef = useRef(null);
  const [popupOpen, setPopupOpen] = useState(defaultOpen);
  const options = [
    {
      key: 1,
      text: dropdownLabel,
      value: 1,
    },
  ];

  if (gridRowData.length == 1 || disabled) {
    return (
      <Form.Field>
        <div>{dropdownLabel}</div>
        {warning != null && warning !== '' && (
          <Label pointing prompt>
            {warning}
          </Label>
        )}
      </Form.Field>
    );
  } else {
    return (
      <Popup
        hoverable
        ref={popupRef}
        open={popupOpen}
        className="form-select-table"
        style={{ width: '600px' }}
        hideOnScroll
        flowing
        on={'click'}
        onClose={() => {
          setPopupOpen(false);
          setCanClose?.(true);
        }}
        closeOnPortalMouseLeave={false}
        closeOnTriggerBlur={false}
        closeOnDocumentClick
        trigger={
          <div className="field">
            <Dropdown
              onOpen={() => {
                setPopupOpen(true);
                setCanClose?.(false);
              }}
              selection
              className={`form-select-table`}
              onClose={onClose}
              defaultValue={options[0].value}
              options={options}
            />
            {warning != null && warning !== '' && (
              <Label pointing prompt={!modified} basic={modified} color="blue">
                {warning}
              </Label>
            )}
          </div>
        }
      >
        <div className="ag-theme-alpine generic-grid">
          <AgGridReact
            ref={gridRef}
            rowData={gridRowData}
            headerHeight={30}
            suppressDragLeaveHidesColumns={true}
            pagination={false}
            rowSelection={'single'}
            getRowNodeId={(data) => {
              return data.id;
            }}
            onFirstDataRendered={({ api }) => {
              const rowNode = api.getRowNode(value);
              if (rowNode) {
                rowNode.setSelected(true);
              }
              api.sizeColumnsToFit();
            }}
            rowHeight={16}
            domLayout={'autoHeight'}
            onRowSelected={({ data, node }) => {
              const { rawData } = data;
              if (value != rawData.id) {
                //constructing the event object
                // TODO: possibly js provide api to create event object,
                // need to check it
                const event: any = {
                  target: {
                    value: rawData.id,
                  },
                };
                rawData.value = rawData.id;
                rawData.values = values;
                rawData.panels = panels;
                onChange(event, rawData);
                setPopupOpen(false);
                setCanClose?.(true);
              }
            }}
            columnDefs={columns}
            frameworkComponents={{ yesNoRenderer: YesNoRenderer }}
            {...gridOptions}
          ></AgGridReact>
        </div>
      </Popup>
    );
  }
}

export default FormSelectTable;
