import React from 'react';
import { Segment } from 'semantic-ui-react';
import PMPLinksPanel from '../PMPLinksPanel';
import messages from 'src/messages';
import { useIntl } from 'react-intl';

function SubscriberModulesTable({ modified, needsRefresh, id }) {
  const { formatMessage } = useIntl();
  return (
    <>
      {modified || needsRefresh ? (
        <Segment inverted className="update-required">
          {formatMessage(messages.saveChangesPanel, {
            panelName: 'subscriber modules',
          })}
        </Segment>
      ) : null}
      <Segment basic disabled={modified}>
        <PMPLinksPanel
          apId={id}
          tableId="links_to_subscriber_table"
          defaultPinnedColumn="sm_name"
          pageSize={10}
        ></PMPLinksPanel>
      </Segment>
    </>
  );
}

export default SubscriberModulesTable;
