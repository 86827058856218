import React from 'react';
import { type ColumnMeta, type Row } from '@tanstack/react-table';
import { Select } from 'semantic-ui-react';
import { zip } from 'src/utils/useful_functions';

type TProps<T extends { id: string }, U> = {
  getChoices: (
    projectId: string,
    row: Row<T>
  ) => Promise<{ choices: string[] }>;
  projectId: string;
  row: Row<T>;
  meta: ColumnMeta<T, U>;
  value: U;
  setValue: (value: U) => void;
};

export function BooleanEditor<
  T extends { id: string },
  U extends string | number | boolean
>({
  meta,
  value,
  setValue,
}: TProps<T, U>) {
  const { editorLabel: label } = meta;
  const metaOpts = meta.boolOptions ?? ['Yes', 'No'];
  const choices = zip(metaOpts, [true, false]).map(([text, value]) => ({
    text,
    value,
  }));

  return (
    <div>
      <label
        style={{ fontWeight: 'bold', marginRight: '1rem', display: 'block' }}
      >
        {label}
      </label>
      <Select
        value={value}
        options={choices.map(({ text, value }) => ({
          key: text,
          text,
          value,
        }))}
        onChange={(_, { value }) => setValue(value as U)}
        style={{ marginBottom: '0.5rem' }}
      />
    </div>
  );
}
