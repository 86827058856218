import React, { useState } from 'react';
import { Segment, Message, Button, Popup } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { useCSVDownloader } from 'react-papaparse';
import { store } from '../../store';
import { postWithAuth, getWithAuth } from '../../api';
import additionalMessages from '../../messages';
import { uiConfirmAction } from '../mainframe/mainframe.reducer';
import { makePlural } from '../../utils/useful_functions';
import NetworkSiteBomModal from 'src/pages/sites/networksites/NetworkSiteBomModal';
import { toast } from 'react-toastify';
import { TOAST_AUTOCLOSE } from '../terragraph/TerragraphPanel';
import { PanelHeading } from 'src/components/PanelHeading';
import { useSelector } from 'react-redux';
import NewSiteModal from './NewSiteModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSiteColumns } from 'src/components/tables/internal/useSiteColumns';
import { useLpTable } from 'src/components/tables/internal/useLpTable';
import { getCSVData } from 'src/components/tables/ColumnUtils';
import { LPTable } from 'src/components/tables/LPTable';
import messages from '../../messages';
import {
  createNetworkSiteTableQueryKey,
  createSubscriberSiteTableQueryKey,
} from 'src/components/tables/internal/TableQueryKeys';

const DEFAULT_STATE = {
  showNewSiteModal: false,
  showTowerSwitch: false,
};

function refreshSitesQuery(queryClient, projectId, isNetworkSite) {
  const qk = isNetworkSite ? 'network_sites' : 'subscriber_sites';
  queryClient.invalidateQueries(['project', projectId, qk, 'table']);
}

const deleteSites = (
  formatMessage,
  queryClient,
  projectId,
  selectedItems,
  panelKind
) => {
  store.dispatch(
    uiConfirmAction({
      header: formatMessage(additionalMessages.deleteSites),
      message: formatMessage(additionalMessages.confirm),
      size: 'mini',
      onConfirm: () => {
        postWithAuth(
          `project/${projectId}/sites`,
          {
            kind: panelKind,
            ids: selectedItems,
          },
          'DELETE'
        ).then(() => {
          const isNetworkSite = panelKind === 'network_site';
          refreshSitesQuery(queryClient, projectId, isNetworkSite);
        });
      },
    })
  );
};

function deleteTowerSwitches(
  queryClient,
  selectedRows,
  projectId,
  formatMessage
) {
  const selectedSiteIds = selectedRows.map((row) => row.id);
  store.dispatch(
    uiConfirmAction({
      header: 'Delete Tower Switches',
      message: formatMessage(additionalMessages.confirm),
      size: 'mini',
      onConfirm: () => {
        // TODO update this to work with multiple sites
        postWithAuth(
          `project/${projectId}/site/${selectedSiteIds[0]}/tower_switch`,
          {
            site_ids: selectedSiteIds.slice(1),
          },
          'DELETE'
        ).then(() => {
          refreshSitesQuery(queryClient, projectId, true);
        });
      },
    })
  );
}

const convertSites = (
  queryClient,
  selectedRows,
  projectId,
  isNetworkSite,
  isSiteConversion = false
) => {
  const sites = selectedRows.map((row) => {
    return {
      name: row.original.name,
      latitude: row.original.latitude,
      longitude: row.original.longitude,
      // if we are currently on a network site
      // then convert to subscriber site
      is_network_site: !isNetworkSite,
      maximum_height: row.original.maximum_height,
      description: row.original.description,
    };
  });

  let errorMsg = '';
  postWithAuth(`project/${projectId}/sites`, sites, 'POST').catch((err) => {
    errorMsg = err.detail;
    console.error(err);
  });

  if (errorMsg) {
    toast(<Message negative>{errorMsg}</Message>, {
      autoClose: false,
    });
  } else {
    const label = makePlural(sites.length, 'site', 'sites');
    const changeType = isSiteConversion ? 'converted' : 'duplicated';
    const message = `${sites.length} ${label} ${changeType}`;

    toast(<Message positive>{message}</Message>, {
      autoClose: TOAST_AUTOCLOSE,
    });
  }
};

const SitesPanel = ({ heading, panelKind, groupKind }) => {
  const { formatMessage } = useIntl();

  const [currentState, setState] = useState(DEFAULT_STATE);
  const { showNewSiteModal, showTowerSwitch } = currentState;

  const [projectId, networkSiteCount, subscriberSiteCount] = useSelector(
    (state) => [
      state.mainFrame.projectId,
      state.mainFrame.networkSiteCount,
      state.mainFrame.subscriberSiteCount,
    ]
  );

  const itemCount = {
    network_sites: networkSiteCount,
    subscriber_sites: subscriberSiteCount,
  }[groupKind];

  const queryKey =
    groupKind === 'network_sites'
      ? createNetworkSiteTableQueryKey(projectId)
      : createSubscriberSiteTableQueryKey(projectId);
  const { data: tableData, refetch } = useQuery({
    queryKey,
    initialData: { rows: [], visible: [] },
    queryFn: () => getWithAuth(`project/${projectId}/sites?kind=${panelKind}`),
  });

  const { columns } = useSiteColumns(groupKind);
  const tableModel = useLpTable({
    data: tableData.rows,
    visibleColumns: tableData.visible,
    columns,
    getRowId: (row) => row.id,
    options: {
      // don't group sites
      grouping: [],
    },
  });

  const updateState = (changes) => {
    setState((prev) => ({ ...prev, ...changes }));
  };

  const selectedSiteIds = tableModel.table
    .getSelectedRowModel()
    .rows.map((row) => row.original.id);
  // for tower switch modal:
  let selectedSiteId;
  if (selectedSiteIds.length > 0) {
    selectedSiteId = selectedSiteIds[0];
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <NewSiteModal
        siteKind={panelKind}
        open={showNewSiteModal}
        close={() => updateState({ showNewSiteModal: false })}
      />

      {showTowerSwitch && (
        <NetworkSiteBomModal
          objId={selectedSiteId}
          kind="network_site"
          url={`project/${projectId}/site/${selectedSiteId}/bom`}
          tsurl={`project/${projectId}/site/${selectedSiteId}/tower_switch`}
          selectedSiteIds={selectedSiteIds}
          tableViewShowTowerSwitch={showTowerSwitch}
          tableViewResetShowTowerSwitch={(value) => {
            updateState({
              showTowerSwitch: value,
            });
            refetch();
          }}
        />
      )}

      <PanelHeading
        title={
          <>
            {formatMessage(heading)} ({itemCount})
          </>
        }
      />

      <div className="detailWrapper" style={{ overflow: 'auto', flex: '1' }}>
        <Segment basic style={{ width: '100%' }}>
          <LPTable queryKey={queryKey} model={tableModel} tableId={panelKind}>
            <LPTable.Toolbar>
              <SitesToolbar
                formatMessage={formatMessage}
                updateState={updateState}
                tableModel={tableModel}
                panelKind={panelKind}
              />
            </LPTable.Toolbar>
          </LPTable>
        </Segment>
      </div>
    </div>
  );
};

function checkUserLimits({
  selectedSiteCount,
  existingSitesCount,
  permittedSitesCount,
}) {
  if (selectedSiteCount + existingSitesCount > permittedSitesCount) {
    return false;
  }
  return true;
}
function SitesToolbar(props) {
  const { formatMessage, updateState, tableModel, panelKind } = props;

  const { CSVDownloader } = useCSVDownloader();
  const queryClient = useQueryClient();

  const [
    projectId,
    projectName,
    permissionWrite,
    userLimits,
    networkSiteCount,
    subscriberSiteCount,
  ] = useSelector((state) => [
    state.mainFrame.projectId,
    state.mainFrame.projectName,
    state.mainFrame.permissionWrite,
    state.mainFrame.userLimits,
    state.mainFrame.networkSiteCount,
    state.mainFrame.subscriberSiteCount,
  ]);

  const isNetworkSite = panelKind === 'network_site';
  const selectedSites = tableModel.table.getSelectedRowModel().rows;
  const enableConversionBtn = checkUserLimits({
    selectedSiteCount: selectedSites.length,
    permittedSitesCount: isNetworkSite
      ? userLimits?.subscriber_sites
      : userLimits?.network_sites,
    existingSitesCount: isNetworkSite ? subscriberSiteCount : networkSiteCount,
  });
  return (
    <>
      {/* Common actions */}
      <Button
        icon="add"
        content={formatMessage(additionalMessages.add)}
        onClick={() => updateState({ showNewSiteModal: true })}
        disabled={!permissionWrite}
      />
      <Button
        icon="trash alternate"
        content={formatMessage(additionalMessages.delete)}
        disabled={!permissionWrite || selectedSites.length === 0}
        onClick={() => {
          const selectedSiteIds = selectedSites.map((row) => row.original.id);
          if (selectedSiteIds.length > 0) {
            deleteSites(
              formatMessage,
              queryClient,
              projectId,
              selectedSiteIds,
              panelKind,
              updateState
            );
          }
        }}
      />

      {/* Network Site only actions (stuff to do with switches) */}
      {isNetworkSite ? (
        <NetworkSitesToolbar
          queryClient={queryClient}
          projectId={projectId}
          selectedSites={selectedSites}
          permissionWrite={permissionWrite}
          updateState={updateState}
          formatMessage={formatMessage}
        />
      ) : null}

      {/* convert site actions */}
      <Popup
        content={formatMessage(messages.limitExceededError, {
          entityName: isNetworkSite ? 'Subscriber Site' : 'Network Site',
          limit: isNetworkSite
            ? userLimits?.subscriber_sites
            : userLimits?.network_sites,
        })}
        disabled={enableConversionBtn}
        trigger={
          <span>
            <Button
              icon="exchange"
              content={`Convert To ${
                isNetworkSite ? 'Subscriber' : 'Network'
              } Site`}
              disabled={
                !permissionWrite ||
                selectedSites.length === 0 ||
                !enableConversionBtn
              }
              onClick={() => {
                store.dispatch(
                  uiConfirmAction({
                    header: 'Convert Site?',
                    message: isNetworkSite
                      ? formatMessage(
                          additionalMessages.convertNetworkSiteMessage
                        )
                      : formatMessage(
                          additionalMessages.convertSubscriberSiteMessage
                        ),
                    size: 'mini',
                    onConfirm: () => {
                      convertSites(
                        queryClient,
                        selectedSites,
                        projectId,
                        isNetworkSite,
                        true
                      );

                      postWithAuth(
                        `project/${projectId}/sites`,
                        {
                          kind: panelKind,
                          ids: selectedSites.map((row) => row.original.id),
                        },
                        'DELETE'
                      ).then(() => {
                        refreshSitesQuery(
                          queryClient,
                          projectId,
                          isNetworkSite
                        );
                      });
                    },
                  })
                );
              }}
            />
          </span>
        }
      />
      <Popup
        content={formatMessage(messages.limitExceededError, {
          entityName: isNetworkSite ? 'Subscriber Site' : 'Network Site',
          limit: isNetworkSite
            ? userLimits?.subscriber_sites
            : userLimits?.network_sites,
        })}
        disabled={enableConversionBtn}
        trigger={
          <span>
            <Button
              icon="exchange"
              content={`Create Duplicate ${
                isNetworkSite ? 'Subscriber' : 'Network'
              } Site`}
              disabled={
                !permissionWrite ||
                selectedSites.length === 0 ||
                !enableConversionBtn
              }
              onClick={() => {
                convertSites(
                  queryClient,
                  selectedSites,
                  projectId,
                  isNetworkSite,
                  false
                );
              }}
            />
          </span>
        }
      ></Popup>
      <CSVDownloader
        filename={`${projectName}_${panelKind}s`}
        bom={true}
        config={{ delimiter: ',' }}
        data={() => getCSVData(tableModel.table)}
      >
        <Button
          icon="cloud download"
          content="Download CSV"
          disabled={tableModel.table.getRowCount() === 0}
        />
      </CSVDownloader>
    </>
  );
}

function NetworkSitesToolbar(props) {
  // Specific actions for Network Sites
  const {
    queryClient,
    projectId,
    selectedSites,
    permissionWrite,
    updateState,
    formatMessage,
  } = props;

  return (
    <>
      <Button
        icon="plus"
        content="Add/Edit Tower Switch"
        disabled={!permissionWrite || selectedSites.length === 0}
        onClick={() => {
          updateState({
            selectedSiteId: selectedSites[0].id,
            showTowerSwitch: true,
            selectedSiteIds: selectedSites.map((row) => row.original.id),
          });
        }}
      />

      <Button
        icon="trash alternate"
        content="Delete Tower Switch"
        disabled={!permissionWrite || selectedSites.length === 0}
        onClick={() => {
          deleteTowerSwitches(
            queryClient,
            selectedSites,
            projectId,
            formatMessage
          );
        }}
      />
    </>
  );
}

export const NetworkSitesPanel = (props) => (
  <SitesPanel
    heading={additionalMessages.networkSites}
    path="/network_sites"
    panelKind="network_site"
    groupKind="network_sites"
  />
);

export const SubscriberSitesPanel = (props) => (
  <SitesPanel
    heading={additionalMessages.subscriberSites}
    path="/subscriber_sites"
    panelKind="subscriber_site"
    groupKind="subscriber_sites"
  />
);
