import React from 'react';
import { Table } from '@tanstack/react-table';

export function useColumnSizing<T>({ table }: { table: Table<T> }) {
  const { columnSizingInfo, columnSizing } = table.getState();
  const isResizingColumn = columnSizingInfo.isResizingColumn;

  /**
   * Instead of calling `column.getSize()` on every render for every header
   * and especially every data cell (very expensive),
   * we will calculate all column sizes at once at the root table level in a useMemo
   * and pass the column sizes down as CSS variables to the <table> element.
   * c.f. https://tanstack.com/table/latest/docs/guide/column-sizing
   */
  const columnSizeVars = React.useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [columnSizingInfo, columnSizing]);

  return { columnSizeVars, isResizingColumn };
}
