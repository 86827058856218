import React from 'react';
import { AggregationFn, createColumnHelper } from '@tanstack/react-table';
import { useSelector } from 'react-redux';
import {
  type PTPTableData,
  columns as ptpTableColumns,
} from '../PTPTableColumns';
import { SELECT_WIDTH, POSITION_STICKY } from './constants';
import { SelectCell } from './SelectCell';

declare module '@tanstack/react-table' {
  interface AggregationFns {
    firstChild: AggregationFn<unknown>;
  }
}

export function getStickyColumnStyle(width: number | null, left: number) {
  return {
    // keep the type checker happy
    position: POSITION_STICKY,
    left,
    backgroundColor: 'inherit',
    zIndex: 2,
    width: width ? `${width}px` : undefined,
    minWidth: width ? `${width}px` : undefined,
  };
}

export function usePtpColumns() {
  const prefs = useSelector((state: any) => state.mainFrame.prefs);
  const columnHelper = createColumnHelper<PTPTableData>();

  // useMemo is recommended by the tanstack docs as it gives the
  // columns a stable reference (and they also need to be somewhat
  // dynamic to allow the prefs to be passed in)
  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => <SelectCell kind="header" table={table} />,
        cell: ({ row, table }) => (
          <SelectCell kind="row" row={row} table={table} />
        ),
        enableResizing: false,
        enableHiding: false,
        meta: {
          tdStyle: getStickyColumnStyle(SELECT_WIDTH, 0),
        },
      }),
      // TODO probably better to do this with mutation and findIndex
      // since we only want to modify the name column
      ...ptpTableColumns.map((column) => ({
        ...column,
        meta: {
          ...column.meta,
          prefs,
          tdStyle:
            column.id === 'name'
              ? getStickyColumnStyle(null, SELECT_WIDTH)
              : column.meta?.tdStyle,
        },
        enableGrouping: column.id === 'name',
      })),
    ],
    [prefs]
  );

  return { columns };
}
