import React from 'react';
import { ColumnMeta } from '@tanstack/react-table';
import { Input } from 'semantic-ui-react';

type TProps<T extends { id: string }, U> = {
  meta: ColumnMeta<T, U>;
  value: U;
  setValue: (value: U) => void;
};

export function TextEditor<
  T extends { id: string },
  U extends string | number | boolean
>({ meta, value, setValue }: TProps<T, U>) {
  const { editorLabel: label } = meta;

  return (
    <div>
      <label
        style={{ fontWeight: 'bold', marginRight: '1rem', display: 'block' }}
      >
        {label}
      </label>
      <Input
        value={value}
        onChange={(_, { value }) => setValue(value as U)}
        style={{ marginBottom: '0.5rem' }}
        onFocus={(e) => e.target.select()}
        autoFocus
      />
    </div>
  );
}
