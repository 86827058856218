import { get } from 'lodash';
import { createContext } from 'react';
import { bands } from 'src/model/BandDefinition';
import { extractBandwidth, fixedPrecision } from 'src/utils/useful_functions';

export const powerFieldInfoFormatter = (values, path): string | null => {
  const max = get(values, path);
  if (max) {
    if (path.includes('eirp')) {
      return `(Limit is ${fixedPrecision(max, 1)} dBm)`;
    } else {
      return `Max power is ${fixedPrecision(max, 1)} dBm`;
    }
  } else {
    return null;
  }
};

export function interferenceInfoFormatter(formGetter, path): string | null {
  const bandwidth = formGetter(path);
  if (bandwidth) {
    return `in ${bandwidth} channel`;
  } else {
    return null;
  }
}

export function getBandwidthAdjustment(bw: number) {
  return 10 * Math.log10(bw);
}

export function getDefaultNoise(band: string, bw: string) {
  const noiseDensity = bands[band]['noiseDensity'];
  const bwNumber = extractBandwidth(bw);
  const bwAdjustment = getBandwidthAdjustment(bwNumber);
  return noiseDensity + bwAdjustment;
}
// noise = noiseDensity + bwAdjustment
// noiseDensity = noise - bwAjustment
export function calculateNoiseDensity(noise: number, bw: string) {
  const bwNumber = extractBandwidth(bw);
  const bwAdjustment = getBandwidthAdjustment(bwNumber);
  return noise - bwAdjustment;
}

export function calculateNoise(noiseDensity: number, bw: string) {
  const bwNumber = extractBandwidth(bw);
  const bwAdjustment = getBandwidthAdjustment(bwNumber);
  return noiseDensity + bwAdjustment;
}

export const FormResetContext = createContext(0);
