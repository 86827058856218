import React from 'react';
import { ColumnHelper, createColumnHelper } from '@tanstack/react-table';
import { useSelector } from 'react-redux';
import {
  type NetworkSiteTableData,
  columns as networkSiteTableColumns,
} from '../NetworkSiteTableColumns';
import { SELECT_WIDTH } from './constants';
import { SelectCell } from './SelectCell';
import { getStickyColumnStyle } from './usePtpColumns';
import {
  type SubscriberSiteTableData,
  columns as subscriberSiteTableColumns,
} from '../SubscriberSiteTableColumns';

function makeSiteColumns<T>(
  columnHelper: ColumnHelper<T>,
  tableColumns: any,
  prefs: any
) {
  return [
    columnHelper.display({
      id: 'select',
      header: ({ table }) => <SelectCell kind="header" table={table} />,
      cell: ({ row, table }) => <SelectCell kind="row" row={row} table={table} />,
      enableResizing: false,
      enableHiding: false,
      meta: {
        tdStyle: getStickyColumnStyle(SELECT_WIDTH, 0),
      },
    }),
    // TODO probably better to do this with mutation and findIndex
    // since we only want to modify the name column
    ...tableColumns.map((column) => ({
      ...column,
      meta: {
        ...column.meta,
        prefs,
        tdStyle:
          column.id === 'name'
            ? getStickyColumnStyle(null, SELECT_WIDTH)
            : column.meta?.tdStyle,
      },
      enableGrouping: column.id === 'name',
    })),
  ];
}

export function useSiteColumns(
  groupKind: 'network_sites' | 'subscriber_sites'
) {
  const prefs = useSelector((state: any) => state.mainFrame.prefs);

  let columnHelper: any;
  let tableColumns: any;
  if (groupKind === 'network_sites') {
    columnHelper = createColumnHelper<NetworkSiteTableData>();
    tableColumns = networkSiteTableColumns;
  } else {
    columnHelper = createColumnHelper<SubscriberSiteTableData>();
    tableColumns = subscriberSiteTableColumns;
  }

  const columns = React.useMemo(
    () => makeSiteColumns(columnHelper, tableColumns, prefs),
    [prefs, columnHelper, groupKind]
  );

  return { columns };
}
