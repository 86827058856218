import { get } from 'lodash';
import { PMPFormPanel, syncFormState } from '../utils';
import NDEquipmentPanel from '../NDEquipmentPanel';
import NDAntennaPanel from '../NDAntennaPanel';
import NDPowerPanel from '../NDPowerPanel';
import {
  antennaHeightND,
  antennaSelection,
  cableLoss,
  equipmentPanelFields,
  smRegistrationLimit,
  modeledBeamwidth,
  smRangeFieldsWithRefreshChoices,
  smReceiveTargetLevel,
  ndInterference,
  smDefaultInterference,
  bandwidth,
  azimuth,
  tilt,
  getNDUserPower,
} from './config-utils';
import {
  cableChoices,
  cableLength,
  calculateLoss,
  gasGroup,
  GasGroupsSpectra,
  getEIRPConfig,
  getPowerConfig,
} from '../../config-utils';

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'nd_equipment',
  component: NDEquipmentPanel,
  fields: [...equipmentPanelFields],
};

const product: PMPFormPanel = {
  kind: 'product',
  title: 'Product Configuration',
  name: 'nd_Product',
  component: NDEquipmentPanel,
  fields: [
    { ...bandwidth, refreshesChoices: true },
    {
      attrName: 'color_code',
      getter: 'radios.0.equipment.color_code',
    },
    ...smRangeFieldsWithRefreshChoices,
    { ...smRegistrationLimit, refreshesChoices: true },
    {
      label: 'Sync',
      attrName: 'sync',
      getter: 'radios.0.equipment.sync',
      refreshesChoices: true,
      warning: ({ ap, formGetter }) => {
        const syncSource = formGetter('radios.0.equipment.sync');
        if (
          !get(ap, 'site.has_tx2000_switch') &&
          syncSource.toLocaleLowerCase() === 'tx2000'
        ) {
          return 'Please add a TX2000 switch to the site.';
        }
        return '';
      },
    },
    {
      attrName: 'symmetry',
      getter: 'radios.0.equipment.symmetry',
      refreshesChoices: true,
    },
    {
      attrName: 'dual_payload',
      getter: 'radios.0.equipment.dual_payload',
      refreshesChoices: true,
    },
    {
      attrName: 'highest_mod_mode',
      getter: 'radios.0.equipment.highest_mod_mode',
      refreshesChoices: true,
    },
    {
      attrName: 'lowest_ethernet_mode',
      getter: 'radios.0.equipment.lowest_ethernet_mode',
      refreshesChoices: true,
    },
  ],
};

const antenna: PMPFormPanel = {
  kind: 'antenna',
  title: 'Antenna Configuration',
  name: 'nd_antenna',
  component: NDAntennaPanel,
  fields: [
    antennaSelection,
    modeledBeamwidth,
    antennaHeightND,
    azimuth,
    tilt,
    calculateLoss,
    cableChoices,
    cableLength,
    cableLoss,
  ],
};

const powerPanel: PMPFormPanel = {
  kind: 'power',
  title: 'Power',
  name: 'nd_power',
  component: NDPowerPanel,
  fields: [
    {
      ...gasGroup,
      componentProps({ formGetter }) {
        let gasGroup = formGetter('radios.0.power.gas_group');
        if (!GasGroupsSpectra.map((gg) => gg.value).includes(gasGroup)) {
          // reset when switching from PMP 450i ATEX/HAZLOC
          gasGroup = 'A';
        }
        return {
          options: GasGroupsSpectra,
          value: gasGroup,
        };
      },
    },
    getEIRPConfig(),
    getPowerConfig(),
    getNDUserPower(false),
    { ...smReceiveTargetLevel, label: 'ATPC HCMP Master Target Rx Power' },
    ndInterference,
    smDefaultInterference,
  ],
};

export const PTP670HCMPViewConfig = {
  syncFormState,
  equipment,
  product,
  antenna,
  power: powerPanel,
};
