import sortBy from 'lodash/sortBy';
import React from 'react';
import { Link } from 'react-router-dom';
import StoredAccordion from 'src/components/StoredAccordion';
import { BasicTable } from 'src/components/tables/basic/BasicTable';

function PMPLinksTableAccordion({ paths = [] }) {
  const pmpLinks = paths.filter((p) => p.kind === 'pmp');
  return (
    <StoredAccordion title="PMP Links">
      <BasicTable
        columns={[
          {
            header: 'PMP Network Device',
            field: 'local_name',
            render: (row) => (
              <Link to={`/aps/${row.local_id}`}>{row.local_name}</Link>
            ),
          },
          {
            header: 'Subscriber',
            field: 'remote_name',
            render: (row) => (
              <Link to={`/subscribers/${row.remote_id}`}>
                {row.remote_name}
              </Link>
            ),
          },
        ]}
        data={sortBy(pmpLinks, 'local_name')}
      />
    </StoredAccordion>
  );
}

export default PMPLinksTableAccordion;
