import { merge } from 'lodash';
import React, { useContext } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { postWithAuth } from 'src/api';
import { CUSTOM_ANTENNA_OTHER } from 'src/app.constants';
import CalcTiltModal from 'src/components/controls/CalcTiltModal';
import CustomTextField from 'src/components/controls/rhf/CustomTextField';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import {
  setAntennaPolarities,
  openAntennaModal,
} from 'src/pages/antennas/antenna.reducer';
import AntennaSelection from 'src/pages/equipment/common/AntennaSelection';
import { store } from 'src/store';
import { makeChoices } from 'src/utils/useful_functions';
import ModeledBeamwidth from './ModeledBeamwidth';
import { getWarningLabel } from './NDAntennaPanel';
import { FormResetContext } from '../utils';

function AntennaField(props) {
  const {
    ap,
    sm,
    intl,
    choices,
    setModified,
    modified,
    refreshChoices,
    field,
  } = props;
  const { antennas } = choices;
  const resetValue = useContext(FormResetContext);
  const { control, getValues, setValue } = useFormContext();
  const prefs = useSelector((state: any) => state.mainFrame.prefs);
  const antennaId = getValues('radios.0.antennas.0.lp_antenna_id');
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const projectId = useSelector(
    (state: RootStateOrAny) => state.mainFrame.projectId
  );
  const {
    attrName,
    label,
    getter,
    component,
    max,
    min,
    reCalculate = false,
    choicesGetter,
    show,
    afterOnChange,
    tooltip,
    warning,
    refreshesChoices,
    defaultValue,
    editable = true,
    componentProps,
  } = field;
  if (
    show != null &&
    !show({ choices, formGetter: getValues, ap, sm, formSetter: setValue })
  ) {
    return null;
  }
  let finalLabel = '';
  if (typeof label === 'function') {
    finalLabel = label({ choices, formGetter: getValues, ap, sm });
  } else if (label) {
    finalLabel = label;
  }
  let ctrl: any = [];
  let maxValue = max;
  let minValue = min;
  let derivedVal = sm != null ? get(sm, getter) : get(ap, getter);
  const warningContent =
    warning && warning({ ap, sm, choices, formGetter: getValues });
  let tooltipText =
    tooltip != null ? tooltip({ choices, formGetter: getValues, ap }) : null;
  let editableField = editable;
  if (typeof max === 'function') {
    maxValue = max({ choices, formGetter: getValues, ap });
  }
  if (typeof min === 'function') {
    minValue = min({ choices, formGetter: getValues, ap });
  }
  if (typeof editable === 'function') {
    editableField = editable({ choices, formGetter: getValues, ap });
  }
  if (typeof defaultValue === 'function') {
    derivedVal = defaultValue({ choices, ap, formGetter: getValues });
  }
  let cprops: Record<string, any>;
  if (typeof componentProps === 'function') {
    cprops = componentProps({
      ap,
      sm,
      choices,
      formGetter: getValues,
      refreshChoices: refreshChoices,
      formSetter: setValue,
    });
  } else {
    cprops = componentProps;
  }
  if (component === Form.Select) {
    let choiceList = get(choices, choicesGetter);
    if (choiceList == null) {
      choiceList = getValues(field.choicesGetter);
    }
    choiceList = makeChoices(choiceList);
    if (choiceList.length > 1) {
      ctrl.push(
        <Controller
          key={getter}
          name={getter}
          render={({ field: { onChange, value, ...rest } }) => (
            <Form.Select
              label={finalLabel}
              value={value != null ? value : derivedVal}
              options={choiceList}
              {...rest}
              disabled={!permissionWrite}
              onChange={(e, data) => {
                onChange(data.value);
                setModified(true);
                if (refreshesChoices) {
                  refreshChoices({ field });
                }
                if (afterOnChange) {
                  afterOnChange(data.value, { getValues, setValue }, choices);
                }
              }}
            ></Form.Select>
          )}
        ></Controller>
      );
    } else if (choiceList.length === 1) {
      ctrl.push(
        <CustomTextField
          key={`${field.getter}-ctext`}
          label={finalLabel}
          value={choiceList[0]['text']}
        ></CustomTextField>
      );
    }
  } else if (component === ModeledBeamwidth) {
    ctrl.push(
      <ModeledBeamwidth
        key={`${field.getter}-beamwidth`}
        ap={ap}
        choices={choices}
        field={field}
        antennaId={antennaId}
        setModified={setModified}
      ></ModeledBeamwidth>
    );
  } else if (component === Form.Checkbox) {
    ctrl.push(
      <Controller
        key={getter}
        name={getter}
        defaultValue={derivedVal}
        control={control}
        render={({ field: { onChange, value, ...rest } }) => (
          <Form.Checkbox
            label={label}
            checked={value}
            onChange={(e, { checked }) => {
              setModified(true);
              onChange(checked);
              if (refreshesChoices) {
                refreshChoices({ field });
              }
              if (afterOnChange)
                afterOnChange(checked, { getValues, setValue }, choices);
            }}
          ></Form.Checkbox>
        )}
      ></Controller>
    );
  } else if (component === GenericScaledField) {
    ctrl.push(
      <GenericScaledField
        key={getter}
        disabled={!editableField || !permissionWrite}
        {...field}
        {...cprops}
        label={finalLabel != '' ? finalLabel : field.label}
        hoverMessage={tooltipText}
        max={maxValue}
        min={minValue}
        recalc={reCalculate && modified}
        onChange={(value) => {
          if (afterOnChange) {
            afterOnChange(value, { getValues, setValue }, choices);
          }
        }}
        defaultValue={derivedVal}
        setModified={setModified}
        watch={resetValue}
        units={field.usePrefs != null ? prefs[field.usePrefs] : field.units}
      ></GenericScaledField>
    );
  } else if (component == AntennaSelection) {
    //Because of re-renders "other" is adding twice
    // so checking it before push
    if (
      choices.allow_custom_antennas &&
      antennas.filter((obj) => obj['id'] == CUSTOM_ANTENNA_OTHER.id).length ===
        0
    ) {
      antennas.push(CUSTOM_ANTENNA_OTHER);
      store.dispatch(
        setAntennaPolarities({
          polarities: choices.supported_polarities,
        })
      );
    }

    ctrl.push(
      <Controller
        key={getter}
        control={control}
        name={getter}
        render={({ field: { onChange, value, ...rest } }) => (
          <AntennaSelection
            kind={location.pathname.includes('/ap') ? 'ap' : 'sm'}
            choices={antennas}
            value={value}
            onChange={(e, data) => {
              onChange(data.value);
              setModified(true);
              if (data.value === CUSTOM_ANTENNA_OTHER.id) {
                store.dispatch(openAntennaModal(null));
              }
              if (refreshesChoices) {
                refreshChoices({ field });
              }
            }}
            values={{}}
            panels={[]}
            warning={''}
            modified={true}
          ></AntennaSelection>
        )}
      ></Controller>
    );
  } else if (component == CustomTextField) {
    ctrl.push(
      <CustomTextField
        key={`${field.getter}-ctext`}
        modified={modified}
        {...field}
        {...cprops}
      ></CustomTextField>
    );
  }
  if (attrName === 'tilt' && minValue !== maxValue && sm == null) {
    ctrl.push(
      <CalcTiltModal
        intl={intl}
        key={`${getter}-modal`}
        min={minValue}
        max={maxValue}
        modified={modified}
        values={getValues()}
        applyTiltCallback={(selectedTilt, closeHandler) => {
          const tilt = getValues('radios[0].antennas[0].tilt');
          if (parseFloat(selectedTilt) === tilt) {
            closeHandler();
          } else {
            setValue('radios[0].antennas[0].tilt', parseFloat(selectedTilt));
            const formData = getValues();
            /* if (
                  ap.site.latitude ==
                    get(formData, 'radios.0.antennas.0.latitude') &&
                  ap.site.longitude ==
                    get(formData, 'radios.0.antennas.0.longitude')
                ) {
                  set(
                    formData,
                    'radios.0.antennas.0.latitude',
                    ap.site.latitude
                  );
                  set(
                    formData,
                    'radios.0.antennas.0.longitude',
                    ap.site.longitude
                  );
                } */
            const finalData = merge(ap, formData);
            postWithAuth(
              `project/${projectId}/access_point/${ap.id}`,
              finalData,
              'PUT'
            ).then(() => {
              setModified(false);
              closeHandler();
            });
          }
        }}
      />
    );
  }
  // we need to show the field in red color when we have tilt warning,
  // other cases we display the error popup

  return (
    <Form.Field key={`${field.getter}-field`} error={warningContent === true}>
      {ctrl}
      {warningContent != null &&
        warningContent != '' &&
        warningContent != true &&
        // Always set modified to false here so that the
        // antenna warning label does not appear blue
        getWarningLabel(warningContent, false)}
    </Form.Field>
  );
}

export default AntennaField;
